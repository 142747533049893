import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Title }     from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';

import { SuccessModalComponent } from 'src/app/defaults/modals/success-modal/success-modal.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { NoshowService } from 'src/app/services/noshow/noshow.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-virtual',
  templateUrl: './dashboard-virtual.component.html',
  styleUrls: ['./dashboard-virtual.component.scss'],
  providers: [DatePipe]
})
export class DashboardVirtualComponent implements OnInit {

  public event_id = 0;
  public publish_response: any;
  public limit = 10;
  public page = 1;
  public meetingSummary = null;
  public meetingSummaryLoaded = false;

  public date = '';

  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  constructor(
    private _user: UserService,
    private router: Router,
    protected _route: ActivatedRoute,
    private eventService: EventService,
    protected modalService: BsModalService,
    private titleService: Title,
    protected noShowService : NoshowService,
    protected meetingSchedule : MeetingscheduleService,
    private datePipe: DatePipe
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });

    this.date = this.datePipe.transform(new Date(), 'hh:mm a');

    this.titleService.setTitle("Event Dashboard");
    /* if(this.eventService.eventFormData){ */
      this.eventService.getEvent(this.event_id).subscribe(
      /* this.eventService.getEvent(this.eventService.eventFormData.id).subscribe( */
        eventData => {
            this.eventService.setEventFullDetails(eventData.data);
        }
      );
      /* this.event_id = this.eventService.eventFormData.id; */
      
    /* } */
    this.getSummaryOfMeetings();
    
  }

  protected publishEvent(){
    if(this.eventService.eventFormData){
      let initialState = {
        img_src: 'publish_event_popup.png',
        title: 'Publish Event?',
        msg: 'Are you sure you want to publish this event?',
        btn_save: 'Publish Event',
        btn_close: 'Close',
        publish_event: true,
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
      // Do something with myData and then hide
      this.modalRef.hide();
      let e = this;
      setTimeout(function() {
        if(pass){
          e.eventService.publishEvent(e.eventService.eventFormData.id).subscribe(
            res => {
                e.publish_response = res;
                if(e.publish_response.error == 0){
                  /* call success modal */
                  /* let redir_url = '/admin/dashboard/'+parseInt(this.publish_response.event_id); */
                  let redir_url = '/admin';
                  let initialState = {
                    img_src: 'publish_event_success_popup.png',
                    title: e.publish_response.title,
                    msg: e.publish_response.message,
                    btn_save: 'Okay',
                    btn_close: 'Close',
                    url: redir_url,
                    type: 'add_company'
                  };
                  /* Show Success Modal */
                  e.modalRef2 = e.modalService.show(SuccessModalComponent, {initialState});
                  e.modalRef2.setClass('mw-30-per modal-dialog-centered');
                  e.modalRef2.content.closeBtnName = 'Close';
          
                  e.modalRef2.content.onClose = (myData) => {
                      e.modalRef2.hide();
                      e.router.navigate([redir_url]);
                  };
                }
            }
          );
        }
      }, 400);
        
      }
    }
  }

  protected getSummaryOfMeetings(){
    let args = {
      'event_id' : this.event_id,
      'limit' : this.limit,
      'page' : this.page,
    }
    this.meetingSummaryLoaded = false;
    this.meetingSummary = null;
    this.eventService.getSummaryOfMeetings(args).subscribe(
      r => {
        this.meetingSummaryLoaded = true;
        this.meetingSummary = r;
      }
    );
  }

  protected createRange(number){
    var items: number[] = [];
    for(var i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }

  protected meetingsummarytNavigateTo(page){
    this.page = page;
    this.getSummaryOfMeetings();
  }
  

}
