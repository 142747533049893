export const Urls = {
    admin_forgotpass: '/api/admin/forgotpassword',
    admin_login: '/api/admin/login',
    admin_checkpass: '/api/admin/checkpassword',
    api_admin_delete: '/api/admin/delete',
    api_admin_get: '/api/admin/get',
    api_admin_save: '/api/admin/save',
    api_admin_sendcredential: '/api/admin/sendcredential',
    api_admin_availablemanager: '/api/admin/availablemanager',

    api_agenda_availabletime: '/api/agenda/availabletime',
    api_agenda_get: '/api/agenda/get',
    api_agenda_save: '/api/agenda/save',
    api_agenda_delete: '/api/agenda/delete',

    api_print_print: '/api/print/print',

    api_attributes_get: '/api/attributes/get',
    api_company_get: '/api/company/get',
    api_company_save: '/api/company/save',
    api_company_delete: '/api/company/delete',

    api_cron_printmeetingsched: '/api/cron/printmeetingsched',

    api_delegates_delete: '/api/delegates/delete',
    api_delegates_get: '/api/delegates/get',
    api_delegates_geteventavailabledelegate: '/api/delegates/geteventavailabledelegate',
    api_delegates_isexist: '/api/delegates/isexist',
    api_delegates_save: '/api/delegates/save',
    api_delegates_sendcredential: '/api/delegates/sendcredential',
    api_delegates_senditinerary: '/api/delegates/senditineraries',

    api_downloadable_checkmeetingschedule: '/api/downloadable/checkmeetingschedule',
    api_downloadable_meetingsched: '/api/downloadable/meetingsched',

    api_eventdelegate_delete: '/api/eventdelegate/delete',
    api_eventdelegate_get: '/api/eventdelegate/get',
    api_events_adddelegate: '/api/events/adddelegate',
    api_events_archive: '/api/events/archive',
    api_events_delete: '/api/events/delete',
    api_events_save: '/api/events/save',
    api_events_get: '/api/events/get',
    api_events_publish : '/api/events/publish',
    api_events_gettimezone : '/api/events/gettimezone',

    api_meetingschedule_availablebytimeslot: '/api/meetingschedule/availablebytimeslot',
    api_meetingschedule_cancel : '/api/meetingschedule/cancel',
    api_meetingschedule_deletetimeslot : '/api/meetingschedule/deletetimeslot',
    api_meetingschedule_generate : '/api/meetingschedule/generate',
    api_meetingschedule_get : '/api/meetingschedule/get',
    api_meetingschedule_statusupdate: '/api/meetingschedule/statusupdate',
    api_meetingschedule_getagendaandtimeslot: '/api/meetingschedule/getagendaandtimeslot',
    api_meetingschedule_getmeetingsummary : '/api/meetingschedule/getmeetingsummary',

    api_meetingschedule_getrequest : '/api/meetingschedule/getrequest',

    api_meetingschedule_requestactions : '/mapi/delegate/meetingrequestaction',
    api_delegates_removebusinesscard: '/mapi/delegate/removebusinesscard',
    api_delegate_meetingitinerary: '/mapi/delegate/meetingitinerary',
    api_delegates_senditineraries: '/api/delegates/senditineraries',

    api_noshowreport_get: '/api/noshowreport/get',
    api_noshowreport_approve: '/api/noshowreport/approve',
    api_noshowdelegate_display : '/api/noshowdelegate/display',
    api_noshowreport_disapprove: '/api/noshowreport/disapprove',
    api_cancellationrequest_get: '/api/cancellationrequest/get',
    api_noshowdelegates_get: '/api/noshowdelegates/get',
    api_noshowreportbydelegates_get: '/api/noshowreportbydelegates/get',

    api_meetingschedule_notcancel : '/api/meetingschedule/notcancel',
    api_meetingschedule_set : '/api/meetingschedule/set',
    api_available_timeslots : '/api/meetingschedule/gettimeslots',

    // enhancements
    api_available_timeslots_virtual : '/api/meetingschedule/gettimeslotsvirtual',
    api_meetingschedule_setvirtual : '/api/meetingschedule/setvirtual',
    api_meetingschedule_generatevirtual : '/api/meetingschedule/generatevirtual',
    api_meetingschedule_getvirtual : '/api/meetingschedule/getvirtual',
    api_events_adddelegatevirtual: '/api/events/adddelegatevirtual',
    api_events_getvirtual: '/api/events/getvirtual',

    api_preferences_get: '/api/preferences/get',
    api_preferences_save: '/api/preferences/save',
    api_preferences_delete: '/api/preferences/delete',
    api_get_states : '/api/states/get',

    api_speaker_delete: '/api/speaker/delete',
    api_speaker_get: '/api/speaker/get',
    api_speaker_save: '/api/speaker/save',

    api_sponsor_delete: '/api/sponsor/delete',
    api_sponsor_get: '/api/sponsor/get',
    api_sponsor_save: '/api/sponsor/save',

    api_sponsorlevel_delete: '/api/sponsorlevel/delete',
    api_sponsorlevel_get: '/api/sponsorlevel/get',
    api_sponsorlevel_save: '/api/sponsorlevel/save',
    api_sponsorlevel_savesort: '/api/sponsorlevel/savesorting',

    api_tables_save: '/api/tables/save',
    api_tables_get: '/api/tables/get',
    api_tables_tablecount: '/api/tables/tablecount',
    api_tables_assign: '/api/tables/assign',
    api_tables_removedelegate: '/api/tables/removedelegate',
    api_tables_availabledelegate: '/api/tables/availabledelegate',
    api_tables_delete: '/api/tables/delete',

    api_csv_savedata: '/api/csv/savedata',
    api_csv_download: '/api/csv/download',

    api_feedbacks_get: '/api/feedback/get',

    api_statistics_companies : '/api/statistics/companies',
    api_statistics_delegates : '/api/statistics/delegates',
    api_statistics_tables : '/api/statistics/tables',
    api_statistics_sessionmessage : '/api/statistics/sessionmessage',

    api_faqs_get : '/api/faqs/get',
    api_faqs_save : '/api/faqs/save',
    api_faqs_delete : '/api/faqs/delete',
    api_faqs_getcategory : '/api/faqs/getcategory',
    api_faqs_savecategory : '/api/faqs/savecategory',
    api_faqscategory_delete : '/api/faqscategory/delete',
    api_faqscategory_savesort : '/api/faqscategory/savesort',

    api_notification_getadminnotif : '/api/notification/getadminnotif',
    api_notification_readadminnotif : '/api/notification/readadminnotif',

    api_timeslot_block : '/api/timeslot/block',
    api_timeslot_unblock : '/api/timeslot/unblock',

    // enhancements zoom
    api_virtualconference_createzoomaccount : '/mapi/virtualconference/createzoomaccount',
    api_virtualconference_setmeetingschedule : '/mapi/virtualconference/setmeetingschedule',
    api_virtualconference_getzoomaccount : '/mapi/virtualconference/getzoomaccount',
    api_virtualconference_getzoomapibalance : '/mapi/virtualconference/getzoomapibalance',
    api_virtualconference_setzoomsettings : '/mapi/virtualconference/setzoomsettings',
    api_virtualconference_getzoomsettings : '/mapi/virtualconference/getzoomsettings',
    api_virtualconference_shortenurl : '/mapi/virtualconference/shortenurl',
    get_token: '/api/token/get',
}
