import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 

/* Model */
import { TableCount, Tables } from 'src/app/lib/models/tables/tables';

/* Library */
import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  public tableCounts: TableCount = {
    vip_table_count: 0,
    booth_table_count: 0,
    regular_table_count: 0,
    total_table_count: 0
  }

  public table_type = {
    vip : 1,
    booth : 2,
    regular : 3,
  }

  public suggestedTableCount: number = 0;
  public httpOptions = this.env.getHttpOptions();

  public showiInadequateTable : boolean = false;

  constructor(
    private http:HttpClient,
    private env: environment
  ) { }


  /* this will save the number per delegate classification (VIP, Booth, & Regular) */
  saveTables(event_id):Observable<any>{
    let url = this.env.getUrl(Urls.api_tables_save);

    const formData = new FormData();
    for (let propt in this.tableCounts) {
      formData.append(propt,this.tableCounts[propt]);
    }
    formData.append('event_id',event_id);

    return this.http.post(url, formData, this.httpOptions).pipe(share())
  }

  setSuggestedTableCount(suggestedCount){
    this.suggestedTableCount = Math.round(suggestedCount / 2);
    
  }

  getTables(data):Observable<any>{
    let url = this.env.getUrl(Urls.api_tables_get);
    url += this.env.createUrlParam(data);

    return this.http.get<any>(url, this.httpOptions);
  }

  getTableCount(data){
    let url = this.env.getUrl(Urls.api_tables_tablecount);
    url += this.env.createUrlParam(data);

    return this.http.get<any>(url, this.httpOptions);
  }

  getDelegateList(data){
    let url = this.env.getUrl(Urls.api_tables_availabledelegate);
    url += this.env.createUrlParam(data);

    return this.http.get<any>(url, this.httpOptions);
  }

  assignDelegate(delegate_id,table_id,event_id = null){
    let url = this.env.getUrl(Urls.api_tables_assign);
    
    const formData = new FormData();
    formData.append('table_id',table_id);
    formData.append('delegate_id',delegate_id);
    formData.append('event_id',event_id);
    return this.http.post<any>(url, formData, this.httpOptions);
  }

  removeDelegate(delegate_id,table_id){
    let url = this.env.getUrl(Urls.api_tables_removedelegate);
    
    const formData = new FormData();
    formData.append('table_id',table_id);
    formData.append('delegate_id',delegate_id);
    return this.http.post<any>(url, formData, this.httpOptions);
  }

  deleteSelectedTable(data){
    let url = this.env.getUrl(Urls.api_tables_delete);
    
    const formData = new FormData();
    formData.append('table_ids',data.ids);
    formData.append('event_id',data.event_id);
    formData.append('type',data.type);

    return this.http.post<any>(url, formData, this.httpOptions);
  }
}
