import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {

  img_src: string;
  title: string;
  msg: string;
  btn_save: string;
  btn_close: string;
  onClose: any;
  url: any;
  type: any;

  add_company
  constructor() { }

  ngOnInit() {
  }

  closeModal(){
    this.onClose();
  }

  submitModal(){
    if(this.type == 'add_company'){
      this.onClose('addOtherCompany');
    }else if(this.type == 'archived_event'){
      this.onClose('archiveEvent');
    }
  }
}
