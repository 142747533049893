import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';
import { SponsorsService } from 'src/app/services/event/sponsors.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddEditSponsorComponent } from 'src/app/defaults/modals/add-edit-sponsor/add-edit-sponsor.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { Caret } from 'src/app/lib/caret';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { EditSponsorlevelComponent } from 'src/app//defaults/modals/edit-sponsorlevel/edit-sponsorlevel.component';

import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-sponsor-list-virtual',
  templateUrl: './sponsor-list-virtual.component.html',
  styleUrls: ['./sponsor-list-virtual.component.scss']
})
export class SponsorListVirtualComponent implements OnInit {

  subscribeSearch = new Subject<string>();

  modalRef: BsModalRef;
  protected searchReadOnly = false;
  
  protected skeleton_sponsor = [
    { id: '1'},
    { id: '2'},
    { id: '3'}
  ];   

	protected sponsors: any = null;
  protected total_sponsor = 0;
  protected sponsorsLoading : any = true;
  protected sponsorsFields: any;
	protected sponsorslevel: any = null;
	protected sponsorslevelLoading : any = true;
  public event_id = 0;
  public max_level = 0;

  protected new_level: any;
  protected search = '';
  protected sort = 'id';

  constructor(
    protected sponsorsService: SponsorsService,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected _user: UserService,
    protected modalService: BsModalService,
    protected messageService: MessageService,
    protected eventService : EventService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.getSponsor();
      });
  }

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
    this.titleService.setTitle("Event Sponsors");
    this.getSponsor();
    this.getSponsorLevel();
  }

	/*-------------------------------------
		get all the sponsors for the specif event
	 -------------------------------------*/
	getSponsor(){
    this.sponsorsLoading = true;
		this.sponsorsService.getSponsor(this.event_id, 0, this.search, this.sort)
			.subscribe(data => {
        console.log('sponsors data',data);
        if(data['error'] == '0'){
          this.sponsorsLoading = false;
          this.sponsors = data.data.datas;
          this.total_sponsor = data.data.total_sponsor_count;
          this.sponsorsFields = data.data.sort_fields;
        }
			});
  }

	/*-------------------------------------
		get all the sponsor level for the specif event
	 -------------------------------------*/
  getSponsorLevel(){
    this.sponsorslevelLoading = true;
		this.sponsorsService.getSponsorLevel(this.event_id, 0)
			.subscribe(data => {
        if(data['error'] == 0){
          this.sponsorslevelLoading = false;
          this.sponsorslevel = data.data;
          this.getSponsor();
          if(this.sponsorslevel){
            this.max_level = parseInt(this.sponsorslevel[0].level);
          }
        }
			});
  }

  drop(event: CdkDragDrop<string[]> ) {
    moveItemInArray(this.sponsorslevel, event.previousIndex, event.currentIndex);

    let args = {
      'event_id' : this.event_id,
      'levels' : JSON.stringify(this.sponsorslevel)
    }

    this.sponsorsService.saveLevelSorting(args).subscribe( r => {
      this.getSponsorLevel();
    });
    /* this.saveLevel(this.sponsorslevel[event.previousIndex].name, this.sponsorslevel[event.previousIndex].id); */
  }

  makeUp(level, index, prev, next){
    console.log('up',index);
    console.log('prev',prev);
    console.log('next',next);
    console.log(this.sponsorslevel[prev].level);
    /* -----------------------------
     Switch the Level of Sponsor Level
    --------------------------------*/
    this.saveLevel(this.sponsorslevel[prev].name, this.sponsorslevel[prev].id, level.level, false);
    this.saveLevel(level.name, level.id, this.sponsorslevel[prev].level);
    //this.sponsorslevel.splice(index, 0, level);
  }

  makeDown(level, index, prev, next){
    console.log('down',index);
    console.log('prev',prev);
    console.log('next',next);
    console.log(this.sponsorslevel[next].level);

    /* -----------------------------
     Switch the Level of Sponsor Level
    --------------------------------*/
    this.saveLevel(this.sponsorslevel[next].name, this.sponsorslevel[next].id, level.level, false);
    this.saveLevel(level.name, level.id, this.sponsorslevel[next].level);
  }
  
  searchList(){
    this.getSponsor();
  }

  sortChange(){
    this.getSponsor();
  }

	openModal(sponsor=null){
    if(this.sponsorslevel){
      if(this.event_id){

        let initialState = {
          event_id: this.event_id,
          sponsor: sponsor,
          sponsorslevel: this.sponsorslevel
        }
    
        this.modalRef = this.modalService.show(AddEditSponsorComponent, {initialState});
        this.modalRef.content.closeBtnName = 'Close';
        this.modalRef.setClass('modal-lg modal-dialog-centered');
        this.modalRef.content.onClose = (myData) => {
          this.modalRef.hide();
          // get Updated Sponsor List
          this.getSponsor();
        };
      }
    }else{
      let m = {
        type: 'danger',
        msg: 'Please add sponsor\'s level first.',
      }

      this.messageService.add(m);
    }
  }
  
  removeSponsor(sponsor=null){
    if(sponsor){
      this.searchReadOnly = true;
      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Sponsor?',
        msg: 'This will remove all the information related to this sponsor both in the dashboard and mobile app.',
        btn_save: 'Delete',
        btn_close: 'Close',
        url: '/admin/sponsor/'+this.event_id
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        this.searchReadOnly = false;
  
        console.log('pass',pass);
        if(pass){
          this.sponsorsService.removeSponsor(sponsor.id).subscribe(
            result => {
              let m = {
                type: 'danger',
                msg: result.message,
              }
            
              if(result.error == 0){
                m.type = 'success';
                this.messageService.add(m);
                this.getSponsor();
                
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

  /*-------------------------------
    Management of Sponsor level
   --------------------------------*/
  saveLevel(name= null, id= 0, level= 0, message= true){
    if(this.event_id){
      // stop here if form is invalid
      console.log('this.new_level',this.new_level);
      if(!name){
        name = this.new_level;
      }

      if (name) {
        let thisData = {
          'id': id,
          'event_id': this.event_id,
          'name': name.trim(),
          'level': this.max_level + 1
        };

        if(level > 0){
          thisData['level']= level;
        }

        this.sponsorsService.saveLevel(thisData).subscribe(data => {
          this.new_level = '';
          this.saveHelper(data, message);
        });
      }else{
        let m = {
          type: 'danger',
          msg: 'Please enter sponsor\'s level.',
        }
        this.messageService.add(m);
      }
    }
  }

  editSaveLevel(level){
    let initialState = {
      sponsorLevel: level,
		title: 'Meeting Details'
    };

    console.log(initialState);
	
		// Show Popup Modal
		this.modalRef = this.modalService.show(EditSponsorlevelComponent, {initialState});
		this.modalRef.content.closeBtnName = 'Close';
		this.modalRef.setClass('modal_md modal-dialog-centered');
	
		this.modalRef.content.onClose = () => {
			// Do something with myData and then hide
			this.modalRef.hide();
		}

		this.modalRef.content.onSaveChanges = (name, id, level, message) => {
      this.saveLevel(name, id, level, message);
			this.modalRef.hide();
		}

  }

	saveHelper(data, message){
    let m = {
      type: 'danger',
      msg: data.message,
    }
    
		if(data.error == 0){
      m.type = 'success';
      if(message){
        this.messageService.add(m);
      }
      this.getSponsorLevel();

    }else{
      if(message){
        this.messageService.add(m);
      }
    }
  }
  
  toEditable(elId: number){
		let eId = 'level_' + elId;
		let e = document.getElementById(eId);
		this.setCaretPosition(e);
	}

	/* 
	*	set the editable content caret postion
	*/
	setCaretPosition(e){
		let caret = new Caret(e);
		let text = e.textContent;
		caret.placeCaretAtEnd(e);
	}
  
	toViewAction(elId: number, name, action){
		let eId = name +''+ elId;
		document.getElementById(eId).style.display = action;
	}

  removeSponsorLevel(level=null){
    if(level){
      this.searchReadOnly = true;
      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Sponsor Level?',
        msg: 'This will remove all the information related to this sponsor level both in the dashboard and mobile app.',
        btn_save: 'Delete',
        btn_close: 'Close',
        url: '/admin/sponsor/'+this.event_id
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        this.searchReadOnly = false;
        if(pass){
          this.sponsorsService.removeSponsorLevel(level.id).subscribe(
            result => {
              console.log(result);
              let m = {
                type: 'danger',
                msg: result.message,
              }
            
              if(result.error == 0){
                m.type = 'success';
                this.messageService.add(m);
                this.getSponsorLevel();
                this.getSponsor();
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

}
