import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PrefService } from 'src/app/services/preferences/pref.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { UserService } from 'src/app/services/user.service';

import { SuccessModalComponent } from 'src/app/defaults/modals/success-modal/success-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';
import { Local } from 'protractor/built/driverProviders';

import { Location } from '@angular/common';

@Component({
  selector: 'app-company-add-edit',
  templateUrl: './company-add-edit.component.html',
  styleUrls: ['./company-add-edit.component.scss']
})
export class CompanyAddEditComponent implements OnInit {
  autoCompleteCountry = new Subject<string>();
  autoCompleteState = new Subject<string>();
  autoCompleteSectors = new Subject<string>();
  autoCompleteNetworks = new Subject<string>();

  company_id = 0;
  event_id = 0;
  company: any;
  country_limit = 239;

  modalRef: BsModalRef;
  
  companyForm: FormGroup;
  submitted = false;

  keyword_country = '';
  keyword_sector = '';
  keyword_network = '';
  state_search_key = '';

  showCountry = false;
  showSector = false;
  showNetwork = false;
  showStates = false;
  states:any = [];

  protected limit = 10;
	protected search = '';
	protected sort = '';
  protected direction = '';

  is_outside = false;

  closeaddcompany = false;
  
	focusedField = {
		name: false,
    country_id: false,
    state: false,
		pref_sector_id: false,
		pref_network_id: false,
    membershipstatus: false,
    website: false,
	};

  constructor(
    protected formBuilder: FormBuilder,
    protected prefService: PrefService,
    protected companyService: CompanyService,
    protected messageService: MessageService,
    private _user: UserService,
    protected modalService: BsModalService,
    public router: Router,
    private _route: ActivatedRoute,
    protected _location: Location,
    private titleService: Title
  ) { 
      this._user.requireLogIn();

      this._route.paramMap.subscribe( url_param =>{
        this.company_id = + url_param.get('company_id');
        this.event_id = + url_param.get('event_id');
        if(this.company_id){
          this.getCompany();
        }
      });

      this.autoCompleteCountry.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(value => {
          this.getCountries();
        });

      this.autoCompleteState.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(value => {
          this.state_search_key = value;
          this.loadStates();
        });

      this.autoCompleteSectors.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(value => {
          this.getSectors();
        });

      this.autoCompleteNetworks.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(value => {
          this.getNetworks();
        });
    } 

  ngOnInit() {

    this.loadForm();

    // get countries
    this.getCountries();

    // get networks
    this.getSectors();

    // get sectors
    this.getNetworks();

    if(this.router.url.match(/add.*/)){
      this.titleService.setTitle("Add an Event's Delegate");
    } else {
      this.titleService.setTitle("Add a Company");
    }

  }

  loadStates(){
    let data = {
      'country_id' : this.companyForm.controls['country_id'].value,
      'search_key' : this.state_search_key,
    };
    this.prefService.getStates(data).subscribe( r => {
      this.states = r.data;
    });
  }

  getCountries(){
    this.companyForm.controls['country_id'].setValue(null);
    let url = this.prefService.getSetUrlParams('countries', 1, this.country_limit, this.keyword_country);
    this.sendRequest(url, 'countries');
  }

  getSectors(){
    this.companyForm.controls['pref_sector_id'].setValue(null);
    let url = this.prefService.getSetUrlParams('sectors', 1, this.limit, this.keyword_sector);
    this.sendRequest(url, 'sectors');
  }

  getNetworks(){
    this.companyForm.controls['pref_network_id'].setValue(null);
    let url = this.prefService.getSetUrlParams('networks', 1, this.limit, this.keyword_network);
    this.sendRequest(url, 'networks');
  }

  getCompany(){

    let thisData = {
      id: this.company_id
    }

    this.companyService.getCompany(thisData).subscribe(data => {
      if(data['error'] == 0){
        this.company = data.data;
        this.titleService.setTitle("Edit Company " + this.company.name);
        this.loadForm();
      }
    });
  }

  	
  loadForm(){
    let facebook_pattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/;
    let twitter_pattern = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/;
    let linkedln_pattern = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;

    let website_pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    
    this.companyForm = this.formBuilder.group({
      id: [this.company ? this.company.id : '0'],
      name: [this.company ? this.company.name : '', [Validators.required]],
      description: [this.company ? this.company.description : ''],
      country_id: [this.company ? this.company.country_id : '', Validators.required],
      state: [this.company ? this.company.state : ''],
      municipality: [this.company ? this.company.municipality : ''],
      zipcode: [this.company ? this.company.zipcode : ''],
      address: [this.company ? this.company.address : ''],
      website: [this.company ? this.company.website : '', [Validators.pattern(website_pattern)]],
      pref_sector_id: [this.company ? this.company.pref_sector_id : ''],
      phoneNumber: [this.company && this.company.social_media_links ? this.company.social_media_links.phoneNumber : '' , [Validators.pattern('[0-9]+')]],
      pref_network_id: [this.company ? this.company.pref_network_id: ''],
      membershipstatus: [this.company ? this.company.membershipstatus : ''],
      facebook: [this.company && this.company.social_media_links ? this.company.social_media_links.facebook : '', [Validators.pattern(facebook_pattern)]],
      linkedln: [this.company && this.company.social_media_links ? this.company.social_media_links.linkedln : '', [Validators.pattern(linkedln_pattern)]],
      whatsapp: [this.company && this.company.social_media_links ? this.company.social_media_links.whatsapp : ''],
      twitter: [this.company && this.company.social_media_links ? this.company.social_media_links.twitter : '', [Validators.pattern(twitter_pattern)]],
      kakao: [this.company && this.company.social_media_links ? this.company.social_media_links.kakao : ''],
      wechat: [this.company && this.company.social_media_links ? this.company.social_media_links.wechat : '']
  }, { });

    if(this.company){
      this.keyword_country = this.company.pref_country_nicename;
      this.keyword_sector = this.company.pref_sector_name;
      this.keyword_network = this.company.pref_network_name;
      this.companyForm.controls['country_id'].setValue(this.company.country_id); 
    }
    this.loadStates();
  }

  get f() { return this.companyForm.controls; }

  saveCompanyInfo() {
    /* console.log(this.companyForm); */
    this.submitted = true;
		this.focusedField.name = true;
    this.focusedField.country_id = true;
    this.focusedField.state = true;
		this.focusedField.pref_sector_id = true;
    this.focusedField.pref_network_id = true;
    this.focusedField.membershipstatus = true;
    this.focusedField.website = true;

    // Set the social media links to object
    let social_media_links = {
      'phoneNumber': this.companyForm.value['phoneNumber'],
      'facebook': this.companyForm.value['facebook'],
      'linkedln': this.companyForm.value['linkedln'],
      'whatsapp': this.companyForm.value['whatsapp'],
      'twitter': this.companyForm.value['twitter'],
      'kakao': this.companyForm.value['kakao'],
      'wechat': this.companyForm.value['wechat']
    };

    // convert social media links to json encode
    this.companyForm.value['social_media_links'] = JSON.stringify(social_media_links);

    /* console.log(this.del); */
    // stop here if form is invalid
    if (this.companyForm.valid) {
      this.companyService.save(this.companyForm.value).subscribe(data => {
        this.saveHelper(data);
      });
    }
  }
  
	saveHelper(data){
    /* console.log('nextUrl',this.nextUrl); */

    let m = {
      type: 'danger',
      msg: data.message,
    }
    
		if(data.error == 0){

      let initialState = {};
      if(this.company_id){
        initialState = {
          img_src: 'success-popup.png',
          title: 'Company Updated',
          msg: 'The company has been successfully updated. You can now view the changes of this company. ',
          btn_close: 'Close',
          url: this.nextUrl,
          type: 'add_company'
        };
      }else{
        initialState = {
          img_src: 'success-popup.png',
          title: 'New company added',
          msg: 'New company has been successfully added. You can now start ading delegates of this company. ',
          btn_save: 'Add another company',
          btn_close: 'Close',
          url: this.nextUrl,
          type: 'add_company'
        };
        this.closeaddcompany = true;
      }

      /* show modal if the add company was not in delegate add form */
      if(!this.del){
        // Show Popup Modal
        this.modalRef = this.modalService.show(SuccessModalComponent, {initialState});
        this.modalRef.setClass('mw-30-per modal-dialog-centered');
        this.modalRef.content.closeBtnName = 'Close';

        this.modalRef.content.onClose = (myData) => {
          // Do something with myData and then hide
          this.modalRef.hide();
          if(myData == 'addOtherCompany'){
            // Clear the form fields
            this.clearForm();
            this.closeaddcompany = false;
          }else{
            this.router.navigate([this.nextUrl]);
          }
        };
      } else {
        console.log(this.router.url);
        this.del.company_data = data.data;
        this.del.company_id = data.company_id;
        this.del.company = data.company;

        m.type = 'success';

        setTimeout(function(){
          document.getElementById("delegate-form-fields").scrollIntoView();
        }, 200);

        this.delegateForm.controls.delegateCompany.setValue(this.del.company);
        this.delegateForm.controls.delegateCompanyId.setValue(this.del.company_id);
        this.messageService.add(m);

        this.backClicked();

      }
    }else{
      this.messageService.add(m);
      this.sort = '';
      this.direction = '';
    }
  }
  
  clearForm(){
    this.submitted = false;
    this.focusedField.name = false;
    this.focusedField.state = false;
		this.focusedField.country_id = false;
		this.focusedField.pref_sector_id = false;
    this.focusedField.pref_network_id = false;
    this.focusedField.membershipstatus = false;
    this.focusedField.website = false;
    this.companyForm.reset();
    this.keyword_country = '';
    this.keyword_sector = '';
    this.keyword_network = '';
    // get countries
    this.getCountries();

    // get networks
    this.getSectors();

    // get sectors
    this.getNetworks();
  }

  backClicked() {
    if(this.router.url.match(/add.*/)){
      this.clearForm(); 
    } else {
      this.clearForm();
      this._location.back();
    }
  }

  focusInField(fieldname: string){
    let a = this;
    if(fieldname == 'country'){
      a.showCountry = true;
    }else if (fieldname == 'sector'){
      a.showSector = true;
    }else if (fieldname == 'network'){
      a.showNetwork = true;
    }else if (fieldname == 'state'){
      a.showStates = true;
    }

  }
  
  focusOutField(fieldname: string, controlName){
		this.focusedField[controlName] = true;
    let a = this;
    setTimeout(function(){
      if(fieldname == 'country'){
        a.showCountry = false;
      }else if (fieldname == 'sector'){
        a.showSector = false;
      }else if (fieldname == 'network'){
        a.showNetwork = false;
      }else if (fieldname == 'state'){
        a.showStates = false;
      }
    }, 200);
  }

  sendRequest(url: string, type: string){
    this.prefService.getData(url, type)
        .subscribe(data => {this.prefService.getHelper(data, type)});
  }

  setValue(displayName, value, controlName, variableName){
    this.companyForm.controls[controlName].setValue(value);
    if(variableName == 'country'){
      this.keyword_country = displayName;
      this.loadStates();
    }else if (variableName == 'sector'){
      this.keyword_sector = displayName;
    }else if (variableName == 'network'){
      this.keyword_network = displayName;
    }
  }

  @Input() del: any = false;
  @Input() delegateForm: any = true;
  @Input() selectedCompany: any = false;
  @Input() displaySocialMedia = true;
  
	@Input() nextUrl: any;
	@Input() backUrl: any;
}
