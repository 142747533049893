import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { EventManagerService } from 'src/app/services/users/event-manager.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddEditEventmanagerComponent } from 'src/app/defaults/modals/add-edit-eventmanager/add-edit-eventmanager.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { ProfileEventmanagerComponent } from 'src/app/defaults/modals/profile-eventmanager/profile-eventmanager.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { ImportCsvService } from 'src/app/services/import-csv.service';
import { ImportCsvComponent } from 'src/app/defaults/import-csv/import-csv.component'; 
import { EventService } from 'src/app/services/event/event.service';


@Component({
  selector: 'app-event-manager-list',
  templateUrl: './event-manager-list.component.html',
  styleUrls: ['./event-manager-list.component.scss']
})
export class EventManagerListComponent implements OnInit {

  @ViewChild(ImportCsvComponent ) child: ImportCsvComponent ; 
  subscribeSearch = new Subject<string>();

  @Input() importMessages: any;

  modalRef: BsModalRef;
  protected searchReadOnly = false;

  protected skeleton_manager = [
    { id: '1'},
    { id: '2'},
    { id: '3'}
  ];   

	protected eventManager: any = null;
	protected eventManagerLoading: any = true;
  protected fileIsLoaded = false;
  protected isDisplayPopup = false;
  protected event_id = 0;
  
  protected search = '';
  
  constructor(
    private _user: UserService,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected eventManagerService: EventManagerService,
    protected modalService: BsModalService,
    protected eventService : EventService,
    protected messageService: MessageService,
    protected importCsv: ImportCsvService,
  ) { 
    this._user.requireLogIn();

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.getEventManager();
      });
  }

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );

    this.getEventManager();
  }

	/*-------------------------------------
		get all the event manager for the specif event
	 -------------------------------------*/
   getEventManager(id= null){

    let param = {
      'event_id': this.event_id,
      'search': this.search
    };
    
    if(id){
      param['id'] = id;
    }
    
    this.eventManagerLoading = true;
		this.eventManagerService.getEventManager(param)
			.subscribe(data => {
        /* console.log('data',data); */
        if(data['error'] == 0){
          this.eventManagerLoading = false;
          this.eventManager = data.data;
        }
			});
  }

  searchList(){
    this.getEventManager();
  }
  
	openModal(manager=null){
    let initialState = {
      event_id: this.event_id,
      eventManager: manager
    }
    console.log(manager);

    this.modalRef = this.modalService.show(AddEditEventmanagerComponent, {initialState});
    this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
    };

    this.modalRef.content.formSubmitted = (myData) => {
      this.modalRef.hide();
      this.getEventManager();
    };
  }

  /* removeEventManager(manager=null){
    
  } */

	openProfile(manager=null){

    let initialState = {
      event_id: this.event_id,
      eventManager: manager
    }

    this.modalRef = this.modalService.show(ProfileEventmanagerComponent, {initialState});
    this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
      if(myData == 'edit'){
        this.openModal(manager);

      }else if(myData == 'send_credential'){
        this.sendCredential(manager.id);

      }else if(myData == 'remove'){
        this.removeEventManager(manager);
      }
      
      // get Updated Speaker List
      this.getEventManager();
    };
  }

  sendCredential(manager_id){
    this.eventManagerService.sendCrentials(manager_id);
  }

  removeEventManager(manager=null){
    if(manager){
      this.searchReadOnly = true;
      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Event Manager?',
        msg: 'This will remove all the information related to this event manager both in the dashboard and mobile app.',
        btn_save: 'Delete',
        btn_close: 'Close',
        url: '/admin/dashboard/eventmanagers/'+this.event_id
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        this.searchReadOnly = false;
        if(pass){
          this.eventManagerService.removeEventManager(manager.id, this.event_id).subscribe(
            result => {
              let m = {
                type: 'danger',
                msg: result.message,
              }
            
              if(result.error == 0){
                m.type = 'success';
                this.messageService.add(m);
                this.getEventManager();
                
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

  protected downloadCsvTemplate(type : any = ''){
    this.importCsv.downloadCsvTemplate(type, this.event_id).subscribe(
      res => {
        if(res && res._body){
          /* console.log(res._body); */
          this.child.downloadFile(res._body,"import_event_manager_template.csv");
        }
      }
    );
  }

  protected saveCsvData(type: any = ''){
    /* 
    * "type" 
    * variable is for the identification of function to be call in serverside or API 
    */
    this.importCsv.saveCSVFileData(type, this.event_id).subscribe(
      response => {
        let m = {
          type: '',
          msg: '',
        }

        if(response.error != 0){
          m.type = 'danger';
        }else{
          m.type = 'success';
        }
        m.msg = response.message;

        this.messageService.add(m);

        this.importMessages = response.error_messages;
        this.fileIsLoaded = false;
        this.isDisplayPopup = false;
        this.getEventManager();
      }
    );
  }

}


