import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
/* services */
import { TableService } from 'src/app/services/tables/table.service';
import { UserService } from '../../../../services/user.service';
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/defaults/message.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';

/* Model */
import { Tables } from 'src/app/lib/models/tables/tables';

@Component({
  selector: 'app-booth-vip-list',
  templateUrl: './booth-vip-list.component.html',
  styleUrls: ['./booth-vip-list.component.scss']
})
export class BoothVipListComponent implements OnInit {

  onSearchStopTyping = new Subject<string>();
  @Input() tables: Tables[] ;
  @Input() isDataLoaded = false;
  @Input() VIPTable_IDs_to_del:number[] = [];
  @Input() BoothTable_IDs_to_del:number[] = [];
  @Output() updateTableList : EventEmitter<any> = new EventEmitter();

  @Output() setVIPTableIDs : EventEmitter<any> = new EventEmitter();
  @Output() setBoothTableIDs : EventEmitter<any> = new EventEmitter();
  

  modalRef: BsModalRef;
  protected active_row_id;
  protected event_id = 0;
  protected delegates_list:any;
  protected isLoadedDelegateList = false;
  protected searchKey = '';
  

  protected page = 1;
  protected sort = 'table_no';
  protected direction = 'asc';

  constructor(
    protected tableService: TableService,
    protected router: Router,
    protected modalService: BsModalService,
    protected eventService : EventService,
    protected messageService: MessageService,
    protected _route: ActivatedRoute
  ) {

    this.onSearchStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => { 
      this.searchKey = value;
      this.showDelegateList(this.active_row_id); 
    });

   }

  ngOnInit() {
    /* get the url parameters */
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });
  }

  protected showDelegateList(table_id){

    if(table_id != this.active_row_id){
      this.searchKey = "";
    }

    this.delegates_list = null;
    this.isLoadedDelegateList = false;
    this.active_row_id = table_id;
    let thisParam = {
      'event_id' : this.event_id,
      'search_key' : this.searchKey
    }
    this.tableService.getDelegateList(thisParam).subscribe(
      delegate => {
        this.delegates_list = delegate.data;
        this.isLoadedDelegateList = true;
        console.log(this.delegates_list);
      }
    );
  }

  protected hideDelegateList(table_id){
    let a = this;
    setTimeout(function() {
      if(table_id == a.active_row_id){
        a.active_row_id = 0;
      }
    }, 200);
  }
  
  protected assignDelegate(delegate_id,table_id){
    this.tableService.assignDelegate(delegate_id,table_id,this.event_id).subscribe(
      response => {
        this.updateTableList.emit();
      }
    );
  }

  protected sortTables(sort){
    this.sort = sort;
    
    if(this.direction == 'asc'){
      this.direction = 'desc';
    }else{
      this.direction = 'asc';
    }
    this.updateTableContent();
  }

  protected updateTableContent(){
    let data = {
      'page' : this.page,
      'sort' : this.sort,
      'direction': this.direction
    }

    this.updateTableList.emit(data);
  }

  protected navigateTo(page){
    this.page = page;
    this.updateTableContent();
  }

  protected jumpTo(e){
		this.navigateTo(e.target.value);
  }


  protected removeDelegate(delegate_id,table_id){
    if(delegate_id && table_id){
      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Delegate?',
        msg: 'This will remove all the information related to this table delegate both in the dashboard and mobile app.',
        btn_save: 'Remove',
        btn_close: 'Close'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
  
        /* console.log('pass',pass); */
        if(pass){
          this.tableService.removeDelegate(delegate_id,table_id).subscribe(
            response => {
              /* console.log(response); */
              let m = {
                type: '',
                msg: '',
              }

              if(response.error != 0){
                m.type = 'danger';
                m.msg = response.message;
              }else{
                m.type = 'success';
                m.msg = response.message;
              }
              this.messageService.add(m);

              this.updateTableList.emit();
            }
          );
        }
      }
    }
  }

  protected createRange(number){
    var items: number[] = [];
    for(var i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }
  

  setSelectedIds(id, type){
    

    if(type === 'VIP'){
      if(!this.VIPTable_IDs_to_del.includes(id)){
        this.VIPTable_IDs_to_del.push(id);    
      }else{
        this.VIPTable_IDs_to_del = this.VIPTable_IDs_to_del.filter( el_data => (id != el_data ));
      }
      /* arr = this.VIPTable_IDs_to_del.filter(item => item !== value) */
      this.setVIPTableIDs.emit(this.VIPTable_IDs_to_del);
    }else{
      console.log(type);
      if(!this.BoothTable_IDs_to_del.includes(id)){
        this.BoothTable_IDs_to_del.push(id);    
      }else{
        this.BoothTable_IDs_to_del = this.BoothTable_IDs_to_del.filter( el_data => (id != el_data ));
      }
      /* arr = this.VIPTable_IDs_to_del.filter(item => item !== value) */
      this.setBoothTableIDs.emit(this.BoothTable_IDs_to_del);
    }
    
  }

  checkIfExist(id, type){
      if(type === 'VIP'){
        if(this.VIPTable_IDs_to_del.includes(id)){
          return true;
        }else{
          return false;
        }
      }else{
        if(this.BoothTable_IDs_to_del.includes(id)){
          return true;
        }else{
          return false;
        }
      }
  }

}
