import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
/* import * as jsPDF from 'jspdf'; */
import 'jspdf-autotable';



/* services */
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';

import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { EventService } from 'src/app/services/event/event.service';
import { TableService } from 'src/app/services/tables/table.service';

import { ReschedMeetingModalComponent } from 'src/app/modules/dashboard/schedule-meetings/resched-meeting-modal/resched-meeting-modal.component';
import { CancellationRequestModalComponent } from 'src/app/modules/dashboard/schedule-meetings/cancellation-request-modal/cancellation-request-modal.component';

/* socket config */
import { environment } from 'src/app/lib/environment';

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.scss']
})
export class MeetingListComponent implements OnInit {

  onSearchStopTyping = new Subject<string>();
  modalRef: BsModalRef;

  protected event_id: any = 0;
  protected table_schedules : any;
  protected date_filter : any = {
    formatted: '',
  };

  /* filter and search */
  protected searchKey: any = '';
  protected _currentSelection: any;
  protected currentSelectionTimeslot: any = 'Select Time';
  protected timeslots: any;
  protected selectedTimeslot_Id : any;
  protected limit: any = 10;
  protected page: any = 1;
  protected dataIsLoaded = false;

  /* downloadables */
  printfile = {
    file : false,
    temp : false
  };
  downloadUrl: string = '';

  protected emptyDataSelection = {
    formatted: 'Select Date'
  };

  get currentSelection() {
    return this.currentSelection = this._currentSelection;
  }
  set currentSelection(value) { 
    this._currentSelection = value === '' || value === null || value === undefined ? this.emptyDataSelection : value;
  }


  constructor(
    protected router: Router,
    protected _route: ActivatedRoute,
    protected meetingSchedule : MeetingscheduleService,
    protected eventService: EventService,
    protected modalService: BsModalService,
    protected messageService: MessageService,
    protected tableService : TableService,
    private titleService: Title,
    protected env : environment,
  ) { 
    if(this.router.getCurrentNavigation().extras.state){
      let ts = this.router.getCurrentNavigation().extras.state;

      if(ts){
        this.event_id = ts.timeslot.event_id;
      }

      this.date_filter = ts.date;
      this._currentSelection = ts.date;
      this.selectedTimeslot_Id = ts.timeslot.id;
      this.currentSelectionTimeslot = ts.timeslot.start_time + ' - ' + ts.timeslot.end_time_orig;
      this.getMeetingSchedule();

      this.getTimeslot();
    }
  }

  ngOnInit() {

    /* get the url parameters */
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
      this.checkDownloadMeetingSched();
    });

    this.getMeetingSchedule(); 

    this.onSearchStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => { 
      /* isSearching indicates i'm searching */
      this.searchKey = value;
      this.getMeetingSchedule(); 
    });

    this.titleService.setTitle("Event Meetings");

    this.env.initSocket(this.event_id);
    this.env.webSocket.on('cancellation request', data => {
			this.getMeetingSchedule();
    });
    
    let a = this;
    setInterval(function(){ 
      if(!a.printfile.file){
        a.checkDownloadMeetingSched();
      }
      
    }, 15000);
  }

  getMeetingSchedule(){
    this.dataIsLoaded = false;
    let thisParam = {
      'search_key' : this.searchKey,
      'filter_date' : this.date_filter.formatted,
      'timeslot' : this.selectedTimeslot_Id,
      'limit' : this.limit,
      'event_id' : this.event_id,
      'page' : this.page
    }

    /* request for the meeting schedule list base on selected table */
    this.meetingSchedule.getMeetingSchedule(thisParam).subscribe(
      schedules => {
        /* set the data into variable */
        this.table_schedules = schedules;
        this.dataIsLoaded = true;
      }
    );
  }

  protected changeTableRange(range){
    this.limit = parseInt(range.target.value);
    this.getMeetingSchedule();
  }

  protected navigateTo(e){
    this.page = e;
    this.getMeetingSchedule();
  }

  protected jumpTo(e){
		this.navigateTo(e.target.value);
  }


  protected resetCurrentSelection(){
    this.date_filter = '';
    this.currentSelection = {
      formatted : 'All date'
    }
    this.getMeetingSchedule();
  }

  protected setCurrentSelection(option) {
    this.date_filter = option;
    this.currentSelection = option;
    this.getTimeslot();
  }

  /* request for the meeting schedule list base on selected table */
  protected getTimeslot(){ 

    let data = {
      'event_id': this.event_id,
      'date' : this.date_filter.formatted
    }
    this.meetingSchedule.getTimeslot(data).subscribe(
      time_slots => {
        this.timeslots = time_slots.data.datas;
        this.getMeetingSchedule();
      }
    );
  }

  protected setCurrentSelectionTimeslot(option) {
      this.currentSelectionTimeslot = option.start_time + " - " + option.end_time;
    this.selectedTimeslot_Id = option.id;
    this.getMeetingSchedule();
  }

  protected resetCurrentSelectionTimeslot(){
    this.selectedTimeslot_Id = '';
    this.currentSelectionTimeslot = 'All timeslot';
    this.getMeetingSchedule();
  }

  protected checkSetter(setter, elem){
    if(setter === elem){
      return true;
    }
  }


  protected cancelMeetingSchedule(sched_id){
    if(sched_id){
      let initialState = {
        img_src : 'not-allowed.png',
        title: 'Cancel Meeting?',
        msg: 'Are you sure you want to cancel this meeting? Both delegates will be available for this timeslot if cancelled.',
        btn_save: 'Cancel Meeting',
        btn_close: 'Close'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.meetingSchedule.cancelMeetingSched(sched_id).subscribe(
            response => {
              this.getMeetingSchedule();
              let m = {
                type: '',
                msg: '',
              }

              if(response.error != 0){
                m.type = 'danger';
              }else{
                m.type = 'success';
              }
              m.msg = response.message;
             
              this.messageService.add(m);

              let data = {
                meeting_sched_id : 0,
                event_id : this.event_id, 
              }
              this.env.webSocket.emit('cancellation request', data);
            }
          );
        }
      }
    }

  }

  /* show the rescheduling form popup */
  protected rescheduleMeetingModal(meetingSchedData){
    let initialState = {
      meetingSchedData: meetingSchedData,
      title: 'Meeting Details'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(ReschedMeetingModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_md modal-dialog-centered');

    this.modalRef.content.onSuccessReschedModal = () => {
      this.getMeetingSchedule();
      this.modalRef.hide();
      /* emit to refresh the count of pending in list */
      let data = {
        meeting_sched_id : 0,
        event_id : this.event_id, 
      }
      this.env.webSocket.emit('cancellation request', data);
    }

    this.modalRef.content.onClose = () => {
      /* this.getMeetingSchedule(); */
      this.modalRef.hide();
    }


  }

  /* show the meeting schedule details in popup */
  protected viewCancellationRequest(meetingSchedData){
    let initialState = {
      meetingSchedData: meetingSchedData,
      title: 'Meeting Cancellation Request'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(CancellationRequestModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_md modal-dialog-centered');

    this.modalRef.content.onClose = () => {
      // Do something with myData and then hide
      this.modalRef.hide();
    }

    /* after submitting cancellation api request update the list */
    this.modalRef.content.onSubmitCancellation = () => {
      this.getMeetingSchedule();

      /* emit to refresh the count of pending in list */
      let data = {
        meeting_sched_id : 0,
        event_id : this.event_id, 
      }
      this.env.webSocket.emit('cancellation request', data);
    }
  }
  
  /**
   * check if there was an available meeting schedule file to be downloaded
   * or if there was a temporary file that is being construct 
   */
  protected checkDownloadMeetingSched(){
    let param = {
      event_id: this.event_id,
      file: 'meeting_schedule'
    };
    this.meetingSchedule.checkDownloadable(param)
    .subscribe(r => {
      if(r.data.file){
        this.printfile = r.data;
      }
      this.startDownload();
    });
  }

  /**
   * start the downloading of the file
   */
  startDownload(){
    let param = {
      event_id: this.event_id,
      Deviceid: 'no-device',
      Devicetoken: 'no-device',
      token: this.meetingSchedule.env.generateToken()
    };
    this.downloadUrl = this.meetingSchedule.startDownloadMeetingSched(param);
  }

  /* print the list of meeting schedule base on date and timeslot */
  protected printSchedules(){
    let param = {
      event_id: this.event_id,
      cron_action_type: 'prepare_meeting_schedule_data'
    };

    this.meetingSchedule.setCronJobForMeetingSchedule(param).subscribe(r => {
      let m = {
        type: '',
        msg: '',
      }

      if(r.error != 0){
        m.type = 'danger';
      }else{
        m.type = 'success';

        this.printfile = {
          file: false,
          temp: true,
        };
      }
      m.msg = r.message;

      this.messageService.add(m);
    });
  }

  protected createRange(number){
    var items: number[] = [];
    for(var i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }
  
}