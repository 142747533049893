import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from './lib/environment';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { PushNotificationService } from './services/push-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  /* default loader */
  primaryColour = '#a6cd39';
  secondaryColour = '#3b67b2';
  backdropBackgroundColour = 'rgba(0, 0, 0, 0.8)';
  public loading = true;
  /* end default loader */

  title = 'face2face-web-app';
  message;

  constructor(
    private env: environment,
    private pushNotificationService: PushNotificationService
  ) { }

  ngOnInit() {
    this.env.exchangeToken();
    this.env.hideLoader();
    
    const userId = 'user001';
    /* this.pushNotificationService.requestPermission(userId);
    this.pushNotificationService.receiveMessage(); */
    this.message = this.pushNotificationService.currentMessage;
  }
}
