import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

/* services */
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';


@Component({
  selector: 'app-archived-events',
  templateUrl: './archived-events.component.html',
  styleUrls: ['./archived-events.component.scss']
})
export class ArchivedEventsComponent implements OnInit {

  events:Event[];
  private isRequesting = true;
  private noEventFound = false;

  constructor(
    private eventService : EventService,
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    /* past events */
    this.isRequesting = true;
    this.noEventFound = false;

    let param = {
      'status': 'archive'
    }

    this.eventService.getEventList(param).subscribe(
      events => {
          this.events = events.data.datas;
          this.isRequesting = false;
          if(this.events.length == 0){
            this.noEventFound = true;
          }
      }
    );
    this.titleService.setTitle("Archived Events");
  }

}
