import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { EventManagerService } from 'src/app/services/users/event-manager.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({

  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

	onClose: any;
  userProfileForm: FormGroup;
  protected submitted = false;
  public event_id = 0;
  public userProfileId = 0;
  public userProfile = null;
  protected send_credentials : boolean = false;
  protected availableEventManagers: any;
  protected showOption = false;

  protected keyword= '';
  protected email= '';
  protected contact_number= '';
  protected full_address= '';

  new_pass= '';
  renew_pass= '';
  old_pass= '';

  passTypeOld = "password";
  showPasswordOld: boolean = false;

  passTypeNew = "password";
  showPasswordNew: boolean = false;

  passTypeReNew = "password";
  showPasswordReNew: boolean = false;

  password_error: boolean = false;
  password_incorrect_error: boolean = false;
  incorrect_message = '';
  error_message = '';
  
  constructor(
    protected formBuilder: FormBuilder,
    protected eventManagerService : EventManagerService,
    protected messageService : MessageService,
    private _user: UserService,
  ) { 

    this._user.requireLogIn();
  }

  ngOnInit() {
    let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.userProfileForm = this.formBuilder.group({
        id: [this.userProfile ? this.userProfile.id : this.userProfileId],
        fullname: [this.userProfile ? this.userProfile.fullname : '', [Validators.required]],
        event_id: [this.event_id],
        email: [this.userProfile ? this.userProfile.email : '', [Validators.required, Validators.pattern(emailRe)]],
        contact_number: [this.userProfile ? this.userProfile.contact_number : '', [Validators.required]],
        full_address: [this.userProfile ? this.userProfile.full_address : ''],
        user_role: [this.userProfile ? this.userProfile.user_role : '2']
    }, { });

    if(this.userProfile){
      this.keyword = this.userProfile.fullname;
      this.email = this.userProfile.email;
      this.contact_number = this.userProfile.contact_number;
      this.full_address = this.userProfile.full_address;
    }
  }

	closeModal(){
		this.onClose({});
  }

  get f() { return this.userProfileForm.controls; }

  saveEventManager(){
    this.submitted = true;
    if(this.new_pass && this.old_pass){

      let thisData = {
        password : this.old_pass
      };

      this._user.checkPassword(thisData)
      .subscribe(data => { 
        if(data['error'] == 0){
          this.password_incorrect_error = false;
          this.editProfile();
        }else{
          this.password_incorrect_error = true;
          this.incorrect_message = data['message'];
        }
      })
    }else{
      
      this.editProfile();
    }
  }

  editProfile(){
    this.userProfileForm.value['new_pass'] = this.new_pass;
    if (this.userProfileForm.valid && !this.password_error && !this.password_incorrect_error) {
      this.eventManagerService.save(this.userProfileForm.value).subscribe(data => {
        this.saveHelper(data);
      });
    }
  }

	saveHelper(data){
    let m = {
      type: 'danger',
      msg: data.message,
    }
    
		if(data.error == 0){
      m.type = 'success';
      this.messageService.add(m);
			localStorage.setItem('user', JSON.stringify(data.user_profile));
      this.closeModal();

    }else{
      this.messageService.add(m);
    }
  }

  showPassOldPass(){
    this.passTypeOld = "text";
    this.showPasswordOld = true;
  } 

  hidePassOldPass(){
    this.passTypeOld = "password";
    this.showPasswordOld = false;
  }

  showPassNewPass(){
    this.passTypeNew = "text";
    this.showPasswordNew = true;
  } 

  hidePassNewPass(){
    this.passTypeNew = "password";
    this.showPasswordNew = false;
  }

  showPassReNewPass(){
    this.passTypeReNew = "text";
    this.showPasswordReNew = true;
  } 

  hidePassReNewPass(){
    this.passTypeReNew = "password";
    this.showPasswordReNew = false;
  }

  focusInField(elementId){
    if(elementId){
      document.getElementById(elementId).style.backgroundColor = "#fff";
    }
  }
  
  focusOutField(e = null, elementId){
    if(elementId){
      document.getElementById(elementId).style.backgroundColor = "#f2f2f2";
    }
    if(e){ e.preventDefault(); }
    
    if(this.new_pass){

      this.password_incorrect_error = false;
      // If the password is min length
      if(this.new_pass.length < 8){
        this.password_error = true;
        this.error_message = 'Password must be 8 characters.';
      }else{

        // If the password is not match
        if(this.new_pass != this.renew_pass){
          this.password_error = true;
          this.error_message = 'The password was not match';

        }else{
          this.password_error = false;
        }
      }
    }
  }
}
