export class Event {
    id: number;
    name: string;
    description:string;
    start_date: any;
    end_date: any;
    start_time: string;
    end_time: string;
    progress: number;
    image_url: string;
    organizer: string;

    type: number;

    event_address: string;
    time_zone: string;
    lat: string;
    long: string;

    request_start_date: any;
    request_end_date: any;
    request_start_time: any;
    request_end_time: any;
    set_meeting: boolean;
    meeting_request: string;
    cancellation_request: any;

    image_banner: any;
    image_letterhead: any;
    image_floor_plan: any;
    image_icon: any;
    banner_image_src : any;
    letterhead_image_src : any;
    icon_image_src : any;
    floorplan_image_src : any;

    permission: string;
    registration_link: string;
    permission_options: any;
    display_noshow: boolean;
    event_date : string;
    publish_date : any;
    event_date_virtual_itinerary: any
}
