import { Component, OnInit } from '@angular/core';
import * as moment from 'moment'

import { DelegateService } from 'src/app/services/users/delegate.service';
import { strrandom } from 'src/app/lib/strrandom';

@Component({
  selector: 'app-send-credential',
  templateUrl: './send-credential.component.html',
  styleUrls: ['./send-credential.component.scss']
})
export class SendCredentialComponent implements OnInit {

  onClose: any;
  delegate_ids: any;
  date: any;
  time: any;
  event_id: any;

  min_date : any;

  submitted = false;
	error = {
		date: false,
    time: false,
    time_message: 'Time is required'
	};

  constructor(
    public delegateService: DelegateService,
    protected strand: strrandom
  ) { }

  ngOnInit() {
    this.delegateService.event_id = this.event_id;
  }

  public setMinDate(date){
    const today = new Date();
    this.min_date = today;
  }

  closeModal(){
    this.onClose();
  }

  sendCredentials(){
    this.delegateService.sendCrentials(null, this.delegate_ids);
    this.closeModal();
  }

  focusField(){
    this.checkFields();
  }

  checkFields(){
    this.error.date = false;
    this.error.time = false;
    /* console.log(this.date);
    console.log(this.time); */

    if(!this.date){
      this.error.date = true;
    }
    if(!this.time){
      this.error.time = true;
    }
  }

  sendDateCredentials(){
    this.submitted = true;
    
    var now = new Date();
    var hh = now.getHours();
    var min = now.getMinutes();
            
    var ampm = (hh>=12)?'pm':'am';
    hh = hh%12;
    hh = hh?hh:12;
    hh = hh < 10 ? parseInt('0'+hh) : hh;
    min = min < 10 ? parseInt('0'+min) : min;
           
    var time = hh+":"+min+" "+ampm;

    if(!this.date || !this.time){
      this.checkFields();
    }else{
      if(moment(this.min_date).format('MM/DD/YYYY') <= moment(this.date).format('MM/DD/YYYY')){
        if(this.time){
          if (this.time.match(/[0-9]/) == null) {
            this.error.time = true;
            this.error.time_message = 'Inputted Time is Invalid!';
          } else{
              if(moment(this.min_date).format('MM/DD/YYYY') == moment(this.date).format('MM/DD/YYYY')){
                if(this.strand.convertTo24Hrs(this.time) < this.strand.convertTo24Hrs(time)){
                  this.error.time = true;
                  this.error.time_message = 'Invalid Time. Time must be greater than ' + time;
                } else {
                  this.submitCredentail();
                }
              } else {
                this.submitCredentail();
              }
            // this.submitCredentail();
            /*console.log("sent");*/
          }
        } else {
          this.error.time = true;
          this.error.time_message = 'Inputted Time is Invalid!';
        }
      }else{
        console.log(this.date , this.time);
        this.error.time = true;
        this.error.time_message = 'Inputted Time is Invalid!';
      }
    }
  }

  submitCredentail(){
    this.submitted = false;
    let thisDate = moment(this.date).format('MM/DD/YYYY');

    this.delegateService.sendCrentials(null, this.delegate_ids, thisDate, this.time);
    this.closeModal();
  }

}
