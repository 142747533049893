import { Component, OnInit, Input, Output , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/* services */
import { EventService } from 'src/app/services/event/event.service';
import { NoshowService } from 'src/app/services/noshow/noshow.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { WebSocketService } from 'src/app/services/websocket/web-socket.service';
import { TableService } from 'src/app/services/tables/table.service';

/* Components */
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { NoshowBoardComponent } from 'src/app/modules/dashboard/noshow-board/noshow-board.component';


import { environment } from 'src/app/lib/environment';
import * as moment from 'moment'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {


	private noshowBoardComponent : NoshowBoardComponent;

	public direction = 'asc';
	public userLogin: '';
	public displayActive = '';
	public event_id: number = 0;
	public noShowReport: any;
	protected cancellationReports: any;
	protected dataIsLoaded = false;
	protected cancellationRequestIsLoaded = false;

	public socket;
	

	constructor(
		protected router: Router,
		protected _route: ActivatedRoute,
		protected eventService: EventService,
		protected noShowService : NoshowService,
		protected meetingSchedule : MeetingscheduleService,
		protected modalService: BsModalService,
		protected env : environment,
		protected tableService : TableService,
		protected meetingSchedService : MeetingscheduleService
	) { }

	ngOnInit() {
		this._route.paramMap.subscribe( url_param =>{
			this.event_id = + url_param.get('event_id');
		});

		this.userLogin = JSON.parse(localStorage.getItem('user'));
		this.getNoShowReport();
		this.getCancellationRequest();
		this.activeMeeting();


		this.env.initSocket(this.event_id);
		this.env.webSocket.on('noshow request', data => {
			let args = {'event_id': this.event_id}
			this.noShowService.getNoShowReport(args);
			this.eventService.getEventNotif(args);
			let args2 = {
				'event_id': this.event_id,
				'order' : 'desc',
				'page' : 1,
				'limit' : 5
			}
			this.noShowService.noShowDelegates(args2);
		});

		this.env.webSocket.on('cancellation request', data => {
			let args = {'event_id': this.event_id}
			this.meetingSchedService.getCancellationRequests(args);
			this.eventService.getEventNotif(args);
		});
	}


	activeMeeting(){
		if(this.router.url.match(/schedules-agendas.*/) || this.router.url.match(/scheduled-meetings.*/)){
			this.displayActive = 'active';
		} else {
			this.displayActive = '';
		}
	}

	protected getNoShowReport(){
    let args = {
			'event_id': this.event_id
		}
		this.noShowService.getNoShowReport(args);
	}

	protected getCancellationRequest(){
    let args = {
			'event_id': this.event_id
    }
    this.meetingSchedule.getCancellationRequests(args);
	}


}
