import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../lib/environment';
import { User } from '../../lib/models/user';
import { Urls } from '../../lib/urls';

@Injectable({
  providedIn: 'root'
})
export class TimeslotsService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private env: environment
  ) { }


  blockTimeSlot(param){
    let url = this.env.getUrl(Urls.api_timeslot_block);
    let formData = new FormData();
		for (let propt in param) {
      formData.append(propt,param[propt]);
    }

    return this.http.post(url,formData, this.env.getHttpOptions());
  }

  unblockTimeSlot(param){
    let url = this.env.getUrl(Urls.api_timeslot_unblock);
    let formData = new FormData();
		for (let propt in param) {
      formData.append(propt,param[propt]);
    }

    return this.http.post(url,formData, this.env.getHttpOptions());
  }
}
