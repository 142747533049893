import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../lib/environment';
import { Urls } from '../../lib/urls';
import { Observable, of } from 'rxjs';


//set http header
const httpOptions = {
  headers: new HttpHeaders({
  'Content-type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private http: HttpClient,
    private env: environment
  ) { }

  public getCompaniesCountAPI(param): Observable<any> {
    let url = this.env.getUrl(Urls.api_statistics_companies);
    url += this.env.createUrlParam(param);

    return this.http.get<any>(url, this.env.getHttpOptions());
  }

  public getDelegatesCountAPI(param): Observable<any> {
    let url = this.env.getUrl(Urls.api_statistics_delegates);
    url += this.env.createUrlParam(param);

    return this.http.get<any>(url, this.env.getHttpOptions());
  }

  public getTableStatisticsAPI(param): Observable<any> {
    let url = this.env.getUrl(Urls.api_statistics_tables);
    url += this.env.createUrlParam(param);

    return this.http.get<any>(url, this.env.getHttpOptions());
  }

  public getSessionsMessagesStatistics(param):  Observable<any> {
    let url = this.env.getUrl(Urls.api_statistics_sessionmessage);
    url += this.env.createUrlParam(param);

    return this.http.get<any>(url, this.env.getHttpOptions());
  }
}
