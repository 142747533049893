import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent implements OnInit {

  onClose: any = '';

  constructor() { }

  ngOnInit() {
  }

  closeModal(){
    this.onClose();
  }

}
