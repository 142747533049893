import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {

  company_id = 0;
  event_id = 0;
  nextUrl: any;

  constructor(
    private _user: UserService,
    protected _location: Location,
    public router: Router,
    private _route: ActivatedRoute
  ) { 
    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
      this.company_id = + url_param.get('company_id');
      this.event_id = + url_param.get('event_id');
    });
    
    if(this.company_id){
      this.nextUrl = "/admin/company/profile/"+this.company_id;
    }else{ 
      this.nextUrl = "/admin/company";
    }
  }

  ngOnInit() {
  }

  backClicked() {
    this._location.back();
  }
}
