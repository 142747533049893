import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';
import { SpeakersService } from 'src/app/services/event/speakers.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { AddEditSpeakerComponent } from 'src/app/defaults/modals/add-edit-speaker/add-edit-speaker.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { ImportCsvService } from 'src/app/services/import-csv.service';
import { ImportCsvComponent } from 'src/app/defaults/import-csv/import-csv.component'; 
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-speaker-list',
  templateUrl: './speaker-list.component.html',
  styleUrls: ['./speaker-list.component.scss']
})
export class SpeakerListComponent implements OnInit {
  @ViewChild(ImportCsvComponent ) child: ImportCsvComponent ; 
  
  @Input() importMessages:any;

  subscribeSearch = new Subject<string>();
  modalRef: BsModalRef;
  protected searchReadOnly = false;
  
  protected skeleton_speaker = [
    { id: '1'},
    { id: '2'},
    { id: '3'}
  ];   

	protected speakers: any = null;
	protected speakersLoading: any = true;
  protected event_id = 0;
  protected fileIsLoaded = false;
  protected isDisplayPopup = false;
  
  protected search = '';

  constructor(
    protected speakersService: SpeakersService,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected _user: UserService,
    protected modalService: BsModalService,
    protected messageService: MessageService,
    private titleService: Title,
    protected eventService : EventService,
    protected importCsv: ImportCsvService,
  ) { 
    this._user.requireLogIn();

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.getSpeaker();
      });
  }

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
    this.titleService.setTitle("Event Speakers");
		this.getSpeaker();
  }

	/*-------------------------------------
		get all the speakers for the specif event
	 -------------------------------------*/
	getSpeaker(){
    this.speakersLoading = true;
		this.speakersService.getSpeaker(this.event_id, 0, this.search)
			.subscribe(data => {
        this.speakersLoading = false;
				this.speakers = data.data;
			});
  }
  
  searchList(){
    this.getSpeaker();
  }

	toViewAction(elId: number, name, action){
		let eId = name +''+ elId;
		document.getElementById(eId).style.display = action;
  }
  
	openModal(speaker=null){
    if(this.event_id){

      let initialState = {
        event_id: this.event_id,
        speaker: speaker
      }
  
      this.modalRef = this.modalService.show(AddEditSpeakerComponent, {initialState});
      this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
      this.modalRef.content.onClose = (myData) => {
        this.modalRef.hide();
        // get Updated Speaker List
        this.getSpeaker();
      };
    }
  }
  
  protected removeSpeaker(speaker=null){
    if(speaker){
      this.searchReadOnly = true;
      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Speaker?',
        msg: 'This will remove all the information related to this speaker both in the dashboard and mobile app.',
        btn_save: 'Delete',
        btn_close: 'Close',
        url: '/admin/speaker/'+this.event_id
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        this.searchReadOnly = false;
  
        /* console.log('pass',pass); */
        if(pass){
          this.speakersService.removeSpeaker(speaker.id).subscribe(
            result => {
              let m = {
                type: 'danger',
                msg: result.message,
              }
            
              if(result.error == 0){
                m.type = 'success';
                this.messageService.add(m);
                this.getSpeaker();
                
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

  protected downloadCsvTemplate(type : any = ''){
    this.importCsv.downloadCsvTemplate(type, this.event_id).subscribe(
      res => {
        if(res && res._body){
          /* console.log(res._body); */
          this.child.downloadFile(res._body,"import_speakers_template.csv");
        }
      }
    );
  }

  protected saveCsvData(type: any = ''){
    /* 
    * "type" 
    * variable is for the identification of function to be call in serverside or API 
    */
    this.importCsv.saveCSVFileData(type, this.event_id).subscribe(
      response => {
        let m = {
          type: '',
          msg: '',
        }

        if(response.error != 0){
          m.type = 'danger';
        }else{
          m.type = 'success';
        }
        m.msg = response.message;

        this.messageService.add(m);
        this.importMessages = response.error_messages;
        this.fileIsLoaded = false;
        this.isDisplayPopup = false;
        this.getSpeaker();
      }
    );
  }

}
