import { Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { strrandom } from 'src/app/lib/strrandom';

import { SponsorsService } from 'src/app/services/event/sponsors.service';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-add-edit-sponsor',
  templateUrl: './add-edit-sponsor.component.html',
  styleUrls: ['./add-edit-sponsor.component.scss']
})
export class AddEditSponsorComponent implements OnInit {

	onClose: any;
  sponsorForm: FormGroup;
  protected submitted = false;
  public event_id = 0;
  public sponsor_id = 0;
  public sponsor = null;
  public sponsorslevel = null;

  protected disableSaveSponsorButton = false;

	/* ======= profile photo =========== */
	profilePhoto: any = '';
	croppedLogo: any = '';
	isProfileImageLoaded = false;
	isProfilePhotoCropped = false;
  profileUploadWrongFile = false;
  
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  
  constructor(
    protected formBuilder: FormBuilder,
    protected sponsorsService : SponsorsService,
    protected messageService : MessageService,
    protected strand: strrandom
  ) { }

  ngOnInit() {
    let facebook_pattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/;
		let twitter_pattern = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/;
		let linkedln_pattern = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
    let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.sponsorForm = this.formBuilder.group({
        id: [this.sponsor ? this.sponsor.id : this.sponsor_id],
        company: [this.sponsor ? this.sponsor.company : '', [Validators.required]],
        event_id: [this.event_id],
        sponsor_level_id: [this.sponsor ? this.sponsor.sponsor_level_id : '', [Validators.required]],
        logo: [],
        profile: [this.sponsor ? this.sponsor.profile : ''],
        website: [this.sponsor ? this.sponsor.social_media_links.website : ''],
        email: [this.sponsor ? this.sponsor.social_media_links.email : '', [Validators.pattern(emailRe)]],
        phone: [this.sponsor ? this.sponsor.social_media_links.phone : ''],
        linkedln: [this.sponsor ? this.sponsor.social_media_links.linkedln : '', [Validators.pattern(linkedln_pattern)]],
        facebook: [this.sponsor ? this.sponsor.social_media_links.facebook : '', [Validators.pattern(facebook_pattern)]],
        twitter: [this.sponsor ? this.sponsor.social_media_links.twitter : '', [Validators.pattern(twitter_pattern)]],
    }, { });

    /* console.log('sponsor',this.sponsor); */
  }

  addLevel(){
    this.closeModal();
  }

	closeModal(){
		this.onClose({});
  }

  get f() { return this.sponsorForm.controls; }

  
  /* ---------------------------------
    start of profile photo cropper 
  ------------------------------------ */
	fileChange(event: any): void {
		let fileType = event.target.files[0].type;

		if(fileType.match('image.*')){
			this.isProfileImageLoaded = false;
			this.isProfilePhotoCropped = false;
			this.profileUploadWrongFile = false;
			this.profilePhoto = event;
		} else {
			this.isProfileImageLoaded = true;
			this.isProfilePhotoCropped = true;
			this.profileUploadWrongFile = true;
			this.profilePhoto = '';
		}
	}

	imageProfilePhotoCropped(event: ImageCroppedEvent) {
		this.croppedLogo = event.base64;
    this.isProfilePhotoCropped =  true;
    let fileOfBlob = new File([event.file], this.strand.generateFileName());

    this.sponsorForm.controls.logo.setValue(fileOfBlob);
	}

	imageProfileLoaded() {
		this.isProfileImageLoaded = true;
	}

	startCrop(event: ImageCroppedEvent) {
		this.imageCropper.crop();

	}
  /* ---------------------------------
    end of profile photo cropper 
  ------------------------------------ */

  removeImage(){
    this.isProfileImageLoaded = false;
    this.isProfilePhotoCropped = false;
    this.profileUploadWrongFile = false;
  }

  saveSponsor(){
    this.submitted = true;
    this.disableSaveSponsorButton = true;

    if (this.sponsorForm.invalid) {
      this.disableSaveSponsorButton = false;
      return;
    }

    // Set the social media links to object
    let social_media_links = {
      'website': this.sponsorForm.value['website'],
      'email': this.sponsorForm.value['email'],
      'phone': this.sponsorForm.value['phone'],
      'linkedln': this.sponsorForm.value['linkedln'],
      'facebook': this.sponsorForm.value['facebook'],
      'twitter': this.sponsorForm.value['twitter']
    };

    // convert social media links to json encode
    this.sponsorForm.value['social_media_links'] = JSON.stringify(social_media_links);
    /* console.log('this.sponsorForm.value',this.sponsorForm.value); */
    if(this.event_id){
      // stop here if form is invalid
      if (this.sponsorForm.valid) {
        this.sponsorsService.save(this.sponsorForm.value).subscribe(data => {
          this.saveHelper(data);
          this.disableSaveSponsorButton = false;
        });
      }
    }
  }

	saveHelper(data){
    let m = {
      type: 'danger',
      msg: data.message,
    }
    
		if(data.error == 0){
      m.type = 'success';
      this.messageService.add(m);
      this.closeModal();

    }else{
      this.messageService.add(m);
    }
  }
}
