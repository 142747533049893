import { Component, OnInit, Input, Output } from '@angular/core';


import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../lib/environment';
import { Urls } from './../../../lib/urls';

import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';


@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  
  constructor(
    protected http: HttpClient,
    protected env: environment,
    private router: Router,
    private _route: ActivatedRoute,
    private eventService : EventService,
  ) { }

  @Input() buttonTitle: string;
  @Input() filename:string;
  @Input() printConfig: any;
  @Input() docuToPrint: any;
  protected headerImgData;
  protected autoTable_dim = 0;

  protected event_id:string = '';

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = url_param.get('event_id');
    });
  }

  protected printList(){
    let url = this.env.getUrl(Urls.api_print_print);
    let formData = new FormData();
    formData.append('print', this.docuToPrint);
    formData.append('event_id', this.event_id);

		this.http.post<any>(url, formData, this.env.getHttpOptions())
			.subscribe(response => {

        let data = response.data;
        let event_name = '';
        let doc = new jsPDF();
        let columns = this.printConfig.columnToShow;
        let rows = data;
        let file_name = this.filename;
        
        let a = this;
        let letter_head = this.eventService.eventFullDetails.letterhead_image_src;
        this.toDataURL(letter_head, function(dataUrl){
          let headerTitle = a.filename;
          file_name = file_name.toLowerCase();
          file_name = file_name.split(' ').join('_');  
          
          /* let header = function (data) { */
            doc.setTextColor(40);
            doc.setFontStyle('normal');
            doc.setFontSize(13);
            
            let headerY = 20;
            if(a.eventService.eventFullDetails.image_letterhead !== ''){
              doc.addImage(dataUrl, 'PNG', 15, 15, 0, 30);
              headerY = 53;
            }
            doc.text(headerTitle, 15, headerY, {maxWidth: 180});
            let headerTitle_dim = doc.getTextDimensions(headerTitle);
            let splitTitle = doc.splitTextToSize(headerTitle,180,{fontSize:13});
            let headerTitle_height = (headerTitle_dim.h * splitTitle.length) + 2;  
    
            if(a.event_id){
              doc.setFontSize(10);
              doc.text(a.eventService.eventFullDetails.event_address, 15, headerTitle_height + headerY, {maxWidth: 180});
              
              let eventLocTitle_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_address);
              let spliteventLocTitle = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
              let eventLocTitle_height = (eventLocTitle_dim.h * spliteventLocTitle.length) + 1;  
              doc.text(a.eventService.eventFullDetails.event_date, 15, headerTitle_height + headerY + eventLocTitle_height, {maxWidth: 180});
              
              let eventDate_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_date);
              let spliteventDate = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
              a.autoTable_dim = (eventDate_dim.h * spliteventDate.length) + headerTitle_height + headerY + eventLocTitle_height  + 1;
            }else{
              a.autoTable_dim = headerTitle_height + headerY + 1;
            }
           
             
          /* }; */
          doc.autoTable(columns, rows, {margin: {top: a.autoTable_dim}});
          doc.save(file_name+'.pdf');
        });
      });
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


}
