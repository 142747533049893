import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { EventService } from 'src/app/services/event/event.service';
import { AgendaService } from 'src/app/services/event/agenda.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { Router, ActivatedRoute  } from '@angular/router'

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddEditAgendaComponent } from 'src/app/defaults/modals/add-edit-agenda/add-edit-agenda.component';
import { AddTimeslotComponent } from 'src/app/defaults/modals/add-timeslot/add-timeslot.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component'
import { SchedMeetingComponent } from 'src/app/defaults/modals/sched-meeting/sched-meeting.component'

import { TimeScheduler } from 'src/app/modules/dashboard/event-schedules-agendas/event-schedules-agendas.helper'
import { SchedMeetingVirtualComponent } from 'src/app/enhancements/defaults/modals/sched-meeting-virtual/sched-meeting-virtual.component';
import { AddTimeslotVirtualComponent } from 'src/app/defaults/modals/add-timeslot-virtual/add-timeslot-virtual.component';

@Component({
  selector: 'app-event-schedules-agendas-virtual',
  templateUrl: './event-schedules-agendas-virtual.component.html',
  styleUrls: ['./event-schedules-agendas-virtual.component.scss']
})
export class EventSchedulesAgendasVirtualComponent extends TimeScheduler implements OnInit {

  event_id: number = 0;
	modalRef: BsModalRef;

	/*
	*	this will tell the heigth of each row
	*	the height was dynamic.
	*/
	// rowH: any = {}

	/*
	*	this tell the css of each agenda
	*	by default
	*/
	// agendaCss: any = {};

	/*
	*	this variable container all agenda in the event
	*/
	// day_agenda: any = null;

	/*
	*	this varible contains all agenda for each time
	*	on the event, unlike day_agend, this was already
	*	sorted so that can be displayed on the UI
	*/
	// agendas: any = {};

	/*
	*	contain the list of time slots
	*	per day
	*/
	// timeSlots: any = {};


	/* button display */
	btn_addAgenda = true;
	time_slots = '';

	constructor(
		protected eventService: EventService,
		protected agendaService: AgendaService,
    	private router: Router,
		private _route: ActivatedRoute,
    	protected messageService: MessageService,
		protected modalService: BsModalService,
		protected meetingSchedule: MeetingscheduleService,
		private titleService: Title
	) {
		/* this is intended here because of conflict of extending from TimeScheduler helper */
		super(eventService, meetingSchedule);
	}

	ngOnInit() {
		this._route.paramMap.subscribe( url_param =>{
			this.event_id = + url_param.get('event_id');
		});
		this.run();
		this.titleService.setTitle("Event Agenda and Timeslot");
		// this.checkLoadedEvent();
		/*this.showtimeslot();*/
	}

	/*showtimeslot(){
		console.log(this.schedules);
	}*/

	// showButton(dateMilisec){
	// 	if(this.timeSlots['ts_day_'+dateMilisec].length){
	// 		this.btn_addAgenda = false;
	// 	} else {
	// 		this.btn_addAgenda = true;
	// 	}
	// }

	/*
	*	check if the event was already loaded
	*/
	// checkLoadedEvent() {
	// 	let checker = setInterval(f => {
	// 		let event = this.eventService.eventFormData
	// 		if(event){
	// 			this.setRows();
	// 			if(event.days.length){
	// 				this.onSelect(event.days[0].milisec);
	// 			}
	// 			clearInterval(checker);
	// 		}
	// 	}, 100)
	// }

	/*
	*	on page load set the row into variable
	*	so that the row is recognized by the resize function
	*/
	// setRows(){
	// 	let days = this.eventService.eventFormData.days;
	// 	for (var i = 0; i < days.length; i++) {
	// 		let time = days[i].time;
	// 		let milisec = 'milisec_' + days[i].milisec;
	// 		if(time.length){
	// 			let key = time[0].hash;

	// 			if(typeof this.rowH[key] == 'undefined'){
	// 				this.rowH[milisec] = {};
	// 				for (var ii = 0; ii < time.length; ii++) {
	// 					let tHash = time[ii].hash;
	// 					this.rowH[milisec][tHash] = 55;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	/*
	*	triggered on changing day
	*/
	// onSelect(date: any){
	// 	this.resizeRows(date, 55);
	// 	/* empty all time slot first */
	// 	this.perTime = {};
	// 	if(!this.day_agenda){
	// 		this.day_agenda = {};
	// 	}

	// 	this.day_agenda['date_'+date] = [];
	// 	this.agendas['_'+date] = [];

	// 	this.agendaService.getAgenda(this.event_id, date)
	// 		.subscribe(data => {
	// 			if(data.error == 0 && data.data.length > 0){
	// 				this.day_agenda['date_'+date] = data.data;
	// 				let a = this;

	// 				setTimeout(data => {
	// 					this.resizeElement(a.day_agenda['date_'+date], date);
	// 					this.setTimeSlots(date);
	// 				}, 100);
	// 			} else {
	// 				this.resizeRows(date);
	// 				this.setTimeSlots(date);
	// 			}
	// 		});
	// }

	/*
	*	get the csss value of an element
	*/
	// getStyle(oElm, strCssRule){
	//     var strValue = "";
	//     if(document.defaultView && document.defaultView.getComputedStyle){
	//         strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
	//     }
	//     else if(oElm.currentStyle){
	//         strCssRule = strCssRule.replace(/\-(\w)/g, function (strMatch, p1){
	//             return p1.toUpperCase();
	//         });
	//         strValue = oElm.currentStyle[strCssRule];
	//     }
	//     return strValue;
	// }

	/*
	*	this will resize all element that the agenda will cover
	*/
	// resizeElement(agendas, dateMilisec){
	// 	let usedRow = {};
	// 	this.agendas['_'+dateMilisec] = {};
	// 	for (let i = agendas.length - 1; i >= 0; i--) {
	// 		let agenda = agendas[i];

	// 		this.agendaCss['agenda_' + agenda.id] = {
	// 			top: '30px',
	// 			height: '180px',
	// 		}

	// 		let firstTime = agenda.start_time;
	// 		let lastTime = agenda.end_time;
	// 		let day = this.getDayByMilisec(dateMilisec);

	// 		let key = 'milisec_'+day.milisec;

	// 		let rows = [];

	// 		for (let ii=0; ii < day.time.length; ii++) {

	// 			let elemTime = day.time[ii].time;
	// 			let elemCovered = this.checkTIme(
	// 				agenda.start_time,
	// 				agenda.end_time,
	// 				day.time[ii].time
	// 			);
	// 			if(elemCovered){
	// 				rows.push(day.time[ii].hash);
	// 			}
	// 		}

	// 		if(rows.length){
	// 			let agenOnrowKey = 'on_time_'+rows[0];
	// 			this.agendas['_'+day.milisec][agenOnrowKey] = agenda;
	// 		}

	// 		let a = this;
	// 		let agendaElemDefaultH = 180;
	// 		let currentRowH = this.countTheRowHeight(rows, dateMilisec);

	// 		let milisecKey = 'milisec_' + dateMilisec;
	// 		if((currentRowH - 60) < agendaElemDefaultH){
	// 			/*
	// 			*	we will resize the height of the row here
	// 			*	the int 60 here is the space gap from 30px + 30px
	// 			*/
	// 			let newcurrentRowH = 0;
	// 			let prevRowH = 30;
	// 			let paddingTop = 15;

	// 			for (let iiii=0; iiii < rows.length; iiii++) {
	// 				let newRowHeight = (agendaElemDefaultH + 60) / rows.length;

	// 				if(usedRow['us_'+rows[iiii]]){
	// 					if(iiii === 0){
	// 						prevRowH = parseInt(this.rowH[milisecKey]['r_'+rows[iiii]]['min-height']);
	// 						newRowHeight = prevRowH + 30 + (prevRowH / 2);

	// 						// this.rowH[milisecKey]['r_'+rows[iiii]]['min-height'] = newRowHeight + 'px';
	// 						// this.rowH[milisecKey]['l_'+rows[iiii]]['min-height'] = newRowHeight + 'px';
	// 					}
	// 				} else {
	// 					// this.rowH[milisecKey]['r_'+rows[iiii]]['min-height'] = newRowHeight  + 'px';
	// 					// this.rowH[milisecKey]['l_'+rows[iiii]]['min-height'] = newRowHeight  + 'px';
	// 					usedRow['us_'+rows[iiii]] = true;
	// 				}

	// 				if((iiii + 1) == rows.length){
	// 					paddingTop = agendaElemDefaultH / rows.length;
	// 					if(rows.length > 2){
	// 						paddingTop = newRowHeight / 2;
	// 					}
	// 					else if(rows.length <= 1){
	// 						paddingTop = 0;
	// 					}
	// 					if(paddingTop){
	// 						// this.rowH[milisecKey]['r_'+rows[iiii]]['padding-top'] = paddingTop + 'px';
	// 						// this.rowH[milisecKey]['l_'+rows[iiii]]['padding-top'] = paddingTop + 'px';
	// 					} else {
	// 						// this.rowH[milisecKey]['r_'+rows[iiii]]['min-height'] = (prevRowH + agendaElemDefaultH + 30) + 'px';
	// 						// this.rowH[milisecKey]['l_'+rows[iiii]]['min-height'] = (prevRowH + agendaElemDefaultH + 30)  + 'px';
	// 					}
	// 				}
	// 				if(iiii === 0){
	// 					let paddingTop = parseInt(this.rowH[milisecKey]['r_'+rows[iiii]]['padding-top']);
	// 					if(paddingTop > 30){
	// 						this.agendaCss['agenda_' + agenda.id].top = prevRowH + 'px';
	// 						if(rows.length == 1){
	// 							// this.rowH[milisecKey]['r_'+rows[iiii]]['padding-top'] = prevRowH + agendaElemDefaultH + 'px';
	// 							// this.rowH[milisecKey]['l_'+rows[iiii]]['padding-top'] = prevRowH + agendaElemDefaultH + 'px';
	// 						}
	// 					} else {
	// 						this.agendaCss['agenda_' + agenda.id].top =  '30px';
	// 					}
	// 				}

	// 				newcurrentRowH += newRowHeight;
	// 			}
	// 			newcurrentRowH = newcurrentRowH - 60 - (prevRowH / 2);

	// 			if(newcurrentRowH > agendaElemDefaultH){
	// 				if(rows.length > 2){
	// 					this.agendaCss['agenda_' + agenda.id].height = (newcurrentRowH - paddingTop) +'px';
	// 				} else {
	// 					this.agendaCss['agenda_' + agenda.id].height = (newcurrentRowH - 30) +'px';
	// 				}
	// 			}
	// 		}
	// 		else {
	// 			/*
	// 			*	we will resize the height of the agenda coantainer first
	// 			*	then adjust the row to fit in
	// 			*/
	// 			let newAgendaHeight = currentRowH;

	// 			let lastRowKey = rows.length - 1;
	// 			let lastRowHeight = parseInt(this.rowH[milisecKey]['r_'+rows[lastRowKey]]['min-height']);

	// 			let paddingTop = lastRowHeight / 2;
	// 			newAgendaHeight = newAgendaHeight + rows.length;
	// 			this.agendaCss['agenda_' + agenda.id].height = (newAgendaHeight - paddingTop) +'px';

	// 			let _paddingTop = 15;

	// 			if((currentRowH - 60 - rows.length) < newAgendaHeight){
	// 				let newRowHeight = (newAgendaHeight + 30) / rows.length;
	// 				let prevRowH = 0;
	// 				for (let iiii=0; iiii < rows.length; iiii++) {

	// 					if(usedRow['us_'+rows[iiii]]){
	// 						if(iiii === 0){
	// 							prevRowH = parseInt(this.rowH[milisecKey]['r_'+rows[iiii]]['min-height']);
	// 							let _newRowHeight = prevRowH + 30;

	// 							// this.rowH[milisecKey]['r_'+rows[iiii]]['min-height'] = _newRowHeight + 'px';
	// 							// this.rowH[milisecKey]['l_'+rows[iiii]]['min-height'] = _newRowHeight + 'px';
	// 						}
	// 					} else {
	// 						// this.rowH[milisecKey]['r_'+rows[iiii]]['min-height'] = newRowHeight  + 'px';
	// 						// this.rowH[milisecKey]['l_'+rows[iiii]]['min-height'] = newRowHeight  + 'px';
	// 						usedRow['us_'+rows[iiii]] = true;
	// 					}

	// 					if((iiii + 1) == rows.length){
	// 						_paddingTop = newRowHeight / 2;
	// 						// this.rowH[milisecKey]['r_'+rows[lastRowKey]]['padding-top'] = _paddingTop + 'px';
	// 						// this.rowH[milisecKey]['l_'+rows[lastRowKey]]['padding-top'] = _paddingTop + 'px';
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	/*
	*	return the total height of the rows
	*	that is covered by the agenda
	*/
	// countTheRowHeight(rows, dateMilisec){
	// 	let currentRowH = 0;

	// 	for (let iii=0; iii < rows.length; iii++) {
	// 		let lRow = document.getElementById('r-'+dateMilisec+'-'+rows[iii]);
	// 		currentRowH += lRow.offsetHeight;
	// 	}
	// 	return currentRowH;
	// }

	/*
	*	resize the rows
	*/
	// resizeRows(dateMilisec, defaultH:any = null){
	// 	let dayKey = 'milisec_'+dateMilisec;
	// 	let rows = this.rowH[dayKey];

	// 	for (let key of Object.keys(this.rowH[dayKey])) {
	// 		let height = 55;
	// 		if(defaultH){
	// 			height = defaultH;
	// 		}

	// 		this.rowH[dayKey]['r_'+key] = {
	// 			'min-height': height+'px',
	// 			'padding-top' : '15px'
	// 		};

	// 		this.rowH[dayKey]['l_'+key] = {
	// 			'min-height': height+'px',
	// 			'padding-top' : '15px'
	// 		};
    //     }
	// }

	/*
	*	compare the time of agenda to the time of the element
	*	this will tell if the element is covered by the agenda's
	*	time, after this we know which element should be adjusted
	*	return bolean
	*/
	// checkTIme(firstTime, lastTime, elemTime){
	// 	let ft = parseInt(this.convertTo24Hrs(firstTime).split(":")[0]);
	// 	let lt = parseInt(this.convertTo24Hrs(lastTime).split(":")[0]);
	// 	let et = parseInt(this.convertTo24Hrs(elemTime).split(":")[0]);
	// 	let isCovered = (ft <= et && lt >= et);
	// 	return isCovered;
	// }

	/*
	*	this will convert 12 hrs time format
	*	into 24 hrs format
	*/
	// convertTo24Hrs(time){
	// 	let hours = Number(time.match(/^(\d+)/)[1]);
	// 	let minutes = Number(time.match(/:(\d+)/)[1]);
	// 	let AMPM = time.match(/\s(.*)$/)[1];
	// 	if(AMPM == "PM" && hours<12) hours = hours+12;
	// 	if(AMPM == "AM" && hours==12) hours = hours-12;
	// 	let sHours = hours.toString();
	// 	let sMinutes = minutes.toString();
	// 	if(hours<10) sHours = "0" + sHours;
	// 	if(minutes<10) sMinutes = "0" + sMinutes;
	// 	return sHours + ":" + sMinutes;
	// }

	/*
	*	return the loaded day using the milisec
	*	given by clicking the tab;
	*/
	getDayByMilisec(dateMilisec){
		let days = this.eventService.eventFormData.days;

		let d: any = null;
		for (var i = days.length - 1; i >= 0; i--) {
			let day = days[i];

			if(day.milisec == dateMilisec){
				d = day;
			}
    }

    //console.log('day', d);

    return d;
	}

	showModal(agenda, day, milisec){
		let modal_title = '';
		if(!agenda){
			agenda = {
				id: '',
				date: '',
				date_milisec: day.milisec,
				description: '',
				end_time: '',
				event_id: this.event_id,
				event_speakers_id: '',
				location: '',
				start_time: '',
				title: ''
			}
			modal_title = 'Add Agenda';
		}else{
			modal_title = 'Edit Agenda';
		}
		let initialState = {
			agenda: agenda,
			day: day,
			title: modal_title
		}

		this.modalRef = this.modalService.show(AddEditAgendaComponent, {initialState});
		this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
		this.modalRef.content.onClose = (myData) => {
			this.modalRef.hide();
			this._onSelect(milisec);
		};
	}

	deleteAgenda(agenda, dateMilisec){
		let initialState = {
			img_src: 'delete-popup.png',
			title: 'Delete Agenda?',
			msg: 'Are you sure you want to delete all '+agenda.title+'? This will delete '+agenda.title+' agenda and cannot be undone.',
			btn_save: 'Delete Now',
			btn_close: 'Close'
		};

		this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
		this.modalRef.setClass('modal_sm modal-dialog-centered');
		this.modalRef.content.onClose = (myData) => {
			this.modalRef.hide();
			if(myData){
				this.removeAgenda(agenda, dateMilisec);
			}
		};
	}

	removeAgenda(agenda, dateMilisec){
		let formData = new FormData();
    	formData.append('id',agenda.id);

		let m = {
			type: 'danger',
			msg: '',
		}
		this.agendaService.deleteAgenda(formData)
			.subscribe(r => {
				if(r.error == 0){
					m.type = 'success'
				}
				m.msg = r.message
				this.messageService.add(m);
				this._onSelect(dateMilisec);
			});
	}

	/* ======================================= time slot ======================================= */

	/*
	*	this variable holds the timeslots per time
	*	from here we know
	*/
	perTime: any = {}

	// setTimeSlots(dateMilisec){
	// 	let days = this.getDayByMilisec(dateMilisec);
	// 	let options = {
	// 		event_id: this.event_id,
	// 		date: days.formatted,
	// 		limit: 200,
	// 	}
	// 	this.meetingSchedule.getTimeslot(options)
	// 		.subscribe(result => {
	// 			this.timeSlots['ts_day_'+dateMilisec] = result.data.datas
	// 			this.addSlotToTime(days, dateMilisec);
	// 			this.showButton(dateMilisec);
	// 		});
	// }

	/*
	*	function that will add timslot into
	*	specific time
	*/
	// addSlotToTime(days, dateMilisec){
	// 	let dayTs = this.timeSlots['ts_day_'+dateMilisec];

	// 	this.perTime[dateMilisec] = [];

	// 	days.time.forEach(val => {
	// 		this.perTime[dateMilisec]['dt_'+val.hash] = [];

	// 		let rowTime = parseInt(this.convertTo24Hrs(val.time).split(":")[0]);

	// 		dayTs.forEach(ts => {
	// 			let tsTime = parseInt(this.convertTo24Hrs(ts.start_time).split(":")[0]);
	// 			if(tsTime == rowTime){
	// 				this.perTime[dateMilisec]['dt_'+val.hash].push(ts);
	// 			}
	// 		});

	// 		setTimeout(f => {
	// 			let el = document.getElementById('r-'+dateMilisec+'-'+val.hash);
	// 			let styles = window.getComputedStyle(el);

	// 			let minHeight = parseInt(styles.getPropertyValue('height'));
	// 			let dayKey = 'milisec_'+dateMilisec;
	// 			// this.rowH[dayKey]['r_'+val.hash]['min-height'] = minHeight + 'px';
	// 			// this.rowH[dayKey]['l_'+val.hash]['min-height'] = minHeight + 'px';
	// 		}, 10);
	// 	});
	// }

	/*
	*	will open a modal add form fields
	*/
	addTS(dateMilisec){
		let day = this.getDayByMilisec(dateMilisec);
		let initialState = {
			day: day,
			event_id: this.event_id
		}

		this.modalRef = this.modalService.show(AddTimeslotVirtualComponent, {initialState});
		this.modalRef.setClass('modal_event_timeslot modal-dialog-centered');
		this.modalRef.content.onClose = (myData) => {
			this.modalRef.hide();
			this._onSelect(dateMilisec);
		};
	}

	/*
	*	remove all time slots
	*/
	removeTS(dateMilisec){
		let initialState = {
			img_src: 'delete-popup.png',
			title: 'Delete Timeslot?',
			msg: 'Are you sure you want to delete all timeslots? This will also delete all scheduled meetings from each timeslot and cannot be undone.',
			btn_save: 'Delete Now',
			btn_close: 'Close'
		};

		this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
		this.modalRef.setClass('modal_sm modal-dialog-centered');
		this.modalRef.content.onClose = (myData) => {
			this.modalRef.hide();
			if(myData){
				this.removeTimeSlots(dateMilisec);
			}
		};
	}

	/*
	*	remove time slots will permanently
	*	delete from database
	*	and all meeting scheduled will also be deleted
	*/
	removeTimeSlots(dateMilisec){
		// let dayTs = this.timeSlots['ts_day_'+dateMilisec];

		let m = {
			type: 'danger',
			msg: 'Cannot delete timeslot, please try again.',
		}
		this.meetingSchedule.deleteTimeSlots(this.event_id, dateMilisec)
			.subscribe(result => {
				if(result.error == 0){
					this.isTimeSlotButtonShow = false;
					m.type = 'success'
					m.msg = result.message
				}
				this.messageService.add(m);
				this._onSelect(dateMilisec);
			});
	}

	/*
	*	update the time status
	*/
	updateSlotStatus(e, timeSlot){
		let formData = new FormData();
		if(e.target.checked){
    		formData.append('status','yes');
		} else {
			formData.append('status','no');
		}

		formData.append('id',timeSlot.id);

		let m = {
			type: 'danger',
			msg: '',
		}

		this.meetingSchedule.updateStatus(formData)
			.subscribe(r => {
				if(r.error == 0){
					m.type = 'success';
				}
				m.msg = r.message;
				this.messageService.add(m);
			});
	}

	/*
	*	redirect to timeslot page
	*/
	goToTimeSlot(date, ts:any){
		this.router.navigate(['admin/scheduled-meetings-virtual/' + ts.event_id], { state: {timeslot: ts, date: date} });
	}

	/*
	*	show schedule a meeting form modal
	*/
	schedMetting(ts){
		let initialState = {
			timeslot: ts
		}

		this.modalRef = this.modalService.show(SchedMeetingVirtualComponent, {initialState});
		this.modalRef.setClass('modal-sched-meeting modal-dialog-centered');
		this.modalRef.content.onClose = (myData) => {
			this.modalRef.hide();
		};
	}
}
