import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { UserService } from 'src/app/services/user.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddEditEventmanagerComponent } from 'src/app/defaults/modals/add-edit-eventmanager/add-edit-eventmanager.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  modalRef: BsModalRef;
  
  upcoming_events:Event[];
  ongoing_events:Event[];
  past_events:Event[];
  attr_count: any;
  protected userLogin: any;
  protected event_id= 0;

  constructor(
    private eventService : EventService,
    private _user: UserService,
    protected modalService: BsModalService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.getCount();

    this.userLogin = JSON.parse(localStorage.getItem('user'));
    this.titleService.setTitle('Home');
  }

  getCount(){
      this.eventService.getAttributesCount().subscribe(
        data => {
          if(data['error'] == '0'){
            this.attr_count = data.data;
          }
        }
      );
  }

	openModal(manager=null){
    let initialState = {
      event_id: this.event_id,
      eventManager: manager
    }

    this.modalRef = this.modalService.show(AddEditEventmanagerComponent, {initialState});
    this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
      this.getCount();
    };
  }
}
