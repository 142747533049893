import { Component, OnInit } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { Router, ActivatedRoute } from '@angular/router';
/* services */
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-resched-meeting-modal-virtual',
  templateUrl: './resched-meeting-modal-virtual.component.html',
  styleUrls: ['./resched-meeting-modal-virtual.component.scss']
})
export class ReschedMeetingModalVirtualComponent implements OnInit {

  onClose: any;
  onSuccessResched: any;
  modalRef: BsModalRef;
  protected _currentSelection: any;
  protected currentSelectionTimeslot: any = 'Select time';
  protected event_id = 0;
  protected timeslots: any;
  protected selectedTimeslot_Id : any;
  protected timeHasError = false;
  protected dateHasError = false;
  public actionbutton = '';
  public meetingSchedData: any;

  protected emptyDataSelection = {
    formatted: 'Select date'
  };

  get currentSelection() {
    return this.currentSelection = this._currentSelection;
  }
  set currentSelection(value) { 
    this._currentSelection = value === '' || value === null || value === undefined ? this.emptyDataSelection : value;
  }

  constructor(
    private eventService : EventService,
    protected meetingSchedule : MeetingscheduleService,
    protected modalService: BsModalService,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected messageService: MessageService,
  ) { }

  ngOnInit() {
    this.actionButton();

    this.eventService.eventFormData.days.forEach((days) => {
      let timecheck = this.checkTimeslotData(days, this.meetingSchedData);
      
    });

    // console.log('test meeting schedule data', this.meetingSchedData);
  }
  
  checkTimeslotData(option, meetingSchedData){
    
    this.event_id = this.eventService.event_id;
    /* request for the meeting schedule list base on selected table */
    let data = {
      'event_id': this.event_id,
      'date' : option.formatted,
      'delegate_1' : meetingSchedData.delegate1 ,
      'delegate_2' : meetingSchedData.delegate2
    }
    
    
    //console.log('this is the data', data);
    
    this.meetingSchedule.getTimeslotVirtual(data).subscribe(
      time_slots => {
        let timecheck: any;
        //console.log(' these are the timeslots genereated', time_slots.data.datas);
        timecheck = time_slots.data.datas;
        
        //console.log('this is the time check', timecheck);
        if(timecheck === undefined || timecheck.length == 0){
          timecheck = false;
        }else{
          timecheck = true
        }
        option['timecheck'] = timecheck;
        //console.log('event days with time check', this.eventService.eventFormData.days);
      }      
      );
    //return timecheck;
  }
  
  closeModal(){
    this.onClose();
  }

  onSuccessReschedModal(){
    this.onSuccessResched();
  }

  actionButton(){
    if(this._currentSelection === '' || this._currentSelection ===  null || this._currentSelection === undefined){
      console.log('qweqweqweqwe', this.currentSelection);
      this.actionbutton = 'bg-disabled';
    }

  }
  
  
  setCurrentSelection(option, meetingSchedData) {

    this.currentSelection = option;
    this.actionbutton = '';

    this.currentSelectionTimeslot = 'Select Time';
    this.event_id = this.eventService.event_id;
    /* request for the meeting schedule list base on selected table */
    let data = {
      'event_id': this.event_id,
      'date' : option.formatted,
      'delegate_1' : meetingSchedData.delegate1 ,
      'delegate_2' : meetingSchedData.delegate2
    }
    this.meetingSchedule.getTimeslotVirtual(data).subscribe(
      time_slots => {
        this.timeslots = time_slots.data.datas;
        let timetodate = [];
        this.timeslots.forEach(datetime => {
          
        });
        //console.log('this is the event data days', this.eventService.eventFormData.days);
      }
      
    );
  }

  setCurrentSelectionTimeslot(option) {
    this.currentSelectionTimeslot = option;
    this.selectedTimeslot_Id = option.id;
  }

  rescheduleMeetingSchedule(meetingSchedData){

    if(this.currentSelection.formatted === 'Select date'){
      this.dateHasError = true;
    }

    if(this.selectedTimeslot_Id){

      this.dateHasError = false;
      this.timeHasError = false;
      
      let data = {
        'time_slot_id': this.selectedTimeslot_Id,
        'event_id' : this.event_id,
        'sched_id' : meetingSchedData.id,
        'delegate_1' : meetingSchedData.delegate1,
        'delegate_2' : meetingSchedData.delegate2,
        'setter' : 'admin'
      }
  
      let m = {
        type: '',
        msg: '',
      }
  
      this.meetingSchedule.setMeetingScheduleVirtual(data).subscribe(
        response => {
          if(response.error == 0){
            m.type = 'success';
            this.onSuccessReschedModal();
          } else {
            m.type = 'danger';
          }
          m.msg = response.message;
          this.messageService.add(m);
          /* console.log(response); */
        }
      );
    }else{
      this.timeHasError = true;
    }
    
  }
}
