import { Component, OnInit, Input } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { UserService } from './../../services/user.service';

import { MessageService } from '../../services/defaults/message.service';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

	constructor(
		private _user: UserService,
		private messageService: MessageService,
		private route: ActivatedRoute,
		private titleService: Title
	) {}

	ngOnInit() {
		this._user.requireNotLogIn();
		this.user = {
			email: ''
		}

		this.route.queryParams
			.subscribe(params => {
				this.queryParam(params);
			});
		
		this.titleService.setTitle("Forgot Password");
	}
	@Input() user: any;

	sendMail(){
		this._user.forgotPassword(this.user)
			.subscribe(data => {this.addMessage(data)})
	}

	queryParam(data){
		if(typeof data.error != 'undefined') {
			this.addMessage(data);
		}
	}

	addMessage(data){
		let m = {
			type: 'danger',
			msg: '',
		}
		if(data.error == 0){
			m.type = 'success';
		} else {
			m.type = 'danger';
		}

		m.msg = data.message;
		this.messageService.add(m);
	}
}
