import { Component, OnInit, Input } from '@angular/core';

import { Pref } from './../../../lib/models/preferences/pref';

import { MessageService } from '../../../services/defaults/message.service';
import { PrefService } from '../../../services/preferences/pref.service';

import { Preferences } from '../preferences';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pref-tab-specializations',
  templateUrl: './pref-tab-specializations.component.html',
  styleUrls: ['./pref-tab-specializations.component.scss']
})
export class PrefTabSpecializationsComponent extends Preferences implements OnInit {

	constructor(
		protected messageService: MessageService,
  		protected prefService: PrefService,
		protected modalService: BsModalService
  	) { 
    	super(messageService, prefService, modalService);
	}

	ngOnInit() {
		this.pref = {
			id: 0,
			name: '',
			preftype: 'specializations'
		}
	}
	@Input() pref: Pref;
}
