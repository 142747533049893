import { Component, Input, ViewChild, OnInit } from '@angular/core';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { strrandom } from 'src/app/lib/strrandom';
import { SponsorsService } from 'src/app/services/event/sponsors.service';

@Component({
  selector: 'app-sponsor-banner',
  templateUrl: './sponsor-banner.component.html',
  styleUrls: ['./sponsor-banner.component.scss']
})
export class SponsorBannerComponent implements OnInit {

  @Input() banner :string = '';

	/* ======= profile photo =========== */
	profilePhoto: any = '';
	croppedLogo: any = '';
	isProfileImageLoaded = false;
	isProfilePhotoCropped = false;
  profileUploadWrongFile = false;
  public sponsorBanner: any;
  
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    protected strand: strrandom,
    protected sponsorsService: SponsorsService
  ) { }

  ngOnInit() {
    /* console.log(this.banner); */
  }  
  
  /* ---------------------------------
    start of profile photo cropper 
  ------------------------------------ */
  fileChange(event: any): void {
    let fileType = event.target.files[0].type;

    if(fileType.match('image.*')){
      this.isProfileImageLoaded = false;
      this.isProfilePhotoCropped = false;
      this.profileUploadWrongFile = false;
      this.profilePhoto = event;
    } else {
      this.isProfileImageLoaded = true;
      this.isProfilePhotoCropped = true;
      this.profileUploadWrongFile = true;
      this.profilePhoto = '';
    }
  }

  imageProfilePhotoCropped(event: ImageCroppedEvent) {
    this.croppedLogo = event.base64;
    this.isProfilePhotoCropped =  true;
    let fileOfBlob = new File([event.file], this.strand.generateFileName());
    /* console.log('fileOfBlob',fileOfBlob); */
    this.sponsorBanner = fileOfBlob;
    this.sponsorsService.setSponsorBanner(fileOfBlob);
  }

  imageProfileLoaded() {
    this.isProfileImageLoaded = true;
  }

  startCrop(event: ImageCroppedEvent) {
    this.imageCropper.crop();

  }
  /* ---------------------------------
    end of profile photo cropper 
  ------------------------------------ */

}
