import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* services */
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/defaults/message.service';
/* models */
import { EventSummary } from 'src/app/lib/models/events/eventsummary';
@Component({
  selector: 'app-eventsummary-virtual',
  templateUrl: './eventsummary-virtual.component.html',
  styleUrls: ['./eventsummary-virtual.component.scss']
})
export class EventsummaryVirtualComponent implements OnInit {

  public youtubevideoId : any;

  private submitted: boolean = false;
  private submitted2: boolean = false;
  private eventSummaryForm: FormGroup;
  private eventSummaryFormData: EventSummary;
  private eventAfterMovieForm: FormGroup;
  private urlPattern = "";
  
  public event_id: number = 0;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService,
    private messageService: MessageService
  ) {}

  ngOnInit() {

    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });

    let summarylink = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let youtubelink = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/;

    this.eventSummaryForm = this.formBuilder.group({
      summary: [''],
      summary_link: ['', [Validators.required,Validators.pattern(summarylink)]]

    }, { });

    this.eventAfterMovieForm = this.formBuilder.group({
      summary_youtube_link: ['', [Validators.required, Validators.pattern(youtubelink)]]
    }, { });

  }

  get f() { return this.eventSummaryForm.controls; }

  get e() { return this.eventAfterMovieForm.controls; }

  saveEventSummary(){
    this.submitted = true;
    /* stop here if form is invalid */
    if (this.eventSummaryForm.invalid) {
        return;
    }
    this.eventService.saveEventSummary(this.eventSummaryForm.value, this.event_id).subscribe(response => {
      var api_response = response;
      let m = {
        type: 'danger',
        msg: '',
      }

      if(api_response){
        if(api_response['error'] == 0){
          m.type = 'success';
        } else {
          m.type = 'danger';
        }
        m.msg = api_response['message'];
  
        this.messageService.add(m);
      }
    });
  }

  getYoutubeId(url){
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      this.youtubevideoId = 'https://www.youtube.com/embed/'+match[2];
      console.log(this.youtubevideoId);
    }
  }

  saveEventAftermovie(){
    this.submitted2 = true;
    /* stop here if form is invalid */
    if (this.eventAfterMovieForm.invalid) {
        return;
    }
    this.eventService.saveEventAfterMovie(this.eventAfterMovieForm.value, this.event_id).subscribe(response => {
      let api_response = response;
      let m = {
        type: 'danger',
        msg: '',
      }
      if(response){
        if(response['error'] == 0){
          m.type = 'success';
        } else {
          m.type = 'danger';
        }
        
        m.msg = response['message'];
        this.messageService.add(m);
      }
    });
  }

}
