import { Component, OnInit, ViewChild } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { DelegateService } from 'src/app/services/users/delegate.service';
import { UserService } from 'src/app/services/user.service';
import { PrefService } from 'src/app/services/preferences/pref.service';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';

import { SendCredentialComponent } from 'src/app/defaults/modals/send-credential/send-credential.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { MessageService } from 'src/app/services/defaults/message.service';
import { ImportCsvService } from 'src/app/services/import-csv.service';
import { ImportCsvComponent } from 'src/app/defaults/import-csv/import-csv.component';
import { EventService } from 'src/app/services/event/event.service'

import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { SendItinerariesComponent } from 'src/app/defaults/modals/send-itineraries/send-itineraries.component';
@Component({
	selector: 'app-delegates',
	templateUrl: './delegates.component.html',
	styleUrls: ['./delegates.component.scss']
})
export class DelegatesComponent implements OnInit {
    @ViewChild( ImportCsvComponent ) child: ImportCsvComponent ;

    subscribeSearch = new Subject<string>();
  	autoCompleteCountry = new Subject<string>();
  	autoCompleteProvince = new Subject<string>();

    modalRef: BsModalRef;

  	public delegates : any;
  	public total_delegates = 0;
  	protected event_id: number = 0;

    country_limit = 239;
 	  protected limit = 9;
  	protected order = 'asc';
  	protected search = '';
  	protected page = 1;
  	protected sort = 'id';

  	protected keyword_country = '';
  	protected keyword_province = '';
  	protected filter_country = '';
    protected filter_province = '';
    protected fileIsLoaded = false;
    protected isDisplayPopup = false;
    protected withShadow = true;
    protected importMessages: any;
  	filter_search = false;

    public timeslot_schedules: any;
    protected date: any;

  showCountry = false;
  showProvince = false;

	focusedField = {
		country: false,
		province: false,
  };

  /* print config */
  protected printConfig: any = {
    'columnToShow': [
          {title: "Name",dataKey: "fullname"},
          {title: "Company",dataKey: "company_name"},
          {title: "Country",dataKey: "country"},
          {title: "Job Title",dataKey: "job_title"},
          {title: "Phone Number", dataKey: "mobile"},
          // {title: "Paid", dataKey: "paid"},
          /* {title: "Email",dataKey: "email"} */
        ]
  }

  constructor(
    public delegateService: DelegateService,
    private _user: UserService,
    public prefService: PrefService,
    private router: Router,
    private _route: ActivatedRoute,
    protected modalService: BsModalService,
    private titleService: Title,
    protected importCsv: ImportCsvService,
    protected messageService: MessageService,
    protected eventService : EventService,
    protected meetingSchedule : MeetingscheduleService,
  ) {
    this._user.requireLogIn();

    this.autoCompleteCountry.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        if(this.keyword_country == ''){
          this.filter_country = null;
          console.log('empty field');
          this.getDelegateList();
        }
        this.getCountries();
      });

    this.autoCompleteProvince.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.getProvince();

        this.filter_province = this.keyword_province;
        this.getDelegateList();
      });

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.filter_search = true;
        this.getDelegateList();
      });
  }

  ngOnInit() {
    this.titleService.setTitle("Event Delegates");
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });

    this.getDelegateList();

    // get countries
    this.getCountries();
    this.getDataOnFirstTab();
  }

  public getDataOnFirstTab() {
    let checker = setInterval(f => {
      let event = this.eventService.eventFormData
      if(event){
        if(event.days.length){
          this.getDelegatesSchedMeetings(event.days[0].formatted);
        }
        clearInterval(checker);
      }
    }, 100)
  }

  public getDelegatesSchedMeetings(date: any = ''){
    if(date){
      this.date = date;
    }
    let param = {
      'delegate' : this.delegates,
      'event_id' : this.event_id,
      'date' : this.date,
    }
    /* request for the meeting schedule list base on selected table */
    this.meetingSchedule.getMeetingSchedule(param).subscribe(
      schedules => {
        this.timeslot_schedules = schedules;
         /*console.log(this.timeslot_schedules.data.datas); */
      }
    );
  }


  getDelegateList(){

    this.delegates= null;
    this.total_delegates = 0;

    let thisData = {
      search: this.search,
      limit: this.limit,
      order: this.order,
      page: this.page,
      sort: this.sort,
      filter_country: this.filter_country,
      filter_province: this.filter_province,
      event_id: this.event_id
    }

    this.delegateService.getDelegate(thisData).subscribe(
      delegates => {
        if(delegates['error'] == 0){
          this.total_delegates = delegates['data']['total_count'];
          this.delegates = delegates['data'];
           /*console.log('123', this.delegates); */
        }
      }
    );
  }

  getCountries(){
    let url = this.prefService.getSetUrlParams('countries', 1, this.country_limit, this.keyword_country, 'name', 'asc');
    this.sendRequest(url, 'countries');
  }

  getProvince(){
    let url = this.prefService.getSetUrlParams('states', 1, this.country_limit, this.keyword_province, 'name', 'asc');
    url += 'countryid=' + this.filter_country;
    this.sendRequest(url, 'states');
  }

  sendRequest(url: string, type: string){
    /* console.log('url',url); */
    this.prefService.getData(url, type)
        .subscribe(data => {this.prefService.getHelper(data, type)
        });
  }

  focusInField(fieldname: string){
    let a = this;
    if(fieldname == 'country'){
      a.showCountry = true;
    }else if(fieldname == 'province'){
      a.showProvince = true;
    }
  }

  focusOutField(fieldname: string, controlName){
		this.focusedField[controlName] = true;
    let a = this;
    setTimeout(function(){
      if(fieldname == 'country'){
        a.showCountry = false;
        this.keyword_province = '';
        this.filter_province = '';
      }else if (fieldname == 'province'){
        a.showProvince = false;
      }
    }, 200);
  }

  setValue(displayName, value, variableName){
    if(variableName == 'country'){
      this.keyword_country = displayName;
      this.filter_country = value;
      this.keyword_province = '';
      this.filter_province = '';
      this.getProvince();
    }else if (variableName == 'province'){
      this.keyword_province = displayName;
      this.filter_province = value;
    }
    this.filter_search = true;

    this.getDelegateList();
  }

  searchDelegate(){
    this.filter_search = true;
    this.getDelegateList();
  }

  navigateTo(page: number) {
    this.page = page;
    this.getDelegateList();
	}

	jumpTo(e){
		this.navigateTo(e.target.value);
  }

  limitChange(){
    this.page = 1;
    this.getDelegateList();
  }

  sortChange(){
    this.getDelegateList();
  }

  clearFilters(){
    this.filter_search = false;
    this.filter_country = '';
    this.filter_province = '';
    this.keyword_country = '';
    this.keyword_province = '';
    this.search = '';
    this.getDelegateList();
    this.getCountries();
  }

  sendCredentials(delegates){
    if(delegates){

      let delegate_ids = [];
      delegates.forEach((data, idx) => {
        if(data){
          if(data.id > 0){
            delegate_ids.push(data.id);
          }
        }
      });

      let initialState = {
        delegate_ids: delegate_ids,
        event_id: this.event_id,
      };

      // Show Popup Modal
      this.modalRef = this.modalService.show(SendCredentialComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal-dialog-centered');

      this.modalRef.content.onClose = (data = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        /* console.log(data); */
      };
    }
  }

  sendItineraries(delegates){
    if(delegates){

      let delegate_ids = [];
      delegates.forEach((data, idx) => {
        if(data){
          if(data.id > 0){
            delegate_ids.push(data.id);
          }
        }
      });

      let initialState = {
        delegate_ids: delegates,
        event_id: this.event_id,
      };

      // Show Popup Modal
      this.modalRef = this.modalService.show(SendItinerariesComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal-dialog-centered modal-setting-delegate');

      this.modalRef.content.onClose = (data = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        /* console.log(data); */
      };
    }
  }

  protected downloadCsvTemplate(type : any = ''){
    this.importCsv.downloadCsvTemplate(type, this.event_id).subscribe(
      res => {
        if(res && res._body){
          /* console.log(res._body); */
          this.child.downloadFile(res._body,"import_delegate_template.csv");
        }
      }
    );
  }

  protected saveCsvData(type: any = ''){
    /*
    * "type"
    * variable is for the identification of function to be call in serverside or API
    */
    this.importCsv.saveCSVFileData(type, this.event_id).subscribe(
      response => {
        let m = {
          type: '',
          msg: '',
        }

        if(response.error != 0){
          m.type = 'danger';
        }else{
          m.type = 'success';
        }
        m.msg = response.message;

        this.importMessages = response.error_messages;
        this.messageService.add(m);

        this.fileIsLoaded = false;
        this.isDisplayPopup = false;
        this.getDelegateList();
      }
    );
  }
}
