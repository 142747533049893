import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { FaqsModalFormComponent } from 'src/app/modules/faqs/form/faqs-modal-form/faqs-modal-form.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { FaqsService } from 'src/app/services/faqs/faqs.service';
import { EventService } from 'src/app/services/event/event.service';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { EditFaqcategoryComponent } from 'src/app/defaults/modals/edit-faqcategory/edit-faqcategory.component';

@Component({
  selector: 'app-faqs-form',
  templateUrl: './faqs-form.component.html',
  styleUrls: ['./faqs-form.component.scss']
})
export class FaqsFormComponent implements OnInit {

  subscribeSearch = new Subject<string>();
  clearFilter = new Subject<string>();
  modalRef: BsModalRef;

  public keyword = '';
  public new_cetegory = '';

  public event_id;
  public faqsData:any = null;
  public faqsCategoryData: any = null;
  public dataIsLoaded = false;

  public keyword_category = '';
  private limit = 9;
  private page = 1;
  protected emptyDataOption: any = {
    name : 'All Categories',
    id : null  
  };
  public category_filter_option: any = this.emptyDataOption;
  public faqcategoryIsloaded = false;
  public toEdit = 0;
  public faqsIsLoaded = false;

  constructor(
    protected router: Router,
    protected _route: ActivatedRoute,
    protected _user: UserService,
    protected modalService: BsModalService,
    protected faqsService : FaqsService,
    protected eventService : EventService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this._user.requireLogIn();

    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });
    this.getFaqCategoryList();
    this.getFaqList();

    this.clearFilter.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.setFilterOption();
      });

    this.subscribeSearch.pipe(
    debounceTime(300),
    distinctUntilChanged())
    .subscribe(value => {
      this.getFaqList();
    });
  }

  get filterOption() {
    return this.category_filter_option;
  }
  set filterOption(value) { 
    this.category_filter_option = value === '' || value === null || value === undefined ? this.emptyDataOption : value;
  }

  public setFilterOption(option = null){
    this.filterOption = option;
    if(option){
      console.log(option);
      this.keyword_category = this.filterOption.name;
    }else{
      this.keyword_category = this.emptyDataOption.name;
    }
    this.getFaqList();
    /* console.log(option); */
    /*  */
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.faqsCategoryData.data.datas, event.previousIndex, event.currentIndex);
    let args = {
        'event_id' : this.event_id,
        'categories' : JSON.stringify(this.faqsCategoryData.data.datas)
    }

    this.faqsService.saveFaqCategorySorting(args).subscribe( r => {
      this.getFaqList();
    });
  }

  savecetegory(id = '',categoryname){
    if(categoryname != null ){
      this.new_cetegory = categoryname;
    }   
    
    let args = {
        'event_id' : this.event_id,
        'category' : this.new_cetegory,
        'id' : id,
    }

    this.faqsService.saveFaqCategory(args).subscribe( r => {
      let m = {
        type: 'danger',
        msg: '',
      }
      m.msg = r['message'];
      if(r['error'] == 0){
        this.getFaqCategoryList();
        this.getFaqList(); 
        this.new_cetegory = '';
        m.type = 'success';
      } else {
        m.type = 'danger';
      }

      this.messageService.add(m);
    });
  }

  toEditPreference(e, data:any){

    e.preventDefault(); 
    
    const config: ModalOptions = {
      keyboard: true,
      animated: true,
      initialState: {
        faqsCategory: data,
      title: 'Meeting Details'
      }
    };

		// Show Popup Modal
		this.modalRef = this.modalService.show(EditFaqcategoryComponent, config);
		this.modalRef.content.closeBtnName = 'Close';
		this.modalRef.setClass('modal_md modal-dialog-centered');
	
		this.modalRef.content.onClose = () => {
			// Do something with myData and then hide
      this.modalRef.hide();
		}

		this.modalRef.content.onSaveChanges = (id,categoryname) => {
      this.savecetegory(id,categoryname);
			this.modalRef.hide();
    }
	
	}

  
  openFaqForm(faq = null){
    if(this.faqsCategoryData){
      /* let initialState = {
        event_id: this.event_id,
        faqcategories : this.faqsCategoryData.data.datas,
        faqdata : faq,
      } */

      const config: ModalOptions = {
        keyboard: true,
        animated: true,
        initialState: {
          event_id: this.event_id,
          faqcategories : this.faqsCategoryData.data.datas,
          faqdata : faq,
        }
      };
  
      this.modalRef = this.modalService.show(FaqsModalFormComponent, config);
      this.modalRef.setClass('modal-dialog add-edit-agenda-form modal-dialog-centered');
      this.modalRef.content.onClose = () => {
        this.modalRef.hide();
      };
      this.modalRef.content.saveSuccess = () => {
        this.modalRef.hide();
        this.getFaqList(); 
      }
    }else{
      let m = {
        type: 'danger',
        msg: 'Please add a category first',
      }
      
      this.messageService.add(m);
    }
    
  }

  searchFaqs(){

  }

  getFaqList(){
    let args = {
        'event_id' : this.event_id,
        'page' : this.page,
        'limit' : this.limit,
        'keyword' : this.keyword,
        'filter_option' : this.filterOption.id
    }
    this.faqsIsLoaded = false;
    this.faqsService.getFaqList(args).subscribe( r => {
      this.faqsData = r;
      this.faqsIsLoaded = true;
      console.log('123123123', this.faqsData);
    });
  }

  getFaqCategoryList(){
    let args = {
        'event_id' : this.event_id,
    }
    this.faqcategoryIsloaded = false;
    this.faqsService.getFaqCategoryList(args).subscribe( r => {
      if(r.data){
        this.faqsCategoryData = r;
      }else{
        this.faqsCategoryData = null;
      }
      this.faqcategoryIsloaded = true;
    });
  }

  deleteCategory(id){
    let initialState = {
      img_src: 'delete-popup.png',
      title: 'Remove FAQs category?',
      msg: 'This will remove all the information related to this category both in the dashboard and mobile app.',
      btn_save: 'Delete',
      btn_close: 'Close',
      url: '/admin/speaker/'+this.event_id
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_sm modal-dialog-centered');

    this.modalRef.content.onClose = (pass = null) => {
      // Do something with myData and then hide
      this.modalRef.hide();

      /* console.log('pass',pass); */
      if(pass){
          let args = {
            'id' : id
          }
          this.faqsService.delegateCategory(args).subscribe( r => {
            let m = {
              type: 'danger',
              msg: '',
            }
            m.msg = r['message'];
            if(r['error'] == 0){
              m.type = 'success';
              this.getFaqList();
            } else {
              m.type = 'danger';
            }
            
            this.messageService.add(m);
            this.getFaqCategoryList();
          });
      }
    }
  }

  deleteFaq(id){
    let initialState = {
      img_src: 'delete-popup.png',
      title: 'Remove question?',
      msg: 'This will remove all the information related to this question both in the dashboard and mobile app.',
      btn_save: 'Delete',
      btn_close: 'Close',
      url: '/admin/speaker/'+this.event_id
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_sm modal-dialog-centered');

    this.modalRef.content.onClose = (pass = null) => {
      // Do something with myData and then hide
      this.modalRef.hide();

      /* console.log('pass',pass); */
      if(pass){
        let args = {
          'id' : id
        }
        this.faqsService.deleteFaq(args).subscribe( r => {

          this.getFaqList();

          let m = {
            type: 'danger',
            msg: '',
          }
          m.msg = r['message'];
          if(r['error'] == 0){
            m.type = 'success';
          } else {
            m.type = 'danger';
          }

          this.messageService.add(m);
        });
      }
    }
  }

  protected navigateTo(page){
    this.page = page;
    this.getFaqList();
  }

}
