import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'src/app/services/defaults/message.service';

import { SuccessModalComponent } from 'src/app/defaults/modals/success-modal/success-modal.component';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-event-profile-card-virtual',
  templateUrl: './event-profile-card-virtual.component.html',
  styleUrls: ['./event-profile-card-virtual.component.scss']
})
export class EventProfileCardVirtualComponent implements OnInit {

  modalRef: BsModalRef;

  public event_id = 0;
  protected userLogin: any;
  
  eventFormData: any;
  
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService,
    private _user: UserService,
    protected modalService: BsModalService,
    protected messageService: MessageService
  ) { 
    this._user.requireLogIn();

    this.userLogin = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit() {

  }

  archiveEvent(){
    // Show Popup Modal
    if(this.eventService.eventFormData){
      /* let initialState = {
        img_src: 'archive-popup.png',
        title: 'Archive Event?',
        msg: 'This will move the event to archive folder and delegates won\'t be able to access it on the mobile app.',
        btn_save: 'Archive Event',
        btn_close: 'Cancel',
        url: '/admin/event/archived',
        type: 'archived_event'
      };
      this.modalRef = this.modalService.show(SuccessModalComponent, {initialState});
      this.modalRef.setClass('modal_sm modal-dialog-centered');
      this.modalRef.content.closeBtnName = 'Close'; */

      if(this.eventService.eventFormData){
        let initialState = {
          img_src: 'archive-popup.png',
          title: 'Archive Event?',
          msg: 'This will move the event to archive folder and delegates won\'t be able to access it on the mobile app.',
          btn_save: 'Archive Event',
          btn_close: 'Cancel',
          url: '/admin/event/archived'
        };
  
        // Show Popup Modal
        this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
        this.modalRef.content.closeBtnName = 'Close';
        this.modalRef.setClass('modal_sm modal-dialog-centered');
  
        this.modalRef.content.onClose = (pass = null) => {
          // Do something with myData and then hide
          this.modalRef.hide();
          if(pass){
                    /* this.modalRef.content.onClose = (myData) => {
                      this.modalRef.hide();
                      if(myData){ */

                        if(this.eventService.eventFormData.id){

                          this.eventService.archiveEvent(this.eventService.eventFormData.id)
                          .subscribe(data => { 

                            let m = {
                              type: 'danger',
                              msg: '',
                            }
                            m.msg = data.message;

                            if(data['error'] == 0){
                              this.router.navigate(['/admin/event/archived']);
                              m.type = 'success';
                              this.messageService.add(m);
                            }else{
                              this.messageService.add(m);
                            }
                          })
                        }
                        
                     /*  }
                    }; */
          }
        }
      }
    }
  }

  removeEvent(){
    if(this.eventService.eventFormData){
      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Event?',
        msg: 'This will remove all the information related to this event both in the dashboard and mobile app.',
        btn_save: 'Delete',
        btn_close: 'Close',
        url: '/admin/event/archived'
      };

      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');

      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.eventService.removeEvent(this.eventService.eventFormData.id).subscribe(
            result => {
              let m = {
                type: 'danger',
                msg: 'Event not exists',
              }
              m.msg = result.message;
            
              if(result.error == 0){
    
                m.type = 'success';
                this.messageService.add(m);
                this.router.navigate(['/admin/event/archived']);
                
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

}
