import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 
import { environment } from 'src/app/lib/environment';
import { Urls } from 'src/app/lib/urls';
import { MessageService } from 'src/app/services/defaults/message.service';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

	public httpOptions = this.env.getHttpOptions();

	constructor(
		protected http:HttpClient,
		protected env: environment,
		protected messageService: MessageService
  ) { }

	/* --------------------------------
		Save new event manager
		If have "id" event manager
	 ----------------------------------*/
	save(data: any){

		const formData = new FormData();
		for (let propt in data) {
			formData.append(propt,data[propt]);
		}

	  	let httpOptions = this.env.getHttpOptions();
	  	let url = this.env.getUrl(Urls.api_admin_save);
	  	return this.http.post<any>(url, formData, httpOptions);
	}

	/*
	*	api call @event_id is the event ID
	*	@id is the id of the event manager
	*/
	getEventManager(param){
		let url = this.env.getUrl(Urls.api_admin_get);

		url += this.env.createUrlParam(param);
    	return this.http.get<any>(url, this.httpOptions);
	}

	/* ----------------------------------
	  Delete event manager
	  ----------------------------------*/
	removeEventManager(id: any, event_id: any){
		let formData = new FormData();
		formData.append('id', id);
		formData.append('event_id', event_id);

		let url = this.env.getUrl(Urls.api_admin_delete);
		return this.http.post<any>(url, formData, this.httpOptions);
	}
	/* ----------------------------------
	  End of Delete event manager
	  ----------------------------------*/


	/* ----------------------------------
	  Send credentail to Event manager
	  ----------------------------------*/
	sendCrentials(id= null){
		let url = this.env.getUrl(Urls.api_admin_sendcredential);

		let formData = new FormData();
		formData.append('id', id);

	  	let m = {
			type: 'danger',
			msg: '',
		}

	  	this.http.post<any>(url, formData, this.httpOptions)
			.subscribe(data => {
				/* console.log(data) */
				m.msg = data.message;
				if(data.error == 0){
					m.type = 'success';
				}
				this.messageService.add(m);
			});
	}


	/*
	*	api call @event_id is the event ID
	*	@id is the id of the event manager
	*/
	getEventManagerByEvent(param){
		let url = this.env.getUrl(Urls.api_admin_get);

		url += this.env.createUrlParam(param);
    	return this.http.get<any>(url, this.httpOptions);
	}

	/*
	*	api call @event_id is the event ID
	*	@id is the id of the event manager
	*/
	getAvailabelEventManagerByEvent(param){
		let url = this.env.getUrl(Urls.api_admin_availablemanager);

		url += this.env.createUrlParam(param);
    	return this.http.get<any>(url, this.httpOptions);
	}
}