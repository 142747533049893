import { Component, OnInit } from '@angular/core';

import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';

import { NoshowService } from 'src/app/services/noshow/noshow.service'
import { ReschedMeetingModalComponent } from 'src/app/modules/dashboard/schedule-meetings/resched-meeting-modal/resched-meeting-modal.component';

@Component({
  selector: 'app-noshow-details-modal',
  templateUrl: './noshow-details-modal.component.html',
  styleUrls: ['./noshow-details-modal.component.scss']
})
export class NoshowDetailsModalComponent implements OnInit {

  onClose: any;
  dismissAction: any;
  onSubmitApproval : any;
  modalRef: BsModalRef;

  constructor(
    private noShowService : NoshowService,
    protected meetingScheduleService : MeetingscheduleService,
    protected modalService: BsModalService,
    protected messageService: MessageService,
  ) { }

  ngOnInit() {
  }

  closeModal(){
    this.onClose();
  }

  
  protected approveNoShowReport(report_id){

    this.onClose();
    
    if(report_id){
      let initialState = {
        img_src: 'verify_noshow.png',
        title: 'Mark as no show?',
        msg: 'Are you sure you want to mark this delegate as no show? Both delegates will be available for this time slot if mark as no show.',
        btn_save: 'Mark as no show',
        btn_close: 'Close'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.noShowService.approveNoShowReport(report_id).subscribe(
            response => {
              this.onSubmitApproval();
              let m = {
                type: '',
                msg: '',
              }

              if(response['error'] != 0){
                m.type = 'danger';
              }else{
                m.type = 'success';
              }
              m.msg = response['message'];
              this.messageService.add(m);
            }
          );
        }else{
          this.dismissAction();
        }
      }
    }

  }

  protected disapproveNoShowReport(report_id){
    this.onClose();
    if(report_id){
      let initialState = {
        img_src: 'verify_noshow.png',
        title: 'Disapprove No Show Request?',
        msg: 'Are you sure you want to disapprove this no show request?',
        btn_save: 'Disapprove',
        btn_close: 'Close'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.noShowService.disapproveNoShowReport(report_id).subscribe(
            response => {
              if(response){
                this.onSubmitApproval();
                let m = {
                  type: '',
                  msg: '',
                }
  
                if(response['error'] != 0){
                  m.type = 'danger';
                }else{
                  m.type = 'success';
                }
                m.msg = response['message'];
               
                this.messageService.add(m);
              }
            }
          );
        }else{
          this.dismissAction();
        }
      }
    }

  }

}
