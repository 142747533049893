import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';


/* Components */
import { NoshowDetailsModalComponent } from 'src/app/modules/dashboard/noshow-board/noshow-details-modal/noshow-details-modal/noshow-details-modal.component';
import { MeetingDetailsModalComponent } from 'src/app/modules/dashboard/tables/meeting-details-modal/meeting-details-modal.component';
import { SidebarComponent } from 'src/app/defaults/sidebar/sidebar.component'

/* Services */
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service'
import { TableService } from 'src/app/services/tables/table.service'
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user.service';
import { NoshowService } from 'src/app/services/noshow/noshow.service';

/* socket config */
import { WebSocketService } from 'src/app/services/websocket/web-socket.service';
import { environment } from 'src/app/lib/environment';


@Component({
  selector: 'app-cancellation-board',
  templateUrl: './cancellation-board.component.html',
  styleUrls: ['./cancellation-board.component.scss']
})
export class CancellationBoardComponent implements OnInit{

  modalRef: BsModalRef;
  
  public event_id = 0;
  protected page = 1;
  protected limit = 10;
  
  constructor(
    protected meetingSchedService : MeetingscheduleService,
    protected modalService: BsModalService,
    protected eventService : EventService,
    protected noShowService : NoshowService,
    protected tableService : TableService,
    protected _route: ActivatedRoute,
    protected _user: UserService,
    protected env : environment,
    protected router: Router
  ) { 
  }

  ngOnInit() {
    this._user.requireLogIn();
    /* get the url parameters */
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
    });
  }

  /* show the meeting schedule details in popup */
  protected viewMeetingDetails(meetingSchedData){
    let initialState = {
      meetingSchedData: meetingSchedData,
      title: 'Meeting Details'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(MeetingDetailsModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_md modal-dialog-centered');

    this.modalRef.content.onClose = () => {
      // Do something with myData and then hide
      this.modalRef.hide();
    }

    /* after submitting cancellation api request update the list */
    this.modalRef.content.onSubmitCancellation = () => {
      let args = {
        'event_id': this.event_id
      }
      this.meetingSchedService.getCancellationRequests(args);
    }

    this.modalRef.content.onCancelRescheduling = () => {
      this.viewMeetingDetails(meetingSchedData);
    }

    this.modalRef.content.cancelDeletion = () => {
      this.viewMeetingDetails(meetingSchedData);
    }
  }

  protected navigateTo(page){
    this.page = page;

    let args = {
      'event_id': this.event_id,
      'limit' : this.limit,
      'page' : this.page
		}
    this.meetingSchedService.getCancellationRequests(args);
  }

  protected jumpTo(e){
		this.navigateTo(e.target.value);
  }

}
