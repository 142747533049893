import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import * as moment from 'moment'

/* models */
import { Event } from 'src/app/lib/models/events/event';

import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';

import { DelegateAddEditComponent } from './../../defaults/modals/delegate-add-edit/delegate-add-edit.component';
import { DelegateService } from './../users/delegate.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from './../../services/defaults/message.service';
import { GeneralService } from 'src/app/services/general.service';
import { copyStyles } from '@angular/animations/browser/src/util';

//set http header
const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class EventService extends DelegateService {

  public fullwidth: boolean = true;
  public eventFormData: any;
  public eventFormDataLoading = true;
  public isEditingContent = false;
  public event_id: any;
  public zoom: number = 10;


  public event_status = {
    'publish' : 1,
    'draft' : 2,
    'archive' : 3,
    'deleted' : 4,
    'ongoing' : 5,
    'past' : 6,
  }

  public progress_number = {
    'event_details' : 1,
    'delegates' : 2,
    'tables' : 3,
    'speakers' : 4,
    'schedules_and_agendas' : 5,
    'sponsors' : 6,
  }

  eventDetails = new EventEmitter();
  eventFullDetails: Event = {
    id: 0,
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    progress: 0,
    image_url: "",
    organizer: "",
    start_time: "",
    end_time: "",

    type: 1,

    event_address: "",
    time_zone: "",
    lat: "",
    long: "",
    request_start_date: "",
    request_end_date: "",
    request_start_time: "",
    request_end_time: "",
    set_meeting: true,
    meeting_request: "1",
    cancellation_request: "1",
    image_banner: "",
    image_letterhead: "",
    image_floor_plan: "",
    image_icon: "",
    banner_image_src : "",
    letterhead_image_src : "",
    icon_image_src : "",
    floorplan_image_src : "",
    permission: "1",
    registration_link: "",
    permission_options: {},
    display_noshow: true,
    event_date : '',
    publish_date : '',
    event_date_virtual_itinerary: ''
  };
  selectedNetworkIds: any = [];


  public httpOptions = this.env.getHttpOptions();
  public tab_type = "ongoing"; /* ongoing default */
  public search_key = "";

  public notif_current_page = 1;
  public notifData: any;

  modalRef: BsModalRef;

  constructor(
    protected http:HttpClient,
    protected env: environment,
    protected modalService: BsModalService,
    protected messageService: MessageService,
		protected gen : GeneralService
  ) {
    super(http, messageService, env, modalService)
  }

  getEventNotif(notif_args){
    this.getEventNotifications(notif_args).subscribe(
      notifs => {
        this.notifData = notifs;
        this.notif_current_page = notifs.data.current_page;
      }
    );
  }

  saveEventDetails():Observable<any>{
    let url = this.env.getUrl(Urls.api_events_save);

    let i, x = "";
    let event_data = this.eventFullDetails;
    const start_date = new Date(event_data.start_date);
    event_data.start_date = moment(start_date).format('MM/DD/YYYY');

    const  end_date = new Date(event_data.end_date);
    event_data.end_date = moment(end_date).format('MM/DD/YYYY');

    const request_start_date = new Date(event_data.request_start_date);
    event_data.request_start_date = moment(request_start_date).format('MM/DD/YYYY');

    const request_end_date = new Date(event_data.request_end_date);
    event_data.request_end_date = moment(request_end_date).format('MM/DD/YYYY');

    let permission_options_json = { 'registration_link': this.eventFullDetails.registration_link , 'selected_networkIds': this.selectedNetworkIds };
    this.eventFullDetails.permission_options = JSON.stringify(permission_options_json);

    const formData = new FormData();
    for (let propt in event_data) {
      console.log(event_data[propt]);
      formData.append(propt,event_data[propt]);
    }

    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  public saveEventSummary(event_data, event_id){
    let data = event_data;
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,event_data[propt]);
    }
    formData.append('tosave', 'eventsummary');
    formData.append('event_id',event_id);

    let url = this.env.getUrl(Urls.api_events_save);

    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  public saveEventAfterMovie(event_data, event_id){
    let data = event_data;
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,event_data[propt]);
    }
    formData.append('tosave', 'aftermovie');
    formData.append('event_id',event_id);

    let url = this.env.getUrl(Urls.api_events_save);

    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  public publishEvent(event_Id){
    let url = this.env.getUrl(Urls.api_events_publish);

    const formData = new FormData();
    formData.append('event_id',event_Id);
    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  /* Get event listing data */
  /* getEventList($type = "", $search_key = ""){ */
  getEventList(param){
    /* this.tab_type = $type;
    this.search_key = $search_key; */
    let url = this.env.getUrl(Urls.api_events_get);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.env.getHttpOptions());
  }

  /* Get archive event data */
  getEventArchiveList(){
    let url = this.env.getUrl(Urls.api_events_get);
    return this.http.get<any>(url, this.env.getHttpOptions());
  }

  getEvent($event_id){
    // console.log('event accessed physical ck');
    if($event_id){
      let url = this.env.getUrl(Urls.api_events_get) + "?event_id=" + $event_id;
      this.eventFormDataLoading = true;
      this.event_id = $event_id;
      this.http.get<any>(url, this.env.getHttpOptions()).subscribe(
        eventData => {
          this.eventFormDataLoading = false;
          this.setEventFullDetails(eventData.data);
          this.eventFormData = this.eventFullDetails;
      });
      return this.http.get<any>(url, this.env.getHttpOptions());
    }
  }

  setEventFullDetails(data){
    this.eventFullDetails = data;
    this.eventFullDetails.publish_date = new Date(data.publish_date);
    console.log('data',this.eventFullDetails);
  }

  getEventVirtual(event_id, delegateTz?){
    // console.log('ck accessed')
    if(event_id){
      let url = this.env.getUrl(Urls.api_events_getvirtual) + "?event_id=" + event_id;
      this.eventFormDataLoading = true;
      this.event_id = event_id;
      this.http.get<any>(url, this.env.getHttpOptions()).subscribe(
        eventData => {
          this.eventFormDataLoading = false;
          this.setEventFullDetailsVirtual(eventData.data);
          this.eventFormData = this.eventFullDetails;
      });
      return this.http.get<any>(url, this.env.getHttpOptions());
    }
  }



  setEventFullDetailsVirtual(data){
    this.eventFullDetails = data;
    this.eventFullDetails.publish_date = new Date(data.publish_date);
    console.log('data',this.eventFullDetails);
  }


  public setEventDetails(eventDetails){
    this.eventFullDetails.name = eventDetails.eventName;
    this.eventFullDetails.organizer = eventDetails.hostName;
    this.eventFullDetails.start_date = eventDetails.startDate;
    this.eventFullDetails.end_date = eventDetails.endDate;
    this.eventFullDetails.start_time = eventDetails.startTime;
    this.eventFullDetails.end_time = eventDetails.endTime;
    this.eventFullDetails.description = eventDetails.description;
  }

  public setType(eventType){
    this.eventFullDetails.type = eventType.type;

    console.log('full details', this.eventFullDetails);
  }

  public setEventAddress(eventAddressDetails){
    this.eventFullDetails.event_address = eventAddressDetails.eventAddress;
    this.eventFullDetails.time_zone = eventAddressDetails.timeZone;
    this.eventFullDetails.lat = eventAddressDetails.lat;
    this.eventFullDetails.long = eventAddressDetails.long;
  }

  public setEventSchedulerDetails(eventSchedulerDetails){
    this.eventFullDetails.request_start_date = eventSchedulerDetails.scheduleStartDate;
    this.eventFullDetails.request_start_time = eventSchedulerDetails.scheduleStartTime;
    this.eventFullDetails.request_end_date = eventSchedulerDetails.scheduleEndDate;
    this.eventFullDetails.request_end_time = eventSchedulerDetails.scheduleEndTime;
    this.eventFullDetails.meeting_request = eventSchedulerDetails.meetingRequest;
    this.eventFullDetails.cancellation_request = eventSchedulerDetails.cancellationRequest;
    this.eventFullDetails.set_meeting = eventSchedulerDetails.setMeeting;
  }

  setEventPrivacyDetails(data){
    this.eventFullDetails.permission = data.privacy;
    this.eventFullDetails.registration_link = data.registrationLink;
    this.eventFullDetails.permission_options = data.selectedNetwork;
  }

  setEventBannerImage(data = null){
    if(data !== null){
      if(data === 'isRemoved'){
        console.log(data);
        this.eventFullDetails.image_banner = data;
        this.eventFullDetails.banner_image_src = '';
      }else{
        let fileOfBlob = new File([data.file], this.rename());

        this.eventFullDetails.banner_image_src = data.base64;
        this.eventFullDetails.image_banner = fileOfBlob;
      }
    }else{
      this.eventFullDetails.image_banner = "";
    }
  }
  setEventLetterheadImage(data = null){
    if(data !== null){
      if(data === 'isRemoved'){
        this.eventFullDetails.image_letterhead = data;
        this.eventFullDetails.letterhead_image_src = '';
      }else{
        let fileOfBlob = new File([data.file], this.rename());

        this.eventFullDetails.letterhead_image_src = data.base64;
        this.eventFullDetails.image_letterhead = fileOfBlob;
      }
    }else{
      this.eventFullDetails.image_letterhead = "";
    }
  }
  setEventIconImage(data = null){
    if(data !== null){
      if(data === 'isRemoved'){
        this.eventFullDetails.image_icon = data;
        this.eventFullDetails.icon_image_src = '';
      }else{
        let fileOfBlob = new File([data.file], this.rename());

        this.eventFullDetails.icon_image_src = data.base64;
        this.eventFullDetails.image_icon = fileOfBlob;
      }
    }else{
      this.eventFullDetails.image_icon = "";
    }
  }
  setEventFloorplanImage(data = null){
    if(data !== null){
      if(data === 'isRemoved'){
        this.eventFullDetails.image_floor_plan = data;
        this.eventFullDetails.floorplan_image_src = '';
      }else{
        let fileOfBlob = new File([data.file], this.rename());

        this.eventFullDetails.floorplan_image_src = data.base64;
        this.eventFullDetails.image_floor_plan = fileOfBlob;
      }
    }else{
      this.eventFullDetails.image_floor_plan =  "";
    }
  }

  private rename(){
    return this.fileNameGenerator(8)+'.png';
  }

  private fileNameGenerator(len){
    let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz,randomPoz+1);
    }
    return randomString;
  }

  /* ----------------------------------
    Get List of Delegate Events
    ----------------------------------*/
  getEventDelegate(delegate_id, type){
    this.tab_type = type;
    let url = this.env.getUrl(Urls.api_eventdelegate_get) + "?delegate_id=" + delegate_id + "&status="+ type;
    return this.http.get<any>(url, this.env.getHttpOptions());
  }
  /* ----------------------------------
    End of Get List of Delegate Events
    ----------------------------------*/

/* ================== add delegate into an event ================== */
  addDelegate (formData){
    let url = this.env.getUrl(Urls.api_events_adddelegate);
    return this.http.post<any>(url, formData, this.env.getHttpOptions())
        .subscribe(data => {
          this.addDelegateAfter(data);
        });
  }

  addDelegateAfter(data){
    let initialState = {
      redirectUrl: 'admin/event/delegates/' + this.event_id
    };
    this.modalRef = this.modalService.show(DelegateAddEditComponent, {initialState});
    this.modalRef.setClass('modal_sm modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
      if(myData.info.action == 'send_credentials'){
        this.sendCrentials(data.data.delegate_id);
      }
    };
  }

  addDelegateVirtual (formData, delegate){

    console.log('nero', delegate);

    let url = this.env.getUrl(Urls.api_events_adddelegatevirtual);
    return this.http.post<any>(url, formData, this.env.getHttpOptions())
        .subscribe(data => {
          this.addDelegateAfterVirtual(data);

          // Create Zoom Account
          var firstName = delegate.fullname.split(' ').slice(0, -1).join(' ');
          var lastName = delegate.fullname.split(' ').slice(-1).join(' ');
          let timezone = delegate.timezone.split(':');

          let url_zoom_account = this.env.getUrl(Urls.api_virtualconference_createzoomaccount);

          let formData_zoom_account = {
            delegate_id: delegate.id,
            email: delegate.email,
            first_name: firstName,
            last_name: lastName
          };
          console.log('formData_zoom_account', formData_zoom_account);

          this.gen.post(url_zoom_account, formData_zoom_account).subscribe(data=>{
            // New Zoom account

          let formData_meeting = {
            conference_delegate_id: data.data.conference_delegate_id,
            delegate_id: data.data.delegate_id,
            zoom_account_id: data.data.zoom_account_id,
            description: delegate.fullname+ "'s Meeting",
            type: 3,
            //start_time: 2021-02-18T09:40:00
            timezone: timezone[0],
            //duration: 20
          };

            if(data.error == 0){
              this.createZoomMeeting(formData_meeting);

            }else{
              // Zoom account already exist
              if(data.code == 1005){
                this.createZoomMeeting(formData_meeting);
              }
            }
          });
        });
  }

  // Create zoom meeting
  createZoomMeeting(formData){
    let url = this.env.getUrl(Urls.api_virtualconference_setmeetingschedule);

    console.log('formData_zoom_meeting', formData);

    this.gen.post(url, formData).subscribe(data=>{
      if(data.error == 0){
        console.log('zoom_meeting_created', data);
      }
    });
  }

  addDelegateAfterVirtual(data){
    let initialState = {
      redirectUrl: 'admin/event/delegates-virtual/' + this.event_id
    };
    this.modalRef = this.modalService.show(DelegateAddEditComponent, {initialState});
    this.modalRef.setClass('modal_sm modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
      if(myData.info.action == 'send_credentials'){
        this.sendCrentials(data.data.delegate_id);
      }
    };
  }
/* ================== end add delegate into an event ================== */

  /* ----------------------------------
  Get Attributes Count
  ----------------------------------*/
  getAttributesCount(){
    let url = this.env.getUrl(Urls.api_attributes_get);
    return this.http.get<any>(url, this.env.getHttpOptions());
  }
  /* ----------------------------------
    End of Get Attribute Count
    ----------------------------------*/

  /* ----------------------------------
  Get Attributes Count
  ----------------------------------*/
	archiveEvent(event_id: any){
		let formData = new FormData();
		formData.append('id', event_id);

		let url = this.env.getUrl(Urls.api_events_archive);
		return this.http.post<any>(url, formData, this.env.getHttpOptions());
	}
  /* ----------------------------------
    End of Get Attribute Count
    ----------------------------------*/

	/* ----------------------------------
	  Delete Event
	  ----------------------------------*/
	removeEvent(event_id: any){
		let formData = new FormData();
		formData.append('id', event_id);

		let url = this.env.getUrl(Urls.api_events_delete);
		return this.http.post<any>(url, formData, this.env.getHttpOptions());
	}
	/* ----------------------------------
	  End of Delete Event
    ----------------------------------*/


	/* ----------------------------------
	  Get timezone name
	  ----------------------------------*/
	getTimezoneLabel(lat, long){
    let url = this.env.getUrl(Urls.api_events_gettimezone) + "?lat=" + lat + "&long="+ long;
    return this.http.get<any>(url, this.env.getHttpOptions());
	}
	/* ----------------------------------
	  End of Delete Event
    ----------------------------------*/

  getSummaryOfMeetings(param){
    let url = this.env.getUrl(Urls.api_meetingschedule_getmeetingsummary);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.env.getHttpOptions());
  }


  getEventNotifications(param){
    let url = this.env.getUrl(Urls.api_notification_getadminnotif);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.env.getHttpOptions());
  }

  markAsRead(notifId){
    let formData = new FormData();
		formData.append('notifId', notifId);

		let url = this.env.getUrl(Urls.api_notification_readadminnotif);
		return this.http.post<any>(url, formData, this.env.getHttpOptions());
  }
}
