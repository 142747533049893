import { Component, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-event-listing',
  templateUrl: './event-listing.component.html',
  styleUrls: ['./event-listing.component.scss']
})
export class EventListingComponent implements OnInit {

 /*  @Input() ongoing_events : Event ;
  @Input() upcoming_events : Event ;
  @Input() past_events : Event ; */
  searchOnStopTyping = new Subject<string>();
  public events:Event[];
  private isRequesting = true;
  private noEventFound = false;
  private search_key = '';
  private isSearching = false;
  private type = '';
  private limit = 10;
  private page = 1;
  private delegate_id = null;

  public add_menu: boolean = false;

  public eventEmptyDetails: Event = {
    id: 0,
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    progress: 0,
    image_url: "",
    organizer: "",
    start_time: "",
    end_time: "",
    event_address: "",
    time_zone: "",
    lat: "",
    long: "",
    request_start_date: "",
    request_end_date: "",
    request_start_time: "",
    request_end_time: "",
    set_meeting: true,
    meeting_request: "1",
    cancellation_request: "1",
    image_banner: "",
    image_letterhead: "",
    image_floor_plan: "",
    image_icon: "",
    banner_image_src : "",
    letterhead_image_src : "",
    icon_image_src : "",
    floorplan_image_src : "",
    permission: "1",
    registration_link: "",
    permission_options: {},
    display_noshow: true,
    event_date: '',
    publish_date : '',

    type : 1,
    event_date_virtual_itinerary: ''
  };

  constructor(
    private eventService : EventService,
    private router: Router,
  ) {  }

  ngOnInit() {
    this.getEventList('ongoing');

    this.searchOnStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => {
      this.isSearching = true;
      this.search_key = value;
      this.searchEvent(value);
    });

    this.eventService.eventFullDetails = this.eventEmptyDetails;
  }

  add_menu_toggle(){
    if(this.add_menu == false){
      this.add_menu = true;
    }else{
      this.add_menu = false;
    }
  }

  searchEvent(search_key = ""){
    let type = this.type;
    this.getEventList(type, search_key);
  }

  selectTab(type = ""){
    this.page = 1;
    let search_key = this.search_key;
    this.getEventList(type, search_key);
  }

  getEventList(type = "", $search_key = ""){
    this.isRequesting = true;
    this.noEventFound = false;
    this.events = null;

    if($search_key){
      this.search_key = $search_key;
    }
    if(type){
      this.type = type;
    }
    let param = {
      'status': this.type,
      'search' : this.search_key,
      'limit' : this.limit,
      'page' : this.page
    }

    this.eventService.getEventList(param).subscribe(
      events => {
        if(events.error == 0){
          this.isRequesting = false;
          this.events = events;
          if(this.events.length == 0){
            this.noEventFound = true;
          }
          console.log('event details', this.events);
        }
      }
    );
  }

  addNewEvent(){
    this.eventService.eventFullDetails = this.eventEmptyDetails;
    this.router.navigate(['/admin/event/add/event-details']);
  }

  addnewVirtualEvent(){
    this.eventService.eventFullDetails = this.eventEmptyDetails;
    this.router.navigate(['/admin/event/add/event-details-virtual']);
  }

  protected navigateTo(page){
    this.page = page;
    this.getEventList();
  }


}
