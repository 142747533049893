import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {

	backLink = {
		link: '/admin/delegates',
		text: 'Go back to list of delegates',
		cancel: '/admin/delegates'
	};

	constructor(
		private _user: UserService,
	) { 
		this._user.requireLogIn();
	}

	ngOnInit() {
	}

}
