import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../lib/environment';
import { Observable, of } from 'rxjs';
import { Socket } from 'ngx-socket-io';


@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends Socket{

  readonly url : string = '';

  constructor(
    socket_config : any
  ) {
    /* super({ url: 'http://panel.face2facescheduler.com:3001', options: {query:{is_admin: 1, event_id : 1 }} }); */
    super(socket_config);
  }

  emitToSocket(evt_name:any, data){
    this.emit(evt_name, data);
  }

  closeSocket() {
    this.disconnect()
  }

}
