import { Component, OnInit, ViewChild } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
/* services */
import { EventService } from 'src/app/services/event/event.service';
import { StatisticsService } from 'src/app/services/event/statistics.service';
import { Chart } from 'chart.js';
import * as moment from 'moment'

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-statistics',
  templateUrl: './event-statistics.component.html',
  styleUrls: ['./event-statistics.component.scss']
})
export class EventStatisticsComponent implements OnInit {
  
  @ViewChild('barGraph') private tableStatsRef;
  @ViewChild('featureComparison') private featureComparisonStatsRef;
  @ViewChild('platform') private platformStatsRef;
  @ViewChild('paymentStats') private paymentStatsRef;
  @ViewChild('meetingSchedules') private meetingSchedulesRef;
  @ViewChild('sessionandmessages') private SessionMessageRef;

  public tablestatisticsChart: any;
  public featureComparisonChart: any;
  public platformChart: any;
  public meetingSchedulesChart: any;
  public sessionsChart: any;
  public SessionsAndMessages: any = null;
  public paymentChart: any;


  public sort_direction = 'asc';
  public event_id;
  public companyPerCountry : any = null;
  public companyPerCountryIsLoaded = false;
  public delegateCount : any = null;
  public delegateCountIsLoaded = false;

  public current_tab = '';
  public page = 1;
  public page2 = 1;
  public color_code : any = [];

  public android_label;
  public ios_label;
  public android_per;
  public ios_per;
  public android;
  public ios;
  public android_color;
  public ios_color;
  public number_of_meeting_sched;

  public total_devices = 0;

  protected c_s = 'country';
  protected c_sd = 'asc';


  protected paid_delegates = 0
  protected not_paid_delegates = 0;


  public bsInlineValue = new Date();
  public dateFilterRange:Date [];
  public maxDate = new Date();
  public dates: any;
  public timezone : any;
  public publish_date : any;

  constructor(
    private statisticsService : StatisticsService,
    private eventService : EventService,
    private _route: ActivatedRoute,
    private _user: UserService,
    
  ) {
    this.bsInlineValue.setDate(this.maxDate.getDate() - 7);
    this.dateFilterRange = [this.bsInlineValue, this.maxDate];
  }

  ngOnInit() {
    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
      this.getCompniesCount();
    });

    /* Table statistics */
    this.getStatistics();
    this.getSessionAndMessages();
  }


  protected createRange(number){
    var items: number[] = [];
    for(var i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }

  public sortCompanyCount(sort){
    this.c_s = sort;
    if(this.c_sd == 'asc'){
      this.c_sd = 'desc';
    }else{
      this.c_sd = 'asc';
    }
    this.getCompniesCount();
  }

  public sortDelegateCount(sort){
    this.c_s = sort;
    if(this.c_sd == 'asc'){
      this.c_sd = 'desc';
    }else{
      this.c_sd = 'asc';
    }
    this.getDelegatesCount();
  }
  
  protected getCompniesCount(){
    let arg = {
      'event_id' : this.event_id,
      'page' : this.page,
      'sortby' : this.c_s,
      'direction' : this.c_sd,
    }
    this.companyPerCountryIsLoaded = false;
    this.statisticsService.getCompaniesCountAPI(arg).subscribe( r => {
      this.companyPerCountry = r;
      this.companyPerCountryIsLoaded = true;
    });
  }

  protected selectTab(tab){
    this.current_tab = tab;
    this.page2 = 1;

    this.c_s = 'country';
    this.c_sd = 'asc';

    this.getDelegatesCount();
  }

  protected getDelegatesCount(){
    let arg = {
      'event_id' : this.event_id,
      'page' : this.page2,
      'section' : this.current_tab,
      'sortby' : this.c_s,
      'direction' : this.c_sd,
    }
    this.delegateCount = null;
    this.delegateCountIsLoaded = false;
    this.statisticsService.getDelegatesCountAPI(arg).subscribe( r => {
      this.delegateCount = r;
      this.delegateCountIsLoaded = true;
    });
  }

  protected companiesCountNavigateTo(page){
    this.page = page;
    this.getCompniesCount();
  }

  protected delegatesCountNavigateTo(page){
    this.page2 = page;
    this.getDelegatesCount();
  }


  protected getSessionAndMessages(){

    let day_start = moment(this.dateFilterRange[0]).format('MM/DD/YYYY');
    let day_end = moment(this.dateFilterRange[1]).format('MM/DD/YYYY');
    this.dates = [day_start,day_end];

    let sessionsChartDays : any = [];
    let sessionsData : any = [];
    let messagesData : any = [];

    let arg = {
      'event_id' : this.event_id,
      'dates' : this.dates,
      'timezone' : this.timezone, 
    }

    /* sessions and messages */
    this.statisticsService.getSessionsMessagesStatistics(arg).subscribe( r => {

      if(r.data){
        r.data.forEach(e => {
          sessionsChartDays.push(e.formatted);
          sessionsData.push(e.number_of_sessions);
          messagesData.push(e.number_of_messages);
        });
      }

      if(this.sessionsChart){
        this.sessionsChart.destroy();
      }

      this.sessionsChart = new Chart(this.SessionMessageRef.nativeElement, {
        type: 'bar',
        data: {
          labels: sessionsChartDays, // your labels array
          datasets: [
            {
              data: sessionsData, // your data array
              borderColor: '#0a3b8c',
              backgroundColor: '#0a3b8c',
            },
            {
              data: messagesData, // your data array
              borderColor: '#688b06',
              backgroundColor: '#688b06',
            }
          ]
        },
        options: {
          responsive: true,
          chartArea: {
            backgroundColor: 'rgba(237, 237, 237, .6)'
          },
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: true,
              barThickness: 20,
              categoryPercentage: .5,
              barPercentage: .5,
              ticks: {
                fontFamily: "'Rubik', sans-serif",
                fontSize:16
              },
              gridLines: {
                display:false,
              }
            }],
            yAxes: [{
              display: true,
              categoryPercentage: .5,
              barPercentage: .5,
              ticks: {
                  min: 0,
                  fontFamily: "'Rubik', sans-serif",
                  fontSize:16,
                  userCallback: function(label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    /* console.log(Math.floor(label));
                    console.log(parseInt(label)); */
                    if (Math.floor(label) ==  label) {
                        return label;
                    }
                },
              },
            }],
          }
        }
      });
      
    });
  }

  protected getStatistics(){
    var day_count = 0;
    let arg = {
      'event_id' : this.event_id
    }
    let days: any = [];
    let used_table_count : any = [];
    let unused_table_count : any =[];
    let featureComparisonLabels : any = [];
    let featureComparisonAndroidStats : any = [];
    let featureComparisonIOSStats : any = [];
    let number_of_meeting_sched : any = [];
    let android_per = 0;
    let ios_per = 0;
    let android = 0;
    let ios = 0;
    let total_tables = 0;

    let a = this;

    Chart.defaults.global.defaultFontSize = 16;
    this.statisticsService.getTableStatisticsAPI(arg).subscribe( r => {
      if(r.data){
        total_tables = r.data.total_event_tables;
        r.data.event_days_stats.forEach(e => {
          day_count++;

          days.push('Day '+ day_count);
          used_table_count.push(e.number_of_table_used);
          unused_table_count.push(total_tables - e.number_of_table_used);
          number_of_meeting_sched.push(e.meeting_schedules);
          

          if(day_count == 1){ 
            this.color_code.push('#688b06');
          }else if(day_count == 2){
            this.color_code.push('#0a3b8c');
          }else if(day_count == 3){
            this.color_code.push('#F9CE16');
          }else if(day_count == 4){
            this.color_code.push('#F87415');
          }else if(day_count == 5){
            this.color_code.push('#D71933');
          }else{
            this.color_code.push(
              '#'+'0123456789abcdef'.split('').map(function(v,i,a){
                return i>5 ? null : a[Math.floor(Math.random()*16)] 
              }).join('')
            );
          }

        });

        r.data.feature_comparison_stats.forEach(e => {
          featureComparisonLabels.push(e.type);
          featureComparisonAndroidStats.push(e.android);
          featureComparisonIOSStats.push(e.ios);
        });

        this.paid_delegates = r.data.payment_status_stats.count_paid_delegates;
        this.not_paid_delegates = r.data.payment_status_stats.count_not_paid_delegates;


        this.total_devices = r.data.platform_stats.total_devices;
        
        this.android_label = r.data.platform_stats.android_label;
        this.android_color = r.data.platform_stats.android_color;
        this.ios_label = r.data.platform_stats.ios_label;
        this.ios_color = r.data.platform_stats.ios_color;
        this.android_per = r.data.platform_stats.android_per;
        this.ios_per = r.data.platform_stats.ios_per;
        this.android = r.data.platform_stats.android;
        this.ios = r.data.platform_stats.ios;
      }

      Chart.pluginService.register({
        beforeDraw: function (chart, easing) {
          if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
            var helpers = Chart.helpers;
            var ctx = chart.chart.ctx;
            var chartArea = chart.chartArea;
  
            ctx.save();
            ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
            ctx.restore();
          }
        }
      });

      

      /* table statistics */
      this.tablestatisticsChart = new Chart(this.tableStatsRef.nativeElement, {
          type: 'bar',
          data: {
            labels: days, // your labels array
            datasets: [
              {
                label: 'Used Tables',
                data: used_table_count, // your data array
                borderColor: '#688b06',
                backgroundColor: '#688b06',
              },
              {
                label: 'Unused Tables',
                data: unused_table_count, // your data array
                borderColor: '#cacaca',
                backgroundColor: '#cacaca',
              }
            ]
          },
          options: {
              animation: {
                duration: 10,
              },
              tooltips: {
                mode: 'label',
                callbacks: {
                label: function(tooltipItem, data) { 
                  return data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.yLabel;
                }
                }
              },
              responsive: true,
              chartArea: {
                  backgroundColor: 'rgba(237, 237, 237, .6)'
              },
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              scales: {
                xAxes: [{ 
                  stacked: true, 
                  fontFamily: "'Rubik', sans-serif",
                  fontSize:16,
                  gridLines: { display: false },
                }],
                yAxes: [{ 
                  stacked: true, 
                  ticks: {
                    max: total_tables,
                    fontFamily: "'Rubik', sans-serif",
                    fontSize:16,
                    callback: function(value) { return value; },
                    labelCallback: function(label, index, labels) {
                        // when the floored value is the same as the value we have a whole number
                        /* console.log(Math.floor(label));
                        console.log(parseInt(label)); */
                        if (Math.floor(label) ==  label) {
                            return label;
                        }
                    },
                  }, 
                }],
              },
          }
        });


      /* feature comparison */
      this.featureComparisonChart = new Chart(this.featureComparisonStatsRef.nativeElement, {
        type: 'horizontalBar',
        data: {
          labels: featureComparisonLabels,
          datasets: [{
            label: 'Android',
            data: featureComparisonAndroidStats,
            backgroundColor: '#688b06',  
          },
          {
            label: 'iOS',
            data: featureComparisonIOSStats,
            fill: true,
            backgroundColor: '#0a3b8c',
            borderColor: '#0a3b8c',
          }]
        },
        options: {
          tooltips: {
              enabled: true
          },
          chartArea: {
            backgroundColor: 'rgba(237, 237, 237, .6)'
          },
          hover :{
              animationDuration:0
          },
          scales: {
              xAxes: [{
                  barThickness: 18,
                  ticks: {
                      beginAtZero:true,
                      fontFamily: "'Rubik', sans-serif",
                      fontSize:16,
                  },
                  scaleLabel:{
                      display:true
                  },
                  gridLines: {
                  }, 
                  stacked: true
              }],
              yAxes: [{
                  barThickness: 18,
                  gridLines: {
                      display:false,
                      color: "#fff",
                      zeroLineColor: "#fff",
                      zeroLineWidth: 0
                  },
                  ticks: {
                      fontFamily: "'Rubik', sans-serif",
                      fontSize:16,
                  },
                  stacked: true
              }]
          },
          legend:{
              display:false,

          },
        }
      });

      /* platforms */
      this.platformChart = new Chart(this.platformStatsRef.nativeElement, {
        type: 'doughnut',
        data: {
            labels: [this.android_label,this.ios_label],
            datasets: [{    
                data: [this.android,	this.ios], // Specify the data values array
                borderColor: [this.android_color, this.ios_color], // Add custom color border 
                backgroundColor: [this.android_color, this.ios_color], // Add custom color background (Points and Fill)
                borderWidth: 0 // Specify bar border width
            }]},         
        options: {
          legend:{
            display:false
          },
          responsive: true, // Instruct chart js to respond nicely.
          maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
          cutoutPercentage: 70
        }
      });

      /* payment statistics */
      this.paymentChart = new Chart(this.paymentStatsRef.nativeElement, {
        type: 'doughnut',
        data: {
            labels: ['Paid Delegates','Unpaid Delegates'],
            datasets: [{    
                data: [this.paid_delegates,	this.not_paid_delegates], // Specify the data values array
                borderColor: ['#688b06', '#ffc107'], // Add custom color border 
                backgroundColor: ['#688b06', '#ffc107'], // Add custom color background (Points and Fill)
                borderWidth: 0 // Specify bar border width
            }]},         
        options: {
          legend:{
            display:false
          },
          responsive: true, // Instruct chart js to respond nicely.
          maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
          cutoutPercentage: 70
        }
      });

      
      /* meetingSchedulesChart */
      this.meetingSchedulesChart = new Chart(this.meetingSchedulesRef.nativeElement, {
        type: 'pie',
        data: {
            labels: days,
            datasets: [{    
                data: number_of_meeting_sched, // Specify the data values array
                backgroundColor: this.color_code,
                borderWidth: 0, // Add custom color background (Points and Fill)
            }]
        },         
        options: {
          legend:{
            display:false
          },
          responsive: true, // Instruct chart js to respond nicely.
          maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
        }
      });
      
    });
    
  }
}
