import { Component, OnInit, Input, Output } from '@angular/core';
import { Event } from 'src/app/lib/models/events/event';
import { Router } from '@angular/router';

/* services */
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss']
})
export class EventItemComponent implements OnInit {

  @Input() eventitem : Event ;
  @Input() delegate_id = null;

  title:string;

  constructor(
    private router: Router,
    public eventService: EventService
  ) { }

  ngOnInit() {
  }

  gotoEventDashboard(event_id, status){
    if(status == '3'){
      this.router.navigate(['/admin/archive/event/profile/'+event_id]);
    }else{
      this.router.navigate(['/admin/dashboard/'+event_id]);
    }
  }

  gotoEventDashboardVirtual(event_id, status){
    if(status == '3'){
      this.router.navigate(['/admin/archive/event/profile/'+event_id]);
    }else{
      this.router.navigate(['/admin/dashboard-virtual/'+event_id]);
    }
  }

}
