import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-event-faqs',
  templateUrl: './event-faqs.component.html',
  styleUrls: ['./event-faqs.component.scss']
})
export class EventFaqsComponent implements OnInit {

  public event_id;

  constructor(
    protected router: Router,
    private _user: UserService,
  	private _route: ActivatedRoute,
		private titleService: Title,
  	) {
  	
	}

  ngOnInit() {
    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });

    this.titleService.setTitle("Event Frequently Ask Questions");
    
  }

}