import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Users */
import { LoginComponent }   from './modules/login/login.component';
import { ForgotpasswordComponent } from './modules/forgotpassword/forgotpassword.component';

/* Dashboard */
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';

/* Events */
import { EventsComponent } from './modules/events/events/events.component';
import { ArchivedEventsComponent } from './modules/events/archived-events/archived-events.component';
import { AddEventDelegateComponent } from './modules/events/delegate/add-event-delegate/add-event-delegate.component';
import { DelegatesComponent } from './modules/events/delegate/delegates/delegates.component';
import { EventSchedulesAgendasComponent } from './modules/dashboard/event-schedules-agendas/event-schedules-agendas.component';
import { EditDelegateComponent } from './modules/delegate/edit-delegate/edit-delegate.component';
import { EditDelegatesComponent } from './modules/events/delegate/edit-delegates/edit-delegates.component';

import { EventDetailsComponent } from './modules/events/add-event/event-details/event-details.component';
import { EventAddressComponent } from './modules/events/add-event/event-address/event-address.component';
import { EventImagesComponent } from './modules/events/add-event/event-images/event-images.component';
import { EventPrivacyComponent } from './modules/events/add-event/event-privacy/event-privacy.component';
import { EventSchedulerComponent } from './modules/events/add-event/event-scheduler/event-scheduler.component';
import { EventReviewComponent } from './modules/events/add-event/event-review/event-review.component';

import { EventManagerComponent } from './modules/event-managers/event-manager/event-manager.component';
import { EventManagerDashboardComponent } from './modules/dashboard/event-managers/event-manager-dashboard/event-manager-dashboard.component';
import { GeneralPreferenceComponent } from './modules/preferences/general-preference/general-preference.component';
import { AddCompanyComponent } from './modules/company/add-company/add-company.component';
import { CompanyComponent } from './modules/company/company/company.component';
import { EditEventComponent } from './modules/events/edit-event/edit-event.component';
import { AddEditComponent } from './modules/delegate/form/add-edit/add-edit.component';
import { DelegateComponent } from './modules/delegate/delegate/delegate.component';
import { DelegateProfileComponent } from './modules/delegate/delegate-profile/delegate-profile.component';
import { DelegatesProfileComponent } from './modules/events/delegate/delegates-profile/delegates-profile.component';
import { EventProfileComponent } from './modules/events/event-profile/event-profile.component';
import { ArchiveEventProfileCardComponent } from './modules/events/archive-event-profile-card/archive-event-profile-card.component';

import { CompanyDashboardComponent } from './modules/dashboard/company/company-dashboard/company-dashboard.component';
import { AddCompanyDashboardComponent } from './modules/dashboard/company/add-company-dashboard/add-company-dashboard.component';
import { SpeakerListComponent } from './modules/dashboard/speakers/speaker-list/speaker-list.component';
import { MeetingListComponent } from './modules/dashboard/schedule-meetings/meeting-list/meeting-list.component';
import { SponsorListComponent } from './modules/dashboard/sponsors/sponsor-list/sponsor-list.component';
/* Tables */
import { TableTabsComponent } from './modules/dashboard/tables/table-tabs/table-tabs.component';
import { CompanyProfileComponent } from './modules/company/company-profile/company-profile.component';
import { CompanyProfileDashboardComponent } from './modules/dashboard/company/company-profile-dashboard/company-profile-dashboard.component';

import { NoshowBoardComponent } from './modules/dashboard/noshow-board/noshow-board.component';
import { CancellationBoardComponent } from './modules/dashboard/cancellation-board/cancellation-board.component';
import { EventSummaryComponent } from './modules/dashboard/event-summary/event-summary.component';
import { EventFeedbackComponent } from './modules/dashboard/event-feedback/event-feedback.component';
import { EventStatisticsComponent } from './modules/dashboard/event-statistics/event-statistics.component';

import { FaqsComponent } from './modules/faqs/faqs.component';
import { EventFaqsComponent } from './modules/dashboard/event-faqs/event-faqs.component';

import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { LandingComponent } from './landing/landing.component';

// enhancements
import { EventDetailsVirtualComponent } from './enhancements/modules/events/add-event/event-details-virtual/event-details-virtual.component'


import { from } from 'rxjs';
import { EventImagesVirtualComponent } from './enhancements/modules/events/add-event/event-images-virtual/event-images-virtual.component';
import { EventSchedulerVirtualComponent } from './enhancements/modules/events/add-event/event-scheduler-virtual/event-scheduler-virtual.component';
import { EventPrivacyVirtualComponent } from './enhancements/modules/events/add-event/event-privacy-virtual/event-privacy-virtual.component';
import { EventReviewVirtualComponent } from './enhancements/modules/events/add-event/event-review-virtual/event-review-virtual.component';
import { DashboardVirtualComponent } from './enhancements/modules/dashboard/dashboard-virtual/dashboard-virtual.component';
import { EventProfileVirtualComponent } from './enhancements/modules/events/event-profile-virtual/event-profile-virtual.component';
import { EventsummaryVirtualComponent } from './enhancements/modules/dashboard/eventsummary-virtual/eventsummary-virtual.component';
import { NoshowBoardVirtualComponent } from './enhancements/modules/dashboard/noshow-board-virtual/noshow-board-virtual.component';
import { CancelationBoardVirtualComponent } from './enhancements/modules/dashboard/cancelation-board-virtual/cancelation-board-virtual.component';
import { EventFeedbackVirtualComponent } from './enhancements/modules/dashboard/event-feedback-virtual/event-feedback-virtual.component';
import { EventStaisticsVirtualComponent } from './enhancements/modules/dashboard/event-staistics-virtual/event-staistics-virtual.component';
import { CompanyDashboardVirtualComponent } from './enhancements/modules/dashboard/company/company-dashboard-virtual/company-dashboard-virtual.component';
import { AddCompanyDashboardVirtualComponent } from './enhancements/modules/dashboard/company/add-company-dashboard-virtual/add-company-dashboard-virtual.component';
import { CompanyProfileDashboardVirtualComponent } from './enhancements/modules/dashboard/company/company-profile-dashboard-virtual/company-profile-dashboard-virtual.component';
import { DelegatesVirtualComponent } from './enhancements/modules/events/delegates-virtual/delegates-virtual.component';
import { DelegateItineraryReportComponent } from './enhancements/modules/events/delegate-itinerary-report/delegate-itinerary-report.component';
import { DelegatesProfileVirtualComponent } from './enhancements/modules/events/delegate/delegates-profile/delegates-profile-virtual/delegates-profile-virtual.component';
import { SpeakerListVirtualComponent } from './enhancements/modules/dashboard/speakers/speaker-list-virtual/speaker-list-virtual.component';
import { SponsorListVirtualComponent } from './enhancements/modules/dashboard/sponsors/sponsor-list-virtual/sponsor-list-virtual.component';
import { EventFaqsVirtualComponent } from './enhancements/modules/dashboard/event-faqs-virtual/event-faqs-virtual.component';
import { EventManagerDashboardVirtualComponent } from './enhancements/modules/dashboard/event-managers/event-manager-dashboard-virtual/event-manager-dashboard-virtual.component';
import { EventSchedulesAgendasVirtualComponent } from './enhancements/modules/dashboard/event-schedules-agendas-virtual/event-schedules-agendas-virtual.component';
import { MeetingListVirtualComponent } from './enhancements/modules/dashboard/schedule-meetings/meeting-list-virtual/meeting-list-virtual.component';
import { AddEventDelegateVirtualComponent } from './enhancements/modules/events/delegate/add-event-delegate-virtual/add-event-delegate-virtual.component';
import { EditDelegateVirtualComponent } from './enhancements/modules/delegate/edit-delegate-virtual/edit-delegate-virtual.component';
import { EditDelegatesVirtualComponent } from './enhancements/modules/events/delegate/edit-delegates-virtual/edit-delegates-virtual.component';
import { EditEventvirtualComponent } from './enhancements/modules/events/edit-eventvirtual/edit-eventvirtual.component';
import { ZoomAccountComponent } from './enhancements/modules/events/zoom-account/zoom-account.component';

const routes: Routes = [
  { path : '', component : LandingComponent},
  { path : 'admin', component : EventsComponent},
  { path : 'admin/login', component: LoginComponent },
  { path : 'admin/dashboard/:event_id', component : DashboardComponent},
  { path : 'admin/dashboard/company/:event_id', component : CompanyDashboardComponent},
  { path : 'admin/dashboard/company/add/:event_id', component : AddCompanyDashboardComponent},
  { path : 'admin/dashboard/company/:event_id/edit/:company_id', component : AddCompanyDashboardComponent},
  { path : 'admin/dashboard/eventmanagers/:event_id', component : EventManagerDashboardComponent},
  { path : 'admin/dashboard/noshow/:event_id', component : NoshowBoardComponent},
  { path : 'admin/dashboard/cancellation/:event_id', component : CancellationBoardComponent},

  { path : 'admin/event/archived', component : ArchivedEventsComponent},
  { path : 'admin/event/add/event-details', component : EventDetailsComponent},
  { path : 'admin/event/add/event-address', component : EventAddressComponent},
  { path : 'admin/event/add/event-scheduler', component : EventSchedulerComponent},
  { path : 'admin/event/add/event-images', component : EventImagesComponent},
  { path : 'admin/event/add/event-privacy', component : EventPrivacyComponent},
  { path : 'admin/event/add/event-review', component : EventReviewComponent},
  { path : 'admin/event/delegates/add/:event_id', component : AddEventDelegateComponent},
  { path : 'admin/event/edit/:event_id', component : EditEventComponent},
  { path : 'admin/event/delegates/:event_id', component : DelegatesComponent},
  { path : 'admin/event/delegates/:event_id/profile/:delegate_id', component : DelegatesProfileComponent},
  { path : 'admin/event/schedules-agendas/:event_id', component : EventSchedulesAgendasComponent},
  { path : 'admin/event/profile/:event_id', component : EventProfileComponent},
  { path : 'admin/archive/event/profile/:event_id', component : ArchiveEventProfileCardComponent},
  { path : 'admin/forgotpassword', component : ForgotpasswordComponent},
  { path : 'admin/eventmanagers', component : EventManagerComponent},
  { path : 'admin/preferences', component : GeneralPreferenceComponent},
  { path : 'admin/company/add', component : AddCompanyComponent},
  { path : 'admin/company/edit/:company_id', component : AddCompanyComponent},
  { path : 'admin/company', component : CompanyComponent},
  { path : 'admin/company/profile/:company_id', component : CompanyProfileComponent},
  { path : 'admin/dashboard/company/:event_id/profile/:company_id', component : CompanyProfileDashboardComponent},
  { path : 'admin/delegates/add', component : AddEditComponent},

  { path : 'admin/event/:event_id/delegates/edit/:delegate_id', component : EditDelegatesComponent},
  { path : 'admin/delegates/edit/:delegate_id', component : EditDelegateComponent},
  { path : 'admin/delegates', component : DelegateComponent},
  { path : 'admin/tables/:event_id', component : TableTabsComponent},
  { path : 'admin/delegates/profile/:delegate_id', component : DelegateProfileComponent},
  { path : 'admin/speaker/:event_id', component : SpeakerListComponent},
  { path : 'admin/scheduled-meetings/:event_id', component : MeetingListComponent},
  { path : 'admin/sponsor/:event_id', component : SponsorListComponent},
  { path : 'admin/event-summary/:event_id', component : EventSummaryComponent},
  { path : 'admin/event-feedback/:event_id', component : EventFeedbackComponent},
  { path : 'admin/event-statistics/:event_id', component : EventStatisticsComponent},
  { path : 'admin/event-faqs/:event_id', component : EventFaqsComponent},
  { path : 'admin/faqs', component : FaqsComponent},
  { path : 'privacy-policy', component : PrivacyPolicyComponent},

  // enhancements july 2020
  { path: 'admin/event/add/event-details-virtual', component : EventDetailsVirtualComponent},
  { path: 'admin/event/add/event-images-virtual', component: EventImagesVirtualComponent},
  { path: 'admin/event/add/event-scheduler-virtual', component: EventSchedulerVirtualComponent},
  { path: 'admin/event/add/event-privacy-virtual', component: EventPrivacyVirtualComponent},
  { path: 'admin/event/add/event-review-virtual', component: EventReviewVirtualComponent},
  { path : 'admin/dashboard-virtual/:event_id', component : DashboardVirtualComponent},
  { path : 'admin/event/profile-virtual/:event_id', component : EventProfileVirtualComponent},
  { path : 'admin/event-summary-virtual/:event_id', component : EventsummaryVirtualComponent},
  { path : 'admin/dashboard/noshow-virtual/:event_id', component : NoshowBoardVirtualComponent},
  { path : 'admin/dashboard/cancellation-virtual/:event_id', component : CancelationBoardVirtualComponent},
  { path : 'admin/event-feedback-virtual/:event_id', component : EventFeedbackVirtualComponent},
  { path : 'admin/event-statistics-virtual/:event_id', component : EventStaisticsVirtualComponent},
  { path : 'admin/dashboard/company-virtual/:event_id', component : CompanyDashboardVirtualComponent},
  { path : 'admin/dashboard/company/add-virtual/:event_id', component : AddCompanyDashboardVirtualComponent},
  { path : 'admin/dashboard/company-virtual/:event_id/profile/:company_id', component : CompanyProfileDashboardVirtualComponent},
  { path : 'admin/event/delegates-virtual/:event_id', component : DelegatesVirtualComponent},
  { path : 'admin/event/delegate-itinerary-report/:event_id', component : DelegateItineraryReportComponent},
  { path : 'admin/event/delegates-virtual/:event_id/profile/:delegate_id', component : DelegatesProfileVirtualComponent},
  { path : 'admin/speaker-virtual/:event_id', component : SpeakerListVirtualComponent},
  { path : 'admin/sponsor-virtual/:event_id', component : SponsorListVirtualComponent},
  { path : 'admin/event-faqs-virtual/:event_id', component : EventFaqsVirtualComponent},
  { path : 'admin/dashboard/eventmanagers-virtual/:event_id', component : EventManagerDashboardVirtualComponent},
  { path : 'admin/event/schedules-agendas-virtual/:event_id', component : EventSchedulesAgendasVirtualComponent},
  { path : 'admin/scheduled-meetings-virtual/:event_id', component : MeetingListVirtualComponent},
  { path : 'admin/event/delegates/add-virtual/:event_id', component : AddEventDelegateVirtualComponent},
  { path : 'admin/delegates/edit-virtual/:delegate_id', component : EditDelegateVirtualComponent},
  { path : 'admin/dashboard/zoom-account/:event_id', component : ZoomAccountComponent},

  { path : 'admin/event/:event_id/delegates/edit-virtual/:delegate_id', component : EditDelegatesVirtualComponent},
  { path : 'admin/event/edit/virtual/:event_id', component : EditEventvirtualComponent} 





];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

