import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {

  protected event_id = 0;
  protected company_id = 0;
  nextUrl: any;
  
  constructor(
    protected router: Router,
    protected _route: ActivatedRoute,
    protected _user: UserService,
    protected location : Location,
  ) { }

  ngOnInit() {

    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
        this.company_id = + url_param.get('company_id');
        this.event_id = + url_param.get('event_id');
      }
    );
  }

  backClicked() {
    if(this.company_id){
      this.router.navigate(['/admin/company']);
    } else {
      this.location.back();
    }
  }

}
