import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-event-images-form',
  templateUrl: './event-images.component.html',
  styleUrls: ['./event-images.component.scss']
})
export class EventImagesFormComponent implements OnInit {

  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;

  @Input() croppedImageEventBanner;
  @Input() croppedImageEventIcon;
  @Input() croppedImageEventLetterhead;
  @Input() croppedImageEventFloor;
  @Input() isEventLetterheadImageLoaded:any = false;
  @Input() isEventLetterheadImageCropped:any = false;
  @Input() isEventIconImageLoaded:any = false;
  @Input() isEventIconImageCropped:any = false;
  @Input() isEventBannerImageLoaded:any = false;
  @Input() isEventBannerImageCropped:any = false;
  @Input() isEventFloorImageLoaded:any = false;
  @Input() isEventFloorImageCropped:any = false;
  
  public event_id: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    protected _route: ActivatedRoute,
    private messageService: MessageService,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventFormData = this.eventService.eventFullDetails;
    
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
    
    if(!this.event_id  && this.eventService.eventFullDetails.name === ''){
      this.router.navigate(['/admin/event/add/event-details']);
    }

  }
  nextStep(){
    if(this.eventService.isEditingContent){
      this.eventService.isEditingContent = false;
      this.router.navigate(['/admin/event/add/event-review']);
    }else{
      this.router.navigate(['/admin/event/add/event-scheduler']);
    }
  }

  public saveChanges(){
    this.eventService.saveEventDetails().subscribe(
      response => {
        let api_response = response
        let m = {
          type: 'danger',
          msg: '',
        }
        if(api_response.error == 0){
          m.type = 'success';
          this.router.navigate(['/admin/dashboard/', this.eventFormData.id]);
        } else {
          m.type = 'danger';
        }
        
        m.msg = api_response.message;
        this.messageService.add(m);
        
      }
    );
  }


}
