import { Component, OnInit } from '@angular/core';

/* services */
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { TableService } from 'src/app/services/tables/table.service';

import { ReschedMeetingModalComponent } from 'src/app/modules/dashboard/schedule-meetings/resched-meeting-modal/resched-meeting-modal.component';
import { ReschedMeetingModalVirtualComponent } from 'src/app/enhancements/app/modules/dashboard/schedule-meetings/resched-meeting-modal-virtual/resched-meeting-modal-virtual.component';
@Component({
  selector: 'app-meeting-details-modal-virtual',
  templateUrl: './meeting-details-modal-virtual.component.html',
  styleUrls: ['./meeting-details-modal-virtual.component.scss']
})
export class MeetingDetailsModalVirtualComponent implements OnInit {

  onClose: any;
  cancelDeletion: any;
  onSubmitCancellation : any;
  onCancelRescheduling : any;
  modalRef: BsModalRef;
  

  constructor(
    protected meetingScheduleService : MeetingscheduleService,
    protected modalService: BsModalService,
    protected messageService: MessageService,
    protected tableService : TableService
  ) { }

  ngOnInit() {
  }

  closeModal(){
    this.onClose();
  }

  protected checkSetter(setter, elem){
    if(setter === elem){
      return true;
    }
  }

  protected cancelMeetingSchedule(sched_id){
    this.onClose();
    
    if(sched_id){
      let initialState = {
        img_src: 'not-allowed.png',
        title: 'Cancel Meeting?',
        msg: 'Are you sure you want to cancel this meeting? Both delegates will be available for this time slot if cancelled.',
        btn_save: 'Cancel Meeting',
        btn_close: 'Close'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.meetingScheduleService.cancelMeetingSched(sched_id).subscribe(
            response => {
              this.onSubmitCancellation();
              let m = {
                type: '',
                msg: '',
              }

              if(response.error != 0){
                m.type = 'danger';
              }else{
                m.type = 'success';
              }
              m.msg = response.message;
             
              this.messageService.add(m);
            }
          );
        }else{
          this.cancelDeletion();
        }
      }
    }

  }

  protected notApproveCancellation(sched_id){
    this.onClose();
    if(sched_id){
      let initialState = {
        img_src: 'not-allowed.png',
        title: 'Disapprove Cancellation Request?',
        msg: 'Are you sure you want to disapprove this cancellation request?',
        btn_save: 'Disapprove',
        btn_close: 'Close'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.meetingScheduleService.notApproveCancellation(sched_id).subscribe(
            response => {
              this.onSubmitCancellation();
              let m = {
                type: '',
                msg: '',
              }

              if(response.error != 0){
                m.type = 'danger';
              }else{
                m.type = 'success';
              }
              m.msg = response.message;
             
              this.messageService.add(m);

              /* console.log(response); */
            }
          );
        }else{
          this.cancelDeletion();
        }
      }
    }

  }


  /* show the rescheduling form popup */
  protected showRescheduleMeetingModal(meetingSchedData){
    console.log(meetingSchedData);
    this.onClose();

    let initialState = {
      meetingSchedData: meetingSchedData,
      title: 'Meeting Details'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(ReschedMeetingModalVirtualComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_md modal-dialog-centered');

    this.modalRef.content.onClose = () => {
      // Do something with myData and then hide
      this.modalRef.hide();
      this.onCancelRescheduling();
    }

    this.modalRef.content.onSuccessReschedModal = () => {
      this.onSubmitCancellation();
      this.modalRef.hide();
    }
  }

}
