import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

	onClose: any;
  public event_id = 0;
  public userProfile = null;

  constructor() { }

  ngOnInit() {
		this.userProfile = JSON.parse(localStorage.getItem('user'));
  }

	closeModal(action){
		this.onClose(action);
  }
}
