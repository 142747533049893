import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'

/* services */
//import { EventVirtualService } from './../../../../../enhancements/services/event/event-virtual.service';
import { EventService } from 'src/app/services/event/event.service'
/* models */
import { Event } from 'src/app/lib/models/events/event';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import jstz from 'jstz';

@Component({
  selector: 'app-event-details-form-virtual',
  templateUrl: './event-details-form-virtual.component.html',
  styleUrls: ['./event-details-form-virtual.component.scss']
})
export class EventDetailsFormVirtualComponent implements OnInit {

  eventForm: FormGroup;
  addressForm: FormGroup;
  eventType: FormGroup;
  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Output() updateEventItem : EventEmitter<any> = new EventEmitter();

  public Editor = ClassicEditor;

  public event_id: number = 0;
  public submitted: boolean = false;
  public today = new Date();
  public startDate = new Date();
  public endDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit() {
    /* field validator */
    this.eventForm = this.formBuilder.group({
        eventName: ['', [Validators.required, Validators.minLength(6)]],
        hostName: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        startTime: ['', Validators.required],
        endTime: ['', Validators.required],
        description: ['', Validators.required],

        type: ['2']
    }, { });


    this.addressForm = this.formBuilder.group({
      eventAddress: ['N/A'],
      timeZone: [ this.getTimezone() ],
    });

    this.eventType = this.formBuilder.group({
      type: [2]
    })
    /* get the data from services */
    this.eventFormData = this.eventService.eventFullDetails;

  }

  getTimezone(){
    var tzraw = new Date();
    var tz: string = moment(tzraw).format('Z');
    var gmt: string = 'UTC';
    gmt += tz;
    tz = '('+gmt+')';
    //alert(tz);
    var jstz = require('jstz');
    const timezone = jstz.determine();

    tz = timezone.name() + " : " + tz;
    //alert(tz);
    return tz;
  }

  get f() { return this.eventForm.controls; }

  /* set the data into a variable in service */
  public saveEventDetails() {

    this.submitted = true;
    /* stop here if form is invalid */
    if (this.eventForm.invalid) {
        console.log(this.eventForm.controls.startDate.errors);
        return;
    }
    this.eventService.setEventDetails(this.eventForm.value);
    this.eventService.setEventAddress(this.addressForm.value);

    console.log('type', this.eventType.value);
    this.eventService.setType(this.eventType.value);

    if(this.eventFormData.id <= 0){
      if(this.eventService.isEditingContent){
        this.eventService.isEditingContent = false;
        this.router.navigate(['/admin/event/add/event-review-virtual']);
      }else{
        this.router.navigate(['/admin/event/add/event-images-virtual']);
      }
    }else{
      /*
      * call the update function in parent component
      * for update
      * call the function for updating
      */
      this.updateEventItem.emit();
      this.router.navigate(['/admin/dashboard/', this.eventFormData.id]);
    }
  }

  public setMinDate(date){
    const start_date = new Date(date);
    this.eventFormData.start_date = moment(start_date).format('MM/DD/YYYY');
  }

  public setMaxDate(date){
    const end_date = new Date(date);
    this.eventFormData.end_date = moment(end_date).format('MM/DD/YYYY');
  }

}
