import { Component, OnInit } from '@angular/core';
import * as moment from 'moment'

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { strrandom } from 'src/app/lib/strrandom';

import { Urls } from 'src/app//lib/urls';
import { GeneralService } from 'src/app/services/general.service';
import { environment } from 'src/app/lib/environment';
import { MessageService } from 'src/app/services/defaults/message.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-zoom-settings',
  templateUrl: './zoom-settings.component.html',
  styleUrls: ['./zoom-settings.component.scss']
})
export class ZoomSettingsComponent implements OnInit {

  onClose: any;
  zoomSettingForm: FormGroup;
  zoom_setting_status: boolean;
  protected submitted = false;
  public zoom_setting: any;
  savingZoomApi = 'savingZoomApi';

  constructor(
		private env : environment,
		private gen : GeneralService,
		protected messageService: MessageService,
    protected formBuilder: FormBuilder,
    protected spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit() {
    console.log(this.zoom_setting);
    if(this.zoom_setting){
      if(this.zoom_setting.status == '1'){
        this.zoom_setting_status = true;
      }else{
        this.zoom_setting_status = false;
      }
    }

    this.zoomSettingForm = this.formBuilder.group({
      //id: [this.speaker ? this.speaker.id : this.speaker_id],
      client_id: [this.zoom_setting ? this.zoom_setting.client_id : '', [Validators.required]],
      client_secret: [this.zoom_setting ? this.zoom_setting.client_secret : '', [Validators.required]],
      status: [this.zoom_setting ? this.zoom_setting.status : '', [Validators.required]]
    }, { });
  }

  closeModal(){
    this.onClose();
  }

  gotoLink(){
    let url = this.env.secureUrl + '/Zoom_Account_Auth_API.pdf';
    window.open(url, "_blank");
  }

  saveZoomSetting(){
    this.submitted = true;

    if(this.zoomSettingForm.valid){
      console.log(this.zoomSettingForm);

      let url = this.env.getUrl(Urls.api_virtualconference_setzoomsettings);

      let formData = {
        client_id: this.zoomSettingForm.value['client_id'],
        client_secret: this.zoomSettingForm.value['client_secret'],
        status: this.zoomSettingForm.value['status'],
      };
      
      this.spinner.show(this.savingZoomApi);

      this.gen.post(url, formData).subscribe(data=>{
        this.spinner.hide(this.savingZoomApi); 
        if(data.error == 0){
         
        }

        let m = {
          type: '',
          msg: '',
        }

        if(data.error != 0){
          m.type = 'danger';
        }else{
          m.type = 'success';
        }
        m.msg = data.message;
        this.messageService.add(m);
        
        this.closeModal();
      });
    }
  }

  updateZoomStatus(e){
    console.log(e.target.checked);
    if(e.target.checked){
      this.zoomSettingForm.controls.status.setValue(1);
		} else {
      this.zoomSettingForm.controls.status.setValue(0);
		}
	}
}
