import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meeting-list-skeleton',
  templateUrl: './meeting-list-skeleton.component.html',
  styleUrls: ['./meeting-list-skeleton.component.scss']
})
export class MeetingListSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
