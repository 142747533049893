import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router'

import { environment } from 'src/app/lib/environment';
import { ParamModel } from 'src/app/lib/models/pagination';
import { strrandom } from 'src/app/lib/strrandom';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor(
    private router: Router,
    private env: environment,
    private str: strrandom
  ) { }

  ngOnInit() {
  }

  /* 
  * this hold's the paginated data
  */
  @Input() data: any;

  /* 
  * this hold's the api url parameter for the request
  */
  @Input() paramModel: ParamModel;

  /* 
  * this hold the service to be used
  * that service must have a paginationRequest() function
  * that function that will be called by navigateTo() function
  */
  @Input() service: any = {};

  navigateTo(page: number) {
    this.paramModel.params.page = page;
    this.service.paginationRequest(this.paramModel.params);
  }
  jumpTo(e){
		this.navigateTo(e.target.value);
	}
}
