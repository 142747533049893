import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http';


/* Library */
import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';


@Injectable({
  providedIn: 'root'
})
export class MeetingscheduleService {

  public cancellationReports;
  public dataIsLoaded = false;
  public httpOptions = this.env.getHttpOptions();
  public meeting_sched_status: any = {
    approved: 1,
    pending_approval: 2,
    cancellation_pending : 3,
    cancelled: 4,
    not_show: 5
  }
  public total_cancellation_request: number = 0;

  public show_range: any = [
    {range : 10},
    {range : 20},
    {range : 30},
    {range : 40},
    {range : 50},
  ];

  constructor(
    private http:HttpClient,
    public env: environment
  ) { }


  getCancellationRequests(params){
    this.getcancellationRequest(params).subscribe(
      response => {
        this.cancellationReports = response;
				this.dataIsLoaded = true;
				this.total_cancellation_request = this.cancellationReports.data.cancellation_stats.pending + this.cancellationReports.data.cancellation_stats.verified + this.cancellationReports	.data.cancellation_stats.not_approved ;
			}
		);
	}

  /*
   * get the list of meeting sched. per event or per table
   * params:
   * event_id OR table_id
   * */

  public getMeetingSchedule(param){

    let url = this.env.getUrl(Urls.api_meetingschedule_get);
    url += this.env.createUrlParam(param);

    return this.http.get<any>(url, this.httpOptions);
  }

  public getMeetingScheduleVirtual(param){

    let url = this.env.getUrl(Urls.api_meetingschedule_getvirtual);
    url += this.env.createUrlParam(param);

    return this.http.get<any>(url, this.httpOptions);
  }


  /*
   * cancel meeting
   *
   *
   * */
  public cancelMeetingSched(sched_id){
    let url = this.env.getUrl(Urls.api_meetingschedule_cancel);

    const formData = new FormData();
    formData.append('sched_id',sched_id);

    return this.http.post<any>(url ,formData , this.httpOptions);
  }


  /*
   * admin will disapprove cancellation request of the delegate
   *
   *
   * */
  public notApproveCancellation(sched_id){
    let url = this.env.getUrl(Urls.api_meetingschedule_notcancel);

    const formData = new FormData();
    formData.append('sched_id',sched_id);

    return this.http.post<any>(url ,formData , this.httpOptions);
  }

  /*
   * this will get the list of timeslot
   *
   *
   * */
  public getTimeslot(option){
    let url = this.env.getUrl(Urls.api_available_timeslots);
    url += this.env.createUrlParam(option);

    return this.http.get<any>(url, this.httpOptions);
  }

  public getTimeslotVirtual(option){
    let url = this.env.getUrl(Urls.api_available_timeslots_virtual);
    url += this.env.createUrlParam(option);

    return this.http.get<any>(url, this.httpOptions);
  }


  /*
   * this function will set a meeting in a random table
   *
   *
   * */
  public setMeetingSchedule(data){
    let url = this.env.getUrl(Urls.api_meetingschedule_set);

    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }

    return this.http.post<any>(url, formData, this.httpOptions);
  }

  public setMeetingScheduleVirtual(data){
    let url = this.env.getUrl(Urls.api_meetingschedule_setvirtual);

    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }

    return this.http.post<any>(url, formData, this.httpOptions);
  }

  /*
  * make a delete request into API
  */
  deleteTimeSlots(event_id, dateMilisec){
    let url = this.env.getUrl(Urls.api_meetingschedule_deletetimeslot);

    const formData = new FormData();
    formData.append('event_id',event_id);
    formData.append('date',dateMilisec);

    return this.http.post<any>(url, formData, this.httpOptions);
  }

  /*
  * make a generate request to api
  */
  generateTimeSlot(formData){
    let url = this.env.getUrl(Urls.api_meetingschedule_generate);
    return this.http.post<any>(url, formData, this.httpOptions);
  }

  generateTimeSlotVirtual(formData){
    let url = this.env.getUrl(Urls.api_meetingschedule_generatevirtual);
    return this.http.post<any>(url, formData, this.httpOptions);
  }

  /*
  * make a request to update the status of timeslot
  */
  updateStatus(formData){
    let url = this.env.getUrl(Urls.api_meetingschedule_statusupdate);
    return this.http.post<any>(url, formData, this.httpOptions);
  }
  /*
  * make a request to to fetch available delegates
  */
  getAvailableDelegatePerTimeslot(param: any){
    let url = this.env.getUrl(Urls.api_meetingschedule_availablebytimeslot);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.httpOptions);
  }

  /*
  * make request to fetch agenda and timeslot
  */
  getAgendaAndTimeSlot(param: any){
    let url = this.env.getUrl(Urls.api_meetingschedule_getagendaandtimeslot);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.httpOptions);
  }


  getcancellationRequest(param){
    let url = this.env.getUrl(Urls.api_cancellationrequest_get);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.httpOptions);
  }

  /**
   * make server request to check if
   * the meeting schedule download is ready or being build
   */
  checkDownloadable(param){
    let url = this.env.getUrl(Urls.api_downloadable_checkmeetingschedule);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.httpOptions);
  }

  /**
   * make a request on download server
   */
  startDownloadMeetingSched(param){
    let url = this.env.getUrl(Urls.api_downloadable_meetingsched);
    url += this.env.createUrlParam(param);
    return url;
  }
  setCronJobForMeetingSchedule(param){
    let url = this.env.getUrl(Urls.api_cron_printmeetingsched);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.httpOptions);
  }

  getMeetingRequests(param){
    let url = this.env.getUrl(Urls.api_meetingschedule_getrequest);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.httpOptions);
  }

  meetingRequestAction(params){
    let url = this.env.getUrl(Urls.api_meetingschedule_requestactions);
    const formData = new FormData();
    for (let propt in params) {
      formData.append(propt,params[propt]);
    }
    return this.http.post<any>(url, formData, this.httpOptions);
  }
}
