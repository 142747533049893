import { BrowserModule, Title } from '@angular/platform-browser';
import { Injectable , NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import {DragDropModule} from '@angular/cdk/drag-drop';

/* ngx-page loader https://www.npmjs.com/package/ngx-loading#installation */
import { NgxLoadingModule } from 'ngx-loading';
import { ExportAsModule } from 'ngx-export-as';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSpinnerModule } from 'ngx-spinner';

/* ngx-bootstrap */
import { TabsModule, AlertModule, BsDatepickerModule, BsDropdownModule } from 'ngx-bootstrap';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { AgmCoreModule, MapsAPILoader } from "@agm/core";
import { ImageCropperModule } from 'ngx-image-cropper';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

/* Default Layouts */
import { SidebarComponent } from './defaults/sidebar/sidebar.component';
import { HeaderComponent } from './defaults/header/header.component';
import { MessageComponent } from './defaults/message/message.component';
import { GeneralHeaderComponent } from './defaults/general-header/general-header.component';

/* User */
import { LoginComponent } from './modules/login/login.component';
import { ForgotpasswordComponent } from './modules/forgotpassword/forgotpassword.component';

/* Dashboard */
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';

/* Events */
import { EventsComponent } from './modules/events/events/events.component';
import { EventListingComponent } from './modules/events/event-listing/event-listing.component';
import { EventItemComponent } from './modules/events/event-item/event-item.component';
import { ArchivedEventsComponent } from './modules/events/archived-events/archived-events.component';

import { EventDetailsFormComponent } from './modules/events/event-forms/event-details/event-details.component';
import { EventAddressFormComponent } from './modules/events/event-forms/event-address/event-address.component';
import { EventSchedulerFormComponent } from './modules/events/event-forms/event-scheduler/event-scheduler.component';
import { EventImagesFormComponent } from './modules/events/event-forms/event-images/event-images.component';
import { EventPrivacyFormComponent } from './modules/events/event-forms/event-privacy/event-privacy.component';
import { EventReviewFormComponent } from './modules/events/event-forms/event-review/event-review.component';

import { EventAddressComponent } from './modules/events/add-event/event-address/event-address.component';
import { EventDetailsComponent } from './modules/events/add-event/event-details/event-details.component';
import { EventImagesComponent } from './modules/events/add-event/event-images/event-images.component';
import { EventPrivacyComponent } from './modules/events/add-event/event-privacy/event-privacy.component';
import { EventSchedulerComponent } from './modules/events/add-event/event-scheduler/event-scheduler.component';
import { EventReviewComponent } from './modules/events/add-event/event-review/event-review.component';

import { BannerComponent } from './modules/events/event-forms/event-images/banner/banner.component';
import { IconComponent } from './modules/events/event-forms/event-images/icon/icon.component';
import { LetterheadComponent } from './modules/events/event-forms/event-images/letterhead/letterhead.component';
import { FloorplanComponent } from './modules/events/event-forms/event-images/floorplan/floorplan.component';

import { ModalModule } from 'ngx-bootstrap';
import { GeneralPreferenceComponent } from './modules/preferences/general-preference/general-preference.component';
import { PrefTabComponent } from './modules/preferences/pref-tab/pref-tab.component';
import { PrefTabNetworkComponent } from './modules/preferences/pref-tab-network/pref-tab-network.component';

import { PrefTabServicesComponent } from './modules/preferences/pref-tab-services/pref-tab-services.component';
import { PrefTabSpecializationsComponent } from './modules/preferences/pref-tab-specializations/pref-tab-specializations.component';
import { PrefTabSectorComponent } from './modules/preferences/pref-tab-sector/pref-tab-sector.component';
import { PrefTabSoftwaresComponent } from './modules/preferences/pref-tab-softwares/pref-tab-softwares.component';
import { PrefTabCountriesComponent } from './modules/preferences/pref-tab-countries/pref-tab-countries.component';
import { EditEventComponent } from './modules/events/edit-event/edit-event.component';
import { CompanyListComponent } from './modules/company/list/company-list/company-list.component';
import { AddCompanyComponent } from './modules/company/add-company/add-company.component';
import { PrefStateComponent } from './modules/preferences/pref-state/pref-state.component';
import { AddEditComponent } from './modules/delegate/form/add-edit/add-edit.component';
import { FormAddEditComponent } from './modules/delegate/form/form-add-edit/form-add-edit.component';
import { EventProfileComponent } from './modules/events/event-profile/event-profile.component';
import { EventProfileCardComponent } from './modules/events/event-profile-card/event-profile-card.component';


/* Company */
import { CompanyAddEditComponent } from './modules/company/form/company-add-edit/company-add-edit.component';

/* Delegates */
import { DelegateComponent } from './modules/delegate/delegate/delegate.component';
import { DelegateProfileComponent } from './modules/delegate/delegate-profile/delegate-profile.component';
import { DelegatePrefServiceComponent } from './modules/delegate/form/delegate-pref-service/delegate-pref-service.component';
import { DelegatePrefSpecializationComponent } from './modules/delegate/form/delegate-pref-specialization/delegate-pref-specialization.component';
import { DelegatePrefCountriesComponent } from './modules/delegate/form/delegate-pref-countries/delegate-pref-countries.component';
import { DelegatePrefSoftwaresComponent } from './modules/delegate/form/delegate-pref-softwares/delegate-pref-softwares.component';
import { DelegateAddEditComponent } from './defaults/modals/delegate-add-edit/delegate-add-edit.component';

/* Skeleton Loader */
import { ListButtonCardComponent } from './defaults/skeleton-loader/list-button-card/list-button-card.component';
import { HeaderCardComponent } from './defaults/skeleton-loader/header-card/header-card.component';
import { SmallAddCardComponent } from './defaults/skeleton-loader/small-add-card/small-add-card.component';
import { EventListingLoaderComponent } from './defaults/event-listing-loader/event-listing-loader.component';


/* Empty States Loader */
import { EmptyStatesComponent } from './defaults/empty-states/empty-states.component';


/* Pop-up Modal */
import { SuccessModalComponent } from './defaults/modals/success-modal/success-modal.component';

/* Tables */
import { TableTabsComponent } from './modules/dashboard/tables/table-tabs/table-tabs.component';
import { BoothVipListComponent } from './modules/dashboard/tables/booth-vip-list/booth-vip-list.component';
import { AddEventDelegateComponent } from './modules/events/delegate/add-event-delegate/add-event-delegate.component';
import { EmptyStatesDashComponent } from './defaults/empty-states-dash/empty-states-dash.component';
import { DelegatesComponent } from './modules/events/delegate/delegates/delegates.component';
import { EventSchedulesAgendasComponent } from './modules/dashboard/event-schedules-agendas/event-schedules-agendas.component';
import { RegularTablesComponent } from './modules/dashboard/tables/regular-tables/regular-tables.component';
import { RegularTableListingComponent } from './defaults/skeleton-loader/regular-table-listing/regular-table-listing.component';
import { RemoveModalComponent } from './defaults/modals/remove-modal/remove-modal.component';
import { SkeletonEventProfileCardComponent } from './defaults/skeleton-loader/skeleton-event-profile-card/skeleton-event-profile-card.component';
import { ArchiveEventProfileCardComponent } from './modules/events/archive-event-profile-card/archive-event-profile-card.component';
import { AddEditAgendaComponent } from './defaults/modals/add-edit-agenda/add-edit-agenda.component';
import { SpeakerListComponent } from './modules/dashboard/speakers/speaker-list/speaker-list.component';
import { AddEditSpeakerComponent } from './defaults/modals/add-edit-speaker/add-edit-speaker.component';
import { CompanyComponent } from './modules/company/company/company.component';
import { CompanyDashboardComponent } from './modules/dashboard/company/company-dashboard/company-dashboard.component';
import { AddCompanyDashboardComponent } from './modules/dashboard/company/add-company-dashboard/add-company-dashboard.component';
import { DelegateListComponent } from './modules/delegate/list/delegate-list/delegate-list.component';
import { SendCredentialComponent } from './defaults/modals/send-credential/send-credential.component';
import { ImportCsvComponent } from './defaults/import-csv/import-csv.component';
import { MeetingDetailsModalComponent } from './modules/dashboard/tables/meeting-details-modal/meeting-details-modal.component';
import { ReschedMeetingModalComponent } from './modules/dashboard/schedule-meetings/resched-meeting-modal/resched-meeting-modal.component';
import { MeetingListComponent } from './modules/dashboard/schedule-meetings/meeting-list/meeting-list.component';
import { MeetingListSkeletonComponent } from './defaults/skeleton-loader/meeting-list/meeting-list-skeleton.component';
import { SponsorListComponent } from './modules/dashboard/sponsors/sponsor-list/sponsor-list.component';
import { AddEditSponsorComponent } from './defaults/modals/add-edit-sponsor/add-edit-sponsor.component';
import { EventManagerComponent } from './modules/event-managers/event-manager/event-manager.component';
import { EventManagerDashboardComponent } from './modules/dashboard/event-managers/event-manager-dashboard/event-manager-dashboard.component';
import { CancellationRequestModalComponent } from './modules/dashboard/schedule-meetings/cancellation-request-modal/cancellation-request-modal.component';
import { AddTimeslotComponent } from './defaults/modals/add-timeslot/add-timeslot.component';
import { AddEditEventmanagerComponent } from './defaults/modals/add-edit-eventmanager/add-edit-eventmanager.component';
import { ProfileEventmanagerComponent } from './defaults/modals/profile-eventmanager/profile-eventmanager.component';
import { EventManagerListComponent } from './modules/event-managers/event-manager-list/event-manager-list.component';
import { DelegateProfileCardComponent } from './modules/delegate/delegate-profile-card/delegate-profile-card.component';
import { DelegatesProfileComponent } from './modules/events/delegate/delegates-profile/delegates-profile.component';
import { CompanyProfileCardComponent } from './modules/company/company-profile-card/company-profile-card.component';
import { CompanyProfileDashboardComponent } from './modules/dashboard/company/company-profile-dashboard/company-profile-dashboard.component';
import { CompanyProfileComponent } from './modules/company/company-profile/company-profile.component';
import { SchedMeetingComponent } from './defaults/modals/sched-meeting/sched-meeting.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserProfileComponent } from './defaults/modals/user-profile/user-profile.component';
import { EditProfileComponent } from './defaults/modals/edit-profile/edit-profile.component';
import { SponsorBannerComponent } from './defaults/modals/add-edit-sponsor/sponsor-banner/sponsor-banner.component';
import { EditDelegateComponent } from './modules/delegate/edit-delegate/edit-delegate.component';
import { EditDelegatesComponent } from './modules/events/delegate/edit-delegates/edit-delegates.component';
import { PaginationComponent } from './defaults/pagination/pagination.component';
import { DelegateInfoComponent } from './modules/delegate/form/delegate-info/delegate-info.component';
import { EditDelegateCardComponent } from './modules/delegate/edit-delegate-card/edit-delegate-card.component';
import { NoshowBoardComponent } from './modules/dashboard/noshow-board/noshow-board.component';
import { CancellationBoardComponent } from './modules/dashboard/cancellation-board/cancellation-board.component';
import { NoshowDetailsModalComponent } from './modules/dashboard/noshow-board/noshow-details-modal/noshow-details-modal/noshow-details-modal.component';
import { PrintComponent } from './defaults/print-button/print/print.component';
import { EditPreferencesComponent } from './defaults/modals/edit-preferences/edit-preferences.component';
import { ImportErrorMessagesComponent } from './defaults/import-csv/error-messages/error-messages.component';
import { EventSummaryComponent } from './modules/dashboard/event-summary/event-summary.component';
import { SafeUrlComponent } from './lib/pipe/safe-url/safe-url.component';
import { EventFeedbackComponent } from './modules/dashboard/event-feedback/event-feedback.component';
import { FeedbackDetailsComponent } from './defaults/modals/feedback-details/feedback-details.component';
import { EventStatisticsComponent } from './modules/dashboard/event-statistics/event-statistics.component';
import { FaqsComponent } from './modules/faqs/faqs.component';
import { FaqsFormComponent } from './modules/faqs/form/faqs-form/faqs-form.component';
import { EventFaqsComponent } from './modules/dashboard/event-faqs/event-faqs.component';
import { FaqsModalFormComponent } from './modules/faqs/form/faqs-modal-form/faqs-modal-form.component';
import { Chart } from 'chart.js';
import { EditFaqcategoryComponent } from './defaults/modals/edit-faqcategory/edit-faqcategory.component';

import { Socket, SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { EditSponsorlevelComponent } from './defaults/modals/edit-sponsorlevel/edit-sponsorlevel.component';
import { MeetingRequestsListModalComponent } from './modules/dashboard/schedule-meetings/meeting-requests-list-modal/meeting-requests-list-modal.component';

const config: SocketIoConfig = { url: '', options: {} };

import { environment } from '../environments/environment';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { LandingComponent } from './landing/landing.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EventDetailsVirtualComponent } from './enhancements/modules/events/add-event/event-details-virtual/event-details-virtual.component';
import { EventDetailsFormVirtualComponent } from './enhancements/modules/events/event-forms/event-details-form-virtual/event-details-form-virtual.component';
import { EventImagesVirtualComponent } from './enhancements/modules/events/add-event/event-images-virtual/event-images-virtual.component';
import { EventImagesFormVirtualComponent } from './enhancements/modules/events/event-forms/event-images-form-virtual/event-images-form-virtual.component';
import { EventSchedulerVirtualComponent } from './enhancements/modules/events/add-event/event-scheduler-virtual/event-scheduler-virtual.component';
import { EventSchedulerFormVirtualComponent } from './enhancements/modules/events/event-forms/event-scheduler-form-virtual/event-scheduler-form-virtual.component';
import { EventPrivacyVirtualComponent } from './enhancements/modules/events/add-event/event-privacy-virtual/event-privacy-virtual.component';
import { EventPrivacyFormVirtualComponent } from './enhancements/modules/events/event-forms/event-privacy-form-virtual/event-privacy-form-virtual.component';
import { EventReviewVirtualComponent } from './enhancements/modules/events/add-event/event-review-virtual/event-review-virtual.component';
import { EventReviewFormVirtualComponent } from './enhancements/modules/events/event-forms/event-review-form-virtual/event-review-form-virtual.component';
import { EventItemVirtualComponent } from './enhancements/modules/events/event-item-virtual/event-item-virtual.component';
import { DashboardVirtualComponent } from './enhancements/modules/dashboard/dashboard-virtual/dashboard-virtual.component';
import { EventProfileVirtualComponent } from './enhancements/modules/events/event-profile-virtual/event-profile-virtual.component';
import { EventProfileCardVirtualComponent } from './enhancements/modules/events/event-profile-card-virtual/event-profile-card-virtual.component';
import { SidebarVirtualComponent } from './enhancements/defaults/sidebar-virtual/sidebar-virtual.component';
import { EventsummaryVirtualComponent } from './enhancements/modules/dashboard/eventsummary-virtual/eventsummary-virtual.component';
import { NoshowBoardVirtualComponent } from './enhancements/modules/dashboard/noshow-board-virtual/noshow-board-virtual.component';
import { CancelationBoardVirtualComponent } from './enhancements/modules/dashboard/cancelation-board-virtual/cancelation-board-virtual.component';
import { EventFeedbackVirtualComponent } from './enhancements/modules/dashboard/event-feedback-virtual/event-feedback-virtual.component';
import { EventStaisticsVirtualComponent } from './enhancements/modules/dashboard/event-staistics-virtual/event-staistics-virtual.component';
import { CompanyDashboardVirtualComponent } from './enhancements/modules/dashboard/company/company-dashboard-virtual/company-dashboard-virtual.component';
import { AddCompanyDashboardVirtualComponent } from './enhancements/modules/dashboard/company/add-company-dashboard-virtual/add-company-dashboard-virtual.component';
import { CompanyProfileDashboardVirtualComponent } from './enhancements/modules/dashboard/company/company-profile-dashboard-virtual/company-profile-dashboard-virtual.component';
import { CompanyListVirtualComponent } from './enhancements/modules/dashboard/company/list/company-list-virtual/company-list-virtual.component';
import { DelegatesVirtualComponent } from './enhancements/modules/events/delegates-virtual/delegates-virtual.component';
import { DelegateListVirtualComponent } from './enhancements/modules/delegate/list/delegate-list-virtual/delegate-list-virtual.component';
import { DelegatesProfileVirtualComponent } from './enhancements/modules/events/delegate/delegates-profile/delegates-profile-virtual/delegates-profile-virtual.component';
import { DelegateProfileCardVirtualComponent } from './enhancements/modules/delegate/delegate-profile-card-virtual/delegate-profile-card-virtual.component';
import { SpeakerListVirtualComponent } from './enhancements/modules/dashboard/speakers/speaker-list-virtual/speaker-list-virtual.component';
import { SponsorListVirtualComponent } from './enhancements/modules/dashboard/sponsors/sponsor-list-virtual/sponsor-list-virtual.component';
import { EventFaqsVirtualComponent } from './enhancements/modules/dashboard/event-faqs-virtual/event-faqs-virtual.component';
import { EventManagerDashboardVirtualComponent } from './enhancements/modules/dashboard/event-managers/event-manager-dashboard-virtual/event-manager-dashboard-virtual.component';
import { EventSchedulesAgendasVirtualComponent } from './enhancements/modules/dashboard/event-schedules-agendas-virtual/event-schedules-agendas-virtual.component';
import { MeetingListVirtualComponent } from './enhancements/modules/dashboard/schedule-meetings/meeting-list-virtual/meeting-list-virtual.component';
import { AddEventDelegateVirtualComponent } from './enhancements/modules/events/delegate/add-event-delegate-virtual/add-event-delegate-virtual.component';
import { SchedMeetingVirtualComponent } from './enhancements/defaults/modals/sched-meeting-virtual/sched-meeting-virtual.component';
import { ReschedMeetingModalVirtualComponent } from './enhancements/app/modules/dashboard/schedule-meetings/resched-meeting-modal-virtual/resched-meeting-modal-virtual.component';
import { EditDelegateVirtualComponent } from './enhancements/modules/delegate/edit-delegate-virtual/edit-delegate-virtual.component';
import { EditDelegatesVirtualComponent } from './enhancements/modules/events/delegate/edit-delegates-virtual/edit-delegates-virtual.component';
import { AddTimeslotVirtualComponent } from './defaults/modals/add-timeslot-virtual/add-timeslot-virtual.component';
import { MeetingDetailsModalVirtualComponent } from './enhancements/modules/dashboard/tables/meeting-details-modal-virtual/meeting-details-modal-virtual.component';
import { EditEventvirtualComponent } from './enhancements/modules/events/edit-eventvirtual/edit-eventvirtual.component';
import { F2FComponent } from './opt/lampp/htdocs/f2-f/f2-f.component';
import { SendItinerariesComponent } from './defaults/modals/send-itineraries/send-itineraries.component';
import { DelegateItineraryReportComponent } from './enhancements/modules/events/delegate-itinerary-report/delegate-itinerary-report.component';
import { ZoomAccountComponent } from './enhancements/modules/events/zoom-account/zoom-account.component';
import { ZoomSettingsComponent } from './enhancements/defaults/modals/zoom-settings/zoom-settings.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    DashboardComponent,
    HeaderComponent,
    MessageComponent,
    EventListingComponent,
    ForgotpasswordComponent,
    EventItemComponent,
    EventsComponent,
    ArchivedEventsComponent,
    EventDetailsComponent,
    EventAddressComponent,
    EventImagesComponent,
    EventSchedulerComponent,
    EventPrivacyComponent,
    EventReviewComponent,
    EventDetailsFormComponent,
    EventAddressFormComponent,
    EventSchedulerFormComponent,
    EventImagesFormComponent,
    EventPrivacyFormComponent,
    EventReviewFormComponent,

    BannerComponent,
    IconComponent,
    LetterheadComponent,
    FloorplanComponent,
    GeneralHeaderComponent,
    GeneralPreferenceComponent,
    PrefTabComponent,
    PrefTabNetworkComponent,
    PrefTabServicesComponent,
    PrefTabSpecializationsComponent,
    PrefTabSectorComponent,
    PrefTabSoftwaresComponent,
    PrefTabCountriesComponent,
    EventListingLoaderComponent,
    EditEventComponent,
    EventImagesComponent,
    EventPrivacyComponent,
    EventSchedulerComponent,
    EventReviewComponent,
    CompanyListComponent,
    AddCompanyComponent,
    PrefStateComponent,
    AddEditComponent,
    FormAddEditComponent,
    EventProfileComponent,
    EventProfileCardComponent,
    SmallAddCardComponent,
    HeaderCardComponent,
    DelegatePrefServiceComponent,
    DelegatePrefSpecializationComponent,
    DelegatePrefCountriesComponent,
    DelegatePrefSoftwaresComponent,
    CompanyAddEditComponent,
    ListButtonCardComponent,
    EmptyStatesComponent,
    SuccessModalComponent,
    DelegateProfileComponent,
    TableTabsComponent,
    BoothVipListComponent,
    DelegateAddEditComponent,
    AddEventDelegateComponent,
    EmptyStatesDashComponent,
    DelegatesComponent,
    EventSchedulesAgendasComponent,
    RegularTablesComponent,
    RemoveModalComponent,
    SkeletonEventProfileCardComponent,
    ArchiveEventProfileCardComponent,
    AddEditAgendaComponent,
    RegularTableListingComponent,
    RemoveModalComponent,
    SpeakerListComponent,
    AddEditSpeakerComponent,
    CompanyComponent,
    CompanyDashboardComponent,
    AddCompanyDashboardComponent,
    DelegateComponent,
    DelegateListComponent,
    SendCredentialComponent,
    ImportCsvComponent,
    MeetingDetailsModalComponent,
    ReschedMeetingModalComponent,
    MeetingListComponent,
    MeetingListSkeletonComponent,
    SponsorListComponent,
    AddEditSponsorComponent,
    EventManagerComponent,
    CancellationRequestModalComponent,
    EventManagerDashboardComponent,
    AddTimeslotComponent,
    AddEditEventmanagerComponent,
    ProfileEventmanagerComponent,
    EventManagerListComponent,
    DelegateProfileCardComponent,
    DelegatesProfileComponent,
    CompanyProfileCardComponent,
    CompanyProfileDashboardComponent,
    CompanyProfileComponent,
    SchedMeetingComponent,
    UserProfileComponent,
    EditProfileComponent,
    SponsorBannerComponent,
    EditDelegateComponent,
    EditDelegatesComponent,
    PaginationComponent,
    DelegateInfoComponent,
    EditDelegateCardComponent,
    NoshowBoardComponent,
    CancellationBoardComponent,
    NoshowDetailsModalComponent,
    PrintComponent,
    EditPreferencesComponent,
    ImportErrorMessagesComponent,
    EventSummaryComponent,
    SafeUrlComponent,
    EventFeedbackComponent,
    FeedbackDetailsComponent,
    EventStatisticsComponent,
    FaqsComponent,
    FaqsFormComponent,
    EventFaqsComponent,
    FaqsModalFormComponent,
    EditFaqcategoryComponent,
    EditSponsorlevelComponent,
    MeetingRequestsListModalComponent,
    PrivacyPolicyComponent,
    LandingComponent,
    EventDetailsVirtualComponent,
    EventDetailsFormVirtualComponent,
    EventImagesVirtualComponent,
    EventImagesFormVirtualComponent,
    EventSchedulerVirtualComponent,
    EventSchedulerFormVirtualComponent,
    EventPrivacyVirtualComponent,
    EventPrivacyFormVirtualComponent,
    EventReviewVirtualComponent,
    EventReviewFormVirtualComponent,
    EventItemVirtualComponent,
    DashboardVirtualComponent,
    EventProfileVirtualComponent,
    EventProfileCardVirtualComponent,
    SidebarVirtualComponent,
    EventsummaryVirtualComponent,
    NoshowBoardVirtualComponent,
    CancelationBoardVirtualComponent,
    EventFeedbackVirtualComponent,
    EventStaisticsVirtualComponent,
    CompanyDashboardVirtualComponent,
    AddCompanyDashboardVirtualComponent,
    CompanyProfileDashboardVirtualComponent,
    CompanyListVirtualComponent,
    DelegatesVirtualComponent,
    DelegateListVirtualComponent,
    DelegatesProfileVirtualComponent,
    DelegateProfileCardVirtualComponent,
    SpeakerListVirtualComponent,
    SponsorListVirtualComponent,
    EventFaqsVirtualComponent,
    EventManagerDashboardVirtualComponent,
    EventSchedulesAgendasVirtualComponent,
    MeetingListVirtualComponent,
    AddEventDelegateVirtualComponent,
    SchedMeetingVirtualComponent,
    ReschedMeetingModalVirtualComponent,
    EditDelegateVirtualComponent,
    EditDelegatesVirtualComponent,
    AddTimeslotVirtualComponent,
    MeetingDetailsModalVirtualComponent,
    EditEventvirtualComponent,
    F2FComponent,
    SendItinerariesComponent,
    DelegateItineraryReportComponent,
    ZoomAccountComponent,
    ZoomSettingsComponent
  ],
  entryComponents: [
    SuccessModalComponent,
    RemoveModalComponent,
    ZoomSettingsComponent,
    NoshowDetailsModalComponent,
    DelegateAddEditComponent,
    TableTabsComponent,
    AddEditAgendaComponent,
    AddEditSpeakerComponent,
    SendCredentialComponent,
    MeetingDetailsModalComponent,
    EditPreferencesComponent,
    ReschedMeetingModalComponent,
    AddEditSponsorComponent,
    AddTimeslotComponent,
    CancellationRequestModalComponent,
    AddEditEventmanagerComponent,
    ProfileEventmanagerComponent,
    SchedMeetingComponent,
    SendCredentialComponent,
    UserProfileComponent,
    EditProfileComponent,
    SponsorBannerComponent,
    FormAddEditComponent,
    FeedbackDetailsComponent,
    FaqsModalFormComponent,
    EditFaqcategoryComponent,
    EditSponsorlevelComponent,
    MeetingRequestsListModalComponent,
    SchedMeetingVirtualComponent,
    ReschedMeetingModalVirtualComponent,
    AddTimeslotVirtualComponent,
    MeetingDetailsModalVirtualComponent,
    SendItinerariesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgxMaterialTimepickerModule,
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ImageCropperModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXiSTnuChE03T8ascsYAeBWm4QAiBaE-E',
      libraries: ["places"]
    }),
    NgxSkeletonLoaderModule,
    ModalModule.forRoot(),
    InfiniteScrollModule,
    NgxLoadingModule.forRoot({}),
    DragDropModule,
    SocketIoModule.forRoot(config),
    ClipboardModule,
    CKEditorModule,
    ExportAsModule,
    NgxSpinnerModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
