import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

	public event_id;

  constructor(
    protected router: Router,
    private _user: UserService,
  	private _route: ActivatedRoute,
		private titleService: Title,
  	) {
  	this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });
	}

  ngOnInit() {
    this.titleService.setTitle("Frequently Ask Questions");
  }

}
