import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

/* services */
import { TableService } from 'src/app/services/tables/table.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/defaults/message.service';

/* Other Component */
import { MeetingDetailsModalComponent } from 'src/app/modules/dashboard/tables/meeting-details-modal/meeting-details-modal.component';
/* Model */
import { Tables } from 'src/app/lib/models/tables/tables';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';

/* socket config */
import { environment } from 'src/app/lib/environment';

@Component({
  selector: 'app-regular-tables',
  templateUrl: './regular-tables.component.html',
  styleUrls: ['./regular-tables.component.scss']
})
export class RegularTablesComponent implements OnInit {

  onSearchStopTyping = new Subject<string>();
  @Output() updateRegularTableList : EventEmitter<any> = new EventEmitter();
  @Input() tables: Tables[] ;
  @Input() isDataLoaded = false;

  modalRef: BsModalRef;

  protected isRegularTableDataLoad = false;
  protected table_schedules : any;

  protected table_no: any = 0;
  protected page: number = 1;
  protected searchKey = '';
  protected table_id = 0;
  protected event_id = 0;
  protected autoTable_dim = 0;
  
  protected displayEmptyState = false;
  protected isSearching = false;
  protected totalMeetingSchedule = 0;
  protected noDataFound = false;
  protected sm_range_start = 0;
  protected sm_range_end = 0;
  protected isMeetingSchedulesLoaded = false;
  protected showDeleteButton = false;

  /* filter number of scheduled meeting */
  protected _currentSelection: any;
  protected options=[
    {name:'Display all tables', start_range:0, end_range: 0},
    {name:'Tables with no meetings', start_range:1, end_range: 1},
    {name:'Meetings per Table (1 - 10)', start_range:1, end_range: 10},
    {name:'Meetings per Table (11 - 30)', start_range:11, end_range: 30},
    {name:'Meetings per Table (31 - 50)', start_range:31, end_range: 50},
    {name:'Meetings per Table (51 - above)', start_range:51, end_range: 9999},
  ];

  protected emptyDataSelection = {
    name: 'Display all tables'
  };
  
  get currentSelection() {
    return this.currentSelection = this._currentSelection;
  }
  set currentSelection(value) { 
    this._currentSelection = value === '' || value === null || value === undefined ? this.emptyDataSelection : value;
  }

  /* filter by date */
  protected date_filter: any;

  
  constructor(
    protected meetingSchedule : MeetingscheduleService,
    protected eventService : EventService,
    protected modalService: BsModalService,
    protected tableService: TableService,
    protected messageService: MessageService,
    protected env : environment,
  ) { }

  ngOnInit() {

    this.onSearchStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => { 
      /* isSearching indicates i'm searching */
      this.isSearching = true;
      this.searchKey = value;
      this.viewTableMeetings(); 
    });
  }

  changePage(page_no){
    if(page_no){
      this.page = page_no;
    }

    this.updateRegularTableList.emit(this.getParam());
  }

  protected viewTableMeetings(table = 0, table_no = 0){

    /* 
    * onclick of one table, reset the empty state variable to false
    */
    this.displayEmptyState = false;
    if(!this.isSearching){
      this.isMeetingSchedulesLoaded = false;
    }

    if(table_no && table){ 
      this.table_id = table;
      this.totalMeetingSchedule = 0;
      this.table_no = table_no; 
      this.noDataFound = false;
      if(!this.searchKey){
        this.isSearching = false;
      }
      /* this.searchKey = ''; */
    }

    let thisParam = {
      'table' : this.table_id,
      'search_key' : this.searchKey,
      'filter_date' : this.date_filter.formatted
    }

    /* request for the meeting schedule list base on selected table */
    this.meetingSchedule.getMeetingSchedule(thisParam).subscribe(
      schedules => {
        /* set the data into variable */
        this.table_schedules = schedules;
        /* 
        * if the total number of scheduled meeting on the selected table is 0 or none 
        * set the empty state variable (displayEmptyState) into true if i'm not searching,
        * since I'm just searching on existing data that displayed on the table I'm not
        * going to display the empty state by setting the displayEmptyState variable into true
        * rather I will display a message "No Data Found" on the datatable
        * note : displayEmptyState = true, will display the emptystate 
        * mag didisplay lang ang empty state kapag wala talagang scheduled meeting/s sa selected table
        */
        if(parseInt(schedules.data.total_count) <= 0){
          if(!this.isSearching){
            this.displayEmptyState = true;
          }
          this.noDataFound = true;
          this.updateRegularTableList.emit(this.getParam());
        }else{
          this.noDataFound = false;
        }
        /* set the total number of schedule meeting base on selected table */
        if(!this.isSearching){
          this.totalMeetingSchedule = schedules.data.total_count;
        }

        this.isRegularTableDataLoad = true;
        this.isMeetingSchedulesLoaded = true;
      }
    );
  }

  protected checkSetter(setter, elem){
    if(setter === elem){
      return true;
    }
  }

  protected ConvertToInt(value){
    return parseInt(value);
  }


  setCurrentSelection(option) {
    this.currentSelection = option;

    this.sm_range_start = 0;
    this.sm_range_end = 0;
    this.isMeetingSchedulesLoaded = false;
    this.isRegularTableDataLoad = false;
    this.sm_range_start = this.currentSelection.start_range;
    this.sm_range_end = this.currentSelection.end_range;

    this.updateRegularTableList.emit(this.getParam());
  }

  setInitialDateFilter(option){
    if(!this.date_filter){
      this.date_filter = option;
    }
  }

  setDateFilter(option){

    this.date_filter = option;

    this.sm_range_start = 0;
    this.sm_range_end = 0;
    this.isMeetingSchedulesLoaded = false;
    this.isRegularTableDataLoad = false;
    this.sm_range_start = this.currentSelection.start_range;
    this.sm_range_end = this.currentSelection.end_range;

    this.updateRegularTableList.emit(this.getParam());
  }

  /* show the meeting schedule details in popup */
  protected viewMeetingDetails(meetingSchedData){
    let initialState = {
      meetingSchedData: meetingSchedData,
      event_id : this.eventService.eventFormData.id,
      title: 'Meeting Details'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(MeetingDetailsModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_md modal-dialog-centered');

    this.modalRef.content.onClose = () => {
      // Do something with myData and then hide
      this.modalRef.hide();
    }

    /* after submitting cancellation api request update the list */
    this.modalRef.content.onSubmitCancellation = () => {
      this.viewTableMeetings();

      /* emit to refresh the count of pending in list */
      let data = {
        meeting_sched_id : 0,
        event_id : this.eventService.eventFormData.id, 
      }
      this.env.webSocket.emit('cancellation request', data);
    }

    this.modalRef.content.onCancelRescheduling = () =>{
      this.viewMeetingDetails(meetingSchedData);
    }

    this.modalRef.content.cancelDeletion = () => {
      this.viewMeetingDetails(meetingSchedData);
    }
  }

  public getParam(){
    let data = {
      'page' : this.page,
      'sm_range_start' : this.sm_range_start,
      'sm_range_end' : this.sm_range_end,
      'filter_date' : this.date_filter.formatted
    }

    return data;
  }

  protected deleteTable(id,type){
    let initialState = {
      img_src: 'delete-popup.png',
      title: 'Delete this Table?',
      msg: 'This will remove all the information related to this table both in the dashboard and mobile app.',
      btn_save: 'Remove',
      btn_close: 'Close'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_sm modal-dialog-centered');

    this.modalRef.content.onClose = (pass = null) => {
      // Do something with myData and then hide
      this.modalRef.hide();

      if(pass){
          let data = {
            'ids' : id,
            'event_id' : this.eventService.eventFormData.id,
            'type' : type,
          }

          this.tableService.deleteSelectedTable(data).subscribe(
            response => {
              this.updateRegularTableList.emit();

              let m = {
                type: '',
                msg: '',
              }

              if(response.error != 0){
                m.type = 'danger';
              }else{
                m.type = 'success';
              }
              m.msg = response.message;
              this.messageService.add(m);
            }
          );
      }
    }
  }

  /* print filtered meeting scheduled list */
  protected printSchedules(){
    let doc = new jsPDF();
    let a = this;

    let columns = [
      {title: "Date",dataKey: "formatted_date"},
      {title: "Time",dataKey: "formatted_time"},
      {title: "Delegate 1",dataKey: "d1_fullname"},
      {title: "Delegate 2",dataKey: "d2_fullname"}
    ];

    let rows = this.table_schedules.data.datas;
    let file_name = this.table_schedules.data.datas[0].event_name + ' : Scheduled Meetings Table no. '+ this.table_no;

    let letter_head = this.eventService.eventFullDetails.letterhead_image_src;
    this.toDataURL(letter_head, function(dataUrl){
      let headerTitle = file_name;
      file_name = file_name.toLowerCase();
      file_name = file_name.split(' ').join('_');  

      /* let header = function (data) { */
        doc.setTextColor(40);
        doc.setFontStyle('normal');
        doc.setFontSize(13);

        let headerY = 20;
        if(a.eventService.eventFullDetails.image_letterhead !== ''){
          doc.addImage(dataUrl, 'PNG', 15, 15, 0  , 30);
          headerY = 53;
        }
        doc.text(headerTitle, 15, headerY, {maxWidth: 180});
        let headerTitle_dim = doc.getTextDimensions(headerTitle);
        let splitTitle = doc.splitTextToSize(headerTitle,180,{fontSize:13});
        let headerTitle_height = (headerTitle_dim.h * splitTitle.length) + 2;  

        doc.setFontSize(10);
        doc.text(a.eventService.eventFullDetails.event_address, 15, headerTitle_height + headerY, {maxWidth: 180});
        
        let eventLocTitle_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_address);
        let spliteventLocTitle = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
        let eventLocTitle_height = (eventLocTitle_dim.h * spliteventLocTitle.length) + 1;  
        doc.text(a.eventService.eventFullDetails.event_date, 15, headerTitle_height + headerY + eventLocTitle_height, {maxWidth: 180});
        
        let eventDate_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_date);
        let spliteventDate = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
        a.autoTable_dim = (eventDate_dim.h * spliteventDate.length) + headerTitle_height + headerY + eventLocTitle_height  + 1; 
      /* }; */
      
      doc.autoTable(columns, rows, {margin: {top: a.autoTable_dim}});
      doc.save(file_name+'.pdf');
    });
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

}
