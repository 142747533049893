import { Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { strrandom } from 'src/app/lib/strrandom';

import { SpeakersService } from 'src/app/services/event/speakers.service';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-add-edit-speaker',
  templateUrl: './add-edit-speaker.component.html',
  styleUrls: ['./add-edit-speaker.component.scss']
})
export class AddEditSpeakerComponent implements OnInit {

	onClose: any;
  speakerForm: FormGroup;
  protected submitted = false;
  public event_id = 0;
  public speaker_id = 0;
  public speaker = null;

	/* ======= profile photo =========== */
	profilePhoto: any = '';
	croppedprofilePhoto: any = '';
	isProfileImageLoaded = false;
	isProfilePhotoCropped = false;
  profileUploadWrongFile = false;

  disablSaveSpeakerButton = false;
  
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  
  constructor(
    protected formBuilder: FormBuilder,
    protected speakersService : SpeakersService,
    protected messageService : MessageService,
    protected strand: strrandom
  ) { }

  ngOnInit() {

    let facebook_pattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/;
		let twitter_pattern = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/;
    let linkedln_pattern = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
    let website_pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
		let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.speakerForm = this.formBuilder.group({
        id: [this.speaker ? this.speaker.id : this.speaker_id],
        fullname: [this.speaker ? this.speaker.fullname : '', [Validators.required]],
        event_id: [this.event_id],
        company: [this.speaker ? this.speaker.company : ''],
        position: [this.speaker ? this.speaker.position : ''],
        photo: [],
        description: [this.speaker ? this.speaker.description : ''],
        website: [this.speaker ? this.speaker.social_media_links.website : '', [Validators.pattern(website_pattern)]],
        email: [this.speaker ? this.speaker.social_media_links.email : '', [Validators.pattern(emailRe)]],
        phone: [this.speaker ? this.speaker.social_media_links.phone : ''],
        linkedln: [this.speaker ? this.speaker.social_media_links.linkedln : '', [Validators.pattern(linkedln_pattern)]],
        facebook: [this.speaker ? this.speaker.social_media_links.facebook : '', [Validators.pattern(facebook_pattern)]],
        twitter: [this.speaker ? this.speaker.social_media_links.twitter : '', [Validators.pattern(twitter_pattern)]],
    }, { });
  }

	closeModal(){
		this.onClose({});
  }

  get f() { return this.speakerForm.controls; }

  
  /* ---------------------------------
    start of profile photo cropper 
  ------------------------------------ */
	fileChange(event: any): void {
		let fileType = event.target.files[0].type;

		if(fileType.match('image.*')){
			this.isProfileImageLoaded = false;
			this.isProfilePhotoCropped = false;
			this.profileUploadWrongFile = false;
			this.profilePhoto = event;
		} else {
			this.isProfileImageLoaded = true;
			this.isProfilePhotoCropped = true;
			this.profileUploadWrongFile = true;
			this.profilePhoto = '';
		}
	}

	imageProfilePhotoCropped(event: ImageCroppedEvent) {
		this.croppedprofilePhoto = event.base64;
    this.isProfilePhotoCropped =  true;
    let fileOfBlob = new File([event.file], this.strand.generateFileName());

    this.speakerForm.controls.photo.setValue(fileOfBlob);
	}

	imageProfileLoaded() {
		this.isProfileImageLoaded = true;
	}

	startCrop(event: ImageCroppedEvent) {
		this.imageCropper.crop();

	}
  /* ---------------------------------
    end of profile photo cropper 
  ------------------------------------ */

  saveSpeaker(){
    this.submitted = true;
    this.disablSaveSpeakerButton = true;

    if (this.speakerForm.invalid) {
      this.disablSaveSpeakerButton = false;
      return;
    }

    // Set the social media links to object
    let social_media_links = {
      'website': this.speakerForm.value['website'],
      'email': this.speakerForm.value['email'],
      'phone': this.speakerForm.value['phone'],
      'linkedln': this.speakerForm.value['linkedln'],
      'facebook': this.speakerForm.value['facebook'],
      'twitter': this.speakerForm.value['twitter']
    };

    // convert social media links to json encode
    this.speakerForm.value['social_media_links'] = JSON.stringify(social_media_links);
    /* console.log('this.speakerForm.value',this.speakerForm.value); */
    if(this.event_id){
      // stop here if form is invalid
      if (this.speakerForm.valid) {
        this.speakersService.save(this.speakerForm.value).subscribe(data => {
          this.saveHelper(data);
          this.disablSaveSpeakerButton = false;
        });
      }
    }
  }

	saveHelper(data){
    let m = {
      type: 'danger',
      msg: data.message,
    }
    
		if(data.error == 0){
      m.type = 'success';
      this.messageService.add(m);
      this.closeModal();

    }else{
      this.messageService.add(m);
    }
  }

  removeImage(){
    this.isProfileImageLoaded = false;
    this.isProfilePhotoCropped = false;
    this.profileUploadWrongFile = false;
    this.speakerForm.controls.photo.setValue('');
  }
}
