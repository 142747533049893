import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';
import { MessageService } from './../../services/defaults/message.service';

import { SuccessModalComponent } from './../../defaults/modals/success-modal/success-modal.component';


import { DelegateAddEditComponent } from './../../defaults/modals/delegate-add-edit/delegate-add-edit.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class DelegateService {
	
	httpOptions = this.env.getHttpOptions();
	modalRef: BsModalRef;
	event_id: any = null;

	/* ======= prevent multiple click of button ======= */
	disableSaveDelegateButton = false;

	constructor(
		protected http: HttpClient,
		protected messageService: MessageService,
		protected env: environment,
    	protected modalService: BsModalService
	) { }

	checkMailForEvent(email: string, event_id: number){
		let url = this.env.getUrl(Urls.api_delegates_get);
		url += '?by=email&delegate=' + email + '&onevent='+event_id;
		return this.http.get<any>(url, this.httpOptions);
	}

	checkMail(email: string, del_id=null){
		let url = this.env.getUrl(Urls.api_delegates_isexist);
		let data = {email: email}
		if(del_id){
			data['id']=del_id;
		}
		return this.http.post<any>(url, data, this.httpOptions);
	}

	saveDelegate(formData: any, edit=false){
		let url = this.env.getUrl(Urls.api_delegates_save);
		this.http.post<any>(url, formData, this.httpOptions)
			.subscribe(data => {
				/* 
				*	means that you want add the specific delegate
				*	show the successfully add modal
				*/
				if(!edit){
					this.saveDelegateAfter(data);

				}
				
				/* 
				*	means that you want edit the specific delegate
				*	only show the toast
				*/
				else{
					let m = {
						type: 'danger',
						msg: data.message,
					}

					if(data.error == 0){
						m.type = 'success';
					}
					this.messageService.add(m);
				}

				this.disableSaveDelegateButton = false;
			});
	}

	saveDelegateAfter(data){
		let initialState = {
			redirectUrl: 'admin/delegates'
		};
		this.modalRef = this.modalService.show(DelegateAddEditComponent, {initialState});
      	this.modalRef.setClass('modal_sm modal-dialog-centered');
		this.modalRef.content.onClose = (myData) => {
			this.modalRef.hide();
			if(myData.info.action == 'send_credentials'){
				this.sendCrentials(data.id);
			}
		};
	}

	sendCrentials(id=null, ids=null, date=null, time=null){
		let url = this.env.getUrl(Urls.api_delegates_sendcredential);

		let formData = new FormData();
		if(id){
			formData.append('id', id);
		}

		if(ids){
			for ( var key in ids ) {
				formData.append('ids['+key+']', ids[key]);
			}
			if(date){
				formData.append('date', date);
			}
			if(time){
				formData.append('time', time);
			}
		}

		if(this.event_id){
			formData.append('event_id', this.event_id);
		}

	  	let m = {
			type: 'danger',
			msg: '',
		}

	  	this.http.post<any>(url, formData, this.httpOptions)
			.subscribe(data => {
				/* console.log(data) */
				m.msg = data.message;
				if(data.error == 0){
					m.type = 'success';
				}
				this.messageService.add(m);
			});
	}
	

	getDelegate(data: any){
		let url = this.env.getUrl(Urls.api_delegates_get);
		url += this.env.createUrlParam(data);
		return this.http.get<any>(url, this.httpOptions);
	}


	getAll(data: any){
	  let url = this.env.getUrl(Urls.api_delegates_get);
	  url += this.env.createUrlParam(data);
	  return this.http.get<any>(url, this.httpOptions);
	}

	getAvailableDelegatesByEventId(param){
		let url = this.env.getUrl(Urls.api_delegates_geteventavailabledelegate);

		url += this.env.createUrlParam(param);
    	return this.http.get<any>(url, this.httpOptions);
	}

	/* ----------------------------------
	  Delete delegate
	  ----------------------------------*/
	removeDelegate(data){
		/* let formData = new FormData();
		formData.append('id', delegate_id); */

		const formData = new FormData();
		for (let propt in data) {
		  formData.append(propt,data[propt]);
		}


		let url = this.env.getUrl(Urls.api_delegates_delete);
		return this.http.post<any>(url, formData, this.httpOptions);
	}
	/* ----------------------------------
	  End of Delete delegate
	  ----------------------------------*/
	sendItineraries(file, event){
		//console.log(file);

		const formData = new FormData();
		formData.append('file', file);
		formData.append('event', event);

		let url = this.env.getUrl(Urls.api_delegates_senditinerary);
		return this.http.post<any>(url, formData, this.httpOptions);
	}
}
