import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delegates-profile-virtual',
  templateUrl: './delegates-profile-virtual.component.html',
  styleUrls: ['./delegates-profile-virtual.component.scss']
})
export class DelegatesProfileVirtualComponent implements OnInit {

  event_id = 0;

  constructor(
    private _user: UserService,
    private router: Router,
    private _route: ActivatedRoute,
    private titleService: Title,
    protected _location: Location
  ) { 
    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
  }

  ngOnInit() {
    this.titleService.setTitle("Delegate's profile");
  }

  backClicked() {
    this._location.back();
  }

}
