import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { EventManagerService } from 'src/app/services/users/event-manager.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { ZoomSettingsComponent } from 'src/app/enhancements/defaults/modals/zoom-settings/zoom-settings.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { ImportCsvService } from 'src/app/services/import-csv.service';
import { ImportCsvComponent } from 'src/app/defaults/import-csv/import-csv.component'; 
import { EventService } from 'src/app/services/event/event.service';
import { GeneralService } from 'src/app/services/general.service';
import { Urls } from 'src/app//lib/urls';
import { environment } from 'src/app//lib/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClipboardService } from 'ngx-clipboard';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-zoom-account',
  templateUrl: './zoom-account.component.html',
  styleUrls: ['./zoom-account.component.scss'],
  providers: [DatePipe]
})
export class ZoomAccountComponent implements OnInit {

  @ViewChild(ImportCsvComponent ) child: ImportCsvComponent ; 
  subscribeSearch = new Subject<string>();

  @Input() importMessages: any;

  modalRef: BsModalRef;
  protected searchReadOnly = false;

  protected skeleton_manager = [
    { id: '1'},
    { id: '2'},
    { id: '3'}
  ];   

  zoomAccounts: any = null;
	zoomAccount: any = null;
	zoomAccountLoading: any = true;
  totalzoomAccount

  protected event_id = 0;
  protected search = '';
  protected limit = 10;
  protected order = 'asc';
  protected page = 1;
  protected sort = 'id';
  protected zoom_api_balance: any = 0;
  protected zoom_today: any;
  createZoomApi = 'createZoomApi';
  create_zoom_api_lbl = 'Creating Zoom Account';
  
  constructor(
    private _user: UserService,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected modalService: BsModalService,
    protected eventService : EventService,
    protected messageService: MessageService,
    protected importCsv: ImportCsvService,
    protected gen: GeneralService,
    protected env: environment,
    protected spinner: NgxSpinnerService,
    private _clipboardService: ClipboardService,
    private datePipe: DatePipe,
  ) { 
    this._user.requireLogIn();

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.getZoomAccount();
      });
  }

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
      }
    );

    this.getZoomApiBalance();
    this.getZoomAccount();
  }

	/*-------------------------------------
		get all the event manager for the specif event
	 -------------------------------------*/
   getZoomAccount(id= null){

    this.zoomAccountLoading = true;
    let url = this.env.getUrl(Urls.api_virtualconference_getzoomaccount);

    url += "?event_id=" + this.event_id;
    url += "&sort=" + this.sort;
    url += "&order=" + this.order;
    url += "&page=" + this.page;
    url += "&limit=" + this.limit;
    if(this.search){
      url += "&search=" + this.search;
    }

    this.gen.get(url).subscribe(data => {
      this.zoomAccountLoading = false;
      if(data.error == 0){
        console.log('zoom_account', data);
        this.totalzoomAccount = data['data']['total_count'];
        if(this.totalzoomAccount > 0){
          this.zoomAccount = data['data']['datas'];
        }else{
          this.zoomAccount = null;
        }
        this.zoomAccounts = data['data'];
      }
    });
  }

  searchList(){
    this.getZoomAccount();
  }

  navigateTo(page: number) {
    this.page = page;
    this.getZoomAccount();
	}

	jumpTo(e){
		this.navigateTo(e.target.value);
  }
  
  limitChange(){
    this.page = 1;
    this.getZoomAccount();
  }

  // Create Zoom Account
  createZoomAccount(zoom, delegate){
    var firstName = delegate.fullname.split(' ').slice(0, -1).join(' ');
    var lastName = delegate.fullname.split(' ').slice(-1).join(' ');
    let timezone = delegate.timezone.split(':');

    let url_zoom_account = this.env.getUrl(Urls.api_virtualconference_createzoomaccount);

    let formData_zoom_account = {
      delegate_id: delegate.id,
      email: delegate.email,
      first_name: firstName, 
      last_name: lastName
    };

    this.create_zoom_api_lbl = "Creating Zoom Account";
    this.spinner.show(this.createZoomApi);

    this.gen.post(url_zoom_account, formData_zoom_account).subscribe(data=>{
      this.spinner.hide(this.createZoomApi); 
      if(data.error == 0){
        zoom['conference_delegate'] = data.data;
      }

      let m = {
        type: '',
        msg: '',
      }

      if(data.error != 0){
        m.type = 'danger';
      }else{
        m.type = 'success';
      }
      m.msg = data.message;
      this.messageService.add(m);
    });
  }

  // Create Zoom Meeting Link
  createZoomMeetingLink(zoom, zoom_uuid?){
    console.log('zoom', zoom);
    var firstName = zoom.delegate.fullname.split(' ').slice(0, -1).join(' ');
    var lastName = zoom.delegate.fullname.split(' ').slice(-1).join(' ');
    let timezone = zoom.delegate.timezone.split(':');

    let url_zoom_meeting = this.env.getUrl(Urls.api_virtualconference_setmeetingschedule);

    let formData_meeting = {
      conference_delegate_id: zoom.conference_delegate.id,
      delegate_id: zoom.conference_delegate.delegate_id,
      zoom_account_id: zoom.conference_delegate.zoom_account_id,
      description: zoom.delegate.fullname+ "'s Meeting",
      type: 3,
      //start_time: 2021-02-18T09:40:00
      timezone: timezone[0],
      //duration: 20
    };
    
    if(zoom_uuid){
      formData_meeting['zoom_uuid'] = zoom_uuid;
      this.create_zoom_api_lbl = "Regenerating Zoom Meeting Link";
    }else{
      this.create_zoom_api_lbl = "Creating Zoom Meeting Link";
    }
    
    this.spinner.show(this.createZoomApi);

    this.gen.post(url_zoom_meeting, formData_meeting).subscribe(data=>{
      this.spinner.hide(this.createZoomApi); 
      if(data.error == 0){
        let temp_conference_meeting = {
          conference_delegate_id: zoom.conference_delegate.id,
          created_at: data.data.created_at,
          delegate_id: zoom.delegate.id,
          zoom_account_id: zoom.conference_delegate.zoom_account_id,
          zoom_agenda: data.data.agenda,
          zoom_duration: null,
          zoom_host_email: data.data.host_email,
          zoom_id: data.data.id,
          zoom_join_url: data.data.join_url,
          zoom_start_time: null,
          zoom_start_url: data.data.start_url,
          zoom_timezone: data.data.timezone,
          zoom_topic: data.data.topic,
          zoom_type: data.data.type,
          zoom_uuid: data.data.uuid,
        };
        
        zoom['conference_meeting'] = temp_conference_meeting;
        this.zoom_api_balance++;
      }

      let m = {
        type: '',
        msg: '',
      }

      if(data.error != 0){
        m.type = 'danger';
      }else{
        m.type = 'success';
      }
      m.msg = data.message;
      this.messageService.add(m);
    });
  }

  recreateZoomMeetingLink(zoom){
    console.log(zoom);

    if(zoom.conference_meeting){
      let initialState = {
        img_src: 'regenerate_meeting.png',
        title: 'Regenerate Zoom Meeting Link?',
        msg: 'Are you sure you want to regenerate zoom meeting link?',
        btn_save: 'Regenerate',
        btn_close: 'Close',
        publish_event: true,
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.createZoomMeetingLink(zoom, zoom.conference_meeting.zoom_uuid);
        }
      }
    }
  }

  copy(text: string){
    this._clipboardService.copyFromContent(text)
  }  
  
  copyClipboard(e){
    console.log('dsada',e);

    let m = {
      type: 'success',
      msg: 'Copy to Clipboard',
    }
    this.messageService.add(m);
  }

  getZoomApiBalance(){
    let url = this.env.getUrl(Urls.api_virtualconference_getzoomapibalance);

    this.gen.get(url).subscribe(data=>{
      if(data.error == 0){
        this.zoom_api_balance = data.data;
        this.zoom_today = this.datePipe.transform(data.date, 'MMM d, y');
      }
    });
  }

  gotoZoomSetting(){
    
    let url = this.env.getUrl(Urls.api_virtualconference_getzoomsettings);

    this.gen.get(url).subscribe(data=>{
      if(data.error == 0){

        let initialState = {
          zoom_setting: data.data,
        };

        // Show Popup Modal
        this.modalRef = this.modalService.show(ZoomSettingsComponent, {initialState});
        this.modalRef.content.closeBtnName = "Close";
        this.modalRef.setClass("modal_md modal-dialog-centered zoom-settings");

        this.modalRef.content.onClose = (data) => {
          // Do something with myData and then hide
          this.modalRef.hide();
          if(data){
            // this.getSetting();
          }
        };
      }
    });
  }
}