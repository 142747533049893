import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FeedbacksService } from 'src/app/services/event/feedbacks.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { PrefService } from 'src/app/services/preferences/pref.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FeedbackDetailsComponent } from 'src/app/defaults/modals/feedback-details/feedback-details.component';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-feedback-virtual',
  templateUrl: './event-feedback-virtual.component.html',
  styleUrls: ['./event-feedback-virtual.component.scss']
})
export class EventFeedbackVirtualComponent implements OnInit {

  modalRef: BsModalRef;

  searchOnStopTyping = new Subject<string>();
  searchCountryOnStopTyping = new Subject<string>();
  public country_limit = 239;
  public keyword_country = '';
 
  public event_id;
  public searchkey = null;
  public eventfeedbacks:any = null;
  public dataIsLoaded = false;
  private limit = 9;
  private page = 1;
  protected emptyDataOption: any = {
    nicename : 'All countries',
    id : null  
  };
  public country_filter_option: any = this.emptyDataOption;

  public selectfilter = false;

  constructor(
    private _user: UserService,
    private feedbackService : FeedbacksService,
    private _route : ActivatedRoute,
    private prefService : PrefService,
    protected modalService: BsModalService,
  ) { }

  ngOnInit() {
    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
      this.getFeedbacklist();
      this.getCountries();
    });
    this.searchOnStopTyping.pipe(debounceTime(300), distinctUntilChanged())
		.subscribe(value => { 
      this.searchkey = value;
      this.getFeedbacklist();
    });
    this.searchCountryOnStopTyping.pipe(debounceTime(300), distinctUntilChanged())
		.subscribe(value => { 
      if(this.country_filter_option.id && this.keyword_country == ''){
        this.filterOption = null;
        this.getFeedbacklist();
      }
      this.getCountries();

    });

    this.getCountries();
  }

  clearFilters(){
    this.selectfilter = false;
    this.country_filter_option = '';
    this.keyword_country = '';
    this.searchkey = '';
    this.getFeedbacklist();
    this.getCountries();
  }

  get filterOption() {
    return this.country_filter_option;
  }
  set filterOption(value) { 
    this.country_filter_option = value === '' || value === null || value === undefined ? this.emptyDataOption : value;
  }

  getCountries(){
    let url = this.prefService.getSetUrlParams('countries', 1, this.country_limit, this.keyword_country, 'name', 'asc');
    this.sendRequest(url, 'countries');
  }

  sendRequest(url: string, type: string){
    /* console.log('url',url); */
    this.prefService.getData(url, type)
        .subscribe(data => {this.prefService.getHelper(data, type)
        });
  }

  private getFeedbacklist(){
    this.dataIsLoaded = false;
    let args = {
      'event_id' : this.event_id,
      'limit' : this.limit,
      'page' : this.page,
      'keyword' : this.searchkey,
      'filter_country' : this.country_filter_option.id,
    }
    this.feedbackService.getFeedbacklist(args).subscribe(
     response => {
      this.eventfeedbacks = response;
      this.dataIsLoaded = true;
    });
  }

  protected navigateTo(page){
    this.page = page;
    this.getFeedbacklist();
  }

  public setFilterOption(option = null){
    this.selectfilter = true;
    this.filterOption = option;
    this.keyword_country = this.filterOption.nicename;
    /* console.log(option); */
    /* this.getCountries(); */
    this.getFeedbacklist()
  }

  public viewFeedbackdetails(feedback){

    let initialState = {
      feedback : feedback,
      btn_close: 'Close'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(FeedbackDetailsComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal modal-dialog-centered');

    this.modalRef.content.onClose = () => {
      this.modalRef.hide();
    }
  }

}
