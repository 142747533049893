import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { CompanyService } from 'src/app/services/company/company.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';

import { strrandom } from 'src/app/lib/strrandom';

import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-company-profile-card',
  templateUrl: './company-profile-card.component.html',
  styleUrls: ['./company-profile-card.component.scss']
})
export class CompanyProfileCardComponent implements OnInit {

  modalRef: BsModalRef;

  protected event_id = 0;
  protected company_id = 0;
  protected companyLaoding = true;
  protected company : any;
  public isCopied = false;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    public _user: UserService,
    public companyService: CompanyService,
    protected messageService: MessageService,
    protected modalService: BsModalService,
    protected strrand: strrandom,
    private titleService: Title,
    private _clipboardService: ClipboardService
  ) { }

  ngOnInit() {

    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
        this.company_id = + url_param.get('company_id');
        this.event_id = + url_param.get('event_id');
      }
    );

    this.getCompany();
  }

  copy(text: string){
    this._clipboardService.copyFromContent(text)
  }


  getCompany(){

    let thisData = new Object();
    if(this.event_id){
      thisData = {
        id: this.company_id,
        withdelegate : 'yes',
        event_id : this.event_id,
      }
    }
    else{
      thisData = {
        id: this.company_id,
        withdelegate : 'yes',
      }
    }
    
    
    this.companyService.getCompany(thisData).subscribe(data => {
      if(data['error'] == 0){
        this.companyLaoding = false;
        this.company = data.data;
        this.titleService.setTitle("Company " + this.company.name);
      }
    });
  }

  removeEventManager(company=null){
    if(company){
      let initialState = {};
      if(company.delegates && company.delegates.datas.length > 0){
        initialState = {
          img_src: 'not-allowed.png',
          title: 'Not Allowed',
          msg: 'Deleting this company will affect <strong>'+company.delegates.datas.length+' delegates</strong>, we suggest to add first a company for them before deleting this one',
          btn_close: 'Okay',
          not_allowed: true
        };
      }else{
        initialState = {
          img_src: 'delete-popup.png',
          title: 'Remove Company?',
          msg: 'This will remove all the information related to this company both in the dashboard and mobile app.',
          btn_save: 'Delete',
          btn_close: 'Close'
        };
      }
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          this.companyService.removeCompany(company.id).subscribe(
            result => {
              let m = {
                type: 'danger',
                msg: result.message,
              }
            
              if(result.error == 0){
                m.type = 'success';
                this.messageService.add(m);
                if(this.event_id){
                  this.router.navigate(['admin/dashboard/company/'+this.event_id]);
                }else{
                  this.router.navigate(['admin/company']);
                }
                
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

  gotoExternalLink(url){
    if(this.strrand.checkUrl(url)){
      window.open(url, "_blank");
    }
  }

  copyClipboard(e){
    console.log('dsada',e);

    let m = {
      type: 'success',
      msg: 'Copy to Clipboard',
    }
    this.messageService.add(m);
  }
}
