import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-sponsorlevel',
  templateUrl: './edit-sponsorlevel.component.html',
  styleUrls: ['./edit-sponsorlevel.component.scss']
})
export class EditSponsorlevelComponent implements OnInit {

  onClose: any;
  onSaveChanges: any;

  constructor() { }

  ngOnInit() {
  }

  closeModal(){
		this.onClose({});
  }

  saveChanges(name, id, level, message){
    this.onSaveChanges(name, id, level, message);
  }

}
