import { Component, OnInit } from '@angular/core';

/* services */
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { MeetingDetailsModalComponent } from 'src/app/modules/dashboard/tables/meeting-details-modal/meeting-details-modal.component';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { MessageService } from 'src/app/services/defaults/message.service';
import { TableService } from 'src/app/services/tables/table.service'

@Component({
  selector: 'app-cancellation-request-modal',
  templateUrl: './cancellation-request-modal.component.html',
  styleUrls: ['./cancellation-request-modal.component.scss']
})
export class CancellationRequestModalComponent extends MeetingDetailsModalComponent implements OnInit  {

  onClose: any;
  onSubmitCancellation : any;
  modalRef: BsModalRef;

  constructor(
    protected meetingScheduleService : MeetingscheduleService,
    protected modalService: BsModalService,
    protected messageService: MessageService,
    protected tableService : TableService,
  ) {
    super(meetingScheduleService, modalService, messageService, tableService);
  }

  ngOnInit() {
  }

  closeModal(){
    this.onClose();
  }

}