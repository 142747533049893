import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

/* services */
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { environment } from 'src/app/lib/environment';

import { DelegateService } from 'src/app/services/users/delegate.service';
import { TableService } from 'src/app/services/tables/table.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserProfileComponent } from 'src/app/defaults/modals/user-profile/user-profile.component';
import { EditProfileComponent } from 'src/app/defaults/modals/edit-profile/edit-profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  modalRef: BsModalRef;
  eventFormData: Event;

  userLogin: any;
  public event_id = 0;
  protected suggestedTableCount: number = 0;
  protected showiInadequateTable = true;
  protected events_list: any
  

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService,
		private _user: UserService,
    public env: environment,
    protected tableService : TableService,
    protected delegateService : DelegateService,
    protected modalService: BsModalService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this._user.requireLogIn();

		this.userLogin = JSON.parse(localStorage.getItem('user'));
    this.tableService.showiInadequateTable = false;
    this.eventService.eventFormData = null;
    
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
        // get request if formData is null
        this.getEvent();
      }
    );
    
    if(this.event_id != 0){
      // get event_id to local storage
      let old_event_id = localStorage.getItem('old_event_id');
      if(this.event_id != parseInt(old_event_id)){
        // save event_id to local storage
        localStorage.setItem('old_event_id', this.event_id.toString());
      }
    }else{
      // remove event_id to local storage
      localStorage.removeItem('old_event_id');
    }

    

    let param = {
      event_id : this.event_id
    };
    this.delegateService.getDelegate(param).subscribe(
      delegates => {

        this.tableService.setSuggestedTableCount(parseInt(delegates.data.total_count));
        this.tableService.getTableCount(param).subscribe(
          tablecount => {
            if(parseInt(tablecount.data.total_table_count) < this.tableService.suggestedTableCount){
              if(this.eventService.eventFullDetails.type == 2){
                // do nothing                
              }else{
                this.tableService.showiInadequateTable = true;
              }
            }
          }
        );

      }
    );

    let eventparam = {
      'limit' : 3
    }

    this.eventService.getEventList(eventparam).subscribe(
      events => {
        if(events.error == 0){
          this.events_list = events;
          //console.log('this is the event list', this.events_list);
        }
      }
    );

    let notif_args = {
      event_id : this.event_id
    }
    this.eventService.getEventNotif(notif_args);

  }

  getEvent(){
    if(this.event_id){
      this.eventService.getEvent(this.event_id).subscribe(
        eventData => {
            this.eventService.setEventFullDetails(eventData.data);
        }
      );
    }
  }

  logout(){
    localStorage.removeItem('user');
    this.env.deleteCookie();
		this._user.requireLogIn();
  }

	openProfile(){
    if(this.userLogin){
      let initialState = {
        event_id: this.event_id,
        userProfile: this.userLogin
      }
  
      this.modalRef = this.modalService.show(UserProfileComponent, {initialState});
      this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
      this.modalRef.content.onClose = (myData) => {
        this.modalRef.hide();
        if(myData == 'edit'){
          this.openModal(this.userLogin);
        }
      };
    }
  }	
  
  openModal(userLogin=null){
    let initialState = {
      event_id: this.event_id,
      userProfile: userLogin
    }

    this.modalRef = this.modalService.show(EditProfileComponent, {initialState});
    this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
      // get Updated Speaker List
      this.openProfile();
    };
  }

  togleSideBar(){
    if(this.eventService.fullwidth){
      this.eventService.fullwidth = false;
      this.renderer.addClass(this.document.body, 'dashboard-full-width');
    } else {
      this.eventService.fullwidth = true;
      this.renderer.removeClass(this.document.body, 'dashboard-full-width');
    }
  }

  viewNotification(id, url){
    this.eventService.markAsRead(id).subscribe(
      r => {
        this.eventService.getEventNotif(this.getParams());
        this.router.navigate([url])
      }
    );
  }

  


  getNextData(toPush: boolean = false){
    this.eventService.getEventNotifications(this.getParams()).subscribe(
      notifs => {
        this.eventService.notif_current_page = notifs.data.current_page;
        this.setDatas(notifs, toPush);
      }
    );
  }

  setDatas(result, toPush){
    if(toPush){
      result.data.datas.forEach(d => {
        this.eventService.notifData.data.datas.push(d);
      });
    }
  }

  getParams(){
    let p = {
      event_id : this.event_id,
      page: this.eventService.notifData.data.next_page,
    }
    return p;
  }

  onScroll() {
    if(this.eventService.notifData.data.total_page > this.eventService.notif_current_page){
      this.getNextData(true);
    }
  }

}
