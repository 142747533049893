import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DelegateService } from './../../../../services/users/delegate.service'
import { MessageService } from './../../../../services/defaults/message.service';
import { CompanyService } from './../../../../services/company/company.service';
import { EventService } from './../../../../services/event/event.service';
import { Delegatemodel } from './../../../../lib/models/delegate'


import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';


import { DelegatePrefServiceComponent } from '../delegate-pref-service/delegate-pref-service.component';
import { DelegatePrefSpecializationComponent } from '../delegate-pref-specialization/delegate-pref-specialization.component';
import { DelegatePrefCountriesComponent } from '../delegate-pref-countries/delegate-pref-countries.component';
import { DelegatePrefSoftwaresComponent } from '../delegate-pref-softwares/delegate-pref-softwares.component';

import { Location } from '@angular/common';

@Component({
  selector: 'app-form-add-edit',
  templateUrl: './form-add-edit.component.html',
  styleUrls: ['./form-add-edit.component.scss']
})
export class FormAddEditComponent implements OnInit {

	onCompanyStopTyping = new Subject<string>();
	onEmailTyping = new Subject<string>();

	delegateForm: FormGroup;
	focusedField = {
		delegateEmail: false,
		delegateCompany: false,
		delegateFullname: false,
		delegateJobTitle: false,
		facebook: false,
		twitter: false,
		linkedin: false,
		skype: false,
		zoom: false,
		delegateAddress: false,
		delegateTimezone: false
	};
	emailExist = false;
	emailExistText = '';

	companyDropDownShow = false;

	displayPaymentStatus = false;


	/* ======= profile photo =========== */
	profilePhoto: any = '';
	croppedprofilePhoto: any = '';
	isProfileImageLoaded = false;
	isProfilePhotoCropped = false;
	profileUploadWrongFile = false;

	/* ======= card photo =========== */
	profilePhotoCard: any = '';
	croppedprofilePhotoCard: any = '';
	isProfileImageLoadedCard = false;
	isProfilePhotoCroppedCard = false;
	profileUploadWrongFileCard = false;

	/* ======= page navigatoion =========== */
	pageStep = 1;
	step2haserror = false;
	step3haserror = false;
	step4haserror = false;
	step5haserror = false;

	/* ======= company =========== */
	isShowCompanyForm: boolean = false;
	displaySocialMedia: boolean = false;

	/* ======= email validation =========== */
	isOnevent: boolean = false;

	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	@ViewChild(ImageCropperComponent) imageCropperCard: ImageCropperComponent;

	constructor(
		protected delegate: DelegateService,
		protected messageService: MessageService,
		protected formBuilder: FormBuilder,
		protected companyService: CompanyService,
		protected eventService: EventService,
		protected _location: Location,
	) {
		this.onCompanyStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => {this.getCompanies()});

		this.onEmailTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => {this.getDelegates()});
	}

	ngOnInit() {

		this.setDelegateModelEmpty();

		let facebook_pattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/;
		let twitter_pattern = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/;
		let linkedln_pattern = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
		let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.delegateForm = this.formBuilder.group({
	        delegateEmail: ['', [Validators.required, Validators.pattern(emailRe)]],
	        delegateCompany: ['', [Validators.required, Validators.min(1)]],
	        delegateCompanyId: ['', [Validators.required, Validators.min(1)]],
	        delegateFullname: ['', [Validators.required]],
			delegateJobTitle: ['', [Validators.required]],
			delegateAddress: [(this.del && this.del.address) ? this.del.address : '', [Validators.required]],
			delegateTimezone: ['', [Validators.required]],
	        facebook: ['', [Validators.pattern(facebook_pattern)]],
	        twitter: ['', [Validators.pattern(twitter_pattern)]],
			linkedin: ['', [Validators.pattern(linkedln_pattern)]],
			skype: ['', [Validators.required]],
			zoom: ['', [Validators.required]],
      }, { });

    this.setTimezoneStatus();

  }

  setTimezoneStatus(){
    if(this.eventService.eventFullDetails.type == 2){
      this.del.timezone_status = 1; //pending
    }
    //console.log('del at this point', this.del);
  }

	setDelegateModelEmpty(set_email:boolean = true){
		this.del = {
			id: 0,
			email: set_email ? '' : this.del.email,
			company: '',
			company_id: '',
			fullname: '',
			job_title: '',
			profile_photo: '',
			social_media_links: {
				facebook: '',
				linkedin: '',
				whatsapp: '',
				twitter: '',
				kakao: '',
				wechat: '',
				skype: '',
				zoom: '',
			},
			pref_network_ids: {},
			pref_services_ids: {},
			pref_specialization_ids: {},
			pref_countries_ids: {},
			pref_sector_ids: {},
			pref_software_ids: {},
			businesscard: '',
			businesscard_url: '',
			push_notif_enabled: 'yes',
			software_other_name: '',
			software_other_is_selected: false,
			company_data: false,
			profile_photo_url: '',
			payment_status: 'no',

			address: '',
			timezone: '',

			member_since: 0,
			timezone_status: 0,

			longitude: 0,
			latitude: 0
		}
	}

	/*
	*	this will set the delegate model
	*/
	public setDelegateModel(d: any = null){
		console.log('di na', d);
		this.del = {
			id: (d && d.id) ? d.id : 0,
			email: (d && d.email) ? d.email : '',
			company: '',
			company_id: '',
			fullname: (d && d.fullname) ? d.fullname : '',
			job_title: (d && d.job_title) ? d.job_title : '',
			profile_photo: (d && d.profile_photo) ? d.profile_photo : '',
			social_media_links: {
				facebook: (d && d.social_media_links && d.social_media_links.facebook) ? d.social_media_links.facebook : '',
				linkedin: (d && d.social_media_links && d.social_media_links.linkedin) ? d.social_media_links.linkedin : '',
				whatsapp: (d && d.social_media_links && d.social_media_links.whatsapp) ? d.social_media_links.whatsapp : '',
				twitter: (d && d.social_media_links && d.social_media_links.twitter) ? d.social_media_links.twitter : '',
				kakao: (d && d.social_media_links && d.social_media_links.kakao) ? d.social_media_links.kakao : '',
				wechat: (d && d.social_media_links && d.social_media_links.wechat) ? d.social_media_links.wechat : '',
				skype: (d && d.social_media_links && d.social_media_links.skype) ? d.social_media_links.skype : '',
				zoom: (d && d.social_media_links && d.social_media_links.zoom) ? d.social_media_links.zoom : '',
			},
			pref_network_ids: {},
			pref_services_ids: {},
			pref_specialization_ids: {},
			pref_countries_ids: {},
			pref_sector_ids: {},
			pref_software_ids: {},
			businesscard: (d && d.businesscard) ? d.businesscard : '',
			businesscard_url: (d && d.businesscard_url) ? d.businesscard_url : '',
			push_notif_enabled: (d && d.push_notif_enabled) ? d.push_notif_enabled : 'yes',
			software_other_name: '',
			software_other_is_selected: false,
			company_data: false,
			profile_photo_url: (d && d.profile_photo_url) ? d.profile_photo_url : '',
			payment_status: (d && d.ed_payment_status) ? d.ed_payment_status : 'no',

			address: (d && d.address) ? d.address : '',
			timezone: (d && d.timezone) ? d.timezone : '',

			member_since: (d && d.company && d.company.member_since) ? d.company.member_since : 0,
			timezone_status: (d && d.timezone_status && d.timezone_status > 0) ? d.timezone_status : this.del && this.del.timezone_status ? this.del.timezone_status : 0,

			longitude: (d && d.longitude) ? d.longitude: 0,
			latitude: (d && d.latitude) ? d.latitude: 0
    	}
		if(d){
			this.setDelegateModelPref(d, 'pref_services_ids', 'service_');
			this.setDelegateModelPref(d, 'pref_specialization_ids', 'specialization_');
			this.setDelegateModelPref(d, 'pref_countries_ids', 'country_');
			this.setDelegateModelPref(d, 'pref_software_ids', 'softwares');

			if(d.company){
				if(d.company_country){
					d.company['pref_country_nicename'] = d.company_country.nicename;
					d.company['pref_country_iso'] = d.company_country.iso;
				}
				this.setCompany(d.company);
			}
			this.delegateForm.controls.delegateFullname.setValue(this.del.fullname);
			this.delegateForm.controls.delegateJobTitle.setValue(this.del.job_title);
			this.delegateForm.controls.skype.setValue(this.del.social_media_links.skype);
			console.log('phampii', this.del.social_media_links.zoom)
			console.log('phampii', this.delegateForm.controls)
			if(this.del.social_media_links.zoom){
				this.delegateForm.controls.zoom.setValue(this.del.social_media_links.zoom);
			}

			this.isProfileImageLoaded = false;
		}
	}


	@ViewChild(DelegatePrefServiceComponent) pref_services_ids:DelegatePrefServiceComponent;
	@ViewChild(DelegatePrefSpecializationComponent) pref_specialization_ids:DelegatePrefServiceComponent;
	@ViewChild(DelegatePrefCountriesComponent) pref_countries_ids:DelegatePrefServiceComponent;
	@ViewChild(DelegatePrefSoftwaresComponent) pref_software_ids:DelegatePrefSoftwaresComponent;

	setDelegateModelPref(d: any, delpref:string, prefPrefix: string){
		if(d && d[delpref]){
			let dp = d[delpref];
			for (var i = dp.length - 1; i >= 0; i--) {
				let key = prefPrefix+dp[i].id;
				this.del[delpref][key] = dp[i].id;
				this[delpref].prefModel['pref_'+dp[i].id] = true;
			}
		}
	}

	get firstCompanyLetter() {return this.del.company_data.name.substring(0, 1);}

	@Input() backLink = '';
	@Input() del: Delegatemodel;
	@Input() event_id: number = 0;
	@Input() virtual: boolean;

	saveDelegate(pageStep=null){
		// console.log('page', pageStep);
		// console.log('this.pageStep', this.pageStep);

		/*
		*	the pageStep is the Tab number in Edit Delegate
		*	means that you want edit the specific tab delegate details
		*/
		if(pageStep){
			this.pageStep = pageStep;

			if(this.del){
				this.delegateForm.controls.delegateEmail.setValue(this.del.email);
				this.delegateForm.controls.delegateCompany.setValue(this.del.company);
        		this.delegateForm.controls.delegateCompanyId.setValue(this.del.company_id);
			}
		}

		let v = this.delegateForm.controls;

		this.focusedField.delegateEmail = true;
		this.focusedField.delegateCompany = true;
		this.focusedField.delegateFullname = true;
		this.focusedField.delegateJobTitle = true;
		this.focusedField.facebook= true;
		this.focusedField.twitter= true;
		this.focusedField.linkedin= true;
		this.focusedField.skype = true;
		this.focusedField.zoom = true;

		this.focusedField.delegateAddress = true;
		this.focusedField.delegateTimezone = true;

		//console.log('this is the v',this.delegateForm.controls);
		//console.log('data', this.del)

		console.log('nene', this.eventService.eventFullDetails.type);
		console.log('nene1', v);
		if(this.pageStep == 1){
			console.log(v.skype.errors);
			console.log(v.zoom.errors);
			if(
				v.delegateEmail.errors ||
				v.delegateCompanyId.errors ||
				v.delegateFullname.errors ||
				v.delegateJobTitle.errors ||
				v.facebook.errors||
				v.twitter.errors||
				v.linkedin.errors ||
        		this.checkFieldVirtualSocialMedia() ||
        		this.checkFieldVirtualAddress()
			){
				console.log('start');
				console.log(v.delegateEmail.errors);
				console.log(v.delegateCompanyId.errors);
				console.log(v.delegateFullname.errors);
				console.log(v.delegateJobTitle.errors);
				console.log(v.facebook.errors);
				console.log(v.twitter.errors);
				console.log(v.linkedin.errors);
				console.log(this.checkFieldVirtualSocialMedia());
				console.log(this.checkFieldVirtualAddress());
				console.log('end');
				console.log('mali');
				/* there was an invalid required field here */
				if(pageStep){
					this.startSave(true);
        		}

			} else {
				console.log('tama');
				if(pageStep){
				this.startSave(true);
				}else{
					this.pageStep = 2;
				}
			}
		}
		else if(this.pageStep == 2) {
			this.checkPageStepHaserror('pref_services_ids', 'step2haserror', 3, pageStep);
		}
		else if(this.pageStep == 3) {
			this.checkPageStepHaserror('pref_specialization_ids', 'step3haserror', 4, pageStep);
		}
		else if(this.pageStep == 4) {
			this.checkPageStepHaserror('pref_countries_ids', 'step4haserror', 5, pageStep);
		}
		else if(this.pageStep == 5) {
			/*if(this.del.software_other_is_selected && !this.del.software_other_name.length){
				// do nothing here, para lang hindi dumami yung conditions
			} else {
				this.checkPageStepHaserror('pref_software_ids', 'step5haserror', 6);
			}*/
			this.checkPageStepHaserror('pref_software_ids', 'step5haserror', 6, pageStep);
			//console.log('del', this.del);
		}
		
	}

	checkFieldVirtualSocialMedia(){
		let v = this.delegateForm.controls;
		if(this.eventService.eventFullDetails.type == 2 && (v.zoom.errors || v.skype.errors)){
			return true;
		}
		return null;
	}
	
	checkFieldVirtualAddress(){
		let v = this.delegateForm.controls;
		if(this.eventService.eventFullDetails.type == 2 && v.delegateAddress && v.delegateAddress.errors){
			return true;
		}
		return null;
	}

	

	/*
	*	checker of an error detemined by validators
	*/
	checkPageStepHaserror(pref_id, step, pageStep, edit){
		if(pageStep <= 6) {
			var size = 0, key;
		    for (key in this.del[pref_id]) {
		        if (this.del[pref_id].hasOwnProperty(key)) size++;
			}


		    if(size < 1){
				this.pageStep = pageStep;
		    	/* if(pageStep < 5){
		    		this[step] = true;
		    	} */
		    	/* else {
		    		if(this.del.software_other_is_selected && this.del.software_other_name.length != 0){
		    			this[step] = false;
		    		} else {
		    			this[step] = true ;
		    		}
		    	} */
		    } else {
		    	this[step] = false;
		    	if(pageStep < 6){
		    		this.pageStep = pageStep;
		    	}

		    	// if(pageStep == 6){
		    	// 	if(this.del){
		    	// 		this[step] = true;
		    	// 	} else {
		    	// 		this[step] = false;
		    	// 	}
		    	// }
		    }
		}
	    if(!this[step] && pageStep == 6 && !edit){
			this.delegate.disableSaveDelegateButton = true;
	    	this.startSave();
		}

		/*
		*	means that you want edit the specific delegate
		*/
		if(edit){
			this.delegate.disableSaveDelegateButton = true;
	    	this.startSave(true);
		}
	}

	/*
	*	this will start an api call where we can save
	*	and pload the profile picture
	*	when calling this part make sure that the required
	*	fields are not empty.
	*/
	startSave(edit= false){

		console.log('delegate data to save', this.del);

		var formData = new FormData();

		let keys = Object.keys(this.del);
		for (var i = keys.length - 1; i >= 0; i--) {
			let key = keys[i];

			let toAppend = this.del[key];
			if(
				key == 'social_media_links' ||
				key == 'pref_software_ids' ||
				key == 'pref_sector_ids' ||
				key == 'pref_countries_ids' ||
				key == 'pref_specialization_ids' ||
				key == 'pref_services_ids' ||
				key == 'pref_network_ids'
			){
				toAppend = JSON.stringify(this.del[key]);
			}
			formData.append(key, toAppend);
		}
		if(this.event_id < 1){
			if(edit){
				/*
				*	means that you edit the specific delegate
				*/
				this.delegate.saveDelegate(formData, true);
				this.backClicked();

			}else{
				/*
				*	mean that the admin was in the general add delegate
				*	this will add or edit delegate user info
				*/
				this.delegate.saveDelegate(formData);
			}
		} else {
			let eId: string = ''+this.event_id;
			formData.append('event_id', eId);
			if(edit){
				/*
				*	means that you edit the specific delegate
				*/
				this.delegate.saveDelegate(formData, true);
				this.backClicked();

			}else{
				/*
				*	mean that the admin was adding the delegate
				*	into event an event
				*/
				if(this.eventService.eventFullDetails.type == 2){
					this.eventService.addDelegateVirtual(formData, this.del);
					this.delegate.disableSaveDelegateButton = false;
				}else{
					this.eventService.addDelegate(formData);
					this.delegate.disableSaveDelegateButton = false;
				}

			}
		}
		/*this.setDelegateModelEmpty();*/
	}

	/*
	*	this will get all companies, to display on the list
	*	of dropdown
	*/
	getCompanies(){
		this.delegateForm.controls.delegateCompanyId.setValue(null);
		this.companyDropDownShow = true;
		this.companyService.getAll({
			search: this.del.company,
			limit: 30
		});
	}


	/* ============================= start search delegate ============================= */
	/*
	*	search for delegate by email address
	*/
	serchedDelegates: any = {};

	serchedDelegatesField: any = {
		search: '',
		limit: 5,
		order: 'asc',
		page: 1,
		sort: 'email',
		event_id: 0,
	}
	getDelegates(page: number = 1){
		if(this.event_id >= 1){
			this.serchedDelegatesField.search = this.del.email;
			this.serchedDelegatesField.page = page;
			this.serchedDelegatesField.event_id = this.event_id;

			this.delegate.getAvailableDelegatesByEventId(this.serchedDelegatesField)
				.subscribe(data => {
					if(page <= 1){
						this.serchedDelegates = data;
					} else {
						let d = data.data.datas;
						d.forEach(val => {
							this.serchedDelegates.data.datas.push(val);
						});
					}
				});

		}
	}

	suggestedDelegateOnScroll(){
		this.getDelegates(this.serchedDelegatesField.page + 1);
	}

	setDelegateEmail(e, email){
		this.del['email'] = email;
		this.focusField('delegateEmail', 'email', e);
	}
	/* ============================= end search delegate ============================= */

	/*
	*	this will set company as selected to use for delegate
	*/
	setCompany(company){
		this.del.company_data = company;
		this.del.company_id = company.id;
		this.del.company = company.name;
		this.isShowCompanyForm = false;
		console.log(this.del);
		this.delegateForm.controls.delegateCompanyId.setValue(this.del.company_id);
	}

	/*
	*	set a field as focused in
	*/
	focusInField(fieldname: string){
		if(fieldname == 'delegateCompany'){
			this.companyDropDownShow = true;
			this.getCompanies();
		}
	}

	/*
	*	will filter the field if valid or not
	*	on out focus
	*/

	focusField(fieldName: string, modelName: string, e){

		this.focusedField[fieldName] = true;

		if(modelName == 'facebook' || modelName == 'twitter' || modelName == 'linkedin' || modelName == 'skype' || modelName == 'zoom'){
			this.delegateForm.controls[fieldName].setValue(this.del.social_media_links[modelName]);
		}else{
			this.delegateForm.controls[fieldName].setValue(this.del[modelName]);
		}

		if(modelName == 'email' && !this.delegateForm.controls.delegateEmail.errors){
			if(this.event_id < 1){
				this.delegate.checkMail(this.del[modelName], this.del.id)
					.subscribe(data => {this.validateEmail(data)});
			}
			else {
				this.delegate.checkMailForEvent(this.del[modelName], this.event_id)
					.subscribe(data => {
						if(data.error === 0 && data.data){
							if(data.data.event_data){
								this.delegateForm.controls.delegateEmail.setValue(null);
								this.isOnevent = true;
							} else {
								this.setDelegateModel(data.data);
							}
						} else {
							this.setDelegateModelEmpty(false);
						}
					});
			}
		}

		if(modelName == 'company'){
			let a = this;
			setTimeout(function(){
				a.companyDropDownShow = false;
			}, 200);
		}
	}

	/*
	*	will vaildate email using regular expression
	*/
	validateEmail(data){
		if(data.exist){
			this.emailExist = true;
			this.delegateForm.controls.delegateEmail.setValue(null);
			this.emailExistText = data.message;
		}
	}

	/*
	*	go back the prev step
	*/
	goBack(){
		this.pageStep--;
	}

	backClicked() {
    	this._location.back();
  	}

	_preventDefault(e){
		e.preventDefault();
	}


	/* ===================== start of profile photo cropper ===================== */
	fileChange(event: any): void {
		let fileType = event.target.files[0].type;

		if(fileType.match('image.*')){
			this.isProfileImageLoaded = false;
			this.isProfilePhotoCropped = false;
			this.profileUploadWrongFile = false;
			this.profilePhoto = event;
		} else {
			this.isProfileImageLoaded = true;
			this.isProfilePhotoCropped = true;
			this.profileUploadWrongFile = true;
			this.profilePhoto = '';
		}
	}

	imageProfilePhotoCropped(event: ImageCroppedEvent) {
		this.croppedprofilePhoto = event.base64;
		this.isProfilePhotoCropped =  true;
		/*this.eventService.setEventBannerImage(this.croppedprofilePhoto);*/

		this.del.profile_photo = event.file;
		console.log(event.file);
	}

	imageProfileLoaded() {
		this.isProfileImageLoaded = true;


	}

	startCrop(event: ImageCroppedEvent) {
		this.imageCropper.crop();

	}
	/* ===================== end of profile photo cropper ===================== */

	/* ===================== start of business card cropper ===================== */
	fileChangeCard(event: any): void {
		let fileType = event.target.files[0].type;

		if(fileType.match('image.*')){
			this.isProfileImageLoadedCard = false;
			this.isProfilePhotoCroppedCard = false;
			this.profileUploadWrongFileCard = false;
			this.profilePhotoCard = event;
		} else {
			this.isProfileImageLoadedCard = true;
			this.isProfilePhotoCroppedCard = true;
			this.profileUploadWrongFileCard = true;
			this.profilePhotoCard = '';
		}
	}

	businesscard: any;
	imageProfilePhotoCroppedCard(event: ImageCroppedEvent) {
		this.del.businesscard_url = event.base64;
		/*this.eventService.setEventBannerImage(this.croppedprofilePhoto);*/

		this.del.businesscard = event.file;
	}

	imageProfileLoadedCard() {
		this.isProfileImageLoadedCard = true;
	}

	startCropCard(event: ImageCroppedEvent) {
		this.imageCropperCard.crop();
		this.isProfilePhotoCroppedCard =  true;
	}

	/* ===================== end of profile photo cropper ===================== */


	/* ===================== start company functions ===================== */
	showCompanyForm(){
		this.isShowCompanyForm = true;
		setTimeout(function(){
			document.getElementById("company-form-fields").scrollIntoView();
		}, 200);
	}
  /* ===================== end company functions ===================== */

  // enhancements for timezone

  public latitude: number;
  public longitude: number;
  public place: string;
  public zoom: number;
  public time_zone: string;

  public handleAddressChange(event){
    this.latitude = event.geometry.location.lat();
    this.longitude = event.geometry.location.lng();
    this.del.latitude = this.latitude;
    this.del.longitude = this.longitude;
    this.place = event.formatted_address;
    this.zoom = 10;

    let currentTimezone = event.utc_offset_minutes/60;
    var gmt = 'UTC';
    if (currentTimezone !== 0) {
      gmt += currentTimezone > 0 ? ' +' : ' ';
      gmt += this.decToTime(currentTimezone);
    }
    this.time_zone = '('+gmt+')';
	this.del.address = this.place;
	this.del.timezone_status = 1;

    this.eventService.getTimezoneLabel(this.latitude, this.longitude).subscribe(result => {
      if(result['error'] == '0'){
        this.del.timezone = result['data'] +" : "+ this.time_zone;
      }else{
        this.del.timezone = this.time_zone;
      }

      this.delegateForm.value.delegateTimezone = this.del.timezone;
      //this.delegateForm.value.eventAddress = this.place;
      // this.delegateForm.value.lat = this.latitude;
      // this.delegateForm.value.long = this.longitude;
      //this.eventService.setEventAddress(this.del.value);
    });
  }

  public decToTime(minutes){
    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }

}
