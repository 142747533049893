import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss']
})
export class RemoveModalComponent implements OnInit {

  img_src: string;
  title: string;
  msg: string;
  btn_save: string;
  btn_close: string;
  onClose: any;
  url: any;
  not_allowed: boolean = false;
  publish_event: boolean = false;

  password : any;
  password_error = false;
  error_message : any;

  passType = "password";
  showPassword: boolean = false;
  
  constructor(
    private _user: UserService
  ) { }

  ngOnInit() {
  }

  focusInField(elementId){
    document.getElementById(elementId).style.backgroundColor = "#fff";
  }

	focusField(e = null, elementId=null){
    if(elementId){
      document.getElementById(elementId).style.backgroundColor = "#f2f2f2";
    }

    if(e){ e.preventDefault(); }
    if(this.password){
      this.password_error = false;
      if(this.password.length < 8){
        this.password_error = true;
        this.error_message = 'Password must be 8 characters';
      }
    }else{
      this.password_error = true;
      this.error_message = 'Please enter password';
    }
  }

  submitModal(){
    this.focusField();
    if(!this.password_error){
      let thisData = {
        password : this.password
      };

      this._user.checkPassword(thisData)
      .subscribe(data => { 
        if(data['error'] == 0){
          this.password_error = false;
          this.onClose(this.password);
        }else{
          this.password_error = true;
          this.error_message = data['message'];
        }
      })
    }
  }

  showPass(){
    this.passType = "text";
    this.showPassword = true;
  } 

  hidePass(){
    this.passType = "password";
    this.showPassword = false;
  }

  closeModal(){
    this.onClose();
  }
}
