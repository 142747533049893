import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';

@Component({
  selector: 'app-event-address-form',
  templateUrl: './event-address.component.html',
  styleUrls: ['./event-address.component.scss']
})
export class EventAddressFormComponent implements OnInit {

  public eventAddressForm: FormGroup;
  public searchControl: FormControl;

  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Output() updateEventItem : EventEmitter<any> = new EventEmitter();

  public latitude: number;
  public longitude: number;
  public place: string;
  public zoom: number;
  public time_zone: string;
  public submitted: boolean = false;
  public event_id: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    protected _route: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventAddressForm = this.formBuilder.group({
      eventAddress: ['', Validators.required],
      timeZone: ['', Validators.required],
      lat: [''],
      long: [''],
      coordinates: ['']
    }, { });

    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
    

    /* get the data from services */
    this.eventFormData = this.eventService.eventFullDetails;
    if(!this.event_id && this.eventService.eventFullDetails.name === ''){
      this.router.navigate(['/admin/event/add/event-details']);
    }
   

    this.zoom = 10;
    this.eventFormData.time_zone;
    if(this.eventFormData.lat !== '' && this.eventFormData.long !== ''){
      this.latitude = parseFloat(this.eventFormData.lat);
      this.longitude = parseFloat(this.eventFormData.long);
    }else{
      //set google maps defaults
      this.latitude = 39.8282;
      this.longitude = -98.5795;
    }

  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 10;
      });
    }
  }

  get f() { return this.eventAddressForm.controls; }

  public saveEventAddress() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.eventAddressForm.invalid) {
        return;
    }
    /* console.log(this.eventFormData); */

    if(this.eventFormData.id <= 0){
      if(this.eventService.isEditingContent){
        this.eventService.isEditingContent = false;
        this.router.navigate(['/admin/event/add/event-review']);
      }else{
        this.router.navigate(['/admin/event/add/event-images']);
      }
    }else{
      /*
      * call the update function in parent component 
      * for update 
      * call the function for updating
      */
      this.updateEventItem.emit();
      this.router.navigate(['/admin/dashboard/', this.eventFormData.id]);
    }
   
  }

  public handleAddressChange(event){
    this.latitude = event.geometry.location.lat();
    this.longitude = event.geometry.location.lng();
    this.place = event.formatted_address;
    this.zoom = 10;

    let currentTimezone = event.utc_offset/60;
    var gmt = 'UTC';
    if (currentTimezone !== 0) {
      gmt += currentTimezone > 0 ? ' +' : ' ';
      gmt += this.decToTime(currentTimezone);
    }
    this.time_zone = '('+gmt+')';
    this.eventFormData.event_address = this.place;

    this.eventService.getTimezoneLabel(this.latitude, this.longitude).subscribe(result => {
      if(result['error'] == '0'){
        this.eventFormData.time_zone = result['data'] +" : "+ this.time_zone;
      }else{
        this.eventFormData.time_zone = this.time_zone;
      }

      this.eventAddressForm.value.timeZone = this.eventFormData.time_zone;
      this.eventAddressForm.value.eventAddress = this.place;
      this.eventAddressForm.value.lat = this.latitude;
      this.eventAddressForm.value.long = this.longitude;
      this.eventService.setEventAddress(this.eventAddressForm.value);
    }); 
  }

  public decToTime(minutes){
    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  }

}
