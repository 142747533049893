import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { MessageService } from 'src/app/services/defaults/message.service';

import { SuccessModalComponent } from 'src/app/defaults/modals/success-modal/success-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-event-review-form',
  templateUrl: './event-review.component.html',
  styleUrls: ['./event-review.component.scss']
})
export class EventReviewFormComponent implements OnInit {
  eventForm: FormGroup;
  eventFormData: Event;
  modalRef: BsModalRef;
  
  protected event_id = 0;
  public submitted = false;
  public isAutoAccept = false;
  public isAutoCancel = false;
  public isOpenAccess = false;
  public isPrivate = false;
  public isCustomize = false;
  public latitude: number;
  public longitude: number;
  public zoom: number = 10;
  public response: any;

  public image_banner: any;
  public image_letterhead: any;
  public image_icon: any;
  public image_floor_plan: any;
  protected saveEventButton = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    protected _route: ActivatedRoute,
    private messageService: MessageService,
    private eventService: EventService,
    protected modalService: BsModalService,
  ) { }

  ngOnInit() {

    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );

    if(!this.event_id  && this.eventService.eventFullDetails.name === ''){
      this.router.navigate(['/admin/event/add/event-details']);
    }


    this.eventFormData = this.eventService.eventFullDetails;
    /* console.log('eventFormData',this.eventFormData); */
    this.image_banner = this.eventFormData.banner_image_src;
    this.image_letterhead = this.eventFormData.letterhead_image_src;
    this.image_icon = this.eventFormData.icon_image_src;
    this.image_floor_plan = this.eventFormData.floorplan_image_src;
    
    if(this.eventFormData.meeting_request === '1'){
      this.isAutoAccept = true;
    }

    if(this.eventFormData.cancellation_request === '1'){
      this.isAutoCancel = true;
    }

    if(this.eventFormData.permission === '1'){
      this.isPrivate = true;
    }else if (this.eventFormData.permission === '2'){
      this.isOpenAccess = true;
    }else{
      this.isCustomize = true;
    }

    /* if(this.eventFormData.name === ''){
      this.router.navigate(['/admin/event/add/event-details']);
      return;
    } */
    this.latitude = parseFloat(this.eventFormData.lat);
    this.longitude = parseFloat(this.eventFormData.long);
  }

  editEventDetails(){
    this.eventService.isEditingContent = true;
    this.router.navigate(['/admin/event/add/event-details']);
  }
  editEventImages(){
    this.eventService.isEditingContent = true;
    this.router.navigate(['/admin/event/add/event-images']);
  }
  editEventPrivacySettings(){
    this.eventService.isEditingContent = true;
    this.router.navigate(['/admin/event/add/event-privacy']);
  }
  editEventScheduler(){
    this.eventService.isEditingContent = true;
    this.router.navigate(['/admin/event/add/event-scheduler']);
  }
  editEventAddress(){
    this.eventService.isEditingContent = true;
    this.router.navigate(['/admin/event/add/event-address']);
  }

  public saveEvent(){
    this.eventService.isEditingContent = false;
    this.saveEventButton = true;
    this.eventService.saveEventDetails().subscribe(
      response => {
        this.response = response
        let m = {
          type: 'danger',
          msg: '',
        }
        if(this.response.error == 0){
           
            let redir_url = '/admin/dashboard/'+parseInt(this.response.event_id);
            let initialState = {
              img_src: 'success-popup.png',
              title: 'Event added',
              msg: 'New event has been successfully added. We suggest to finish setting up all the details of your event like delegates, tables, schedules, etc. before publishing the event.',
              btn_save: 'Okay',
              btn_close: 'Close',
              url: redir_url,
              type: 'add_company'
            };
            /* Show Success Modal */
            this.modalRef = this.modalService.show(SuccessModalComponent, {initialState});
            this.modalRef.setClass('mw-30-per modal-dialog-centered');
            this.modalRef.content.closeBtnName = 'Close';
    
            this.modalRef.content.onClose = (myData) => {
                this.modalRef.hide();
                this.router.navigate([redir_url]);
            };

        } else {
          m.type = 'danger';
          m.msg = this.response.message;
          this.messageService.add(m);
        }
        this.saveEventButton = false;
      }
    );
  }

}
