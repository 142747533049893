import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-review',
  templateUrl: './event-review.component.html',
  styleUrls: ['./event-review.component.scss']
})
export class EventReviewComponent implements OnInit {

  constructor(
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Add Event Review");
  }

}
