import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-general-preference',
  templateUrl: './general-preference.component.html',
  styleUrls: ['./general-preference.component.scss']
})
export class GeneralPreferenceComponent implements OnInit {

  constructor(
    private _user: UserService
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    
  }

}
