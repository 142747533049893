import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-address',
  templateUrl: './event-address.component.html',
  styleUrls: ['./event-address.component.scss']
})
export class EventAddressComponent implements OnInit {

  constructor(
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Add Event Address");
  }

}
