import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-images',
  templateUrl: './event-images.component.html',
  styleUrls: ['./event-images.component.scss']
})
export class EventImagesComponent implements OnInit {

  constructor(
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Add Event Icon And Banner");
  }

}
