export class Delegatemodel {
    id: number;
    email: string;
    company: string;
    company_id: any;
    fullname: string;
    job_title: string;
    profile_photo: any;
    social_media_links: any;
    pref_network_ids: any;
    pref_services_ids: any;
    pref_specialization_ids: any;
    pref_countries_ids: any;
    pref_sector_ids: any;
    pref_software_ids: any;
    
    businesscard: any;
    businesscard_url: string;

    push_notif_enabled: string;
    software_other_name: any;
    software_other_is_selected: any;
    company_data: any;
    profile_photo_url: any;
    payment_status: string;

    address: string;
    timezone: string;

    member_since: number;
    timezone_status: number;
    longitude: number
    latitude: number
}
