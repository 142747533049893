import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.scss']
})
export class FloorplanComponent implements OnInit {

  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Input() croppedImageEventFloor:any = "";
  @Input() isEventFloorImageLoaded:any = false;
  @Input() isEventFloorImageCropped:any = false;

  /* ======= event floor =========== */
  imageChangedEventFloor: any = '';
  

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventFormData = this.eventService.eventFullDetails;
    this.croppedImageEventFloor = this.eventFormData.floorplan_image_src;
    if(this.eventFormData.image_floor_plan !== "" && this.eventFormData.image_floor_plan !== "isRemoved"){
      this.isEventFloorImageLoaded = true;
      this.isEventFloorImageCropped = true;
    }
  }

  /* ======= event floor plan =========== */
  fileChangeEventFloor(event: any): void {
    this.isEventFloorImageLoaded = false;
    this.isEventFloorImageCropped = false;
    this.imageChangedEventFloor = event;
  }
  imageEventFloorCropped(event: ImageCroppedEvent) {
      this.croppedImageEventFloor = event.base64;
      this.isEventFloorImageCropped =  true;

      this.eventService.setEventFloorplanImage(event);
  }
  imageEventFloorLoaded() {
    this.isEventFloorImageLoaded = true;
      // show cropper
  }
  cropEventFloor() {
    this.imageCropper.crop();
  }
  cropperEventFloorReady() {
      // cropper ready
  }
  loadEventFloorImageFailed() {
      // show message
  }
  
  removeImage(){
    this.imageChangedEventFloor = '';
    this.croppedImageEventFloor = '';
    this.isEventFloorImageLoaded = false;
    this.isEventFloorImageCropped = false;

    this.eventService.setEventFloorplanImage('isRemoved');
  }

}
