import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { EventManagerService } from 'src/app/services/users/event-manager.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { UserService } from 'src/app/services/user.service';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-eventmanager',
  templateUrl: './add-edit-eventmanager.component.html',
  styleUrls: ['./add-edit-eventmanager.component.scss']
})
export class AddEditEventmanagerComponent implements OnInit {
  autoCompleteForm = new Subject<string>();

  onClose: any;
  formSubmitted : any;
  eventManagerForm: FormGroup;
  protected submitted = false;
  public event_id = 0;
  public eventManagerId = 0;
  public eventManager = null;
  protected send_credentials : boolean = false;
  protected availableEventManagers: any;
  protected showOption = false;

  protected keyword= '';
  protected email= '';
  protected contact_number= '';
  protected full_address= '';
  
  constructor(
    protected formBuilder: FormBuilder,
    protected eventManagerService : EventManagerService,
    protected messageService : MessageService,
    private _user: UserService,
  ) { 

    this._user.requireLogIn();

    this.autoCompleteForm.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.getAvailabelEventManagerByEvent();
      });
  }

  ngOnInit() {
    
    let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.eventManagerForm = this.formBuilder.group({
        id: [this.eventManager ? this.eventManager.id : this.eventManagerId],
        fullname: [this.eventManager ? this.eventManager.fullname : '', [Validators.required]],
        event_id: [this.event_id],
        email: [this.eventManager ? this.eventManager.email : '', [Validators.required, Validators.pattern(emailRe)]],
        contact_number: [this.eventManager ? this.eventManager.contact_number : '', [Validators.required]],
        full_address: [this.eventManager ? this.eventManager.full_address : ''],
        user_role: [this.eventManager ? this.eventManager.user_role : '2']
    }, { });

    if(this.eventManager){
      this.keyword = this.eventManager.fullname;
      this.email = this.eventManager.email;
      this.contact_number = this.eventManager.contact_number;
      this.full_address = this.eventManager.full_address;
    }

    this.getAvailabelEventManagerByEvent();
  }


  focusInField(){
    let a = this;
    a.showOption = true;
  }
  
  focusOutField(){
    let a = this;
    setTimeout(function(){
      a.showOption = false;
    }, 200);
  }

  setValue(value){
    this.showOption = false;
    this.keyword = value.fullname;
    this.email = value.email;
    this.contact_number = value.contact_number;
    this.full_address = value.full_address;

    this.eventManagerForm.controls.id.setValue(value.id);
    this.eventManagerForm.controls.fullname.setValue(this.keyword);
    this.eventManagerForm.controls.email.setValue(this.email);
    this.eventManagerForm.controls.contact_number.setValue(this.contact_number);
    this.eventManagerForm.controls.full_address.setValue(this.full_address);
  }

  getAvailabelEventManagerByEvent(){

    let param = {
      'event_id': this.event_id,
      'keyword': this.keyword
    };
    
		this.eventManagerService.getAvailabelEventManagerByEvent(param)
			.subscribe(data => {
        /* console.log('data',data); */
        if(data['error'] == 0){
          this.availableEventManagers = data.data;
        }
			});
  }

	closeModal(){
		this.onClose({});
  }

  get f() { return this.eventManagerForm.controls; }

  saveEventManager(){
    this.submitted = true;
    /* console.log(this.eventManagerForm.value); */
    if (this.eventManagerForm.valid) {
      this.eventManagerForm.value['send_credential'] = this.send_credentials;

      /* console.log(this.eventManagerForm.value) */

      this.eventManagerService.save(this.eventManagerForm.value).subscribe(data => {
        this.saveHelper(data);
      });
    }
  }

	saveHelper(data){
    let m = {
      type: 'danger',
      msg: data.message,
    }
    
		if(data.error == 0){
      m.type = 'success';
      this.messageService.add(m);
      this.formSubmitted();

    }else{
      this.messageService.add(m);
    }
  }
}
