import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { EventService } from 'src/app/services/event/event.service';
import { Title }     from '@angular/platform-browser';

/* models */
import { Event } from 'src/app/lib/models/events/event';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  
  constructor(
    private _user: UserService,
    private eventService : EventService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Add Event Details");
    /* this.eventService.eventFullDetails = this.eventEmptyDetails; */
  }

}
