import { Component, OnInit } from '@angular/core';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { Router, ActivatedRoute } from '@angular/router';

import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { NoshowDetailsModalComponent } from 'src/app/modules/dashboard/noshow-board/noshow-details-modal/noshow-details-modal/noshow-details-modal.component';

/* Services */
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { NoshowService } from 'src/app/services/noshow/noshow.service';
import { TableService } from 'src/app/services/tables/table.service';
import { EventService } from 'src/app/services/event/event.service';

/* socket config */
import { environment } from 'src/app/lib/environment';

@Component({
  selector: 'app-noshow-board-virtual',
  templateUrl: './noshow-board-virtual.component.html',
  styleUrls: ['./noshow-board-virtual.component.scss']
})
export class NoshowBoardVirtualComponent implements OnInit {
  modalRef: BsModalRef;

  public event_id = 0;
  protected dataIsLoaded = false;
  protected dataIsLoaded2 = false;
  protected direction = 'desc';
  protected page = 1;
  protected limit = 10;
  protected displayNoshowDelegate: boolean;

  protected page_noshow_delegate = 1;
  protected limit1 = 5;

  protected miximizedScreen = false;

  /* print config */
  protected printConfig: any = {
    'columnToShow': [
        {title: "No. of no show",dataKey: "noshow_count"},
        {title: "Delegate",dataKey: "rd_fullname"},
        {title: "Company",dataKey: "rd_company_name"}
      ]
  }

  constructor(
    protected meetingSchedService : MeetingscheduleService,
    protected noShowService : NoshowService,
    protected modalService: BsModalService,
    protected eventService : EventService,
    protected tableService : TableService,
    protected _route: ActivatedRoute,
    protected env : environment
  ) {
  }

  ngOnInit() {
    /* get the url parameters */
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });
    this.dataIsLoaded = false;
    this.getNoShowDelegates();
  }

  emitIntoSocket(){
		let data = {
		meeting_sched_id : 0,
		event_id : this.event_id, 
		}
		this.env.webSocket.emit('noshow request', data);
	}

  getNoShowReport(){
      let param = {
        'event_id': this.event_id,
        'limit' : this.limit,
        'page' : this.page
      }
      this.noShowService.getNoShowReport(param);
  }
  
  protected getNoShowDelegates(){
    let args = {
      'event_id': this.event_id,
      'order' : this.direction,
      'page' : this.page_noshow_delegate,
      'limit' : this.limit1
    }
    this.noShowService.noShowDelegates(args);
  }

  protected sortNoShowDelegates(sort){
    
    if(this.direction == 'asc'){
      this.direction = 'desc';
    }else{
      this.direction = 'asc';
    }
    this.getNoShowDelegates();
  }
  

  /* show the meeting schedule details in popup */
  protected viewMeetingDetails(meetingSchedData){
    let param = {
      'event_id': this.event_id,
      'report_no' : meetingSchedData.id,
    }

    this.noShowService.getNoShowReportAPI(param).subscribe( r => {
      let initialState = {
        meetingSchedData: r.data.datas[0],
        title: 'No Show Report'
      };
  
      // Show Popup Modal
      this.modalRef = this.modalService.show(NoshowDetailsModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal-dialog-centered');
  
      this.modalRef.content.onClose = () => {
        // Do something with myData and then hide
        this.modalRef.hide();
      }
  
      /* after submitting cancellation api request update the list */
      this.modalRef.content.onSubmitApproval = () => {
        /* this.getNoShowDelegates(); */
        this.emitIntoSocket();
  
        let param = {
          'event_id': this.event_id
        }
        this.noShowService.getNoShowReport(param);
      }

      this.modalRef.content.dismissAction = () => {
        let a = this;
        setTimeout(function() {
          a.viewMeetingDetails(r.data.datas[0]);
        }, 200);
      };

      

    });

  }


  protected navigateTo(page){
    this.page = page;

    this.getNoShowReport();
  }

  protected navigateTo2(page){
    this.page_noshow_delegate = page;
    this.getNoShowDelegates();
  }

  protected jumpTo(e){
		this.navigateTo(e.target.value);
  }

  protected displayNoshow(){
    this.dataIsLoaded2 = false;
    let data = {
      'event_id': this.event_id,
      'state' : this.eventService.eventFullDetails.display_noshow
    }
    this.noShowService.enableDisplayNoshowDelegate(data).subscribe(
      response => {
        this.dataIsLoaded2 = true;
      }
    );
  }
  protected maximizeDelegateList(){
    this.miximizedScreen = true;
  }

  protected minimizeDelegateList(){
    this.miximizedScreen = false;
  }


}
