import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-event-delegate-virtual',
  templateUrl: './add-event-delegate-virtual.component.html',
  styleUrls: ['./add-event-delegate-virtual.component.scss']
})
export class AddEventDelegateVirtualComponent implements OnInit {

  event_id: number = 0;

	backLink = {};

	constructor(
		private router: Router,
		private _route: ActivatedRoute,
		private titleService: Title
	) { }

	ngOnInit() {
		this._route.paramMap.subscribe( url_param =>{
			this.event_id = + url_param.get('event_id');
		});
		this.backLink = {
			link: '/admin/event/delegates-virtual/' + this.event_id,
			text: 'Go back to list of delegates',
			cancel: '/admin/event/delegates/'  + this.event_id
		};
		this.titleService.setTitle("Add an Event's Delegate");
	}

}
