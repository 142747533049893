import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DelegateService } from './../../../../services/users/delegate.service'
import { MessageService } from './../../../../services/defaults/message.service';
import { CompanyService } from './../../../../services/company/company.service';
import { EventService } from './../../../../services/event/event.service';
import { Delegatemodel } from './../../../../lib/models/delegate'

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

import { DelegatePrefServiceComponent } from '../delegate-pref-service/delegate-pref-service.component';
import { DelegatePrefSpecializationComponent } from '../delegate-pref-specialization/delegate-pref-specialization.component';
import { DelegatePrefCountriesComponent } from '../delegate-pref-countries/delegate-pref-countries.component';
import { DelegatePrefSoftwaresComponent } from '../delegate-pref-softwares/delegate-pref-softwares.component';

import { FormAddEditComponent } from 'src/app/modules/delegate/form/form-add-edit/form-add-edit.component';

import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { GeneralService } from 'src/app/services/general.service';
import { environment } from 'src/app/lib/environment';
import { Urls } from 'src/app/lib/urls';

@Component({
	selector: 'app-delegate-info',
	templateUrl: './delegate-info.component.html',
	styleUrls: ['./delegate-info.component.scss']
})
export class DelegateInfoComponent extends FormAddEditComponent implements OnInit {

	modalRef: BsModalRef;

	@Input() backLink = '';
	@Input() del: Delegatemodel;
	@Input() event_id;

	onCompanyStopTyping = new Subject<string>();

	delegateForm: FormGroup;
	focusedField = {
		delegateEmail: false,
		delegateCompany: false,
		delegateFullname: false,
		delegateJobTitle: false,
		facebook: false,
		twitter: false,
		linkedin: false,
		skype: false,
		zoom: false,
		delegateAddress: false,
		delegateTimezone: false
	};
	emailExist = false;
	emailExistText = '';

	companyDropDownShow = false;

	displayPaymentStatus = false;


	/* ======= profile photo =========== */
	profilePhoto: any = '';
	croppedprofilePhoto: any = '';
	isProfileImageLoaded = false;
	isProfilePhotoCropped = false;
	profileUploadWrongFile = false;

	/* ======= page navigatoion =========== */
	pageStep = 1;
	step2haserror = false;
	step3haserror = false;
	step4haserror = false;
	step5haserror = false;

	/* ======= company =========== */
	isShowCompanyForm: boolean = false;
	displaySocialMedia: boolean = false;

	/* ======= email validation =========== */
	isOnevent: boolean = false;

	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

	constructor(
		protected delegate: DelegateService,
		protected messageService: MessageService,
		protected formBuilder: FormBuilder,
		protected companyService: CompanyService,
		protected eventService: EventService,
		protected _location: Location,
		protected modalService: BsModalService,
		protected gen: GeneralService,
		protected env: environment,
	) {
		super(delegate, messageService, formBuilder, companyService, eventService, _location);
		this.onCompanyStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => {this.getCompanies()});
	}

	ngOnInit() {
		this.setDelegateModelEmpty();

		let facebook_pattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/;
		let twitter_pattern = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/;
		let linkedln_pattern = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
		let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.delegateForm = this.formBuilder.group({
			delegateEmail: ['', [Validators.required, Validators.pattern(emailRe)]],
			delegateCompany: ['', [Validators.required, Validators.min(1)]],
			delegateCompanyId: ['', [Validators.required, Validators.min(1)]],
			delegateFullname: ['', [Validators.required]],
			delegateJobTitle: ['', [Validators.required]],
			delegateAddress: [(this.del && this.del.address) ? this.del.address : '', [Validators.required]],
			facebook: ['', [Validators.pattern(facebook_pattern)]],
			twitter: ['', [Validators.pattern(twitter_pattern)]],
			linkedin: ['', [Validators.pattern(linkedln_pattern)]],
			skype: ['', [Validators.required]],
				  zoom: ['', [Validators.required]],
    }, { });

    this.setTimezoneStatus();
  }

  setTimezoneStatus(){
    if(this.eventService.eventFullDetails.type == 2){
      this.del.timezone_status = 1; //pending
    }
  }

	setDelegateModelEmpty(set_email:boolean = true){
		this.del = {
			id: 0,
			email: set_email ? '' : this.del.email,
			company: '',
			company_id: '',
			fullname: '',
			job_title: '',
			profile_photo: '',
			social_media_links: {
				facebook: '',
				linkedin: '',
				whatsapp: '',
				twitter: '',
				kakao: '',
        wechat: '',
        skype: '',
			},
			pref_network_ids: {},
			pref_services_ids: {},
			pref_specialization_ids: {},
			pref_countries_ids: {},
			pref_sector_ids: {},
			pref_software_ids: {},
			businesscard: '',
			businesscard_url: '',
			push_notif_enabled: 'yes',
			software_other_name: '',
			software_other_is_selected: false,
			company_data: false,
			profile_photo_url: '',
      payment_status: 'no',

      address: '',
      timezone: '',

      member_since: 0,
      timezone_status: 0,

      longitude: 0,
      latitude: 0
		}
	}

	/*
	*	this will set the delegate model
	*/
	public setDelegateModel(d: any = null){
		this.del = {
			id: (d && d.id) ? d.id : 0,
			email: (d && d.email) ? d.email : '',
			company: '',
			company_id: '',
			fullname: (d && d.fullname) ? d.fullname : '',
			job_title: (d && d.job_title) ? d.job_title : '',
			profile_photo: (d && d.profile_photo) ? d.profile_photo : '',
			social_media_links: {
				facebook: (d && d.social_media_links && d.social_media_links.facebook) ? d.social_media_links.facebook : '',
				linkedin: (d && d.social_media_links && d.social_media_links.linkedin) ? d.social_media_links.linkedin : '',
				whatsapp: (d && d.social_media_links && d.social_media_links.whatsapp) ? d.social_media_links.whatsapp : '',
				twitter: (d && d.social_media_links && d.social_media_links.twitter) ? d.social_media_links.twitter : '',
				kakao: (d && d.social_media_links && d.social_media_links.kakao) ? d.social_media_links.kakao : '',
				wechat: (d && d.social_media_links && d.social_media_links.wechat) ? d.social_media_links.wechat : '',
				skype: (d && d.social_media_links && d.social_media_links.skype) ? d.social_media_links.skype : '',
				zoom: (d && d.social_media_links && d.social_media_links.zoom) ? d.social_media_links.zoom : '',
			},
			pref_network_ids: {},
			pref_services_ids: {},
			pref_specialization_ids: {},
			pref_countries_ids: {},
			pref_sector_ids: {},
			pref_software_ids: {},
			businesscard: (d && d.businesscard) ? d.businesscard : '',
			businesscard_url: (d && d.businesscard_url) ? d.businesscard_url : '',
			push_notif_enabled: (d && d.push_notif_enabled) ? d.push_notif_enabled : 'yes',
			software_other_name: '',
			software_other_is_selected: false,
			company_data: false,
			profile_photo_url: (d && d.profile_photo_url) ? d.profile_photo_url : '',
			payment_status: (d && d.ed_payment_status) ? d.ed_payment_status : '',

			address: (d && d.address) ? d.address : '',
			timezone: (d && d.timezone) ? d.timezone : '',

			member_since: (d && d.company && d.company.member_since) ? d.company.member_since : 0,
			timezone_status: (d && d.timezone_status && d.timezone_status > 0) ? d.timezone_status : this.del.timezone_status,

			longitude: (d && d.longitude) ? d.longitude: 0,
			latitude: (d && d.latitude) ? d.latitude: 0
		}
		if(d){
			this.setDelegateModelPref(d, 'pref_services_ids', 'service_');
			this.setDelegateModelPref(d, 'pref_specialization_ids', 'specialization_');
			this.setDelegateModelPref(d, 'pref_countries_ids', 'country_');
			this.setDelegateModelPref(d, 'pref_software_ids', 'softwares');

			if(d.company){
				if(d.company_country){
					d.company['pref_country_nicename'] = d.company_country.nicename;
					d.company['pref_country_iso'] = d.company_country.iso;
				}
				this.setCompany(d.company);
			}
			this.delegateForm.controls.delegateFullname.setValue(this.del.fullname);
			this.delegateForm.controls.delegateJobTitle.setValue(this.del.job_title);

			this.isProfileImageLoaded = false;
		}
	}


	@ViewChild(DelegatePrefServiceComponent) pref_services_ids:DelegatePrefServiceComponent;
	@ViewChild(DelegatePrefSpecializationComponent) pref_specialization_ids:DelegatePrefServiceComponent;
	@ViewChild(DelegatePrefCountriesComponent) pref_countries_ids:DelegatePrefServiceComponent;
	@ViewChild(DelegatePrefSoftwaresComponent) pref_software_ids:DelegatePrefSoftwaresComponent;

	setDelegateModelPref(d: any, delpref:string, prefPrefix: string){
		if(d && d[delpref]){
			let dp = d[delpref];
			for (var i = dp.length - 1; i >= 0; i--) {
				let key = prefPrefix+dp[i].id;
				this.del[delpref][key] = dp[i].id;
				this[delpref].prefModel['pref_'+dp[i].id] = true;
			}
		}
	}

	get f() { return this.delegateForm.controls; }
	get firstCompanyLetter() {return this.del.company_data.name.substring(0, 1);}

	_preventDefault(e){
		e.preventDefault();
	}

	public latitude: number;
	public longitude: number;
	public place: string;
	public zoom: number;
	public time_zone: string;

  	public handleAddressChange(event){
		this.latitude = event.geometry.location.lat();
		this.longitude = event.geometry.location.lng();
		this.del.latitude = this.latitude;
		this.del.longitude = this.longitude;
		this.place = event.formatted_address;
		this.zoom = 10;

		let currentTimezone = event.utc_offset_minutes/60;
		var gmt = 'UTC';
		if (currentTimezone !== 0) {
		gmt += currentTimezone > 0 ? ' +' : ' ';
		gmt += this.decToTime(currentTimezone);
		}
		this.time_zone = '('+gmt+')';
		this.del.address = this.place;
		this.del.timezone_status = 1;

		this.eventService.getTimezoneLabel(this.latitude, this.longitude).subscribe(result => {
		if(result['error'] == '0'){
			this.del.timezone = result['data'] +" : "+ this.time_zone;
		}else{
			this.del.timezone = this.time_zone;
		}

		this.delegateForm.value.delegateTimezone = this.del.timezone;
		this.delegateForm.value.eventAddress = this.place;
		// this.delegateForm.value.lat = this.latitude;
		// this.delegateForm.value.long = this.longitude;
		//this.eventService.setEventAddress(this.del.value);
		});
  	}

  	public decToTime(minutes){
		var sign = minutes < 0 ? "-" : "";
		var min = Math.floor(Math.abs(minutes));
		var sec = Math.floor((Math.abs(minutes) * 60) % 60);
		return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  	}

  	removeDelegateBusinessCard(delegate){
		console.log('delegate', delegate);
		// let initialState = {
		// 	img_src: 'delete-popup.png',
		// 	title: 'Remove Business card?',
		// 	msg: 'This will remove the business card of the delegate when saved',
		// 	btn_save: 'Delete',
		// 	btn_close: 'Close',
		// 	url: ''
		// };

		// // Show Popup Modal
		// this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
		// this.modalRef.content.closeBtnName = 'Close';
		// this.modalRef.setClass('modal_sm modal-dialog-centered');

		// this.modalRef.content.onClose = (pass = null) => {
		// 	// Do something with myData and then hide
		// 	this.modalRef.hide();

		// 	if(pass){
		// 		let url = this.env.getUrl(Urls.api_delegates_removebusinesscard);
		// 		let formData = {
		// 			delegate_id: delegate.id
		// 		};

		// 		this.gen.post(url, formData).subscribe(data=>{
		// 			if(data.error == 0){
		// 			}
		// 		});
		// 	}
		// };

		this.profilePhotoCard = '';
		this.isProfilePhotoCroppedCard = false;
		this.isProfileImageLoadedCard = false;

		this.del.businesscard_url = 'removed';
		this.del.businesscard = 'removed';

		// let m = {
		// 	type: 'success',
		// 	msg: 'Business card removed',
		// }

		// this.messageService.add(m);
	}
}
