import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-states',
  templateUrl: './empty-states.component.html',
  styleUrls: ['./empty-states.component.scss']
})
export class EmptyStatesComponent implements OnInit {

  @Input() img_src :string = '';
  @Input() title :string = '';
  @Input() msg :string = '';
  @Input() img_width :number = 15;
  @Input() btn_text :string = '';
  @Input() url_link :string = '';

  constructor() { }

  ngOnInit() {
    
  }

}
