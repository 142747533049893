import { Component, OnInit, Input } from '@angular/core';

import { Pref } from './../../../lib/models/preferences/pref';

import { MessageService } from '../../../services/defaults/message.service';
import { PrefService } from '../../../services/preferences/pref.service';

import { Preferences } from '../preferences';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pref-tab-sector',
  templateUrl: './pref-tab-sector.component.html',
  styleUrls: ['./pref-tab-sector.component.scss']
})
export class PrefTabSectorComponent extends Preferences implements OnInit {

  constructor(
  	protected messageService: MessageService,
  	protected prefService: PrefService,
    protected modalService: BsModalService
  ) { 
    super(messageService, prefService, modalService);
  }

  ngOnInit() {
  	this.pref = {
  		id: 0,
      	name: '',
  		preftype: 'sectors'
    }
  }
  @Input() pref: Pref;
}
