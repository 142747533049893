import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-states-dash',
  templateUrl: './empty-states-dash.component.html',
  styleUrls: ['./empty-states-dash.component.scss']
})
export class EmptyStatesDashComponent implements OnInit {

  @Input() img_src :string = '';
  @Input() title :string = '';
  @Input() msg1 :string = '';
  @Input() msg2 :string = '';

  constructor() { }

  ngOnInit() {
  }

}
