import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/* services */
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { environment } from 'src/app/lib/environment';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserProfileComponent } from 'src/app/defaults/modals/user-profile/user-profile.component';
import { EditProfileComponent } from 'src/app/defaults/modals/edit-profile/edit-profile.component';

@Component({
  selector: 'app-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.scss']
})
export class GeneralHeaderComponent implements OnInit {

  modalRef: BsModalRef;
  eventFormData: Event;
  public event_id = 0;
  userLogin: any;

  public eventNotification: any;

  public eventEmptyDetails: Event;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService,
		private _user: UserService,
    public env: environment,
    protected modalService: BsModalService
  ) { }

  ngOnInit() {
    this._user.requireLogIn();
		this.userLogin = JSON.parse(localStorage.getItem('user'));
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
    
    if(this.event_id != 0){
      // get event_id to local storage
      let old_event_id = localStorage.getItem('old_event_id');
      if(this.event_id != parseInt(old_event_id)){
        // save event_id to local storage
        localStorage.setItem('old_event_id', this.event_id.toString());
        this.getEvent();
      }
    }else{
      // remove event_id to local storage
      localStorage.removeItem('old_event_id');
    }

    // get request if formData is null
    if(!this.eventService.eventFormData){
      this.getEvent();
    }
    
  }

  getEvent(){
    if(this.event_id){
      this.eventService.getEvent(this.event_id).subscribe(
        eventData => {
            this.eventService.setEventFullDetails(eventData.data);
            //this.eventFormData = this.eventService.eventFullDetails;
        }
      );
    }
  }

  logout(){
    localStorage.removeItem('user');
    this.env.deleteCookie();
		this._user.requireLogIn();
  }

	openProfile(){
    if(this.userLogin){
      let initialState = {
        event_id: this.event_id,
        userProfile: this.userLogin
      }
  
      this.modalRef = this.modalService.show(UserProfileComponent, {initialState});
      this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
      this.modalRef.content.onClose = (myData) => {
        this.modalRef.hide();
        if(myData == 'edit'){
          this.openModal(this.userLogin);
        }
    
        // get Updated Speaker List
        //this.getEventManager();
      };
    }
  }

	openModal(userLogin=null){
    let initialState = {
      event_id: this.event_id,
      userProfile: userLogin
    }

    this.modalRef = this.modalService.show(EditProfileComponent, {initialState});
    this.modalRef.setClass('add-edit-agenda-form modal-dialog-centered');
    this.modalRef.content.onClose = (myData) => {
      this.modalRef.hide();
      // get Updated Speaker List
      this.openProfile();
    };
  }
  
  /* addNewEvent(){
    this.eventService.eventFullDetails = this.eventEmptyDetails;
    this.router.navigate(['/admin/event/add/event-details']);
  } */

}
