import { Component, OnInit } from '@angular/core';

import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-archive-event-profile-card',
  templateUrl: './archive-event-profile-card.component.html',
  styleUrls: ['./archive-event-profile-card.component.scss']
})
export class ArchiveEventProfileCardComponent implements OnInit {

  constructor(
    protected eventService : EventService
  ) { }

  ngOnInit() {
  }

}