import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-add-card',
  templateUrl: './small-add-card.component.html',
  styleUrls: ['./small-add-card.component.scss']
})
export class SmallAddCardComponent implements OnInit {

  @Input() buttonName :string = '';

  constructor() { }

  ngOnInit() {
    
  }

}
