import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
import { Title } from '@angular/platform-browser';


import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { Router, ActivatedRoute } from '@angular/router';
/* services */
import { TableService } from 'src/app/services/tables/table.service';
import { ImportCsvService } from 'src/app/services/import-csv.service';
import { UserService } from '../../../../services/user.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { DelegateService } from 'src/app/services/users/delegate.service';
import { EventService } from 'src/app/services/event/event.service';
/* Model */
import { TableCount, Tables } from 'src/app/lib/models/tables/tables';
import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';

import { ImportCsvComponent } from 'src/app/defaults/import-csv/import-csv.component'; 
import { environment } from 'src/app/lib/environment';

@Component({
  selector: 'app-table-tabs',
  templateUrl: './table-tabs.component.html',
  styleUrls: ['./table-tabs.component.scss']
})
export class TableTabsComponent implements OnInit {

  @ViewChild(ModalDirective, {  }) modal: ModalDirective;
  @ViewChild(ImportCsvComponent ) child: ImportCsvComponent ; 
  
  protected modalRef: BsModalRef
  protected tableCount : TableCount;

  protected vip_table_number : number = 0;
  protected booth_table_number : number = 0;
  protected regular_table_number : number = 0;
  
  protected suggestedTableCount: number = 0;
  protected total_table_number = 0;

  /* for empty state and skel. loader */
  protected isDataLoaded = false;
  protected hasRequestError = false;
  protected fileIsLoaded = false;
  protected isDisplayPopup = false;

  /* for delegate */
  public delegates : any;
  public total_delegates = 0; 
  protected search = '';

  /* for validation */
  /* protected isLessThan = true; */

  public tables: any;

 /*  request default parameter */
  protected limit = 10;
  protected page = 1;
  protected sort = 'table_no';
  protected direction = 'asc';
  protected filter_date = '';
  protected sm_range_start = 0;
  protected sm_range_end = 0;
  protected event_id = 0;
  protected initial_type = 'vip';
  protected showEmptyState = false;

  protected date_filter: any;
  protected disableAddTableButton = false;
  protected disableShowAddTableModalButton = false;

  protected VIPTable_IDs_to_del:number[] = [];
  protected BoothTable_IDs_to_del:number[] = [];
  protected autoTable_dim = 0;

  protected importMessages: any; 

  constructor(
    protected _user: UserService,
    protected modalService: BsModalService,
    protected tableService: TableService,
    protected messageService: MessageService,
    protected router: Router,
    protected _route: ActivatedRoute,
    protected importCsv: ImportCsvService,
    protected eventService : EventService,
    protected delegateService : DelegateService,
    private titleService: Title,
    private env: environment
  ) {
    this._user.requireLogIn();
  }

  ngOnInit() {
    /* get the url parameters */
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    });
    this.titleService.setTitle("Event Tables");

    /* load the table list */
    this.getTableList(this.initial_type);
    this.getDelegateList();

  }

  /* modal for adding tables */
  showTableModal() {
    this.getTableCount();
    let a = this;
    a.modal.show();
    a.disableShowAddTableModalButton = true;
  }

  onHidden($event: ModalDirective) {
    this.disableShowAddTableModalButton = false;
  }

  handler(type: string, $event: ModalDirective) {
    console.log(
      `event ${type} is fired${$event.dismissReason
        ? ', dismissed by ' + $event.dismissReason
        : ''}`
    );
  }

  /* protected showTableModal(template: TemplateRef<any>){
    
  }  */

  setInitialDateFilter(option){
    if(!this.date_filter){
      this.date_filter = option;
    }
  }

  /* checkTotalTables(){
    let validation_result = this.isInvalid();
    return validation_result.error;
  } */

  /* check if the total number entered table is equal or greater than the suggested number of table */
  /* protected isInvalid(){
    let e = {
      error: false,
      msg: '',
    }

    this.tableCount.total_table_count = parseInt(this.tableCount.vip_table_count) + parseInt(this.tableCount.booth_table_count) + parseInt(this.tableCount.regular_table_count);

    if(this.tableCount.total_table_count < this.tableService.suggestedTableCount){
      this.isLessThan = true;
      this.hasRequestError = true;
      e.error = true;
      e.msg = "Total number of tables is less than to the suggested number of tables.";
    }else{
      e.error = false;
      this.hasRequestError = false;
      this.isLessThan = false;
    }
   
    return e;
  } */

  /* save the number of tables for vip, booth & regular */
  saveTables(){
    let m = {
      type: '',
      msg: '',
    }
    this.env.showLoader();
    this.disableAddTableButton = true;
    /* let validation_result = this.isInvalid();
    if(!validation_result.error){ */
        this.tableService.saveTables(this.event_id).subscribe(
          table_response => {
            m.msg = table_response.message;
            this.env.hideLoader();
            if(table_response.error == 0){
              this.getTableList(this.initial_type);
              m.type = 'success';
              this.closeModal();
              this.total_table_number = this.tableCount.total_table_count;
            } else {
              m.type = 'danger';
              this.hasRequestError = true;
            }
            this.messageService.add(m);
            this.disableAddTableButton = false;
          }
        );
    /* }else{
      m.type = 'danger';
      m.msg = validation_result.msg;
      this.messageService.add(m);
    } */
    
  }

  getDelegateList(){
    this.delegates= null;
    this.total_delegates = 0;

    let thisData = {
      search: this.search,
      event_id : this.event_id

    }

    this.delegateService.getAll(thisData).subscribe(
      delegates => {
        /* console.log(delegates); */
        if(delegates['error'] == 0){
          this.total_delegates = delegates['data']['total_count'];
          this.delegates = delegates['data'];
           /*console.log(this.delegates);*/ 
        }
      }
    );
  }


  protected getTableList(type: string, e: any = ''){
    this.resetParam();
    this.initial_type = type;

    if(e.page){ this.page = e.page; }
    if(e.sort){ this.sort = e.sort; }
    if(e.direction){ this.direction = e.direction; }
    if(e.filter_date){ this.filter_date = e.filter_date }
    
    if(e != ''){ this.sm_range_start = e.sm_range_start; }
    if(e != ''){ this.sm_range_end = e.sm_range_end; }

    if(this.initial_type == 'regular'){
      this.limit = 54;
      if(!this.filter_date){
        this.filter_date = this.date_filter.formatted;
      }
    }

    let thisParam = {
      'event_id' : this.event_id,
      'type' : this.initial_type,
      'limit': this.limit,
      'page': this.page,
      'sort': this.sort,
      'direction' : this.direction,
      'sm_range_start' : this.sm_range_start,
      'sm_range_end' : this.sm_range_end,
      'filter_date' : this.filter_date
    }

    this.tableService.getTables(thisParam).subscribe(
      tablelist => {
        this.tables = tablelist;
        if(tablelist.data.total_count < 1){
          this.showEmptyState = true;
        }
        this.isDataLoaded = true;
      }
    );
    this.getTableCount();
  }

  protected getTableCount(){
    let thisParam = {
      'event_id' : this.event_id
    }
    this.tableService.getTableCount(thisParam).subscribe(
      tablecount => {
        let data = tablecount.data;
        if(data){
          this.vip_table_number = tablecount.data.vip_table_count;
          this.booth_table_number = tablecount.data.booth_table_count;
          this.regular_table_number = tablecount.data.regular_table_count;

          this.tableService.tableCounts = data;
        }
        this.tableCount = this.tableService.tableCounts;
        this.total_table_number = parseInt(this.tableService.tableCounts.total_table_count);
        /* this.isInvalid(); */

        if(parseInt(tablecount.data.total_table_count) < this.tableService.suggestedTableCount){
          this.tableService.showiInadequateTable = true;
        }else{
          this.tableService.showiInadequateTable = false;
        }
      }
    );
  }

  protected assignDelegate(delegate_id,table_id){
    this.tableService.assignDelegate(delegate_id,table_id,this.event_id).subscribe(
      response => {
      }
    );
  }

  protected ConvertToInt(value){
    let val = value ? parseInt(value) : 0;
    return val;
  }

  protected closeModal(){
    this.getTableCount();
    this.modal.hide();
    this.disableShowAddTableModalButton = false;
  }


  protected resetParam(){
    this.isDataLoaded = false;
    this.showEmptyState = false;
    this.tables = null;
    this.limit = 10;
    this.page = 1;
  }

  protected printListOfTables(){
    let thisParam = {
      'event_id' : this.event_id,
      'type' : this.initial_type,
      'limit': 0,
      'sort': this.sort,
      'direction' : this.direction
    }

    this.tableService.getTables(thisParam).subscribe(
      tablelist => {
        let data = tablelist.data.datas;
        let doc = new jsPDF();

        if(this.initial_type === 'vip'){
          var file_name = this.eventService.eventFullDetails.name + " : List of VIP Tables";
        }else{
          var file_name = this.eventService.eventFullDetails.name + " : List of Booth Tables";
        }

        let columns = [
          {title: "Table No.",dataKey: "table_no"},
          {title: "Assigned Delegate",dataKey: "d_fullname"},
          {title: "Company",dataKey: "d_company_name"}
        ];
    
        let rows = data;
        let a = this;

        let letter_head = this.eventService.eventFullDetails.letterhead_image_src;

        this.toDataURL(letter_head, function(dataUrl){
          let headerTitle = file_name;
          file_name = file_name.toLowerCase();
          file_name = file_name.split(' ').join('_');  
    
          /* let header = function (data) { */
            doc.setTextColor(40);
            doc.setFontStyle('normal');
            doc.setFontSize(13);

            let headerY = 20;
            if(a.eventService.eventFullDetails.image_letterhead !== ''){
              doc.addImage(dataUrl, 'PNG', 15, 15, 0  , 30);
              headerY = 53;
            }
            doc.text(headerTitle, 15, headerY, {maxWidth: 180});
            let headerTitle_dim = doc.getTextDimensions(headerTitle);
            let splitTitle = doc.splitTextToSize(headerTitle,180,{fontSize:13});
            let headerTitle_height = (headerTitle_dim.h * splitTitle.length) + 2;  
    
            doc.setFontSize(10);
            doc.text(a.eventService.eventFullDetails.event_address, 15, headerTitle_height + headerY, {maxWidth: 180});
            
            let eventLocTitle_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_address);
            let spliteventLocTitle = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
            let eventLocTitle_height = (eventLocTitle_dim.h * spliteventLocTitle.length) + 1;  
            doc.text(a.eventService.eventFullDetails.event_date, 15, headerTitle_height + headerY + eventLocTitle_height, {maxWidth: 180});
            
            let eventDate_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_date);
            let spliteventDate = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
            a.autoTable_dim = (eventDate_dim.h * spliteventDate.length) + headerTitle_height + headerY + eventLocTitle_height  + 1; 
          /* }; */

          doc.autoTable(columns, rows, {margin: {top: a.autoTable_dim}});
          doc.save(file_name+'.pdf');
        });

      }
    );
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  
  protected saveCsvData(type: any = ''){
    /* 
    * "type" 
    * variable is for the identification of function to be call in serverside or API 
    */
    this.env.showLoader();
    this.importCsv.saveCSVFileData(type, this.event_id).subscribe(
      response => {

        let m = {
          type: '',
          msg: '',
        }
        this.env.hideLoader();
        if(response.error != 0){
          m.type = 'danger';
          m.msg = response.message;
        }else{
          m.type = 'success';
          m.msg = response.message;
        }
       
        this.messageService.add(m);
        this.importMessages = response.error_messages;
        this.fileIsLoaded = false;
        this.isDisplayPopup = false;
        this.getTableList(this.initial_type);
      }
    );
  }

  protected downloadCsvTemplate(type : any = ''){
    this.importCsv.downloadCsvTemplate(type, this.event_id).subscribe(
      res => {
        if(res && res._body){
          /* this.downloadFile(res._body); */
          this.child.downloadFile(res._body,"import_table_template.csv");
        }
      }
    );
  }

  protected setVIPTableIDs(IDs){
    this.VIPTable_IDs_to_del = IDs;
    /* console.log(this.VIPTable_IDs_to_del); */
  }

  protected setBoothTableIDs(IDs){
    this.BoothTable_IDs_to_del = IDs;
    /* console.log(this.BoothTable_IDs_to_del); */
  }

  protected deleteSelectedTable(type){
    let initialState = {
      img_src: 'delete-popup.png',
      title: 'Delete Seleted Table?',
      msg: 'This will remove all the information related to this table both in the dashboard and mobile app.',
      btn_save: 'Remove',
      btn_close: 'Close'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_sm modal-dialog-centered');

    this.modalRef.content.onClose = (pass = null) => {
      // Do something with myData and then hide
      this.modalRef.hide();

      if(pass){
          let data = {
            'ids' : null,
            'event_id' : this.event_id,
            'type' : type,
          }
          if(type == 'vip'){
            data.ids = this.VIPTable_IDs_to_del
          }else{
            data.ids = this.BoothTable_IDs_to_del;
          }
          this.env.showLoader();
          this.tableService.deleteSelectedTable(data).subscribe(
            response => {
              this.getTableList(this.initial_type);
              this.env.hideLoader();
              this.VIPTable_IDs_to_del = [];
              this.BoothTable_IDs_to_del = [];

              let m = {
                type: '',
                msg: '',
              }

              if(response.error != 0){
                m.type = 'danger';
              }else{
                m.type = 'success';
              }
              m.msg = response.message;
              this.messageService.add(m);
            }
          );
      }
    }
  }

}
