import { Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Caret } from 'src/app/lib/caret';
import { ParamModel } from 'src/app/lib/models/pagination';

import { EditPreferencesComponent } from 'src/app/defaults/modals/edit-preferences/edit-preferences.component';

export class Preferences {

	onStopTyping = new Subject<string>();

	@Input() paramModel: ParamModel;

	modalRef : BsModalRef;
	public limit = 10;
	public search = '';
	public sort = '';
	public direction = 'desc';
	public pref: any = [];
	public searchReadOnly: boolean = false;

	public skeleton_table = [
	  { id: '1'},
	  { id: '2'},
	  { id: '3'}
	];   

	constructor(
	  	protected messageService: any,
		protected prefService: any,
		protected modalService: any
  	) { 
		this.onStopTyping.pipe(debounceTime(400), distinctUntilChanged())
		.subscribe(value => {
			this.startSearch();
		});
	}
	  
	setParams(){
		this.paramModel = {params: this.prefService.prefLink[this.pref.preftype]};
	}

	saveData(pref:any = '', reload:boolean = true){
		let p = this.pref
		if(pref){
			p = pref
		}
		this.prefService.save(p)
			.subscribe(data => {this.saveHelper(data, reload)});
	}

	saveHelper(data, reload:boolean = true){
		let m = {
			type: 'danger',
			msg: 'Please enter name of preference '+this.pref.preftype+'.',
		}
		m.msg = data.message;
		if(data.error == 0){
			m.type = 'success';
			this.pref.name = "";
		} 
		this.messageService.add(m);
		this.sort = '';
		this.direction = '';
		if(reload){
			this.limitChange();
		}
	}

  	limitChange(){
		let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search);
		this.sendRequest(url);
	}

  	startSearch() {
		let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search);
		this.sendRequest(url);
  	}

  	navigateTo(page: number, type:any = null) {
		if(type){
			this.pref.preftype = type;
		}

		let url = this.prefService.getSetUrlParams(this.pref.preftype, page, this.limit, this.search);
		this.sendRequest(url);
	}

	jumpTo(e){
		this.navigateTo(e.target.value);
	}

	sortHow(col: string){
		if(this.sort == col){
			if(this.direction == 'asc'){
				this.direction = 'desc';
			} else {
				this.direction = 'asc';
			}
		} else {
			this.sort = col;
		}
		let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search, this.sort, this.direction);
		this.sendRequest(url);
	}

  	sendRequest(url: string){
		this.prefService.getData(url, this.pref.preftype)
	  		.subscribe(data => {
				this.prefService.getHelper(data, this.pref.preftype);
				this.setParams();
			});
	}
	toEditable(elId: number){
		let eId = this.pref.preftype + '_' + elId;
		let e = document.getElementById(eId);
		this.setCaretPosition(e);
	}

	/* 
	*	set the editable content caret postion
	*/
	setCaretPosition(e){
		let caret = new Caret(e);
		let text = e.textContent;
		caret.placeCaretAtEnd(e);
	}

	toEditPreference(prefData){
		let initialState = {
		prefData: prefData,
		title: 'Meeting Details'
		};
	
		// Show Popup Modal
		this.modalRef = this.modalService.show(EditPreferencesComponent, {initialState});
		this.modalRef.content.closeBtnName = 'Close';
		this.modalRef.setClass('modal_md modal-dialog-centered');
	
		this.modalRef.content.onClose = () => {
			// Do something with myData and then hide
			this.modalRef.hide();
		}

		this.modalRef.content.onSaveChanges = (e) => {
			this.saveEdit(e, prefData);
			this.modalRef.hide();
		}
	
	}

	saveEdit(e, pref:any){
		e.preventDefault();
		let text = e.target.textContent;
		if(text.length){
			if(!pref.name){
				pref.name = text;
			}
			pref['preftype'] = this.pref.preftype;
			this.saveData(pref, false);
		} else {
			let m = {
				type: 'danger',
				msg: 'Please enter name of preference '+this.pref.preftype+'.',
			}
			e.target.text = pref.name;
			this.messageService.add(m);
		}
		this.setCaretPosition(e.target);
	}

  	removePref(id=null, type=null){
		if(id){
			this.searchReadOnly = true;
			let initialState = {
				img_src: 'delete-popup.png',
				title: 'Remove Preference?',
				msg: 'This will remove all the information related to this preference both in the dashboard and mobile app.',
				btn_save: 'Delete',
				btn_close: 'Close'
			};
		
			// Show Popup Modal
			this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
			this.modalRef.content.closeBtnName = 'Close';
			this.modalRef.setClass('modal_sm modal-dialog-centered');
		
			this.modalRef.content.onClose = (pass = null) => {
				// Do something with myData and then hide
				this.modalRef.hide();
				this.searchReadOnly = false;
		
				if(pass){
					this.prefService.removePreference(id, type).subscribe(
						result => {
						let m = {
							type: 'danger',
							msg: result.message,
						}
						
						if(result.error == 0){
							m.type = 'success';
							this.messageService.add(m);
							this.getPreference(type);
							
						}else{
							this.messageService.add(m);
						}
						}
					);
				}
			};
		}
	}
	  
	getPreference(type: null){
		if(type){
			this.prefService.getData(this.prefService.getSetUrlParams(type), type)
			.subscribe(data => {this.prefService.getHelper(data, type)});
		}
	}
}