import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import { ValidateUrl } from 'src/app/lib/validators/url.validator';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { PrefService } from '../../../../../services/preferences/pref.service';



@Component({
  selector: 'app-event-privacy-form-virtual',
  templateUrl: './event-privacy-form-virtual.component.html',
  styleUrls: ['./event-privacy-form-virtual.component.scss']
})
export class EventPrivacyFormVirtualComponent implements OnInit {

  onSearchStopTyping = new Subject<string>();
  eventForm: FormGroup;
  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Input() selectedNetworks: any = "";
  @Output() updateEventItem : EventEmitter<any> = new EventEmitter();

  public networkList = [];

  public urlPattern = "^(https?|chrome):\/\/[^\s$.?#].[^\s]*$";
  public submitted: boolean= false;
  public event_id: number = 0;
  protected networkIsLoaded = false;
  public emptystate = false;
  public networklist = false;
  public noselectednetwork = false;
  public searchKey: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private eventService: EventService,
    private _route: ActivatedRoute,
    private prefService : PrefService
  ) { 
    
  }

  ngOnInit() {
      this._route.paramMap.subscribe( url_param =>{
          this.event_id = + url_param.get('event_id');
        }
      );

      if(!this.event_id && this.eventService.eventFullDetails.name === ''){
        this.router.navigate(['/admin/event/add/event-details-virtual']);
      }

      this.eventForm = this.formBuilder.group({
        privacy: [''],
        registrationLink:['', Validators.pattern(this.urlPattern)],
        networkList: new FormArray([])
      }, { });

      if(this.event_id === null || this.event_id == 0){
        this.selectedNetworks = this.eventService.selectedNetworkIds;
      }
      
      this.prefService.getData(this.prefService.getSetUrlParams('networks'), 'networks')
        .subscribe(networks => {
          this.networkList = networks.data.datas;
          this.networkList.forEach(element => {
            element.id = parseInt(element.id);
          });
          this.addCheckboxes();
          this.networkIsLoaded = true;
      });
      this.eventFormData = this.eventService.eventFullDetails;

      this.onSearchStopTyping.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(value => { 
        this.searchKey = value;
        this.searchNetwork(value); 
      });
  }


  public searchNetwork(searchkey){
    this.networkList = [];
    this.prefService.getData(this.prefService.getSetUrlParams('networks',1,10,searchkey), 'networks')
      .subscribe(networks => {
        this.networkList = networks.data.datas;
        this.networkList.forEach(element => {
          element.id = parseInt(element.id);
        });

        
        if(this.networkList.length < 1){
          this.emptystate = true;
        } else {
          this.emptystate = false;
          this.addCheckboxes();
        }

        /* console.log(this.networkList); */
    });
  }

  private addCheckboxes() {
    let formControlArray = this.eventForm.controls.networkList as FormArray;
    while (formControlArray.length > 0) {
      formControlArray.removeAt(0);
    }

    this.networkList.map((o, i) => {
      const control = new FormControl(this.selectedNetworks.includes(this.networkList[i].id)); // if first item set to true, else false
      (formControlArray).push(control);
    });
  }

  get f() { return this.eventForm.controls; }
  
  saveEventDetails(){
      this.submitted = true;
      const permissions = this.eventFormData.permission;

      /* stop here if form is invalid */
      if (this.eventForm.invalid) {
          return;
      }

      /* Check if has selected network.*/
      const selectedNetworkIds = this.eventForm.value.networkList
          .map((v, i) => v ? this.networkList[i].id : null)
          .filter(v => v !== null);
        this.eventService.selectedNetworkIds = selectedNetworkIds;
      if(permissions == '3') {
        if(this.eventService.selectedNetworkIds.length == 0) {
          this.noselectednetwork = true;
          return;
        }
      }

      /* console.log('savedetails',this.eventService.eventFullDetails); */
      this.eventService.setEventPrivacyDetails(this.eventForm.value);

      if(this.eventFormData.id <= 0){
        this.router.navigate(['/admin/event/add/event-review-virtual']);
      }else{
        this.updateEventItem.emit();
        this.router.navigate(['/admin/dashboard/', this.eventFormData.id]);
      } 
  }

  /* this will save the data in variable */
  setAsSelect(network_id){
    /* let selectedNetworks = this.eventService.selectedNetworkIds */
    
    if(!this.selectedNetworks.includes(network_id)){
      this.selectedNetworks.push(network_id);
    }else{
      /* 
      * if the id is already exist in array remove it 
      */
      let listToDelete: any = [];
      listToDelete.push(network_id);

      let result = this.selectedNetworks.filter( el_data => (network_id != el_data ));
      this.selectedNetworks = result;
    }

    console.log(this.selectedNetworks);
  }

}
