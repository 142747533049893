import { Component, OnInit, Input } from '@angular/core';


import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* services */
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/defaults/message.service';
/* models */
import { EventSummary } from 'src/app/lib/models/events/eventsummary';
import { FaqsService } from 'src/app/services/faqs/faqs.service';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-faqs-modal-form',
  templateUrl: './faqs-modal-form.component.html',
  styleUrls: ['./faqs-modal-form.component.scss']
})
export class FaqsModalFormComponent implements OnInit {

  @Input() event_id: number = null;
  @Input() faqcategories: any = null;
  @Input() faqdata: any = null;
  
  public Editor = ClassicEditor;
  private faqForm: FormGroup;
  private faqFormData: EventSummary;
  private eventAfterMovieForm: FormGroup;
  private urlPattern = "";

  public submitted = false;
  public question_id;
  

  onClose: any;
  saveSuccess: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService,
    private messageService: MessageService,
    protected faqsService : FaqsService
  ) { }

  ngOnInit() {
    let youtubelink = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+$/;

    this.faqForm = this.formBuilder.group({
      question: [this.faqdata ? this.faqdata.question : '',Validators.required],
      profile : [this.faqdata ? this.faqdata.answer : '', Validators.required ],
      category_id : [this.faqdata ? this.faqdata.faq_category_id : '',Validators.required],
      youtube_link: [this.faqdata ? this.faqdata.youtubelink : '', Validators.pattern(youtubelink)]
    }, { });

    /* this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
    }); */
  }

  get f() { return this.faqForm.controls; }

  closeModal(){
		this.onClose({});
  }

  
  saveQuestion(){
      this.submitted = true;
  
      if (this.faqForm.invalid) {
        return;
      }

      if(this.faqdata){
        this.question_id = this.faqdata.id;
      }

      this.faqsService.saveFaq(this.faqForm.value, this.event_id, this.question_id ).subscribe( r => {
        let m = {
          type: 'danger',
          msg: r['message'],
        }
        if(r['error'] == 0){
          m.type = 'success';
          this.messageService.add(m);
          this.saveSuccess({});
    
        }else{
          this.messageService.add(m);
        }
      });
  
  }

}
