import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 

import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

	public httpOptions = this.env.getHttpOptions();

	constructor(
		protected http:HttpClient,
		protected env: environment
	) { }

	getAgenda(event_id: number, date: string){
		let url = this.env.getUrl(Urls.api_agenda_get) + "?event_id=" + event_id +"&date="+date;
		return this.http.get<any>(url, this.httpOptions);
	}

	/*
	*	save or update an agend
	*/
	saveAgenda(agenda){
		var formData = new FormData();
		for (let key of Object.keys(agenda)) {
			if(key != 'extracted_time'){
				formData.append(key, agenda[key]);
			} 
        }
		let url = this.env.getUrl(Urls.api_agenda_save);
    	return this.http.post<any>(url, formData, this.httpOptions);
	}

	/*
	*	make request to get avaialble time
	*/
	getAvailableTime(event_id: number, date: string, type:string = '', selected_start_time:string = ''){
		let url = this.env.getUrl(Urls.api_agenda_availabletime) + "?event_id=" + event_id +"&date="+date;
		if(type != '' &&  selected_start_time != ''){
			url += '&type=' + type;
			url += '&selected_start_time=' + selected_start_time;
		}
    	return this.http.get<any>(url, this.httpOptions);
	}

	/*
	*	make request to delete agenda by id
	*/
	deleteAgenda(formData) {
		let url = this.env.getUrl(Urls.api_agenda_delete);
		return this.http.post<any>(url, formData, this.httpOptions);
	}
}
