import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 

import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  constructor(
    protected http:HttpClient,
    protected env: environment,
  ) { }

  getFaqList(param) : Observable<any>{
    let url = this.env.getUrl(Urls.api_faqs_get);
    url += this.env.createUrlParam(param);
    
    return this.http.get(url, this.env.getHttpOptions());
  }
  getFaqCategoryList(param) : Observable<any>{
    let url = this.env.getUrl(Urls.api_faqs_getcategory);
    url += this.env.createUrlParam(param);
    
    return this.http.get(url, this.env.getHttpOptions());
  }
  saveFaq(data, event_id , question_id = ''){
    let url = this.env.getUrl(Urls.api_faqs_save);
    
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }
    formData.append('event_id',event_id);

    if(question_id){
      formData.append('id',question_id);
    }

    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  saveFaqCategory(data){
    let url = this.env.getUrl(Urls.api_faqs_savecategory);
    
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }
    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  saveFaqCategorySorting(data){
    let url = this.env.getUrl(Urls.api_faqscategory_savesort);
    
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }
    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  delegateCategory(data){
    let url = this.env.getUrl(Urls.api_faqscategory_delete);
    
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }
    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  deleteFaq(data){
    let url = this.env.getUrl(Urls.api_faqs_delete);
    
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }
    return this.http.post(url, formData, this.env.getHttpOptions());
  }
}
