import { Injectable } from '@angular/core';
import { Router } from '@angular/router'

import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';
import { MessageService } from './../../services/defaults/message.service';

@Injectable({
  providedIn: 'root'
})
export class PrefService {

  public limit: number = 10;
  public prefs = {
    networks: [],
    services: [],
    states: [],
    specializations: [],
    countries: [],
    sectors: [],
    softwares: []
  }
  public prefsLoading = {
    networks: true,
    services: true,
    states: true,
    specializations: true,
    countries: true,
    sectors: true,
    softwares: true
  }
  public prefLink = {};
  public pagination = [];

  constructor(
  	private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private env: environment
  ) { }


  save(data: any){
  	let httpOptions = this.env.getHttpOptions();

    let url = this.env.getUrl(Urls.api_preferences_save);
    return this.http.post<any>(url, data, httpOptions);
  }

  getData(params: string = '', prefType: string = '' ){
    let httpOptions = this.env.getHttpOptions();
    let url = this.env.getUrl(Urls.api_preferences_get) + params;
    return this.http.get<any>(url, httpOptions);
  }

  // this will set the url param based on selected preference
  // the values are saved into prefLink object
  // return string URI for api request
  getSetUrlParams(
    type: string, 
    page: number = 0, 
    limit: number = 0, 
    search: string = '',
    sort: string = '',
    order: string = '' 
  ){
    if(limit <= 0){
      limit = this.limit;
    }

    if(typeof this.prefLink[type] == 'undefined'){
      this.prefLink[type] = {
        page: page,
        pref: type,
        limit: limit,
        sort: sort,
        order: order
      }
    }
    else {
      this.prefLink[type].page = page;
      this.prefLink[type].limit = limit;
    }
    if(search.length > 0) {
      this.prefLink[type].search = search;
    } else {
      delete this.prefLink[type].search;
    }
    if(sort.length > 1){
      this.prefLink[type].sort = sort;
      this.prefLink[type].order = 'asc';
    }

    if(order === 'asc' || order === 'desc'){
      this.prefLink[type].order = order;
    }

    let p = this.prefLink[type];

    let uriStr = '?';
    for (let key of Object.keys(p)) {
      if(p[key]){
        uriStr += key + '=' + p[key] + '&';
      }
    }
    return uriStr;
  }
  

  // this will save the data into service model
  // this will tell angular either to render the data
  // or render empty state
  getHelper(data, prefType: string){
    let m = {
      type: 'danger',
      msg: 'Please enter name of network.',
    }

    m.msg = data.message;
    if(data.error == 0){
      m.type = 'success';
      this.prefs[prefType] = data.data;
      this.prefsLoading[prefType] = false;
      this.setAllPagesNumbers(data.data, prefType);
    } 
    else {
      this.messageService.add(m);
    }
  }

  setAllPagesNumbers(data, prefType: string){
    let totalPage = data.total_page;
    let pageNUmber = [];
    for (var i = 1; i <= totalPage; i++) {
      pageNUmber.push(i);
    }
    this.prefs[prefType]['pageNumbers'] = pageNUmber;
  }

  /* 
  * for pagination call, this function was beeing called
  * pagination compenent
  */
  paginationRequest(params){
    let url = this.getSetUrlParams(params.pref, params.page, params.limit, params.search);
    this.getData(url, params.pref)
      .subscribe(data => {
        this.getHelper(data, params.pref);
      });
  }

	/* ----------------------------------
	  Delete preference
	  ----------------------------------*/
	removePreference(id: any, type: any){
    let httpOptions = this.env.getHttpOptions();
    
		let formData = new FormData();
		formData.append('id', id);
		formData.append('pref', type);

		let url = this.env.getUrl(Urls.api_preferences_delete);
		return this.http.post<any>(url, formData, httpOptions);
	}
	/* ----------------------------------
	  End of Delete preference
    ----------------------------------*/
    
    getStates(data){
      let httpOptions = this.env.getHttpOptions();
      
      let url = this.env.getUrl(Urls.api_get_states);
      url += this.env.createUrlParam(data);
      return this.http.get<any>(url, httpOptions);
    }
}
