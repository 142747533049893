import { Component, OnInit } from '@angular/core';
import * as moment from 'moment'

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { DelegateService } from 'src/app/services/users/delegate.service';
import { strrandom } from 'src/app/lib/strrandom';
import { EventService } from 'src/app/services/event/event.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';

import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { Urls } from 'src/app//lib/urls';
import { GeneralService } from 'src/app/services/general.service';
import { environment } from 'src/app/lib/environment';
import { MessageService } from 'src/app/services/defaults/message.service';
import { DatePipe } from '@angular/common';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-send-itineraries',
  templateUrl: './send-itineraries.component.html',
  styleUrls: ['./send-itineraries.component.scss'],
  providers: [DatePipe]
})
export class SendItinerariesComponent implements OnInit {

  subscribeSearch = new Subject<string>();
  onClose: any;
  delegate_ids: any;
  event_id: any;
  selectedDate: any;

  protected autoTable_dim = 0;

  filter_search = false;
  public delegates : any;
  public total_delegates = 0;
  public total_page = 0;
  protected limit = 10;
  protected order = 'asc';
  protected search = '';
  protected page = 1;
  protected sort = 'id';
  protected keyword_country = '';
  protected keyword_province = '';
  protected filter_country = '';
  protected filter_province = '';

  isLoaded: boolean = false;

  selectedDelegates: any = null;
  total_selectedDelegates: any = 0;
  selected_all: boolean = false;

  selectedDelegatesSend: any = 0;
  filebase64data: any;

  meetingList = 'meetingList';
  zoom_setting: any = null;

  constructor(
    public delegateService: DelegateService,
    public eventService: EventService,
    public meetingSchedule: MeetingscheduleService,
		private env : environment,
		private gen : GeneralService,
		protected messageService: MessageService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) {

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.filter_search = true;
        this.page = 1;
        this.delegates = null;
        this.isLoaded = false;
        this.getDelegateList();
    });
  }

  ngOnInit() {
    this.getDelegateList();
    this.getZoomSetting();
  }

  onScroll() {
    console.log(this.page);
    console.log(this.total_page);

    if(this.total_page > this.page){
      this.page++;
      this.getDelegateList(true);
    }
  }

  getDelegateList(infiniteScroll?){
    let thisData = {
      search: this.search,
      limit: this.limit,
      order: this.order,
      page: this.page,
      sort: this.sort,
      filter_country: this.filter_country,
      filter_province: this.filter_province,
      event_id: this.event_id
    }

    this.delegateService.getDelegate(thisData).subscribe(
      delegates => {
        this.isLoaded = true;

        if(delegates['error'] == 0){
          this.total_delegates = delegates['data']['total_count'];
          this.total_page = delegates['data']['total_page']

          if (!infiniteScroll || infiniteScroll === false){
            this.delegates = [];
          }

          delegates['data']['datas'].forEach((data) => {
            this.delegates.push(data);
            if(this.selected_all){
              this.total_selectedDelegates++;
              this.selectedDelegates.push(data);
            }
          });

          console.log('delegates',this.delegates);
        }else{
          this.delegates = [];
          this.total_delegates = 0;
          this.total_page = 0;
        }
      }
    );
  }

  selectAll(type){
    if(type){

      let i = this.page;
      for (i; i < this.total_page; i++) {
        this.page++;
        this.getDelegateList(true);
      }

      this.selected_all = true;
			this.selectedDelegates = [];
      this.total_selectedDelegates = 0;
      if(this.delegates && this.total_delegates > 0){
        this.delegates.forEach((delegate, idx) => {
          this.selectedDelegates.push(delegate);
          this.total_selectedDelegates++;
        });
      }

    }else{

      this.selected_all = false;
			this.selectedDelegates = [];
      this.total_selectedDelegates = 0;
    }

    console.log('sdfd',this.total_selectedDelegates);
  }

  searchfunc($param){
    this.search = $param;
    this.page = 1;
    this.delegates = null;
    this.isLoaded = false;
    this.getDelegateList();
  }

  changeSelectedDelegates(delegate){
		if(!this.selectedDelegates){
			this.selectedDelegates = [];
    }

		let indexOf = this.selectedDelegates.findIndex(x => x.id == delegate.id);
    console.log('delegate.id',delegate.id);
    console.log('indexOf',indexOf);
    if(indexOf >= 0){
			this.selectedDelegates.splice(indexOf, 1);
      this.total_selectedDelegates--;
    }else{
			this.selectedDelegates.push(delegate);
      this.total_selectedDelegates++;
    }
  }

  checkIfSelectedDelegate(delegate){
		if(this.selectedDelegates){
      if (this.selectedDelegates.find(x => x.id == delegate.id)){
        return true;
      }else{
        return false;
      }
		}else{
      return false;
    }
  }

  closeModal(){
    this.onClose();
  }

  sendItinerary(){
    this.selectedDelegatesSend = 0;
    if(this.selectedDelegates){
      console.log('this.selectedDelegates',this.selectedDelegates);
      this.selectedDelegates.forEach(data => {
        this.filebase64data = null;
        this.getItinerarySchedule(data);
      });
    }
  }

  getItinerarySchedule(data){
    let url = this.env.getUrl(Urls.api_delegate_meetingitinerary); //mapi
    let formData = {
      event_id: data.event_delegateevent_id,
      event_delegate_id: data.event_delegateid
    };

    formData['is_virtual'] = true;

    this.gen.post(url, formData).subscribe(sched=>{
      if(sched.error == 0){
        console.log('schedule_itineray', sched);
        sched.data.forEach(res => {
          let temp_data = [];
          if(res.data){
            res['meeting_date'] = this.datePipe.transform(res.date, 'MMM d, y');
            res.data.forEach((res1, key) => {
              let temp_arr = [];
              res1['meeting_time'] = this.datePipe.transform(res1.start_time_delegate, 'h:mm a') +' - '+this.datePipe.transform(res1.end_time_delegate, 'h:mm a');
              res1['other_delegate_fullname'] = data.event_delegateid == res1.d1_id ? res1.d2_fullname : res1.d1_fullname;
              res1['other_delegate_company'] = data.event_delegateid == res1.d1_id ? res1.d2_company_name : res1.d1_company_name;
              res1['other_delegate_jobtitle'] = data.event_delegateid == res1.d1_id ? res1.d2_job_title : res1.d1_job_title;
              if(this.eventService.eventFullDetails.type == 1){
                if(res1.table_type == 3){
                  res1['table'] = 'Table '+res1.table_table_no;
                }else if(res1.table_type == 2){
                  res1['table'] = 'Booth '+res1.table_table_no;
                }else if(res1.table_type == 1){
                  res1['table'] = 'VIP '+res1.table_table_no;
                }
              }else{
                res1['zoom_email_address'] = data.event_delegateid != res1.d1_id ? res1.d2_social_media_links.zoom : res1.d1_social_media_links.zoom;
                if(this.zoom_setting.status == '1'){
                  let url = this.env.getUrl(Urls.api_virtualconference_shortenurl);
                  if(data.email == res1.d1_email){
                    let type = "s"; //start
                    url += '?t=' + type;
                    url += '&c=' + res1.zoom_id;
                    res1['meeting_link'] = url;
                  }else{
                    let type = "j";
                    url += '?t=' + type;
                    url += '&c=' + res1.zoom_id;
                    res1['meeting_link'] = url;
                  }
                }
              }
              temp_arr.push(this.datePipe.transform(res1.start_time_delegate, 'h:mm a') +' - '+this.datePipe.transform(res1.end_time_delegate, 'h:mm a'));
              temp_arr.push(data.event_delegateid == res1.d1_id ? res1.d2_fullname : res1.d1_fullname);
              temp_arr.push(data.event_delegateid == res1.d1_id ? res1.d2_company_name : res1.d1_company_name);
              temp_arr.push(data.event_delegateid == res1.d1_id ? res1.d2_job_title : res1.d1_job_title);
              if(this.eventService.eventFullDetails.type == 1){
                if(res1.table_type == 3){
                  temp_arr.push('Table '+res1.table_table_no);
                }else if(res1.table_type == 2){
                  temp_arr.push('Booth '+res1.table_table_no);
                }else if(res1.table_type == 1){
                  temp_arr.push('VIP '+res1.table_table_no);
                }
              }else{
                temp_arr.push(data.event_delegateid == res1.d1_id ? res1.d2_social_media_links.zoom : res1.d1_social_media_links.zoom);
                if(this.zoom_setting.status == '1'){
                  let url = this.env.getUrl(Urls.api_virtualconference_shortenurl);
                  if(data.email == res1.d1_email){
                    // temp_arr.push(res1.zoom_meeting_link_1);
                    let type = "s";
                    url += '?t=' + type;
                    url += '&c=' + res1.zoom_id;
                    temp_arr.push(url);
                  }else{
                    // temp_arr.push(res1.zoom_meeting_link_2);
                    let type = "j"; //join
                    url += '?t=' + type;
                    url += '&c=' + res1.zoom_id;
                    temp_arr.push(url);
                  }
                }
              }
              temp_data.push(temp_arr);
            });
            res['meeting_data'] = temp_data;
          }
        });
        console.log(sched.data);
        this.sendSchedule(sched.data, data);
      }
    });
  }

  /* print filtered meeting scheduled list */
  protected sendSchedule(data, delegate){
    let a = this;

    let doc;
    if(a.eventService.eventFullDetails.type == 1){
      doc = new jsPDF();
    }else{
      doc = new jsPDF({
        orientation: "landscape"
      });
    }

    doc.setTextColor(40);
    doc.setFontStyle('normal');
    doc.setFontSize(13);

    let file_name = a.eventService.eventFullDetails.name + " : " + delegate.fullname + "'s meeting schedules ";

    let letter_head = "/assets/images/f2f_logo.png";
    this.toDataURL(letter_head, function(dataUrl){
      console.log('dataUrl', dataUrl);
      let headerTitle = file_name;

      let headerY = 20;
      if(letter_head){
        // addImage(imageData, format, x, y, width, height, alias, compression, rotation)
        doc.addImage(dataUrl, 'PNG', 15, 15, 45, 10, '', 'FAST');
        headerY = headerY + 15;
      }
      // text(text, x, y, optionsopt, transform)
      doc.text(headerTitle, 15, headerY, {maxWidth: 180});

      let headerTitle_dim = doc.getTextDimensions(headerTitle);
      let splitTitle = doc.splitTextToSize(headerTitle, 180, {fontSize:13});
      let headerTitle_height = (headerTitle_dim.h * splitTitle.length) + 2;
      console.log('headerTitle_dim', headerTitle_dim);
      console.log('splitTitle', splitTitle);
      console.log('headerTitle_height', headerTitle_height);
      doc.setFontSize(11);
      doc.text(a.eventService.eventFullDetails.event_date, 15, headerTitle_height + headerY, {maxWidth: 180});

      let eventDate_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_date);
      let spliteventDate = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
      a.autoTable_dim = (eventDate_dim.h * spliteventDate.length) + headerTitle_height + headerY + 1;

      data.forEach(element => {
        if(element.data){
          let rows = element.data;
          if(element.data){
            if(a.eventService.eventFullDetails.type == 1){
              doc.autoTable(
                {
                  theme: 'striped',
                  columnStyles: {
                    europe: { halign: 'center' },
                    0: {
                      cellWidth: 30,
                      fontSize: 8
                    },
                    1: {
                      cellWidth: 35,
                      fontSize: 8
                    },
                    2: {
                      cellWidth: 50,
                      fontSize: 8
                    },
                    3: {
                      cellWidth: 48,
                      fontSize: 8
                    },
                    4: {
                      cellWidth: 18,
                      fontSize: 8
                    },
                  },
                  head: [
                    [{ content: element.meeting_date, colSpan: 6, styles: { halign: 'center', fillColor: [104, 139, 6], textColor: [256, 256, 256] } }],
                    [
                      {content: 'Meeting time'},
                      {content: 'Delegate Name'},
                      {content: 'Company'},
                      {content: 'Position'},
                      {content: 'Table No'},
                    ]
                  ],
                  body: element.meeting_data,
                  margin: { top: a.autoTable_dim },
                  styles: { fillColor: [204, 204, 204], textColor: [68, 68, 68] }
                }
              );
            }else{
              if(a.zoom_setting.status == '1'){
                doc.autoTable({
                  theme: 'striped',
                  columnStyles: {
                    europe: { halign: 'center' },
                    0: {
                      cellWidth: 30,
                      fontSize: 8
                    },
                    1: {
                      cellWidth: 35,
                      fontSize: 8
                    },
                    2: {
                      cellWidth: 50,
                      fontSize: 8
                    },
                    3: {
                      cellWidth: 40,
                      fontSize: 8
                    },
                    4: {
                      cellWidth: 50,
                      fontSize: 8
                    },
                    5: {
                      fontSize: 8
                    }
                  },
                  head: [
                    [{ content: element.meeting_date, colSpan: 6, styles: { halign: 'center', fillColor: [104, 139, 6], textColor: [256, 256, 256] } }],
                    [
                      { content: 'Meeting time'},
                      { content: 'Delegate Name'},
                      { content: 'Company'},
                      { content: 'Position'},
                      { content: 'Zoom Email Address Id'},
                      { content: 'Zoom Meeting URL', styles: { cellWidth: 'wrap'}},
                    ]
                  ],
                  body: element.meeting_data,
                  margin: { top: a.autoTable_dim },
                  styles: { fillColor: [204, 204, 204], textColor: [68, 68, 68] }
                });
              }else{

                doc.autoTable({
                  theme: 'striped',
                  columnStyles: {
                    europe: { halign: 'center' },
                    0: {
                      cellWidth: 30,
                      fontSize: 8
                    },
                    1: {
                      cellWidth: 45,
                      fontSize: 8
                    },
                    2: {
                      cellWidth: 65,
                      fontSize: 8
                    },
                    3: {
                      cellWidth: 70,
                      fontSize: 8
                    },
                    4: {
                      cellWidth: 45,
                      fontSize: 8
                    },
                  },
                  head: [
                    [{ content: element.meeting_date, colSpan: 6, styles: { halign: 'center', fillColor: [104, 139, 6], textColor: [256, 256, 256] } }],
                    [
                      { content: 'Meeting time'},
                      { content: 'Delegate Name'},
                      { content: 'Company'},
                      { content: 'Position'},
                      { content: 'Zoom Email Address Id'}
                    ]
                  ],
                  body: element.meeting_data,
                  margin: { top: a.autoTable_dim },
                  styles: { fillColor: [204, 204, 204], textColor: [68, 68, 68] }
                });
              }
            }
          }
        }
      });
      var fileBlob = doc.output('blob');
      var reader = new FileReader();

      reader.readAsDataURL(fileBlob);
      reader.onloadend = function() { // for blob to base64
        a.filebase64data = reader.result;
        if(a.filebase64data){
          let url = a.env.getUrl(Urls.api_delegates_senditineraries);
          let formData = new FormData();
          formData.append('file_name', file_name);
          formData.append('file', fileBlob);
          formData.append('b64_file', a.filebase64data);
          formData.append('event_name', a.eventService.eventFullDetails.name);
          formData.append('delegate_email', delegate.email);

          a.spinner.show(a.meetingList);

          a.gen.post(url, formData).subscribe(send=>{
            console.log('data_send_pdf', send);
            if(send.error == 0){
              a.selectedDelegatesSend++;
              console.log(a.selectedDelegatesSend+ '== ' +a.selectedDelegates.length);
              if(a.selectedDelegatesSend == a.selectedDelegates.length){
                setTimeout(()=> {
                  a.spinner.hide(a.meetingList);

                  let m = {
                    type: 'success',
                    msg: send.message,
                  }

                  a.messageService.add(m);
                  a.closeModal();
                }, 1000)
              }
            }
          });
        }
      }
      //doc.save(a.eventService.eventFullDetails.name + ' Final Itinerary'+'.pdf');
    });
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  getZoomSetting(){
    let url = this.env.getUrl(Urls.api_virtualconference_getzoomsettings);

    this.gen.get(url).subscribe(data=>{
      if(data.error == 0){
        this.zoom_setting = data.data;
      }
    });
  }
}
