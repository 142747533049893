import { Component, OnInit } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-meeting-requests-list-modal',
  templateUrl: './meeting-requests-list-modal.component.html',
  styleUrls: ['./meeting-requests-list-modal.component.scss']
})
export class MeetingRequestsListModalComponent implements OnInit {

  onClose: any;
  modalRef: BsModalRef;
  
  public timeslot_id;
  public event_id;
  public meetingRequest: any;
  public delegate_id;
  public type: any;
  public dataIsLoading = false;
  protected current_page;

  
  constructor(
    protected meetingSchedule : MeetingscheduleService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
  }

  closeModal(){
    this.onClose();
  }

  selectTab(type){
    this.getMeetingRequest(type);
  }

  getMeetingRequest(type = null){
    if(type){
      this.type = type;
    }
    this.dataIsLoading = true;
    let  args = {
      'time_slot_id' : this.timeslot_id,
      'event_id' : this.event_id,
      'type' : this.type,
      'delegate' : this.delegate_id
    }
    this.meetingSchedule.getMeetingRequests(args).subscribe( r => {
      this.meetingRequest = r;
      this.dataIsLoading = false;
    });
  }

  cancelMeetingRequest(ms_id,edid){
    let args = {
      meeting_schedule_id : ms_id,
      action : 'cancel',
      edid : edid
    }
    this.meetingSchedule.meetingRequestAction(args).subscribe( r => {
      let m = {
        type: 'danger',
        msg: r['message'],
      }
      if(r['error'] == 0){
        m.type = 'success';
        this.messageService.add(m);
      }else{
        this.messageService.add(m);
      }
      this.getMeetingRequest();
    });
  }

  declineMeetingRequest(ms_id,edid){
    let args = {
      meeting_schedule_id : ms_id,
      action : 'decline',
      edid : edid
    }
    this.meetingSchedule.meetingRequestAction(args).subscribe( r => {
      let m = {
        type: 'danger',
        msg: r['message'],
      }
      if(r['error'] == 0){
        m.type = 'success';
        this.messageService.add(m);
      }else{
        this.messageService.add(m);
      }
      this.getMeetingRequest();
    });
  }

  approveMeetingRequest(ms_id,edid){
    let args = {
      meeting_schedule_id : ms_id,
      action : 'accept',
      edid : edid
    }
    this.meetingSchedule.meetingRequestAction(args).subscribe( r => {
      let m = {
        type: 'danger',
        msg: r['message'],
      }
      if(r['error'] == 0){
        m.type = 'success';
        this.messageService.add(m);
      }else{
        this.messageService.add(m);
      }
      this.getMeetingRequest();
    });
    
  }


  getNextData(toPush: boolean = false){
    this.meetingSchedule.getMeetingRequests(this.getParams()).subscribe(
      r => {
        this.current_page = r.data.current_page;
        this.setDatas(r, toPush);
      }
    );
  }

  setDatas(result, toPush){
    if(toPush){
      result.data.datas.forEach(d => {
        this.meetingRequest.data.datas.push(d);
      });
    }
  }

  getParams(){
    let  args = {
      'time_slot_id' : this.timeslot_id,
      'event_id' : this.event_id,
      'type' : this.type,
      'delegate' : this.delegate_id,
      'page' : this.meetingRequest.data.next_page,
    }
    return args;
  }

  onScroll() {
    if(this.meetingRequest.data.total_page > this.current_page){
      this.getNextData(true);
    }
  }

}
