import { Component, OnInit } from '@angular/core';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-sched-meeting',
  templateUrl: './sched-meeting.component.html',
  styleUrls: ['./sched-meeting.component.scss']
})
export class SchedMeetingComponent implements OnInit {

  timeslot: any  = {}
  onClose: any = '';
  delegates: any = [];
  data: any = {
    total_count: 0,
    total_page: 0,
    previous_page: null,
    current_page: 0,
    next_page: 1,
    pages: null,
    datas: []
  };
  searchKey: string = '';

  selectedDelegates: any = {};
  cansave: boolean = false
  disableScheduleMeetingButton = false;

  constructor(
    protected messageService: MessageService,
    private meetingSched: MeetingscheduleService
  ) { }

  ngOnInit() {
    this.getDelegates();
  }

  closeModal(){
    this.onClose({});
  }

  getDelegates(toPush: boolean = false){
    this.meetingSched.getAvailableDelegatePerTimeslot(this.getParams())
      .subscribe(result => {
        this.setDatas(result, toPush);
      });
  }

  setDatas(result, toPush){
    this.data = result.data;
    if(!toPush){
      this.delegates = this.data.datas;
    } else {
      this.data.datas.forEach(d => {
        this.delegates.push(d);
      });
    }
  }

  startSearch(){
    this.data.next_page = 1;
    this.getDelegates();
  }

  getParams(){
    let p = {
      id: this.timeslot.id,
      page: this.data.next_page,
      sector: this.searchKey
    }
    return p;
  }

  onScroll() {
    if(this.data.total_page > this.data.current_page){
      this.getDelegates(true);
    }
  }

  setSelected(delegate){
    if(typeof this.selectedDelegates['ed_' + delegate.edid] == 'undefined'){
      this.selectedDelegates['ed_' + delegate.edid] = delegate.edid;
    } else {
      delete this.selectedDelegates['ed_' + delegate.edid];
    }

    let sd = Object.keys(this.selectedDelegates);

    if(sd.length > 2){
      delete this.selectedDelegates[sd[0]];
    }
    if(sd.length >= 2) { 
      this.cansave = true; 
    } else {
      this.cansave = false; 
    }
  }

  setMeetingSchedule(){
    this.disableScheduleMeetingButton = true;
    let m = {
			type: 'danger',
			msg: 'Please select delegates.',
    }
    let sd = Object.keys(this.selectedDelegates);

    if(this.cansave){
      let data = {
        event_id: this.timeslot.event_id,
        time_slot_id: this.timeslot.id,
        delegate_1: this.selectedDelegates[sd[0]],
        delegate_2: this.selectedDelegates[sd[1]],
        setter: 'admin'
      }

      this.meetingSched.setMeetingSchedule(data)
        .subscribe(result => {
          if(result.error == 0){
            m.type = 'success';
          }
          m.msg = result.message
          this.messageService.add(m);
          this.timeslot.scheduled_meetings++;
          this.closeModal();
          this.disableScheduleMeetingButton = false;
        })
    } else {
      this.messageService.add(m);
    }
  }
}
