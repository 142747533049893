import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../lib/environment';
import { User } from '../lib/models/user';
import { Urls } from '../lib/urls';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private env: environment
  ) {}

  requireLogIn():any {
    //this.env.exchangeToken();
    let payload = this.env.isLogedIn();
    if(!payload){
      this.router.navigate(['admin/login']);
    }
  }

  requireNotLogIn():any {
    //this.env.exchangeToken();
    let payload = this.env.isLogedIn();
    if(payload){
      this.router.navigate(['/admin']);
    }
  }

  loginUser (user: User): Observable<any> {
    let httpOptions = this.env.getHttpOptions();

    let url = this.env.getUrl(Urls.admin_login);
    return this.http.post<any>(url, user, httpOptions);
  }

  forgotPassword(user): Observable<any>{
    let httpOptions = this.env.getHttpOptions();

    let url = this.env.getUrl(Urls.admin_forgotpass);
    return this.http.post<any>(url, user, httpOptions);
  }

  checkPassword (user): Observable<any> {
    let httpOptions = this.env.getHttpOptions();

    let url = this.env.getUrl(Urls.admin_checkpass);
    return this.http.post<any>(url, user, httpOptions);
  }

  /*loginUser(user: User): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const url = this.env.getUrl('/api/test/test');
    console.log(this.http);
    this.http.post(url, user, httpOptions)
      .pipe();
  }*/


  // getHeroes(): Observable<Hero[]> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Authorization': 'Bearer '+this.env.getToken(),
  //       'Devicetoken': 'asdasda',
  //       'Deviceid': 'did'
  //     })
  //   };
  //   // TODO: send the message _after_ fetching the heroes
  //   this.messageService.add('HeroService: fetched heroes');
  //   let url = this.env.getUrl(this.heroesUrl);
  //   return this.http.get<Hero[]>(url, httpOptions)
  //     .pipe(
  //       tap(_ => this.log('fetched heroes')),
  //       catchError(this.handleError<Hero[]>('getHeroes', []))
  //     );
  //   // return of(HEROES);
  // }
  
  // getHero(id: number): Observable<Hero> {
  //   // TODO: send the message _after_ fetching the hero
  //   // this.messageService.add(`HeroService: fetched hero id=${id}`);
  //   // return of(HEROES.find(hero => hero.id === id));
  //   const url = this.env.getUrl(this.heroesUrl+`?id=${id}`);
  //   return this.http.get<Hero>(url).pipe(
  //     tap(_ => this.log(`fetched hero id=${id}`)),
  //     catchError(this.handleError<Hero>(`getHero id=${id}`))
  //   );
  // }

  // /** PUT: update the hero on the server */
  // updateHero (hero: Hero): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //   };
  //   const url = this.env.getUrl(this.heroesUrl+`?post=1&asd`);
  //   return this.http.post<any>(`${url}`, hero, httpOptions).pipe(
  //     tap(_ => this.log(`updated hero id=${hero.id}`)),
  //     catchError(this.handleError<any>('updateHero'))
  //   );
  // }

  // /** Log a HeroService message with the MessageService */
  // private log(message: string) {
  //   this.messageService.add(`HeroService: ${message}`);
  // }

  // /**
  //  * Handle Http operation that failed.
  //  * Let the app continue.
  //  * @param operation - name of the operation that failed
  //  * @param result - optional value to return as the observable result
  //  */
  // private handleError<T> (operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
    
  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead
    
  //     // TODO: better job of transforming error for user consumption
  //     this.log(`${operation} failed: ${error.message}`);
    
  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }
}
