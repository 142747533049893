import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-button-card',
  templateUrl: './list-button-card.component.html',
  styleUrls: ['./list-button-card.component.scss']
})
export class ListButtonCardComponent implements OnInit {

  skeletons = [
    { id: '1'},
    { id: '2'},
    { id: '3'}
  ];   

  constructor() { }

  ngOnInit() {
  }

}
