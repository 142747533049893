import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Delegatemodel } from 'src/app/lib/models/delegate'
import { Router, ActivatedRoute } from '@angular/router';
import { DelegateService } from 'src/app/services/users/delegate.service'
import { MessageService } from 'src/app/services/defaults/message.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { EventService } from 'src/app/services/event/event.service';
import { UserService } from 'src/app/services/user.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormAddEditComponent } from 'src/app/modules/delegate/form/form-add-edit/form-add-edit.component';

import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-delegate-virtual',
  templateUrl: './edit-delegate-virtual.component.html',
  styleUrls: ['./edit-delegate-virtual.component.scss']
})
export class EditDelegateVirtualComponent extends FormAddEditComponent implements OnInit {

  @Input() del: Delegatemodel;
  @Input() pageStep: number;
  public event_id= 0;
  public delegate_id= 0;
  public currentStep= 1;
  public delegateProfile: any;

  constructor(
    protected router: Router,
    protected _route: ActivatedRoute,
    protected delegate: DelegateService,
    protected messageService: MessageService,
    protected formBuilder: FormBuilder,
    protected companyService: CompanyService,
    protected eventService: EventService,
    private _user: UserService,
    private titleService: Title,
    protected _location: Location,
  ) {
    super(delegate, messageService, formBuilder, companyService, eventService, _location);
  }

  ngOnInit() {
    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
      this.event_id = + url_param.get('event_id');
      this.delegate_id = + url_param.get('delegate_id');
    });

    let facebook_pattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/;
    let twitter_pattern = /http(s)?:\/\/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/;
    let linkedln_pattern = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/;
    let emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.delegateForm = this.formBuilder.group({
        delegateEmail: ['', [Validators.required, Validators.pattern(emailRe)]],
        delegateCompany: ['', [Validators.required, Validators.min(1)]],
        delegateCompanyId: ['', [Validators.required, Validators.min(1)]],
        delegateFullname: ['', [Validators.required]],
        delegateJobTitle: ['', [Validators.required]],
        delegateAddress: [(this.del && this.del.address) ? this.del.address : '', [Validators.required]],
        facebook: ['', [Validators.pattern(facebook_pattern)]],
        twitter: ['', [Validators.pattern(twitter_pattern)]],
        linkedin: ['', [Validators.pattern(linkedln_pattern)]],
        skype: ['', [Validators.required]],
			  zoom: ['', [Validators.required]],
    }, { });

    this.setDelegateProfile();
  }

  setDelegateProfile(){

    let thisParam = {
      'delegate' : this.delegate_id
    }

    if(this.delegate_id != 0){
      this.delegate.getDelegate(thisParam).subscribe(
        delegates => {
          if(delegates['error'] == 0){
            this.delegateProfile = delegates['data'];
            this.titleService.setTitle("Edit " + this.delegateProfile.fullname);
            this.setDelegateModel(delegates['data']);
          }
        }
      );
    }
  }

  saveChanges(pageStep=1){
    this.currentStep = pageStep;
  }

}
