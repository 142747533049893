import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regular-table-listing',
  templateUrl: './regular-table-listing.component.html',
  styleUrls: ['./regular-table-listing.component.scss']
})
export class RegularTableListingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  protected createRange(number){
    var items: number[] = [];
    for(var i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }

}
