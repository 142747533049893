import { Component, OnInit, ViewChild } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';
import { MessageService } from 'src/app/services/defaults/message.service';
import { EventPrivacyFormComponent } from './../event-forms/event-privacy/event-privacy.component'
import { PrefService } from '../../../services/preferences/pref.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {

  

  eventForm: FormGroup;
  eventFormData: Event;
  private childPrivacy : EventPrivacyFormComponent
  
  public event_id: number = 0;
  public isEditing: boolean = false;
  public submitted: boolean = false;
  public urlPattern = "^(https?|chrome):\/\/[^\s$.?#].[^\s]*$";
  public croppedImageEventBanner = "";
  public croppedImageEventIcon = "";
  public croppedImageEventLetterhead = "";
  public croppedImageEventFloor = "";
  public isEventLetterheadImageLoaded = false;
  public isEventLetterheadImageCropped = false;
  public isEventBannerImageLoaded = false;
  public isEventBannerImageCropped = false;
  public isEventFloorImageLoaded = false;
  public isEventFloorImageCropped = false;
  public isEventIconImageLoaded = false;
  public isEventIconImageCropped = false;
  public dataIsLoaded = false;
  
  public selectedNetworkIds: any = "";
  /* public selectedNetworks: any; */

  constructor(
    private eventService: EventService,
    private _route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private prefService : PrefService,
    private _user: UserService,
    private titleService: Title
  ) {
    this._user.requireLogIn();
   }

  ngOnInit() {
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
    
    if(this.event_id != 0){
      this.eventForm = this.formBuilder.group({
        privacy: [''],
        registrationLink:['', Validators.pattern(this.urlPattern)],
        networkList: new FormArray([])
      }, { });

      this.eventService.getEvent(this.event_id).subscribe(
        eventData => {
          if(eventData.error == 0) {
            this.eventFormData = eventData.data;
            this.titleService.setTitle("Edit " + eventData.data.name);
            this.eventFormData.start_date = new Date(this.eventFormData.start_date);
            this.eventFormData.end_date = new Date(this.eventFormData.end_date);
            this.eventFormData.request_start_date = new Date(this.eventFormData.request_start_date);
            this.eventFormData.request_end_date = new Date(this.eventFormData.request_end_date);

            /*  
            * break the pemmission option 
            * this include registration link and selected networks 
            */
            let permission_option = JSON.parse(this.eventFormData.permission_options);
            this.selectedNetworkIds = permission_option.selected_networkIds;
            this.eventFormData.registration_link = permission_option.registration_link;

            this.croppedImageEventBanner = this.eventFormData.banner_image_src;
            this.croppedImageEventIcon = this.eventFormData.icon_image_src;
            this.croppedImageEventLetterhead = this.eventFormData.letterhead_image_src;
            this.croppedImageEventFloor = this.eventFormData.floorplan_image_src;

            if(this.eventFormData.image_letterhead !== ""){
              this.isEventLetterheadImageLoaded = true;
              this.isEventLetterheadImageCropped = true;
            }

            if(this.eventFormData.image_banner !== ""){
              this.isEventBannerImageLoaded = true;
              this.isEventBannerImageCropped = true;
            }

            if(this.eventFormData.image_floor_plan !== ""){
              this.isEventFloorImageLoaded = true;
              this.isEventFloorImageCropped = true;
            }

            if(this.eventFormData.image_icon !== ""){
              this.isEventIconImageLoaded = true;
              this.isEventIconImageCropped = true;
            }

            this.dataIsLoaded = true;

            if(this.eventFormData.id > 0){
              this.isEditing = true;
            }
            
            this.childPrivacy = new EventPrivacyFormComponent(this.formBuilder, this.router, this.eventService, this._route, this.prefService );
            this.childPrivacy.selectedNetworks = this.selectedNetworkIds;
          } else {
            let m = {
              type: 'danger',
              msg: eventData.message,
            }
            this.messageService.add(m);
            this.router.navigate(['/admin']);
          }
        }
      );
    }
    

  }


  public saveChanges(){
    /* console.log(this.eventService.eventFullDetails); */
    
    this.eventService.saveEventDetails().subscribe(
      response => {
        let api_response = response
        let redir_url = '/admin/dashboard/'+parseInt(response.event_id);
        let m = {
          type: 'danger',
          msg: '',
        }
        if(api_response.error == 0){
          m.type = 'success';
          /* this.router.navigate([redir_url]); */
        } else {
          m.type = 'danger';
        }
        
        m.msg = api_response.message;
        this.messageService.add(m);
      }
    );
  }

}
