import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import * as moment from 'moment'

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';


@Component({
  selector: 'app-event-scheduler-form-virtual',
  templateUrl: './event-scheduler-form-virtual.component.html',
  styleUrls: ['./event-scheduler-form-virtual.component.scss']
})
export class EventSchedulerFormVirtualComponent implements OnInit {

  @Input() defaultTime: string = '12:00 AM';
  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Output() updateEventItem : EventEmitter<any> = new EventEmitter();

  eventForm: FormGroup;
  isEnableSetMeeting = true;

  public submitted: boolean = false;
  public event_id: number = 0;
  public today = new Date();
  public isDisabledDates = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit() {

      this.eventForm = this.formBuilder.group({
          meetingRequest: [''],
          cancellationRequest: [''],
          setMeeting:[''],
          scheduleStartDate: ['', Validators.required],
          scheduleEndDate: ['', Validators.required],
          scheduleStartTime: ['', Validators.required],
          scheduleEndTime: ['', Validators.required]
      }, { });

      this._route.paramMap.subscribe( url_param =>{
          this.event_id = + url_param.get('event_id');
        }
      );

      if(!this.event_id  && this.eventService.eventFullDetails.name === ''){
        this.router.navigate(['/admin/event/add/event-details-virtual']);
      }

      this.eventFormData = this.eventService.eventFullDetails;
      
      if(this.eventFormData.set_meeting){
        this.isEnableSetMeeting = true;
        this.isDisabledDates = false;
      }
  }

  get f() { 
      return this.eventForm.controls; 
  }

  saveEventDetails(){
    this.submitted = true;
    /* stop here if form is invalid */
    if (this.eventForm.invalid && this.eventForm.value.setMeeting) {
        return;
    } 
    this.eventService.setEventSchedulerDetails(this.eventForm.value);
   
    if(this.eventFormData.id <= 0){
      if(this.eventService.isEditingContent){
        this.eventService.isEditingContent = false;
        this.router.navigate(['/admin/event/add/event-review-virtual']);
      }else{
        this.router.navigate(['/admin/event/add/event-privacy-virtual']);
      }
    }else{
      this.updateEventItem.emit();
      this.router.navigate(['/admin/dashboard/', this.eventFormData.id]);
    }
  }

  disableDates(e){
    this.isDisabledDates = false;
    this.eventFormData.request_end_time = '';
    this.eventFormData.request_start_time = '';
    this.eventFormData.request_start_date = '';
    this.eventFormData.request_end_date = '';
    if(this.eventForm.value.setMeeting){
      this.isDisabledDates = true;
    }
  }

  public setMinDate(date){
    const start_date = new Date(date);
    this.eventFormData.request_start_date = moment(start_date).format('MM/DD/YYYY');
  }

  public setMaxDate(date){
    const end_date = new Date(date);
    this.eventFormData.request_end_date = moment(end_date).format('MM/DD/YYYY');
  }

}
