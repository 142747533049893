import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 
import * as moment from 'moment'

import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';

//set http header
const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class FeedbacksService {

  constructor(
    protected http:HttpClient,
    protected env: environment,
  ) { }


  getFeedbacklist(param){
    /* this.tab_type = $type;
    this.search_key = $search_key; */
    let url = this.env.getUrl(Urls.api_feedbacks_get);
    url += this.env.createUrlParam(param);
    return this.http.get<any>(url, this.env.getHttpOptions());
  }

}
