import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-privacy',
  templateUrl: './event-privacy.component.html',
  styleUrls: ['./event-privacy.component.scss']
})
export class EventPrivacyComponent implements OnInit {

  constructor(
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Add Event Privacy");
  }

}
