import { AgendaService } from 'src/app/services/event/agenda.service';
import { EventService } from 'src/app/services/event/event.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';

export class TimeScheduler {

    /* 
    *   holds the event id
    */
    event_id: number = 0;

    /* 
    *   holds the data on the event
    */
    event: any = null;

    /* 
    *   holds all agenda and event data
    */
    schedules: any = {};

    /* 
    *   show the delete time slots button
    */
    isTimeSlotButtonShow = false;

    status = '';

    constructor(
        protected eventService: EventService,
		protected meetingSchedule: MeetingscheduleService
    ) {

    }

    run(){
        this._checkLoadedEvent();
    }

    /*
	*	check if the event was already loaded
	*/
	_checkLoadedEvent() {
		let checker = setInterval(f => {
			this.event = this.eventService.eventFormData
			if(this.event){
				if(this.event.days.length){
					this._onSelect(this.event.days[0].milisec);
        		}
				clearInterval(checker);
			}
		}, 100)
    }
    
    _onSelect(dayMilisec){
        let params = {
            event_id: this.event_id,
            date: dayMilisec
        }
        this.meetingSchedule.getAgendaAndTimeSlot(params)
			.subscribe(result => {
                let results = result.data;
                results.forEach(val => {
                    if(val.type == 'timeslot'){
                        if(val.data.status == 'yes'){
                            val.data.status = true;
                        } else {
                            val.data.status = false;
                        }
                    }
                });
                this.schedules['milisec_'+dayMilisec] = results;
                this._showButton(dayMilisec);
                 console.log(this.schedules['milisec_'+dayMilisec] ); 
			});
    }

    _showButton(dayMilisec){
        let sched = this.schedules['milisec_'+dayMilisec];
        sched.forEach(val => {
            if(val.type == 'timeslot'){
                this.isTimeSlotButtonShow = true;
            }
        });
	}

    /* 
    *   this will fetch data from the server
    */
    // getAgendaAndTimeSlot(){
    //     console.log(this.event_id);
    // }
}