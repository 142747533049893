import { Injectable } from '@angular/core';
import { Router } from '@angular/router'

import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';
import { MessageService } from './../../services/defaults/message.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  httpOptions = this.env.getHttpOptions();

  public limit: number = 10;
  public prefs = {
    networks: [],
    services: [],
  }
  private prefLink = {};
  public pagination = [];

  public companies: any;
  public companiesDataLoading = true;

  constructor(
  	private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private env: environment
  ) { }

  save(data: any){
  	let httpOptions = this.env.getHttpOptions();
    let url = this.env.getUrl(Urls.api_company_save);
    return this.http.post<any>(url, data, httpOptions);
  }

  getAll(data: any){
    let url = this.env.getUrl(Urls.api_company_get);
    this.companiesDataLoading = true;
    url += this.env.createUrlParam(data);
    return this.http.get<any>(url, this.httpOptions)
      .subscribe(data => {
        this.companiesDataLoading = false;
        this.companies = data;
      });
  }

	getCompany(data: any){
    let url = this.env.getUrl(Urls.api_company_get);
    url += this.env.createUrlParam(data);
    return this.http.get<any>(url, this.httpOptions);
  }
  
	/* ----------------------------------
	  Delete company
	  ----------------------------------*/
  removeCompany(id: any){
		let formData = new FormData();
		formData.append('id', id);

		let url = this.env.getUrl(Urls.api_company_delete);
		return this.http.post<any>(url, formData, this.httpOptions);
	}
	/* ----------------------------------
	  End of Delete company
	  ----------------------------------*/
}
