import { Component, OnInit, ViewChild, Input  } from '@angular/core';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Input() croppedImageEventIcon: any = '';
  @Input() isEventIconImageLoaded: any = false;
  @Input() isEventIconImageCropped: any = false;
  /* ======= event icon =========== */
  imageChangedEventIcon: any = '';
  

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventFormData = this.eventService.eventFullDetails;
    this.croppedImageEventIcon = this.eventFormData.icon_image_src;
    console.log(this.eventFormData.image_icon);
    if(this.eventFormData.image_icon !== ""  && this.eventFormData.image_icon !== "isRemoved"){
      this.isEventIconImageLoaded = true;
      this.isEventIconImageCropped = true;
    }
  }

  /* ======= event icon =========== */
  fileChangeEventIcon(event: any): void {
    this.isEventIconImageLoaded = false;
    this.isEventIconImageCropped = false;
    this.imageChangedEventIcon = event;
  }
  imageEventIconCropped(event: ImageCroppedEvent) {
      this.croppedImageEventIcon = event.base64;
      this.isEventIconImageCropped =  true;

      this.eventService.setEventIconImage(event);
  }
  imageEventIconLoaded() {
    this.isEventIconImageLoaded = true;
      // show cropper
  }
  cropEventIcon() {
    this.imageCropper.crop();
  }
  cropperEventIconReady() {
      // cropper ready
  }
  loadEventIconImageFailed() {
      // show message
  }

  removeImage(){
    this.imageChangedEventIcon = '';
    this.croppedImageEventIcon= '';
    this.isEventIconImageLoaded = false;
    this.isEventIconImageCropped = false;

    this.eventService.setEventIconImage('isRemoved');
  }

}
