import { Component, OnInit } from '@angular/core';


import { MessageService } from '../../../services/defaults/message.service';
import { PrefService } from '../../../services/preferences/pref.service';
import { Preferences } from './../../../modules/preferences/preferences';

import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-edit-preferences',
  templateUrl: './edit-preferences.component.html',
  styleUrls: ['./edit-preferences.component.scss']
})
export class EditPreferencesComponent implements OnInit {

  onClose: any;
  onSaveChanges: any;
  constructor(
    /* protected messageService: MessageService,
  	protected prefService: PrefService,
    protected modalService: BsModalService, */
  ) {
    /* super(messageService, prefService, modalService); */
  }

  ngOnInit() {
  }

  closeModal(){
		this.onClose({});
  }

  saveChanges(e){
    this.onSaveChanges(e);
  }

}
