import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { DelegateService } from 'src/app/services/users/delegate.service';
import { UserService } from 'src/app/services/user.service';
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoveModalComponent } from 'src/app/defaults/modals/remove-modal/remove-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service';
import { TableService } from 'src/app/services/tables/table.service';
import { TimeslotsService } from 'src/app/services/timeslot/timeslots.service';

/* Other Component */
import { MeetingDetailsModalComponent } from 'src/app/modules/dashboard/tables/meeting-details-modal/meeting-details-modal.component';
import { NoshowService } from 'src/app/services/noshow/noshow.service';
import { MeetingRequestsListModalComponent } from 'src/app/modules/dashboard/schedule-meetings/meeting-requests-list-modal/meeting-requests-list-modal.component';

/* socket config */
import { environment } from 'src/app/lib/environment';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';

import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-delegate-profile-card',
  templateUrl: './delegate-profile-card.component.html',
  styleUrls: ['./delegate-profile-card.component.scss']
})
export class DelegateProfileCardComponent implements OnInit {

  modalRef: BsModalRef;

  public event_id = 0;
  public delegate_id = 0;
  public delegatesProfile : any;
  public prefData : any;
  public total_prefData = 0;
  public scheduleIsLoaded = false;
  public noshowreportbydelegate : any ;

  public events : any;
  public total_events : 0;
  public total_event_count = 0;
  public total_coming_count = 0;
  public total_past_count = 0;
  public timeslot_schedules: any;

  public prefname : string = '';
  protected date: any;

  protected meetingRequest: any;
  protected page = 1;
  protected limit = 10;

  protected autoTable_dim = 0;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    public delegateService: DelegateService,
    private _user: UserService,
    protected env : environment,
    protected eventService: EventService,
    protected messageService: MessageService,
    protected modalService: BsModalService,
    protected meetingSchedule : MeetingscheduleService,
    protected tableService : TableService,
    private titleService: Title,
    protected noShowService : NoshowService,
    private timeslotsService : TimeslotsService,
  ) {
    this._user.requireLogIn();
  }

  ngOnInit() {
    
    this._route.paramMap.subscribe( url_param =>{
        this.delegate_id = + url_param.get('delegate_id');
        this.event_id = + url_param.get('event_id');

        let thisParam = {
          'delegate' : this.delegate_id,
          'event_id' : this.event_id
        }
        if(this.delegate_id != 0){
          
          this.delegateService.getDelegate(thisParam).subscribe(
            delegates => {
              if(delegates['error'] == 0){
                console.log('delegate data',delegates['data']);
                this.delegatesProfile = delegates['data'];
                this.titleService.setTitle("Delegate " + this.delegatesProfile.fullname);
                this.selectPrefTab('services');
              }
            }
          );
          this.getEventList(this.delegate_id, 'ongoing');
          this.getDataOnFirstTab();
        }
        if(this.event_id){
          this.getNoShowReportByDelegate();  
        }
      }
    );
  }

  getNoShowReportByDelegate(){
    let param = {
      'delegate' : this.delegate_id,
      'event_id': this.event_id,
      'limit' : this.limit,
      'page' : this.page,
    }
    
    this.noShowService.getNoShowReportByDelegates(param).subscribe(
      response => {
        this.noshowreportbydelegate = response;
        console.log(this.noshowreportbydelegate);
      }
    );
}

  public getDataOnFirstTab() {
		let checker = setInterval(f => {
			let event = this.eventService.eventFormData
			if(event){
				if(event.days.length){
					this.getDelegatesSchedMeetings(event.days[0].formatted);
				}
				clearInterval(checker);
			}
		}, 100)
	}

  public getDelegatesSchedMeetings(date: any = ''){
    this.scheduleIsLoaded = false;
    if(date){
      this.date = date;
    }
    let param = {
      'delegate_id' : this.delegate_id,
      'event_id' : this.event_id,
      'date' : this.date,
    }
    /* request for the meeting schedule list base on selected table */
    this.meetingSchedule.getMeetingSchedule(param).subscribe(
      schedules => {
        this.timeslot_schedules = schedules;
        this.scheduleIsLoaded = true;
      }
    );
  }

  resendCredential(delegate_id){
    this.delegateService.sendCrentials(delegate_id);
  }

  selectPrefTab(prefname){
    this.prefData = [];
    this.prefname = prefname;
    this.total_prefData = 0;
    if(prefname == 'services'){
      if(this.delegatesProfile.pref_services_ids){
        this.prefData = this.delegatesProfile.pref_services_ids;
        this.total_prefData = this.delegatesProfile.pref_services_ids.length;
      }
    }else if(prefname == 'specializations'){
      if(this.delegatesProfile.pref_specialization_ids){
        this.prefData = this.delegatesProfile.pref_specialization_ids;
        this.total_prefData = this.delegatesProfile.pref_specialization_ids.length;
      }
    }else if(prefname == 'countries'){
      if(this.delegatesProfile.pref_countries_ids){
        this.prefData = this.delegatesProfile.pref_countries_ids;
        this.total_prefData = this.delegatesProfile.pref_countries_ids.length;
      }
    }
  }

  gotoExternalLink(url){
    window.open(url, "_blank");
  }

  selectTab($type = ""){
    this.events = null;
    this.getEventList(this.delegate_id, $type);
  }

  getEventList($delegate_id, $type = ''){
    this.eventService.getEventDelegate($delegate_id, $type).subscribe(
      events => {
        this.events = [];
        if(events){
          /* console.log('this.events',events); */
          this.total_events = events.data.total_count;
          this.total_coming_count = events.data.event_count['coming_event_count'];
          this.total_past_count = events.data.event_count['past_event_count'];
        }

        if(events.error == 0){
          this.events = events.data.datas;
        }
      }
    );
  }

  removeDelegate(){
    if(this.delegate_id){

      let initialState = {
        img_src: 'delete-popup.png',
        title: 'Remove Delegate?',
        msg: 'This will remove all the details related to this delegate.',
        btn_save: 'Delete',
        btn_close: 'Close',
        url: '/admin/delegates'
      };

      // Show Popup Modal
      this.modalRef = this.modalService.show(RemoveModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal_sm modal-dialog-centered');

      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.modalRef.hide();
        if(pass){
          let param = {
            id : this.delegate_id,
            event_id : this.event_id,
          }
          this.delegateService.removeDelegate(param).subscribe(
            result => {
              let m = {
                type: 'danger',
                msg: result.message,
              }

              if(result.error == 0){
    
                m.type = 'success';
                this.messageService.add(m);
                if(this.event_id){
                  this.router.navigate(['admin/event/delegates/'+this.event_id]);
                }else{
                  this.router.navigate(['admin/delegates']);
                }
                
              }else{
                this.messageService.add(m);
              }
            }
          );
        }
      };
    }
  }

  /* show the meeting schedule details in popup */
  protected viewMeetingDetails(meetingSchedData){
    let initialState = {
      meetingSchedData: meetingSchedData,
      title: 'Meeting Details'
    };

    // Show Popup Modal
    this.modalRef = this.modalService.show(MeetingDetailsModalComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal_md modal-dialog-centered');

    this.modalRef.content.onClose = () => {
      // Do something with myData and then hide
      this.modalRef.hide();
    }

    /* after submitting cancellation api request update the list */
    this.modalRef.content.onSubmitCancellation = () => {
      this.getDelegatesSchedMeetings();

      /* emit to refresh the count of pending in list */
      let data = {
        meeting_sched_id : 0,
        event_id : this.event_id, 
      }
      this.env.webSocket.emit('cancellation request', data);
    }

    this.modalRef.content.onCancelRescheduling = () =>{
      this.viewMeetingDetails(meetingSchedData);
    }

    this.modalRef.content.cancelDeletion = () => {
      this.viewMeetingDetails(meetingSchedData);
    }
  }

  protected blockTimeslot(timeslot_id){
    let args = {
      'timeslot_id' : timeslot_id,
      'delegate_id' : this.delegate_id,
      'event_id' : this.event_id,
      'by_admin' : 1,
    }
    this.timeslotsService.blockTimeSlot(args).subscribe( r => {
      this.getDelegatesSchedMeetings();
    });
  }

  protected unblockTimeslot(timeslot_id){
    let args = {
      'timeslot_id' : timeslot_id,
      'delegate_id' : this.delegate_id,
      'event_id' : this.event_id
    }
    this.timeslotsService.unblockTimeSlot(args).subscribe( r => {
      this.getDelegatesSchedMeetings();
    });
  }

  public showMeetingRequests(timeslot_id){

    let  args = {
      'time_slot_id' : timeslot_id,
      'event_id' : this.event_id,
      'type' : 'received',
      'delegate' : this.delegate_id,
    }

    this.meetingSchedule.getMeetingRequests(args).subscribe( r => {
      this.meetingRequest = r;

      let initialState = {
        timeslot_id : timeslot_id,
        event_id : this.event_id,
        meetingRequest : this.meetingRequest,
        delegate_id : this.delegate_id,
      };
  
      this.modalRef = this.modalService.show(MeetingRequestsListModalComponent, {initialState});
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.setClass('modal-dialog-centered');
  
      this.modalRef.content.onClose = (pass = null) => {
        // Do something with myData and then hide
        this.getDelegatesSchedMeetings();
        this.modalRef.hide();
      }
    });   
  }

  protected printSchedules(delegate_name){
    
      let data = this.timeslot_schedules.data.datas;
      let doc = new jsPDF();

      let file_name = this.eventService.eventFullDetails.name + " : "+ delegate_name +"'s meeting schedules for "+ this.date;

      let columns = [
        {title: "Meeting Time",dataKey: "formatted_time"},
        {title: "Table No.",dataKey: "table"},
        {title: "Delegate Name",dataKey: "delegate"},
        {title: "Company",dataKey: "delegate_company"}
      ];
  
      let rows = data;
      let a = this;
      let letter_head = this.eventService.eventFullDetails.letterhead_image_src;

      this.toDataURL(letter_head, function(dataUrl){
        let headerTitle = file_name;
        file_name = file_name.toLowerCase();
        file_name = file_name.split(' ').join('_');  

        doc.setTextColor(40);
        doc.setFontStyle('normal');
        doc.setFontSize(13);

        let headerY = 20;
        if(a.eventService.eventFullDetails.image_letterhead !== ''){
          doc.addImage(dataUrl, 'PNG', 15, 15, 0  , 30);
          headerY = 53;
        }
        doc.text(headerTitle, 15, headerY, {maxWidth: 180});
        let headerTitle_dim = doc.getTextDimensions(headerTitle);
        let splitTitle = doc.splitTextToSize(headerTitle,180,{fontSize:13});
        let headerTitle_height = (headerTitle_dim.h * splitTitle.length) + 2;  

        doc.setFontSize(10);
        doc.text(a.eventService.eventFullDetails.event_address, 15, headerTitle_height + headerY, {maxWidth: 180});
        
        let eventLocTitle_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_address);
        let spliteventLocTitle = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
        let eventLocTitle_height = (eventLocTitle_dim.h * spliteventLocTitle.length) + 1;  
        doc.text(a.eventService.eventFullDetails.event_date, 15, headerTitle_height + headerY + eventLocTitle_height, {maxWidth: 180});
        
        let eventDate_dim = doc.getTextDimensions(a.eventService.eventFullDetails.event_date);
        let spliteventDate = doc.splitTextToSize(a.eventService.eventFullDetails.event_address,180,{fontSize:10});
        a.autoTable_dim = (eventDate_dim.h * spliteventDate.length) + headerTitle_height + headerY + eventLocTitle_height  + 1; 

        doc.autoTable(columns, rows, {margin: {top: a.autoTable_dim}});
        doc.save(file_name+'.pdf');
      });
  }
  public toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  actionItiniraryReport(type){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        event_delegate_id: this.delegatesProfile.ed_id,
        delegate_name: this.delegatesProfile.fullname,
        delegate_email: this.delegatesProfile.email,
        delegate_id: this.delegate_id,
        type: type
      }
    }

    //console.log('action itinerary query params', navigationExtras);

    this.router.navigate(['/admin/event/delegate-itinerary-report/'+this.event_id], navigationExtras);
  }
}
