import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-add-company-dashboard-virtual',
  templateUrl: './add-company-dashboard-virtual.component.html',
  styleUrls: ['./add-company-dashboard-virtual.component.scss']
})
export class AddCompanyDashboardVirtualComponent implements OnInit {

  event_id = 0;
  company_id = 0;
  nextUrl: any;
  
  constructor(
    private _user: UserService,
		private router: Router,
    private _route: ActivatedRoute,
    protected _location: Location,
    private titleService: Title
  ) {
    this._user.requireLogIn();
  }

  ngOnInit() {
		this._route.paramMap.subscribe( url_param =>{
			this.event_id = + url_param.get('event_id');
      this.company_id = + url_param.get('company_id');
    });
    
    if(this.company_id){
      this.titleService.setTitle("Edit Company");
      this.nextUrl = "/admin/dashboard/company/"+this.event_id+"/profile/"+this.company_id;
    }else{
      this.titleService.setTitle("Add Company");
      this.nextUrl = "/admin/dashboard/company/"+this.event_id;
    }
  }

  backClicked() {
    this._location.back();
  }

}
