import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 
import { environment } from 'src/app/lib/environment';
import { Urls } from 'src/app/lib/urls';

@Injectable({
  providedIn: 'root'
})
export class SpeakersService {

	public httpOptions = this.env.getHttpOptions();

	constructor(
		protected http:HttpClient,
    	protected env: environment,
	) { }


	/* --------------------------------
		Save new speaker
		If have "id" Update speaker
	 ----------------------------------*/
	save(data: any){

		const formData = new FormData();
		for (let propt in data) {
			formData.append(propt,data[propt]);
		}

	  	let httpOptions = this.env.getHttpOptions();
	  	let url = this.env.getUrl(Urls.api_speaker_save);
	  	return this.http.post<any>(url, formData, httpOptions);
	}

	/*
	*	api call @event_id is the event ID
	*	@id is the id of the speaker
	*/
	getSpeaker(event_id, id: number = 0, search = ''){
		let param = {
			event_id: event_id,
			search: search
		};

		if(id > 0){
			param['id'] = id;
		}

		let url = this.env.getUrl(Urls.api_speaker_get);

		url += this.env.createUrlParam(param);
    	return this.http.get<any>(url, this.httpOptions);
	}

	/* ----------------------------------
	  Delete speaker
	  ----------------------------------*/
	removeSpeaker(speaker_id: any){
		let formData = new FormData();
		formData.append('id', speaker_id);

		let url = this.env.getUrl(Urls.api_speaker_delete);
		return this.http.post<any>(url, formData, this.httpOptions);
	}
	/* ----------------------------------
	  End of Delete speaker
	  ----------------------------------*/
}
