import { Component, OnInit } from '@angular/core';

import { AgendaService } from 'src/app/services/event/agenda.service';
import { strrandom } from 'src/app/lib/strrandom';
import { MessageService } from 'src/app/services/defaults/message.service';
import { MeetingscheduleService } from 'src/app/services/meetingschedule/meetingschedule.service'

@Component({
  selector: 'app-add-timeslot-virtual',
  templateUrl: './add-timeslot-virtual.component.html',
  styleUrls: ['./add-timeslot-virtual.component.scss']
})
export class AddTimeslotVirtualComponent implements OnInit {

  event_id: number = 0;
  onClose: any;
  toGenerate: any = {}

  hours : any = [1,2,3,4,5,6,7,8,9,10,11,12];
  mins : any = ['00','05',10,15,20,25,30,35,40,45,50,55];

  /* this holds the value of available time starts */
  timeStart: any = [];

  /* this holds the value of available time end */
  end_time: any = [];

  /* this holds the value selected day */
  day: any;

  /* this will be the text for the regerated time slots */
  generatedTS = 'timeslots will be generated';

  estimatedTimeslots: number = 0;

  constructor(
    protected agendaService: AgendaService,
    protected std: strrandom,
    protected messageService: MessageService,
    protected meetingSchedule: MeetingscheduleService
  ) { }

  ngOnInit() {
    this.toGenerate = {
      duration: 15,
      interval: 5,
      start_time: '',
      end_time: '',
      start_hrs: '',
      start_mins: '',
      start_units: '',
      end_hrs: '',
      end_mins: '',
      end_units: '',
    }
    this.setSartTime();
  }

  closeModal(){
    this.onClose({});
  }

  /*
	*	set available time, get it from the API
  *	and set it as a variable
  * since it was same with agenda start time
  * we will just use it
	*/
	setSartTime(){
    let l = this.day.time.length
    this.day.time.forEach((val, key) => {
      if(key < (l - 1)){
        this.timeStart.push(val.time);
      }
    });
  }

  concateStartTime(e){
    if(this.toGenerate.start_hrs != '' && this.toGenerate.start_mins != '' && this.toGenerate.start_units != ''){
      console.log(this.toGenerate.start_hrs+':'+this.toGenerate.start_mins+' '+this.toGenerate.start_units);
      this.toGenerate.start_time = this.toGenerate.start_hrs+':'+this.toGenerate.start_mins+' '+this.toGenerate.start_units;
    }
  }

  concateEndTime(e){
    if(this.toGenerate.end_hrs != '' && this.toGenerate.end_mins != '' && this.toGenerate.end_units != ''){
      console.log(this.toGenerate.end_hrs+':'+this.toGenerate.end_mins+' '+this.toGenerate.end_units);
      this.toGenerate.end_time = this.toGenerate.end_hrs+':'+this.toGenerate.end_mins+' '+this.toGenerate.end_units;
      this.estimate();
    }


  }

  /*
  * update the end time
  */
  updateEndTime(e){
		this.end_time = [];
		let startTimeVal = e;
    startTimeVal = e.target.value;

    this.day.time.forEach((val, key) => {
      let st = this.std.converToMin(startTimeVal);
      let vt = this.std.converToMin(val.time);
      if(st < vt){
        this.end_time.push(val.time);
      }
    });
    this.toGenerate.end_time = this.end_time[0];
    this.estimate();
  }

  // get estimatedCount(){ return 1; }

  /*
  * estimate how many timeslot will be gerated
  */
  estimate(){
    let tg = this.toGenerate;
    let totalDuration = parseInt(tg.duration) + parseInt(tg.interval);

    let estimated = 0;
    if(totalDuration && tg.start_time && tg.end_time){
      let formData = this.getFormData();
      formData.append('estimate', 'yes');

      this.meetingSchedule.generateTimeSlot(formData)
      .subscribe(r => {
        this.estimatedTimeslots = r.estimate;
        if(this.estimatedTimeslots > 1){
          this.generatedTS = 'timeslots will be generated';
        } else {
          this.generatedTS = 'timeslot will be generated';
        }
      });
    }



    // return estimated;
  }

  /*
  * validate the min must only 60 and not greater
  */
  validateMin(e, min){
    let v = parseInt(e.target.value);
    if(v > 120){
      this.toGenerate[min] = 120;
    }
    this.estimate();
  }

  /*
  * validate if the typed is a number or not
  * decimal is accepted
  */
  validateNumber(event){
    return this.std.validateNumber(event);
  }

  /*
  * start generation of time slot this will
  * POST an API request to to server
  */
  generateTimeSlot(){
    let m = {
			type: 'danger',
			msg: '',
		}

    if(this.estimatedTimeslots > 0){
      let formData = this.getFormData();
      formData.append('estimate', 'no');

      this.meetingSchedule.generateTimeSlotVirtual(formData)
        .subscribe(r => {
          if(r.error == 0){
            m.type = 'success';
          }
          m.msg = r.message;
          this.messageService.add(m);
          this.onClose({});
        });
    } else {
      m.msg = 'Please input a valid time.';
      this.messageService.add(m);
    }
  }

  /*
  * prepare the form data for new post request
  * this form data is for generate timeslot
  */
  getFormData(){
    let formData = new FormData();
    formData.append('date',this.day.milisec);
    formData.append('a_date',this.day.a_date);
    formData.append('duration',this.toGenerate.duration);
    formData.append('interval',this.toGenerate.interval);
    formData.append('start_time',this.std.convertTo24Hrs(this.toGenerate.start_time));
    formData.append('end_time',this.std.convertTo24Hrs(this.toGenerate.end_time));
    formData.append('event_id', ''+this.event_id);
    return formData;
  }

}
