import { Component, OnInit, Input } from '@angular/core';

import { MessageService } from '../../../services/defaults/message.service';
import { PrefService } from '../../../services/preferences/pref.service';

import { Preferences } from '../preferences';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pref-state',
  templateUrl: './pref-state.component.html',
  styleUrls: ['./pref-state.component.scss']
})
export class PrefStateComponent extends Preferences implements OnInit {

  constructor(
  	protected messageService: MessageService,
  	protected prefService: PrefService,
    protected modalService: BsModalService
  ) { 
    super(messageService, prefService, modalService);
  }

  ngOnInit() {
  	this.pref = {
  		id: 0,
      name: '',
  		preftype: 'states',
      countryid: 0
    }
  }

  @Input() country: any;

  saveState(){
    this.pref.countryid = this.country.id;
    this.saveData(this.pref, true);
  }

  saveData(pref:any = '', reload:boolean = true){
    let p = this.pref
    if(pref){
      p = pref
    }
    this.prefService.save(p)
      .subscribe(data => {this.saveHelper(data, reload)});
  }

  saveHelper(data, reload:boolean = true){
    let m = {
      type: 'danger',
      msg: 'Please enter name of preference '+this.pref.preftype+'.',
    }
    m.msg = data.message;
    if(data.error == 0){
      m.type = 'success';
      this.pref.name = "";
      if(reload){
        this.country.country_state_count++;
      }
    } 
    this.messageService.add(m);
    this.sort = '';
    this.direction = '';
    if(reload){
      this.limitChange();
    }
  }

  limitChange(){
    let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search);
    url += 'countryid=' + this.country.id;
    this.sendRequest(url);
  }

  startSearch() {
    let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search);
    url += 'countryid=' + this.country.id;
    this.sendRequest(url);
  }

  navigateTo(page: number) {
    let url = this.prefService.getSetUrlParams(this.pref.preftype, page, this.limit, this.search);
    url += 'countryid=' + this.country.id;
    this.sendRequest(url);
  }

  jumpTo(e){
    this.navigateTo(e.target.value);
  }

  sortHow(col: string){
    if(this.sort == col){
      if(this.direction == 'asc'){
        this.direction = 'desc';
      } else {
        this.direction = 'asc';
      }
    } else {
      this.sort = col;
    }
    let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search, this.sort, this.direction);
    url += 'countryid=' + this.country.id;
    this.sendRequest(url);
  }

    sendRequest(url: string){
    this.prefService.getData(url, this.pref.preftype)
        .subscribe(data => {this.prefService.getHelper(data, this.pref.preftype)});
  }
  toEditable(elId: number){
    let eId = this.pref.preftype + '_' + elId;
    document.getElementById(eId).focus();
  }

  saveEdit(e, pref:any){
    e.preventDefault();

    let text = e.target.textContent;
    if(text.length){
      pref.name = text;
      pref['preftype'] = this.pref.preftype;
      pref['countryid'] = this.country.id;
      
      this.saveData(pref, false);
    } else {
      let m = {
        type: 'danger',
        msg: 'Please enter name of preference '+this.pref.preftype+'.',
      }
      e.target.text = pref.name;
      this.messageService.add(m);
    }
  }
}
