import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 

/* Library */
import { environment } from './../lib/environment';
import { Urls } from './../lib/urls';


@Injectable({
  providedIn: 'root'
})
export class ImportCsvService {

  public httpOptions = this.env.getHttpOptions();
  public csvFile: any;

  constructor(
    private http:HttpClient,
    private env: environment
  ) { }

  public saveCSVFileData(type: any = '' , event_id: any = ''){

    const formData = new FormData();
    formData.append('csv_file',this.csvFile[0]);
    formData.append('event_id', event_id);
    formData.append('type', type);
    let url = this.env.getUrl(Urls.api_csv_savedata);

    return this.http.post<any>(url, formData ,this.httpOptions);
  }

  public downloadCsvTemplate(type: any = '' , event_id: any = ''){
    let data = {
      'type': type,
      'event_id': event_id
    }

    let url = this.env.getUrl(Urls.api_csv_download);
    url += this.env.createUrlParam(data);

    return this.http.get<any>(url, this.httpOptions);
  }
}
