import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PrefTabNetworkComponent } from 'src/app/modules/preferences/pref-tab-network/pref-tab-network.component';
import { PrefTabServicesComponent } from 'src/app/modules/preferences/pref-tab-services/pref-tab-services.component';
import { PrefTabSpecializationsComponent } from 'src/app/modules/preferences/pref-tab-specializations/pref-tab-specializations.component';
import { PrefTabCountriesComponent } from 'src/app/modules/preferences/pref-tab-countries/pref-tab-countries.component';
import { PrefTabSectorComponent } from 'src/app/modules/preferences/pref-tab-sector/pref-tab-sector.component';
import { PrefTabSoftwaresComponent } from 'src/app/modules/preferences/pref-tab-softwares/pref-tab-softwares.component';
import { FormAddEditComponent } from 'src/app/modules/delegate/form/form-add-edit/form-add-edit.component';

@Component({
  selector: 'app-pref-tab',
  templateUrl: './pref-tab.component.html',
  styleUrls: ['./pref-tab.component.scss'],
})
export class PrefTabComponent implements OnInit {

  private prefLink = {};

  @ViewChild(PrefTabNetworkComponent) networks:PrefTabNetworkComponent;
  @ViewChild(PrefTabServicesComponent) services:PrefTabServicesComponent;
  @ViewChild(PrefTabSpecializationsComponent) specializations:PrefTabSpecializationsComponent;
  @ViewChild(PrefTabCountriesComponent) countries:PrefTabCountriesComponent;
  @ViewChild(PrefTabSectorComponent) sectors:PrefTabSectorComponent;
  @ViewChild(PrefTabSoftwaresComponent) softwares:PrefTabSoftwaresComponent;
  @ViewChild(FormAddEditComponent) delegateForm:FormAddEditComponent;

  constructor(private titleService: Title) {}

  ngOnInit() {
    /* on page load we have to request for the data of the first tab only */
    this.onSelect('networks');
  }

  /* 
  * this function is triggered only on click of the tab
  * in this case it will help the server to load only the specific tab
  * 
  */
  onSelect(type: string, isTabClicked:boolean = false){
    if(isTabClicked){
      this[type].startSearch();
    } else {
      this[type].navigateTo(1, type);
    }

    let firstChar = type.substring( 0, 1 ); /* == the first character of the string */
    let upper = firstChar.toUpperCase();
    let tail = type.substring( 1 ); /* == all chararcters after the first character */
    type = upper + tail; /* concat the first  */

    this.titleService.setTitle(type + " Preference");
  }
}
