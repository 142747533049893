import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-delegate-add-edit',
  templateUrl: './delegate-add-edit.component.html',
  styleUrls: ['./delegate-add-edit.component.scss']
})
export class DelegateAddEditComponent implements OnInit {

	user_id: any = null;
	send_credentials: boolean = false;
	onClose: any;
	redirectUrl: string;

	constructor(
		private router: Router,
	) { }

	ngOnInit() {
	}

	closeModal(act: any = null){
		let info = {
			action: act,
		}
		this.onClose({ info });
		this.router.navigate([this.redirectUrl]);
	}

	sendCredentials(){
		if(this.send_credentials){
			this.closeModal('send_credentials');
		} else {
			this.closeModal();
		}
	}
}
