import { Component, OnInit, Input } from '@angular/core';
import { Title }     from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/lib/models/user';

import { environment } from 'src/app/lib/environment';
import { MessageService } from 'src/app/services/defaults/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	passType = "password";
	showPassword: boolean = false;

	constructor(
		private env: environment,
		private _user: UserService,
		private messageService: MessageService,
		private titleService: Title,
	) { }

	ngOnInit() {
		this.env.deleteCookie();
		this._user.requireNotLogIn();
		this.user = {
			email: '',
			password: '',
			rememberme: false,
		}
		this.titleService.setTitle("Admin and Event Manager's Login");
	}

	@Input() user: User;

	login(): void {
		this.messageService.clearAll();
		this._user.loginUser(this.user)
			.subscribe(data => {
				this.setAsLogedIN(data); 
			});
	}

	setAsLogedIN(data): any {
		let m = {
			type: 'danger',
			msg: '',
		}

		if(data.error == 0){
			let days = 0;
			if(this.user.rememberme){
				days = 60;
			}
			this.env.setToken(data.token, days);
			localStorage.setItem('user', JSON.stringify(data.data));
			m.type = 'success';
		} else {
			m.type = 'danger';
		}

		m.msg = data.message;
		this.messageService.add(m);
		this._user.requireNotLogIn();
	}

  	showPass(){
		this.passType = "text";
		this.showPassword = true;
  	} 

  	hidePass(){
		this.passType = "password";
		this.showPassword = false;
  	}
}
