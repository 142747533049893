import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-listing-loader',
  templateUrl: './event-listing-loader.component.html',
  styleUrls: ['./event-listing-loader.component.scss']
})
export class EventListingLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
