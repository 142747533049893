import { Component, OnInit, ViewChild, Input  } from '@angular/core';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';

@Component({
  selector: 'app-letterhead',
  templateUrl: './letterhead.component.html',
  styleUrls: ['./letterhead.component.scss']
})
export class LetterheadComponent implements OnInit {

  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  
  @Input() croppedImageEventLetterhead: any;
  @Input() isEventLetterheadImageLoaded:any;
  @Input() isEventLetterheadImageCropped:any;

  imageChangedEventLetterhead: any = '';
  

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventFormData = this.eventService.eventFullDetails;
    this.croppedImageEventLetterhead = this.eventFormData.letterhead_image_src;

    if(this.eventFormData.image_letterhead !== "" && this.eventFormData.image_letterhead !== "isRemoved"){
      this.isEventLetterheadImageCropped =  true;
      this.isEventLetterheadImageLoaded = true;
    }
  }

  /* ======= event letterhead =========== */
  fileChangeEventLetterhead(event: any): void {
    this.isEventLetterheadImageLoaded = false;
    this.isEventLetterheadImageCropped = false;
    this.imageChangedEventLetterhead = event;
  }
  imageEventLetterheadCropped(event: ImageCroppedEvent) {
      this.croppedImageEventLetterhead = event.base64;
      this.isEventLetterheadImageCropped =  true;
      this.eventService.setEventLetterheadImage(event);
  }
  imageEventLetterheadLoaded() {
    this.isEventLetterheadImageLoaded = true;
      // show cropper
  }
  cropEventLetterhead() {
    this.imageCropper.crop();
  }
  cropperEventLetterheadReady() {
      // cropper ready
  }
  loadEventLetterheadImageFailed() {
      // show message
  }

  removeImage(){
    this.imageChangedEventLetterhead = '';
    this.croppedImageEventLetterhead = '';
    this.isEventLetterheadImageLoaded = false;
    this.isEventLetterheadImageCropped = false;
    this.eventService.setEventLetterheadImage('isRemoved');
  }

}
