import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-eventmanager',
  templateUrl: './profile-eventmanager.component.html',
  styleUrls: ['./profile-eventmanager.component.scss']
})
export class ProfileEventmanagerComponent implements OnInit {

	onClose: any;
  public event_id = 0;
  public eventManager = null;

  constructor() { }

  ngOnInit() {
  }

	closeModal(action){
		this.onClose(action);
  }
}
