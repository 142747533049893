import { Component, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';

import { UserService } from 'src/app/services/user.service';
  
@Component({
  selector: 'app-event-scheduler',
  templateUrl: './event-scheduler.component.html',
  styleUrls: ['./event-scheduler.component.scss']
})
export class EventSchedulerComponent implements OnInit {

  constructor(
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Add Event Schedules");
  }

}
