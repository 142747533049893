import { Component, Input, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';


@Component({
  selector: 'app-delegate-list',
  templateUrl: './delegate-list.component.html',
  styleUrls: ['./delegate-list.component.scss']
})
export class DelegateListComponent implements OnInit {

  @Input() delegates: any;
  @Input() total_delegates: any = 0;
  @Input() filter_search: any = false;

  protected event_id : any;
  
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private eventService: EventService
  ) { }

  ngOnInit() {
    //this.eventFormData = this.eventService.eventFullDetails;
    this._route.paramMap.subscribe( url_param =>{
        this.event_id = + url_param.get('event_id');
      }
    );
  }

}
