import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { PrefService } from 'src/app/services/preferences/pref.service';
import { EventService } from 'src/app/services/event/event.service';

import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'src/app/services/defaults/message.service';
@Component({
  selector: 'app-company-list-virtual',
  templateUrl: './company-list-virtual.component.html',
  styleUrls: ['./company-list-virtual.component.scss']
})
export class CompanyListVirtualComponent implements OnInit {

  subscribeSearch = new Subject<string>();
  autoCompleteCountry = new Subject<string>();
  autoCompleteSectors = new Subject<string>();
  autoCompleteNetworks = new Subject<string>();

  protected event_id = 0;

  country_limit = 239;
  protected limit = 9;
  protected order = 'asc';
  protected search = '';
  protected page = 1;
  protected sort = 'id';

  keyword_country = '';
  keyword_sector = '';
  keyword_network = '';

  filter_country = '';
  filter_network = '';
  filter_sector = '';
  filter_search = false;

  showCountry = false;
  showNetwork = false;
  showSector = false;

	focusedField = {
		country: false,
		network: false,
		sector: false,
  };
  
  addCompanyRouterLink = '';

  /* print config */
  protected printConfig: any = {
    'columnToShow': [
          {title: "Company Name",dataKey: "name"},
          {title: "Country",dataKey: "pref_country_nicename"},
          {title: "Sector",dataKey: "pref_sector_name"},
          {title: "Network",dataKey: "pref_network_name"},
          {title: "Membership Status",dataKey: "membershipstatus"}
        ]
  }

  constructor(
    private _user: UserService,
    public companyService : CompanyService,
    public prefService: PrefService,
    private router: Router,
    private eventService: EventService,
    private _route: ActivatedRoute,
    protected messageService: MessageService,
    private _clipboardService: ClipboardService
  ) { 
    this._user.requireLogIn();

    this.autoCompleteCountry.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        if(this.keyword_country == ''){
          this.filter_country = '';
          this.getCompanyList();
        }
        this.getCountries();
      });

    this.autoCompleteNetworks.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(value => {
        if(this.keyword_network == ''){
          this.filter_network = '';
          this.getCompanyList();
        }
        this.getNetworks();

      });

    this.autoCompleteSectors.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => {
        if(this.keyword_sector == ''){
          this.filter_sector = '';
          this.getCompanyList();
        }
        this.getSectors();
      });

    this.subscribeSearch.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.filter_search = true;
        this.getCompanyList();
      });
  }
  
  ngOnInit() {

    this._route.paramMap.subscribe( url_param =>{
      this.event_id = parseInt(url_param.get('event_id'));
      if(url_param.get('event_id')){
        this.addCompanyRouterLink = '/admin/dashboard/company/add/'+url_param.get('event_id').toString();
      }else{
        this.addCompanyRouterLink = '/admin/company/add';
      }
    });

    this.getCompanyList();

    // get countries
    this.getCountries();

    // get network
    this.getNetworks();

    // get sector
    this.getSectors();
  }

  getCountries(){
    let url = this.prefService.getSetUrlParams('countries', 1, this.country_limit, this.keyword_country, 'name', 'asc');
    this.sendRequest(url, 'countries');
  }
  
  getNetworks(){
    let url = this.prefService.getSetUrlParams('networks', 1, this.limit, this.keyword_network);
    this.sendRequest(url, 'networks');
  }

  getSectors(){
    let url = this.prefService.getSetUrlParams('sectors', 1, this.limit, this.keyword_sector);
    this.sendRequest(url, 'sectors');
  }

  sendRequest(url: string, type: string){
    /* console.log('url',url); */
    this.prefService.getData(url, type)
        .subscribe(data => {this.prefService.getHelper(data, type)
        });
  }

  focusInField(fieldname: string){
    let a = this;
    if(fieldname == 'country'){
      a.showCountry = true;
    }else if(fieldname == 'network'){
      a.showNetwork = true;
    }else if(fieldname == 'sector'){
      a.showSector = true;
    }
  }
  
  focusOutField(fieldname: string, controlName){
		this.focusedField[controlName] = true;
    let a = this;
    setTimeout(function(){
      if(fieldname == 'country'){
        a.showCountry = false;
        this.keyword_province = '';
        this.filter_province = '';
      }else if (fieldname == 'network'){
        a.showNetwork = false;
        this.keyword_network = '';
        this.filter_network = '';
      }else if (fieldname == 'sector'){
        a.showSector = false;
        this.keyword_sector = '';
        this.filter_sector = '';
      }
    }, 200);
  }

  setValue(displayName, value, variableName){
    if(variableName == 'country'){
      this.keyword_country = displayName;
      this.filter_country = value;
    }else if (variableName == 'network'){
      this.keyword_network = displayName;
      this.filter_network = value;
    }else if (variableName == 'sector'){
      this.keyword_sector = displayName;
      this.filter_sector= value;
    }
    this.filter_search = true;

    this.getCompanyList();
  }

  getCompanyList(){
    let thisData = {
      search: this.search,
      limit: this.limit,
      order: this.order,
      page: this.page,
      sort: this.sort,
      filter_country: this.filter_country,
      filter_network: this.filter_network,
      filter_sector: this.filter_sector,
      event_id: this.event_id
    }

    this.companyService.getAll(thisData);
  }

  searchList(){
    this.filter_search = true;
    this.getCompanyList();
  }
  
  navigateTo(page: number) {
    this.page = page;
    this.getCompanyList();
	}

	jumpTo(e){
		this.navigateTo(e.target.value);
  }
  
  limitChange(){
    this.page = 1;
    this.getCompanyList();
  }

  sortChange(){
    this.getCompanyList();
  }

  clearFilters(){
    this.filter_search = false;
    this.filter_country = '';
    this.filter_network = '';
    this.filter_sector = '';
    this.keyword_country = '';
    this.keyword_sector = '';
    this.keyword_network = '';
    this.search = '';
    this.getCompanyList();
  }

  copy(text: string){
    this._clipboardService.copyFromContent(text)
  }

  copyClipboard(e){
    console.log('dsada',e);

    let m = {
      type: 'success',
      msg: 'Copy to Clipboard',
    }
    this.messageService.add(m);
  }

}
