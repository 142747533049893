import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

/* services */
import { EventService } from 'src/app/services/event/event.service';
/* models */
import { Event } from 'src/app/lib/models/events/event';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() eventFormData: Event;
  @Input() isEditing: boolean = false;
  @Input() setMeeting : Event ;
  @Input() croppedImageEventBanner:any = "";
  @Input() isEventBannerImageLoaded:any = false;
  @Input() isEventBannerImageCropped:any = false;

  /* ======= event banner =========== */
  imageChangedEventBanner: any = '';
  

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventFormData = this.eventService.eventFullDetails;
    this.croppedImageEventBanner = this.eventFormData.banner_image_src;
    console.log(this.eventFormData.image_banner);
    if(this.eventFormData.image_banner !== "" && this.eventFormData.image_banner !== "isRemoved"){
      this.isEventBannerImageCropped =  true;
      this.isEventBannerImageLoaded = true;
    }
  }

  /* ======= event banner =========== */
  fileChangeEventBanner(event: any): void {
    this.isEventBannerImageLoaded = false;
    this.isEventBannerImageCropped = false;
    this.imageChangedEventBanner = event;
  }
  imageEventBannerCropped(event: ImageCroppedEvent) {
    this.croppedImageEventBanner = event.base64;
    this.isEventBannerImageCropped =  true;

    this.eventService.setEventBannerImage(event);
  }
  imageEventBannerLoaded() {
    this.isEventBannerImageLoaded = true;
      // show cropper
  }
  cropEventBanner(event: ImageCroppedEvent) {
    this.imageCropper.crop();
  }
  cropperEventBannerReady() {
      // cropper ready
  }
  loadEventBannerImageFailed() {
      // show message
  }

  removeImage(){
    this.isEventBannerImageLoaded = false;
    this.isEventBannerImageCropped = false;
    this.croppedImageEventBanner = "";
    this.imageChangedEventBanner = null;
    this.eventService.setEventBannerImage('isRemoved');
  }

}
