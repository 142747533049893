import { Component, OnInit, Input } from '@angular/core';

import { PrefTabSoftwaresComponent } from './../../../preferences/pref-tab-softwares/pref-tab-softwares.component';
import { Delegatemodel } from 'src/app/lib/models/delegate'

@Component({
  selector: 'app-delegate-pref-softwares',
  templateUrl: './delegate-pref-softwares.component.html',
  styleUrls: ['./delegate-pref-softwares.component.scss']
})
export class DelegatePrefSoftwaresComponent extends PrefTabSoftwaresComponent implements OnInit {

	_prefs = {};
	prefModel: any = {};

	protected showinputtext : boolean = false;

	ngOnInit() {
		this.pref = {
			id: 0,
			name: '',
			preftype: 'softwares'
		}
		this.setData();
	}
	/*
	*	this will get data from the server
	*	and save in into variables
	*/
	setData(){
		this.limit = 45;
		this.sort = 'name';
		this.direction = 'asc';

		let url = this.prefService.getSetUrlParams(
			this.pref.preftype, 1, this.limit, this.search, this.sort, this.direction
		);
		this.prefService.getData(url, this.pref.preftype)
	  		.subscribe(data => {
	  			this.prefService.getHelper(data, this.pref.preftype),
	  			this.setDataHelper(data)
	  		});
	}

	/*
	*	search for the preference
	*/
	_startSearch(e) {
		e.preventDefault();
	    let url = this.prefService.getSetUrlParams(this.pref.preftype, 1, this.limit, this.search);
	    this.prefService.getData(url, this.pref.preftype)
	  		.subscribe(data => {
	  			this.prefService.getHelper(data, this.pref.preftype),
	  			this.setDataHelper(data)
	  		});
  	}

  	/*
	*	clear all selected value in preference
	*	this is based on the curent step
  	*/
  	clearSelected(){
  		let keys = Object.keys(this.prefModel);
  		for (var i = keys.length - 1; i >= 0; i--) {
  			let str = keys[i];
  			let res = str.split("_");
  			if(res.length == 2){
  				let id = res[1];
				let key = 'softwares'+id;
  				let specialization = this.del.pref_software_ids[key];
  				if(typeof specialization != 'undefined'){
  					delete this.del.pref_software_ids[key];
  					this.prefModel[str] = false;
  				}
  			}
  		}
  		if(this.del){
  			this.del.software_other_is_selected = false;
  		}
  	}

  	/*
	*	since we to need to set data per collumn
	*	this will re-arrange the sorted array
  	*/
	setDataHelper(data) {
		let d = data.data.datas;
		let perColumn = Math.ceil(d.length / 3);

		const newData = [];
		let colData = [];
		let startCount = 0;

		for (var i = 0; i <= d.length - 1; i++) {
			startCount++;

			colData.push(d[i]);

			let pref = 'pref_' + d[i]['id'];
			if(typeof this.prefModel[pref] == 'undefined'){
				this.prefModel[pref] = false;
			}

			if(startCount == perColumn || (d.length - 1) == i){
				newData.push(colData);
				colData = [];
				startCount = 0;
			}
		}
		this._prefs = newData;
	}

	/*
	*	this will set the selected preference on variable
	*	so that we can fetch it later on save
	*/
	onSelect(e, id: number){
		let key = 'softwares'+id;
		if(e.target.checked){
			this.del.pref_software_ids[key] = id;
		} else {
			delete this.del.pref_software_ids[key];
		}
	}

	showInput(e){
		if(e.target.checked){
			this.showinputtext = true;
		} else {
			this.showinputtext = false;
		}
	}

	@Input() del: Delegatemodel;
	@Input() step5haserror: boolean;
	@Input() pageStep: number;
}
