import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-event-profile',
  templateUrl: './event-profile.component.html',
  styleUrls: ['./event-profile.component.scss'],
  providers: [DatePipe]
})
export class EventProfileComponent implements OnInit {

  currentDate = new Date();
  date = '';

  constructor(
    protected eventService : EventService,
    private titleService: Title,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
     console.log(this.eventService.eventFormData) 
    this.titleService.setTitle("Event Profile");
    this.date = this.datePipe.transform(new Date(), 'MM/dd/yyyy');

  }

}