import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http'; 

import { environment } from './../../lib/environment';
import { Urls } from './../../lib/urls';

//set http header
const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class NoshowService {

  public total_noshow: number = 0;
  public total_pending: number = 0;
  public noShowReport : any;
  public noShowDelegate : any;
  public dataIsLoaded = false;
  public dataIsLoaded2 = false;

  public noshow_status = {
    pending : 1,
    approved : 2,
    not_approved : 3,
  }

  constructor(
    protected http:HttpClient,
    protected env: environment
  ) { 
    
  }

  getNoShowReport(params){
    this.getNoShowReportAPI(params).subscribe(
      response => {
        this.noShowReport = response;
        this.dataIsLoaded = true;
				this.total_noshow = this.noShowReport.data.noshow_stats.pending + this.noShowReport.data.noshow_stats.verified + this.noShowReport.data.noshow_stats.not_approved;
			}
		);
  }
  
  noShowDelegates(param){
    this.dataIsLoaded2 = false;
    this.getNoShowDelegates(param).subscribe(
      response => {
        this.noShowDelegate = response;
        this.dataIsLoaded2 = true;
      }
    );
  }

  getNoShowReportAPI(data):Observable<any>{
    let url = this.env.getUrl(Urls.api_noshowreport_get);
    url += this.env.createUrlParam(data);
    
    return this.http.get(url, this.env.getHttpOptions());
  }

  getNoShowDelegates(data):Observable<any>{
    let url = this.env.getUrl(Urls.api_noshowdelegates_get);
    url += this.env.createUrlParam(data);
    
    return this.http.get(url, this.env.getHttpOptions());
  }

  getNoShowReportByDelegates(data):Observable<any>{
    let url = this.env.getUrl(Urls.api_noshowreportbydelegates_get);
    url += this.env.createUrlParam(data);
    
    return this.http.get(url, this.env.getHttpOptions());
  }


  enableDisplayNoshowDelegate(data):Observable<any>{
    let url = this.env.getUrl(Urls.api_noshowdelegate_display);
    
    const formData = new FormData();
    for (let propt in data) {
      formData.append(propt,data[propt]);
    }
    return this.http.post(url, formData, this.env.getHttpOptions());
  }

  disapproveNoShowReport(report_id){
    let url = this.env.getUrl(Urls.api_noshowreport_disapprove);
    let formData = new FormData();
		formData.append('report_id', report_id);

    return this.http.post(url,formData, this.env.getHttpOptions());
  }
  approveNoShowReport(report_id){
    let url = this.env.getUrl(Urls.api_noshowreport_approve);
    let formData = new FormData();
		formData.append('report_id', report_id);

    return this.http.post(url,formData, this.env.getHttpOptions());
  }
}
