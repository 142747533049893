import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delegate-profile',
  templateUrl: './delegate-profile.component.html',
  styleUrls: ['./delegate-profile.component.scss']
})
export class DelegateProfileComponent implements OnInit {
  
  event_id = 0;

  constructor(
    private _user: UserService,
    protected location : Location,
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
  }

}