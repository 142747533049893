import { Component, OnInit, TemplateRef } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-manager-dashboard',
  templateUrl: './event-manager-dashboard.component.html',
  styleUrls: ['./event-manager-dashboard.component.scss']
})
export class EventManagerDashboardComponent implements OnInit {

  constructor(
    private _user: UserService,
    private titleService: Title
  ) { 
    this._user.requireLogIn();
  }

  ngOnInit() {
    this.titleService.setTitle("Event Managers");
  }
}


