import { Component, OnInit, Input, Output ,EventEmitter, ViewChild } from '@angular/core';

import { ImportCsvService } from 'src/app/services/import-csv.service';

@Component({
  selector: 'app-import-csv',
  templateUrl: './import-csv.component.html',
  styleUrls: ['./import-csv.component.scss']
})
export class ImportCsvComponent implements OnInit {

  @ViewChild('fileInput') fileInput: any;
 /*  @Output() setFile : EventEmitter<any> = new EventEmitter(); */
  @Output() saveCsvData : EventEmitter<any> = new EventEmitter();
  @Output() downloadCsvTemplate: EventEmitter<any> = new EventEmitter();
  
  @Input() isDisplayPopup = false;
  @Input() fileIsLoaded = false;
  @Input() withShadow = false;

  protected fileName = '';
  protected hasError = false;
  protected e = {
    'error' : '',
  }

  @Input() title: any;
  constructor(
    protected importCsv: ImportCsvService,
  ) { }

  ngOnInit() {
    this.isDisplayPopup = false;
    this.fileIsLoaded = false;
  }

 /*  showPopup(){
    if(this.isDisplayPopup){
      this.isDisplayPopup = false;
    }else{
      this.isDisplayPopup = true;
    }
  } */

  protected handleFileInput(file){
    /* console.log(file); */
    if(file){
      let ext = this.getExtension(file[0].name)
      if(ext == 'csv'){
        this.fileName = file[0].name;
        this.fileIsLoaded = true;
        this.setFile(file);
        this.hasError = false;
      }else{
        this.fileName = '';
        this.fileIsLoaded = false;
        this.hasError = true;
        this.e.error = 'File type is not supported.'
      }
    }
  }

  protected getExtension(name) {

    const lastDot = name.lastIndexOf('.');
  
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
  
    return ext;
  }

  protected saveData(){
    this.saveCsvData.emit();
    
    this.isDisplayPopup = false;
    this.fileIsLoaded = false;
    
    this.fileName = '';
    this.setFile('');
    this.fileInput.nativeElement.value = '';
  }

  protected setFile(file){
    if(this.importCsv.csvFile){
      this.importCsv.csvFile = '';
    }
    this.importCsv.csvFile = file;
  }

  downloadTemplate(){
    this.downloadCsvTemplate.emit();
  }

  downloadFile(data,file_name){
    let blob = new Blob([data], {type: 'text/csv;charset=UTF-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", file_name);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  

  


}
