import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  list: any = [];

  add(message: any) {
    this.list.push(message);

    let a = this;
    setTimeout(function(){
      a.clear(0)
    }, 8000);
  }
 
  clear(el) {
    this.list.splice(el, 1);
  }

  clearAll() {
    this.list = [];
  }
}
