import { Component, OnInit } from '@angular/core';
import { SpeakersService } from 'src/app/services/event/speakers.service';
import { AgendaService } from 'src/app/services/event/agenda.service';
import { MessageService } from 'src/app/services/defaults/message.service';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-add-edit-agenda',
  templateUrl: './add-edit-agenda.component.html',
  styleUrls: ['./add-edit-agenda.component.scss']
})
export class AddEditAgendaComponent implements OnInit {

	onClose: any;
	agenda: any;
	day: any;

	/* conatains all available time within the event day */
	timeStart: any = [];

	/* conatains all available end time within the selected timeStart */
	end_time: any = [];

	speakers: any = null;

	saveClicked: boolean = false;


	constructor(
		protected speakersService: SpeakersService,
    	protected messageService: MessageService,
		protected agendaService: AgendaService,
		protected eventService: EventService
	) { }

	ngOnInit() {
		this.getSpeaker();
		this.setSartTime();
	}

	closeModal(){
		this.onClose({});
	}

	/*
	*	get all the speakers for the specif event
	*/
	getSpeaker(){
		this.speakersService.getSpeaker(this.agenda.event_id)
			.subscribe(data => {
				this.speakers = data.data;
			});
	}

	/*
	*	set available time, get it from the API
	*	and set it as a variable
	*/
	setSartTime(){
		this.agendaService.getAvailableTime(this.agenda.event_id, this.day.milisec)
			.subscribe(data => {
				if(data.error == 0){
					this.timeStart = data.data;
				}
			});
	}

	/*
	*	set the end time call from api
	*/
	updateEndTime(e){
		this.end_time = [];
		let startTimeVal = e;
		startTimeVal = e.target.value;
		this.agenda.start_time = startTimeVal;

		this.agendaService.getAvailableTime(this.agenda.event_id, this.day.milisec, 'end', startTimeVal)
			.subscribe(data => {
				if(data.error == 0){
					this.end_time = data.data;
				}
			});

	}

	/*
	*	update the time ending of the agenda
	*/
	updateAgendEndTime(e){
		this.agenda.end_time = e.target.value;
	}

	title_error = '';

	fields = {
		focusedTitle: false,
		focusedLocation: false,
		focusedDescription: false,
	}
	/*focusField(field){
		this.fields[field] = true;
	}*/

	validateFields(){
		this.saveClicked = true;
		this.fields = {
			focusedTitle: true,
			focusedLocation: true,
			focusedDescription: true,
		}

		if(
			this.agenda.start_time && 
			this.agenda.end_time && 
			!this.vTitle() &&
			!this.vLocDesc('location') && 
			!this.vLocDesc('description')
		){
			return true;
		} else {
			return false;
		}
	}
	vTitle(){
		let str = this.agenda.title.length;
		let error = true;
		if(str){
			if(str >= 1 && str <= 255){
				this.title_error = 'No error.';
				error = false;
			} else {
				this.title_error = 'Please add title not more than 255 characters long.';
			}
		} else {
			this.title_error = 'Please enter agenda title.';
		}
		return error;
	}

	vLocDesc(input: any = 'location'){
		let str = this.agenda[input].length;
		let error = true;
		if(str){
			if(str >= 1 && str <= 255){
				error = false;
			}
		} else {
			return false;
		}
		return error;
	}
	
	get validateTitle(){ return this.vTitle(); }
	get validateLoc(){  return this.vLocDesc('location'); }
	get validateDesc(){ return this.vLocDesc('description'); }

	/*
	*	save or update an agenda 
	*/
	saveAgenda(){
		if(this.validateFields()){
			let m = {
				type: 'danger',
				msg: '',
			}

			this.agendaService.saveAgenda(this.agenda)
				.subscribe(data => {
					if(data.error == 0){
						m.type = 'success';
					}
					m.msg = data.message;
					this.messageService.add(m);
					this.onClose({});
				});
		}
	}

	convertTo24Hrs(time){
		let hours = Number(time.match(/^(\d+)/)[1]);
		let minutes = Number(time.match(/:(\d+)/)[1]);
		let AMPM = time.match(/\s(.*)$/)[1];
		if(AMPM == "PM" && hours<12) hours = hours+12;
		if(AMPM == "AM" && hours==12) hours = hours-12;
		let sHours = hours.toString();
		let sMinutes = minutes.toString();
		if(hours<10) sHours = "0" + sHours;
		if(minutes<10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	}
}
