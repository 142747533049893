import { Component, OnInit, Input } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-faqcategory',
  templateUrl: './edit-faqcategory.component.html',
  styleUrls: ['./edit-faqcategory.component.scss']
})
export class EditFaqcategoryComponent implements OnInit {

  onClose: any;
  onSaveChanges: any;
  @Input() faqsCategory: any = null;

  private faqForm: FormGroup;
  

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.faqForm = this.formBuilder.group({
      category_name: [this.faqsCategory ? this.faqsCategory.name : ''],
    }, { });
  }

  closeModal(){
		this.onClose({});
  }

  saveChanges(id){
    this.onSaveChanges(id,this.faqForm.value.category_name);
  }

}
