import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-company-profile-dashboard-virtual',
  templateUrl: './company-profile-dashboard-virtual.component.html',
  styleUrls: ['./company-profile-dashboard-virtual.component.scss']
})
export class CompanyProfileDashboardVirtualComponent implements OnInit {

  protected event_id = 0;
  protected company_id = 0;
  
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    public _user: UserService
  ) { }

  ngOnInit() {

    this._user.requireLogIn();
    this._route.paramMap.subscribe( url_param =>{
        this.company_id = + url_param.get('company_id');
        this.event_id = + url_param.get('event_id');
      }
    );
  }

}
