import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { Pref } from './../../../lib/models/preferences/pref';

import { MessageService } from '../../../services/defaults/message.service';
import { PrefService } from '../../../services/preferences/pref.service';

import { Preferences } from '../preferences';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pref-tab-countries',
  templateUrl: './pref-tab-countries.component.html',
  styleUrls: ['./pref-tab-countries.component.scss']
})
export class PrefTabCountriesComponent extends Preferences implements OnInit {

  modalRef: BsModalRef;
  country: any;

  constructor(
  	protected messageService: MessageService,
  	protected prefService: PrefService,
    protected modalService: BsModalService,
  ) { 
    super(messageService, prefService, modalService);
  }

  ngOnInit() {
  	this.pref = {
  		id: 0,
      name: '',
  		preftype: 'countries'
    }
    this.country = {
      id: 0
    };
  }

  @Input() pref: Pref;

  openModal(template: TemplateRef<any>, country){
    this.country = country;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'country-states' })
    );

    let url = this.prefService.getSetUrlParams('states', 0, 0, '', 'name', 'asc');
    url += 'countryid=' + country.id;

    this.modalService.onShow.subscribe(
      this.prefService.getData(url, 'states')
      .subscribe(data => {this.prefService.getHelper(data, 'states')})
    )
  }
}
